import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  text: {
    fontSize: '0.9rem',
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};
export default function App(props) {
  const { data, setSelectedValue, selectedValue } = props;
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const isAllSelected = data.length > 0 && selected.length === data.length;

  useEffect(() => {
    setSelectedValue([]);
    setSelected([]);
  }, []);

  useEffect(() => {
    setSelectedValue(selectedValue);
    setSelected(selectedValue);
  }, [selectedValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === data.length ? [] : data);
      return;
    }
    setSelected(value);
    setSelectedValue(value);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
        className={classes.text}
      >
        {/* <MenuItem
                    value="all"
                    classes={{
                        root: isAllSelected ? classes.selectedAll : ""
                    }}
                >
                    <ListItemIcon>
                        <Checkbox
                            classes={{ indeterminate: classes.indeterminateColor }}
                            checked={isAllSelected}
                            indeterminate={
                                selected.length > 0 && selected.length < data.length
                            }
                        />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                    />
                </MenuItem> */}
        {data != null &&
          data.map((option) => (
            <MenuItem key={option.id} value={option.name}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(option.name) > -1} />
              </ListItemIcon>
              <ListItemText className={classes.text} primary={option.name} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
