import axios from '../../axios/axios-default';

export function getAvgTimeBarChart(startDate, endDate, parameter) {
  return axios.get(
    'getAvgTimeBarChart?startDate=' +
      startDate +
      '&endDate=' +
      endDate +
      '&parameter=' +
      parameter
  );
}
