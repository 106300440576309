import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
import PermIdentity from '@material-ui/icons/PermIdentityOutlined';
// core components

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem.js';
import Button from '../../components/CustomButtons/Button.js';
import Card from '../../components/Card/Card.js';
import CardBody from '../../components/Card/CardBody.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardFooter from '../../components/Card/CardFooter.js';
import CustomInput from '../../components/CustomInput/CustomInput.js';

import styles from 'assets/jss/material-dashboard-react/views/loginPage';
import * as actions from '../../store/actions/index';

import logo from 'assets/img/trackable.png';
import { connect } from 'react-redux';
import Danger from '../../components/Typography/Danger.js';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { isWareHouseUrl } from '../../Util/Util';
import RouterProps from '../../Util/Property/RouterProperties';

const useStyles = makeStyles(styles);

const LoginPage = (props) => {
  const classes = useStyles();
  const { ...rest } = props;

  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');

  // add login form to function state
  const [loginForm, setLoginForm] = useState({
    userName: {
      elType: 'input',
      label: 'username',
      inputProps: {
        type: 'text',
        value: '',
        startAdornment: (
          <InputAdornment position="start">
            <PermIdentity className={classes.inputIconsColor} />
          </InputAdornment>
        ),
        className: classes.loginInput,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      valid: false,
    },
    password: {
      elType: 'input',
      label: 'password',
      placeholder: '',
      inputProps: {
        type: 'password',
        value: '',
        startAdornment: (
          <InputAdornment position="start">
            <Icon className={classes.inputIconsColor}>lock_outline</Icon>
          </InputAdornment>
        ),
        autoComplete: 'off',
        className: classes.loginInput,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
        minLenght: 6,
      },
      valid: false,
    },
  });

  const [isLogingFormValid, setLoginFormValid] = useState(false);

  setTimeout(function() {
    setCardAnimation('');
  }, 700);

  useEffect(() => {
    // localStorage.removeItem('highlightConfiguration');
    localStorage.clear();
  }, []);

  let formElementArray = [];
  for (let key in loginForm) {
    formElementArray.push({
      id: key,
      config: loginForm[key],
    });
  }

  const checkValidity = (value, rules) => {
    let isValid = true;
    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }
    // if(rules.minLenght){
    //   isValid = value.length >= rules.minLenght && isValid;
    // }
    return isValid;
  };

  const onChangeHandeler = (event, elId) => {
    let formData = { ...loginForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = event.target.value;
    elementData.valid = checkValidity(
      inputProps.value,
      elementData.validations
    );
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    let isFormValid = true;
    for (let inputEl in loginForm) {
      isFormValid = loginForm[inputEl].valid && isFormValid;
    }

    setLoginForm(formData);
    setLoginFormValid(isFormValid);
  };

  const onLoginFormSubmit = (event) => {
    event.preventDefault();
    props.onAuth(
      loginForm.userName.inputProps.value,
      loginForm.password.inputProps.value
    );
  };

  let errorMessage = null;
  if (props.error) {
    errorMessage = props.error.message === 'Request failed with status code 401' || props.error.message === 'Request failed with status code 400' ? (
        <Danger>
          <p> User Name or Password is incorrect </p>{' '}
        </Danger>
      ) : (
        <Danger>
          <p> {props.error} </p>{' '}
        </Danger>
      );
  }

  let redirect = null;
  if (props.isAuthenticated) {
    // to redirect to dashboard this need to fixed to redirect to logout page when login again due to
    // session expiration
    redirect = <Redirect to="/"> </Redirect>;
    if (isWareHouseUrl(window.location)) {
      redirect = <Redirect to={RouterProps.mainDomain + '/warehouse'} />;
    }
  }

  let formElement = (
    <React.Fragment>
      <CardBody>
        {redirect}
        {formElementArray.map((element) => {
          return (
            <GridContainer key={element.id}>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText={element.config.label}
                  id={element.id}
                  formControlProps={element.config.formControlProps}
                  inputProps={element.config.inputProps}
                  type={element.config.elType}
                  value={element.config.value}
                  className={element.config.className}
                  changed={(event) => onChangeHandeler(event, element.id)}
                />
              </GridItem>
            </GridContainer>
          );
        })}
        {/* <CustomInput
        labelText="First Name..."
        id="first"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          type: "text",
          endAdornment: (
            <InputAdornment position="end">
              <People className={classes.inputIconsColor} />
            </InputAdornment>
          )
        }}
      />
      <CustomInput
        labelText="Email..."
        id="email"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          type: "email",
          endAdornment: (
            <InputAdornment position="end">
              <Email className={classes.inputIconsColor} />
            </InputAdornment>
          )
        }}
      />
      <CustomInput
        labelText="Password"
        id="pass"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          type: "password",
          endAdornment: (
            <InputAdornment position="end">
              <Icon className={classes.inputIconsColor}>
                lock_outline
              </Icon>
            </InputAdornment>
          ),
          autoComplete: "off"
        }}
      /> */}
        {errorMessage}
      </CardBody>
      <CardFooter className={classes.cardFooter}>
        <Button
          type="submit"
          disabled={!isLogingFormValid}
          className={classes.loginButton}
          simple
          color="primary"
        >
          Login
        </Button>
      </CardFooter>
    </React.Fragment>
  );

  return (
    <div>
      <div className={classes.pageHeader}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <LoadingOverlay
                active={props.isLoading}
                spinner
                text="Authenticating ..."
              >
                <Card
                  style={{ minHeight: '22rem' }}
                  className={classes[cardAnimaton]}
                >
                  <form onSubmit={onLoginFormSubmit} className={classes.form}>
                    <div className={classes.logo}>
                      <img src={logo} alt="Trackable logo" />
                    </div>
                    {/* <div className={classes.socialLine}>
                    <Button
                      justIcon
                      href="#pablo"
                      target="_blank"
                      color="transparent"
                      onClick={e => e.preventDefault()}
                    >
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button
                      justIcon
                      href="#pablo"
                      target="_blank"
                      color="transparent"
                      onClick={e => e.preventDefault()}
                    >
                      <i className={"fab fa-facebook"} />
                    </Button>
                    <Button
                      justIcon
                      href="#pablo"
                      target="_blank"
                      color="transparent"
                      onClick={e => e.preventDefault()}
                    >
                      <i className={"fab fa-google-plus-g"} />
                    </Button>
                  </div> */}
                    {formElement}
                  </form>
                </Card>
              </LoadingOverlay>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token != null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password) => dispatch(actions.auth(username, password)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
