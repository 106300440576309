import React, { Component } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import generalProperties from '../../Util/Property/GeneralProperties';
import claimsSettingsProperties from '../../Util/Property/ClaimsSettingsProperty';
import ClaimsSettingsProperty from '../../Util/Property/ClaimsSettingsProperty';
import { AccountBalanceRounded } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import GenerateClaim from './GenerateClaim';
import _ from 'lodash';
import { notyDefault, notyTypes } from '../../components/Noty/NotyCustom';
import PermissionProperties from '../../Util/Property/PermissionProperties';
import { checkPermissoinListAvailable } from '../../Util/Permission/CheckPermission';
import { buildFilterObject } from '../../Util/Util';
import urlProperties from '../../Util/Property/UrlProperties';
import Spinner from 'components/Spinner/Spinner';
import styles from './ClaimsManagement.module.css';
import LoadingOverlay from 'react-loading-overlay';

class ClaimsManagement extends Component {
  state = {
    isDeleteDialogOpen: false,
    deleteId: null,
    deleteData: null,
    isAddDialogOpen: false,
    generateClaimForm: claimsSettingsProperties.claimGenerateForm,
    permissions: {},
    claimsListLoading: false,
    isLoading: false,
  };

  onExpenseCategoryEditHandler = (id, isEdit) => {
    let permissionAvailable;
    if (isEdit) {
      permissionAvailable = this.state.permissions[
        PermissionProperties.editClaims
      ];
    } else {
      permissionAvailable = this.state.permissions[
        PermissionProperties.viewClaims
      ];
    }
    if (!permissionAvailable) {
      return;
    }
    const viewType = isEdit ? 'edit' : 'view';
    this.props.history.push(`${this.props.match.url}/${id}/${viewType}`);
  };

  deleteExpenseCategoryDialogCloseHandler = () => {
    this.setState({
      isDeleteDialogOpen: false,
      deleteId: null,
      deleteData: null,
    });
  };
  addClaimDialogCloseHandler = () => {
    this.setState({
      isAddDialogOpen: false,
    });
  };
  addClaimDialogConfirmHandler = async () => {
    this.setState({
      isLoading: true,
    });
    if (!this.validateData()) {
      this.setState({
        isLoading: false,
      });
      return;
    }
    const { generateClaimForm } = this.state;
    const data = {
      userId: generateClaimForm.employeeName.inputProps.value,
      startDate: generateClaimForm.startDate.inputProps.value.format(
        'yyyy-MM-DD'
      ),
      endDate: generateClaimForm.endDate.inputProps.value.format('yyyy-MM-DD'),
    };
    await this.props.createClaimReport(data);
    this.setState({
      isLoading: false,
    });
  };

  deleteConfirmHandler = () => {
    const { deleteExpenseRule } = this.props;
    const { deleteId, deleteData } = this.state;
    deleteData['isActive'] = false;
    deleteId && deleteExpenseRule(deleteId, deleteData);

    this.deleteExpenseCategoryDialogCloseHandler();
  };

  deleteExpenseCategoryHandler = (id, deleteData) => {
    this.setState({
      deleteId: id,
      deleteData: deleteData,
    });
    this.deleteExpenseCategoryDialogOpenHandler();
  };
  addNewClaim = () => {
    this.setState({
      isAddDialogOpen: true,
    });
  };

  deleteExpenseCategoryDialogOpenHandler(id) {
    this.setState({
      isDeleteDialogOpen: true,
    });
  }
  componentDidMount() {
    this.props.getUsers();
    let permissionArray = [
      PermissionProperties.addClaims,
      PermissionProperties.viewClaims,
      PermissionProperties.deleteClaims,
      PermissionProperties.editClaims,
    ];

    this.setState({
      permissions: checkPermissoinListAvailable(permissionArray),
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      userList,
      claimsReportCreateSuccess,
      claimsReportCreateError,
      createdClaimReport,
      claimsReportCreateInProgress,
    } = this.props;
    if (userList !== prevProps.userList && userList.length > 0) {
      this.setState({
        generateClaimForm: {
          ...prevState.generateClaimForm,
          employeeName: {
            ...prevState.generateClaimForm.employeeName,
            inputProps: {
              ...prevState.generateClaimForm.employeeName.inputProps,
              options: this.buildUserOptions(),
            },
          },
        },
      });
    }
    if (
      !claimsReportCreateInProgress &&
      claimsReportCreateInProgress !== prevProps.claimsReportCreateInProgress
    ) {
      if (claimsReportCreateSuccess && !_.isEmpty(createdClaimReport)) {
        this.props.history.push(
          `${this.props.match.url}/${createdClaimReport.id}/edit`
        );
      } else {
        const { response = {} } = claimsReportCreateError || {};
        const { data = {} } = response || {};
        const { details = '' } = data || {};
        notyDefault({
          type: notyTypes.ERROR,
          text:
            details || ClaimsSettingsProperty.messages.error.createClaimReport,
        });
      }
    }
  }
  buildUserOptions = () => {
    const { userList } = this.props;
    if (userList.length > 0) {
      return userList.map((user) => {
        return {
          value: user.id,
          displayValue: user.first_name + ' ' + user.last_name,
        };
      });
    }
    return [];
  };
  onChangeHandler = (event, elId) => {
    let formData = { ...this.state.generateClaimForm };
    if (elId !== 'employeeName') {
      formData[elId].inputProps.value = event;
    } else {
      formData[elId].inputProps.value = event.target.value;
    }

    let isFormValid = true;
    for (let key in this.state.generateClaimForm) {
      isFormValid = this.state.generateClaimForm[key].valid && isFormValid;
    }
    this.setState({
      generateClaimForm: formData,
      formIsValid: isFormValid,
    });
  };
  validateData = () => {
    const { generateClaimForm } = this.state;
    const start = generateClaimForm.startDate.inputProps.value;
    const end = generateClaimForm.endDate.inputProps.value;
    if (start.isAfter(end)) {
      notyDefault({
        type: notyTypes.ERROR,
        text: ClaimsSettingsProperty.messages.error.invalidClaimReportDates,
      });
      return false;
    }
    let isValid = true;
    _.forEach(Object.values(generateClaimForm), ({ inputProps }) => {
      if (!inputProps.value) {
        isValid = false;
        notyDefault({
          type: notyTypes.ERROR,
          text: ClaimsSettingsProperty.messages.error.invalidClaimReport,
        });
      }
    });
    return isValid;
  };

  render() {
    const { claimsListLoading } = this.state;
    const { generateClaimForm, permissions } = this.state;
    let formElementArray = [];
    for (let key in generateClaimForm) {
      formElementArray.push({
        id: key,
        config: generateClaimForm[key],
      });
    }
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                {/* expense category delete confirm dialogBox */}

                <Dialog
                  open={this.state.isDeleteDialogOpen}
                  onClose={this.deleteExpenseCategoryDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {
                        claimsSettingsProperties.messages.normal
                          .deleteExpenseRule
                      }
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <div className={'material-grid-wrapper '}>
                      <Button
                        size="sm"
                        variant="contained"
                        onClick={this.deleteExpenseCategoryDialogCloseHandler}
                        color="primary"
                      >
                        {generalProperties.cancel}
                      </Button>
                      <Button
                        size="sm"
                        variant="contained"
                        onClick={this.deleteConfirmHandler}
                        color="primary"
                        autoFocus
                      >
                        {generalProperties.confirm}
                      </Button>
                    </div>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.isAddDialogOpen}
                  onClose={this.addClaimDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={<Spinner />}
                    text="Loading ..."
                  >
                    <DialogContent>
                      <GenerateClaim
                        formElementArray={formElementArray}
                        onChangeHandler={this.onChangeHandler}
                      />
                    </DialogContent>

                    <DialogActions>
                      <div className={'material-grid-wrapper '}>
                        <Button
                          class="generic-button-class div-button-wrapper"
                          size="sm"
                          variant="contained"
                          onClick={this.addClaimDialogCloseHandler}
                          color="primary"
                        >
                          {generalProperties.cancel}
                        </Button>
                        <Button
                          class="generic-button-class div-button-wrapper"
                          size="sm"
                          variant="contained"
                          onClick={this.addClaimDialogConfirmHandler}
                          color="primary"
                          autoFocus
                        >
                          {generalProperties.confirm}
                        </Button>
                      </div>
                    </DialogActions>
                  </LoadingOverlay>
                </Dialog>

                {/* expenses category table content */}
                <div className={'material-grid-wrapper '}>
                  <MaterialTable
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <AccountBalanceRounded />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Claims
                        </Typography>
                      </div>
                    }
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                    tableRef={this.tableRef}
                    columns={[
                      { title: 'Claim ID', field: 'id', width: '120px' },
                      {
                        title: 'Emp ID',
                        field: 'employee_id',
                        width: '120px',
                      },
                      { title: 'Emp Name', field: 'employee_name' },
                      { title: 'Name', field: 'name' },
                      {
                        title: 'Stage',
                        field: 'stage_name',
                        filterField: 'stage_filter',
                        width: '150px',
                      },
                      { title: 'Generated By', field: 'assign_user_name' },
                    ]}
                    // data={data}
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        const isAddPermAvailable =
                          permissions[PermissionProperties.addClaims];
                        this.setState({
                          claimsListLoading: true,
                        });
                        const filterDto = buildFilterObject(query);
                        if (!isAddPermAvailable) {
                          filterDto['employee_id'] = localStorage.getItem(
                            'userId'
                          );
                        }
                        let url =
                          urlProperties.baseUrl +
                          urlProperties.filterClaimReport +
                          '?';
                        url += 'perPage=' + query.pageSize;
                        url += '&page=' + query.page;
                        url +=
                          '&sortColumn=' +
                          (typeof query.orderBy === 'undefined'
                            ? 'name'
                            : query.orderBy.field);
                        url += '&sortOrder=' + query.orderDirection;
                        url += '&search=' + query.search;
                        fetch(url, {
                          headers: {
                            Authorization:
                              'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/json',
                          },
                          method: 'POST',
                          body: JSON.stringify(filterDto),
                        })
                          .then((response) => response.json())
                          .then((result) => {
                            resolve({
                              data: result.data,
                              page: result.page,
                              totalCount: result.total,
                            });
                            this.setState({
                              claimsListLoading: false,
                            });
                          })
                          .catch(() => {
                            this.setState({
                              claimsListLoading: false,
                            });
                          });
                      })
                    }
                    isLoading={claimsListLoading}
                    actions={[
                      (rowData) => ({
                        icon: 'edit',
                        tooltip: 'Edit Claim',
                        iconProps: {
                          className: 'action-buttons edit-button',
                        },
                        onClick: (event, rowData) =>
                          this.onExpenseCategoryEditHandler(rowData.id, true),
                        hidden: !this.state.permissions[
                          PermissionProperties.editClaims
                        ],
                      }),

                      {
                        icon: () => (
                          <Button
                            class="generic-button-class div-button-wrapper"
                            onClick={this.addNewClaim}
                            variant="contained"
                            color="primary"
                          >
                            Generate New Claim
                          </Button>
                        ),
                        tooltip: 'Generate New Claim',
                        isFreeAction: true,
                        hidden: !this.state.permissions[
                          PermissionProperties.addClaims
                        ],
                      },
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: false,
                      search: false,
                      maxBodyHeight: '70vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        backgroundColor: '#e6e4df',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                    onRowClick={(event, rowData, toggleDetailPanel) =>
                      this.onExpenseCategoryEditHandler(rowData.id, true)
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { claimsManagement } = state;
  const {
    users,
    claimsReports,
    claimsReportData,
    claimsReportCreate,
  } = claimsManagement;
  return {
    userList: users.data,
    usersLoading: users.loading,
    claimsReportList: claimsReports.data,
    claimsReportsListLoading: claimsReports.isLoading,
    claimsReportsError: claimsReports.error,
    claimsReportData: claimsReportData.data,
    claimsReportDataLoading: claimsReportData.isLoading,
    claimsReportDataError: claimsReportData.error,
    claimsReportCreateSuccess: claimsReportCreate.success,
    claimsReportCreateError: claimsReportCreate.error,
    claimsReportCreateInProgress: claimsReportCreate.isLoading,
    createdClaimReport: claimsReportCreate.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createClaimReport: (data) => dispatch(actions.createClaimReport(data)),
    updateClaimReport: (data) => dispatch(actions.updateClaimReport(data)),
    getAllClaimReports: () => dispatch(actions.getAllClaimReports()),
    geClaimReportById: (id) => dispatch(actions.geClaimReportById(id)),
    updateClaimReportById: (data) =>
      dispatch(actions.updateClaimReportById(data)),
    generateClaimReport: (uuid) => dispatch(actions.generateClaimReport(uuid)),
    sendClaimReport: (uid, uuid, reportName) =>
      dispatch(actions.sendClaimMessage(uid, uuid, reportName)),
    getUsers: () => dispatch(actions.getUsers()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimsManagement);
