/* eslint-disable no-empty */
import React, { useState, useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone from 'react-dropzone';
import axios from '../../axios/axios-default';
import { notyDefault, notyTypes } from '../../components/Noty/NotyCustom';
import readXlsxFile from 'read-excel-file';
import { Typography } from '@material-ui/core';
import { customerProperties } from 'Util/Property/CustomerPorerties';
import * as moment from 'moment';
import writeXlsxFile from 'write-excel-file';
import UrlProperties from 'Util/Property/UrlProperties';
import LoadingOverlay from 'react-loading-overlay';
import { MdOutlineDoneOutline } from 'react-icons/md';
import { MdClose } from 'react-icons/md';

const validCount = 50;

export default function UploadFiles(props) {
  const {
    open,
    fileUploadClose,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    url,
    uploadLoacation,
    acceptFileType,
  } = props;
  const [file, setFile] = React.useState([]);

  const [error, setError] = React.useState('');
  const [hasError, setHasError] = React.useState(false);
  const [dataArray, setDataArray] = React.useState([]);
  const [newFile, setNewFile] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const baseUrl = UrlProperties.baseUrl.split(':8089')[0];
  const excelColumns = {
    name: 0,
    address: 1,
    district: 2,
    province: 3,
    phone: 4,
    fax: 5,
    email: 6,
    person: 7,
    designation: 8,
    joinDate: 9,
    lat: 10,
    lan: 11,
    category1: 12,
    category2: 13,
    category3: 14,
    tid: 15,
  };

  const symbols = ['#', '\\', '%', '{', '}', ';', '?'];

  const handleClose = () => {
    setFile([]);
    fileUploadClose();
  };

  useEffect(() => {
    setFile([]);
  }, []);

  useEffect(() => {
    if (uploadLoacation === 'customer') {
      readFile();
    }
  }, [file]);

  const readFile = () => {
    setHasError(false);
    readXlsxFile(file[0])
      .then((rows) => {
        setIsLoading(true);
        setDataArray([]);
        var objectArray = [];
        for (let i = 1; i < rows.length; i++) {
          var row = rows[i];
          if (row.length != 16) {
            setError(
              'Invalid Excel Format ( The columns should have name,address,district,province,phone,fax,email,person,tid, designation,join_date,latitude and longitude. )'
            );
            setHasError(true);
            break;
          } else {
            if (isIncompletedRow(row)) {
              setError(
                'Please remove the unused rows ( from ' +
                  (i + 1) +
                  ' to ' +
                  rows.length +
                  ' )'
              );
              setHasError(true);
              break;
            } else {
              if (
                row[excelColumns.name] == null ||
                row[excelColumns.address] == null ||
                row[excelColumns.district] == null ||
                row[excelColumns.province] == null ||
                row[excelColumns.phone] == null
              ) {
                var fieldArray = [];
                var errorText = '';
                if (row[excelColumns.name] == null) {
                  fieldArray.push('name');
                } else {
                  if (row[excelColumns.name].length > 150) {
                    setError(
                      'Name characters length should be less than 150!  \n ( Line : ' +
                        (i + 1) +
                        ' )'
                    );
                    setHasError(true);
                    break;
                  }
                }
                if (row[excelColumns.address] == null) {
                  fieldArray.push('address');
                } else {
                  if (row[excelColumns.address].length > 500) {
                    setError(
                      'Address characters length should be less than 500!  \n ( Line : ' +
                        (i + 1) +
                        ' )'
                    );
                    setHasError(true);
                    break;
                  }
                }
                if (row[excelColumns.district] == null) {
                  fieldArray.push('district');
                } else {
                  if (row[excelColumns.district].length > 65) {
                    setError(
                      'District characters length should be less than 65!  \n ( Line : ' +
                        (i + 1) +
                        ' )'
                    );
                    setHasError(true);
                    break;
                  }
                }
                if (row[excelColumns.province] == null) {
                  fieldArray.push('province');
                } else {
                  if (row[excelColumns.province].length > 65) {
                    setError(
                      'Province characters length should be less than 65!  \n ( Line : ' +
                        (i + 1) +
                        ' )'
                    );
                    setHasError(true);
                    break;
                  }
                }
                if (row[excelColumns.phone] == null) {
                  fieldArray.push('phone');
                } else {
                  if (row[excelColumns.phone].length > 200) {
                    setError(
                      'Phone characters length should be less than 200!  \n ( Line : ' +
                        (i + 1) +
                        ' )'
                    );
                    setHasError(true);
                    break;
                  }
                }

                // if (row[excelColumns.email] == null) {
                //     fieldArray.push("email");
                // }

                if (fieldArray.length == 1) {
                  errorText =
                    'Please include ' +
                    fieldArray[0] +
                    " or If Empty use '-'  ( Line : " +
                    (i + 1) +
                    ' )';
                } else {
                  errorText = 'Please include ';
                  for (let x = 0; x < fieldArray.length; x++) {
                    if (x == 0) {
                      errorText = errorText + fieldArray[x];
                    } else {
                      if (x == fieldArray.length - 1) {
                        errorText = errorText + ' and ' + fieldArray[x];
                      } else {
                        errorText = errorText + ', ' + fieldArray[x];
                      }
                    }
                  }
                  errorText = errorText + ' ! ( Line : ' + (i + 1) + ' )';
                }
                setError(errorText);
                setHasError(true);
                break;
              } else {
                if (
                  (isUpperCase(row[excelColumns.district]) &&
                    isUpperCase(row[excelColumns.province])) ||
                  row[excelColumns.district].includes('_') ||
                  row[excelColumns.province].includes('_')
                ) {
                  if (isValidProvince(row[excelColumns.province])) {
                    if (
                      isValiDistricts(
                        row[excelColumns.province],
                        row[excelColumns.district]
                      )
                    ) {
                      if (row[excelColumns.email] == null) {
                        setError(
                          "If the email is empty, use ' - ' !  \n ( Line : " +
                            (i + 1) +
                            ' )'
                        );
                        setHasError(true);
                        break;
                      } else if (row[excelColumns.email].length > 300) {
                        setError(
                          'Email characters length should be less than 300!  \n ( Line : ' +
                            (i + 1) +
                            ' )'
                        );
                        setHasError(true);
                        break;
                      } else {
                        if (row[excelColumns.email] == '-') {
                        } else {
                          if (isValidEmail(row[excelColumns.email])) {
                          } else {
                            setError(
                              'Invalid Email !  \n ( Line : ' + (i + 1) + ' )'
                            );
                            setHasError(true);
                            break;
                          }
                        }
                      }

                      if (row[excelColumns.fax] == null) {
                        setError(
                          "If the fax is empty, use ' - ' !  \n ( Line : " +
                            (i + 1) +
                            ' )'
                        );
                        setHasError(true);
                        break;
                      } else {
                        if (row[excelColumns.fax].length > 200) {
                          setError(
                            'Fax characters length should be less than 200!  \n ( Line : ' +
                              (i + 1) +
                              ' )'
                          );
                          setHasError(true);
                          break;
                        }
                        if (row[excelColumns.fax] == '-') {
                        }
                      }

                      if (row[excelColumns.person] == null) {
                        setError(
                          "If the person is empty, use ' - ' !  \n ( Line : " +
                            (i + 1) +
                            ' )'
                        );
                        setHasError(true);
                        break;
                      } else {
                        if (row[excelColumns.person].length > 100) {
                          setError(
                            'Contact Person characters length should be less than 100!  \n ( Line : ' +
                              (i + 1) +
                              ' )'
                          );
                          setHasError(true);
                          break;
                        }
                        if (row[excelColumns.person] == '-') {
                        }
                      }
                      if (row[excelColumns.tid] == null) {
                        setError(
                          "If the tid is empty, use ' - ' !  \n ( Line : " +
                            (i + 1) +
                            ' )'
                        );
                        setHasError(true);
                        break;
                      } else {
                        if (row[excelColumns.tid].length > 100) {
                          setError(
                            'TID characters length should be less than 100!  \n ( Line : ' +
                              (i + 1) +
                              ' )'
                          );
                          setHasError(true);
                          break;
                        }
                        if (row[excelColumns.tid] == '-') {
                        }
                      }
                      if (row[excelColumns.designation] == null) {
                        setError(
                          "If the designation is empty, use ' - ' !  \n ( Line : " +
                            (i + 1) +
                            ' )'
                        );
                        setHasError(true);
                        break;
                      } else {
                        if (row[excelColumns.designation].length > 100) {
                          setError(
                            'Designation characters length should be less than 100!  \n ( Line : ' +
                              (i + 1) +
                              ' )'
                          );
                          setHasError(true);
                          break;
                        }
                        if (row[excelColumns.designation] == '-') {
                        }
                      }

                      if (row[excelColumns.joinDate] == null) {
                        setError(
                          "If the join date is empty, use ' - ' !  \n ( Line : " +
                            (i + 1) +
                            ' )'
                        );
                        setHasError(true);
                        break;
                      } else {
                        if (row[excelColumns.joinDate] == '-') {
                        } else {
                          if (
                            moment(
                              row[excelColumns.joinDate],
                              'DD-MM-YYYY',
                              true
                            ).isValid()
                          ) {
                          } else {
                            setError(
                              "Invalid Join Date. Date format shuld be DD-MM-YYYY ! If the join date is empty, use ' - ' \n ( Line : " +
                                (i + 1) +
                                ' )'
                            );
                            setHasError(true);
                            break;
                          }
                        }
                      }

                      if (
                        row[excelColumns.lat] == null ||
                        row[excelColumns.lan] == null
                      ) {
                        setError(
                          "If the lat or lan is empty, use ' 0 ' !  \n ( Line : " +
                            (i + 1) +
                            ' )'
                        );
                        setHasError(true);
                        break;
                      } else {
                        if (
                          row[excelColumns.lat] == null ||
                          row[excelColumns.lan] == null
                        ) {
                          setError(
                            'Invalid Latitude and Longitude !  \n ( Line : ' +
                              (i + 1) +
                              ' )'
                          );
                          setHasError(true);
                          break;
                        } else {
                          if (
                            isValidLatitude(row[excelColumns.lat]) &&
                            isValidLongitude(row[excelColumns.lan])
                          ) {
                          } else {
                            setError(
                              'Invalid Latitude and Longitude !  \n ( Line : ' +
                                (i + 1) +
                                ' )'
                            );
                            setHasError(true);
                            break;
                          }
                        }
                      }

                      if (row[excelColumns.category1] == null) {
                        setError(
                          "If the category1 is empty, use ' - ' !  \n ( Line : " +
                            (i + 1) +
                            ' )'
                        );
                        setHasError(true);
                        break;
                      } else {
                        if (row[excelColumns.category1].length > 100) {
                          setError(
                            'Category1 characters length should be less than 100!  \n ( Line : ' +
                              (i + 1) +
                              ' )'
                          );
                          setHasError(true);
                          break;
                        }
                        if (row[excelColumns.category1] == '-') {
                        }
                      }
                      if (row[excelColumns.category2] == null) {
                        setError(
                          "If the category2 is empty, use ' - ' !  \n ( Line : " +
                            (i + 1) +
                            ' )'
                        );
                        setHasError(true);
                        break;
                      } else {
                        if (row[excelColumns.category2].length > 100) {
                          setError(
                            'Category2 characters length should be less than 100!  \n ( Line : ' +
                              (i + 1) +
                              ' )'
                          );
                          setHasError(true);
                          break;
                        }
                        if (row[excelColumns.category2] == '-') {
                        }
                      }
                      if (row[excelColumns.category3] == null) {
                        setError(
                          'Please select Business Type from Dropdown !  \n ( Line : ' +
                            (i + 1) +
                            ' )'
                        );
                        setHasError(true);
                        break;
                      } else {
                        if (row[excelColumns.category3] == '-') {
                        } else if (row[excelColumns.category3].length > 100) {
                          setError(
                            'Category3 characters length should be less than 100!  \n ( Line : ' +
                              (i + 1) +
                              ' )'
                          );
                          setHasError(true);
                          break;
                        } else {
                          if (
                            row[excelColumns.category3] == 'PRIVATE LIMITED' ||
                            row[excelColumns.category3] == 'SOLE PROPRIETOR' ||
                            row[excelColumns.category3] == 'PARTNERSHIP' ||
                            row[excelColumns.category3] == 'INDIVIDUAL'
                          ) {
                          } else {
                            setError(
                              'Please select Business Type(category3) from Dropdown !  \n ( Line : ' +
                                (i + 1) +
                                ' )'
                            );
                            setHasError(true);
                            break;
                          }
                        }
                      }
                    } else {
                      setError(
                        'Invalid Districs !  \n ( Line : ' + (i + 1) + ' )'
                      );
                      setHasError(true);
                      break;
                    }
                  } else {
                    setError(
                      'Invalid Province !  \n ( Line : ' + (i + 1) + ' )'
                    );
                    setHasError(true);
                    break;
                  }
                } else {
                  setError(
                    'Province and district should be uppercase !  \n ( Line : ' +
                      (i + 1) +
                      ' )'
                  );
                  setHasError(true);
                  break;
                }
              }

              if (!isContainTheSpecialCharater(row)) {
                setError(
                  "Please remove the special characters ' " +
                    symbols +
                    " '  \n ( Line : " +
                    (i + 1) +
                    ' )'
                );
                setHasError(true);
                break;
              }
            }
          }

          var arr = [];
          for (let j = 0; j < row.length; j++) {
            var obj = {
              value: row[j] == null ? '-' : row[j],
            };
            arr.push(obj);
          }
          objectArray.push(arr);
        }
        setDataArray(objectArray);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const isContainTheSpecialCharater = (row) => {
    var isValid = true;
    for (let i = 0; i < row.length; i++) {
      let currentColumnData = row[i];
      if (currentColumnData != null) {
        currentColumnData = currentColumnData.toString();
        for (let j = 0; j < symbols.length; j++) {
          if (currentColumnData.includes(symbols[j])) {
            isValid = false;
            break;
          }
        }
        if (!isValid) {
          break;
        }
      }
    }
    return isValid;
  };

  const isIncompletedRow = (row) => {
    var count = 0;
    row.map((el) => {
      if (el == null) {
        count = count + 1;
      }
    });
    return count == 15;
  };

  const isValidLatitude = (lat) => {
    return isFinite(lat) && Math.abs(lat) <= 90;
  };

  const isValidLongitude = (lng) => {
    return isFinite(lng) && Math.abs(lng) <= 180;
  };

  const isValidEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const isValidProvince = (val) => {
    return !(customerProperties.districts[val] == undefined);
  };

  const isValiDistricts = (province, district) => {
    var isValid = false;
    var districArray = customerProperties.districts[province];
    districArray.map((val) => {
      if (val.value === district) {
        isValid = true;
      }
    });
    return isValid;
  };

  const isUpperCase = (str) => {
    let result = str
      .split('')
      .map((letter) => /[A-Z]/.test(letter))
      .reduce((a, b) => a + b);

    return result === str.length;
  };

  const upload = async (e) => {
    setIsLoading(true);
    if (!file[0]) {
      notyDefault({
        type: notyTypes.ERROR,
        text: 'Please Upload File First',
      });
      setIsLoading(false);
    } else if (file[0].size / 1024 / 1024 > 10) {
      notyDefault({
        type: notyTypes.ERROR,
        text:
          'Uploaded file exceeds 10MB size limit. Please upload a file less than 10MB!',
      });
      setIsLoading(false);
    } else {
      if (uploadLoacation === 'customer') {
        // readFile();
        if (!hasError) {
          let currentFile = file[0];
          let formData = new FormData();

          formData.append('file', currentFile);
          formData.append('fileType', 'excel');

          axios
            .post(url, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((results) => {
              if (results.status == 200) {
                notyDefault({
                  type: notyTypes.SUCCESS,
                  text: `Exists Count: ${
                    results.data.existCount
                  }, Failed Names: ${
                    results.data.failedNames.length > 0
                      ? results.data.failedNames.toString()
                      : 0
                  }, Created Count: ${results.data.createdCount}`,
                  isTimeoutCancel: true,
                });
                handleClose();
                setIsLoading(false);
              } else {
                notyDefault({
                  type: notyTypes.ERROR,
                  text: results.data.message,
                  isTimeoutCancel: true,
                });
                setIsLoading(false);
              }
            })
            .catch(function(error) {
              notyDefault({
                type: notyTypes.ERROR,
                text: error.message,
                isTimeoutCancel: true,
              });
              setIsLoading(false);
            });
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: 'Please Check File Again',
            isTimeoutCancel: true,
          });
          setIsLoading(false);
        }
      } else {
        notyDefault({
          type: notyTypes.SUCCESS,
          text: 'Not Deployed',
          isTimeoutCancel: true,
        });
        setIsLoading(false);
        let currentFile = file[0];
        //     let formData = new FormData();

        //     formData.append("file", currentFile);
        //     formData.append("fileType", "excel");

        //     axios.post(url, formData, {
        //         headers: {
        //             "Content-Type": "multipart/form-data",
        //         }
        //     })
        //         .then(results => {
        //             if (results.status == 200) {
        //                 notyDefault({ type: notyTypes.SUCCESS, text: results.data.message });
        //                 handleClose();
        //             } else {
        //                 notyDefault({ type: notyTypes.ERROR, text: results.data.message });
        //             }
        //         }).catch(function (error) {
        //             notyDefault({ type: notyTypes.ERROR, text: error.message });
        //         });
      }
    }
  };

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '140px',
    width: '400px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#B22900',
    borderStyle: 'dashed',
    backgroundColor: '#ffcec7',
    color: '#f73123',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <LoadingOverlay active={isLoading} spinner text="Loading ...">
          <DialogTitle
            style={{ color: '#f73123', height: '20px' }}
            id="alert-dialog-title"
          >
            {'File Upload'}
          </DialogTitle>
          <DialogContent>
            <Dropzone
              accept={acceptFileType}
              onDrop={(acceptedFiles) => {
                setIsLoading(true);
                setFile(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {file.length == 0 ? (
                      <p>
                        <h6 style={{ color: '#f73123' }}>
                          Drag & drop files here or <br /> click here to select
                          files
                        </h6>
                      </p>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p>{file[0].name} </p>
                        {!isLoading && (
                          <div
                            style={{
                              marginLeft: '5px',
                              height: '20px',
                              width: '20px',
                              display: 'flex',
                              backgroundColor: hasError ? 'red' : 'green',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: '50%',
                            }}
                          >
                            {hasError ? (
                              <MdClose color="white" size="15px" />
                            ) : (
                              <MdOutlineDoneOutline color="white" size="12px" />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
            {uploadLoacation === 'customer' ? (
              hasError ? (
                <div style={{ width: '22rem', marginTop: '1rem' }}>
                  <Typography
                    style={{
                      wordWrap: 'break-word',
                      textAlign: 'center',
                      color: '#f73123',
                      fontWeight: 800,
                    }}
                  >
                    {error}
                  </Typography>
                </div>
              ) : null
            ) : (
              ''
            )}
          </DialogContent>
          <DialogActions>
            {uploadLoacation === 'customer' ? (
              <Button
                color="#B22900"
                title="Download Customer Template"
                href={`${baseUrl}/public/documents/customers.xlsx`}
                target="_blank"
              >
                <text
                  style={{ color: '#B22900', textDecorationLine: 'underline' }}
                >
                  {' '}
                  Download Customer Template
                </text>
              </Button>
            ) : (
              ''
            )}

            <Button
              className={'generic-button-class div-button-wrapper'}
              onClick={handleClose}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              className={'generic-button-class div-button-wrapper'}
              onClick={(e) => {
                upload(e);
              }}
              color="primary"
              variant="contained"
              autoFocus
            >
              Upload
            </Button>
          </DialogActions>
        </LoadingOverlay>
      </Dialog>
    </div>
  );
}
