import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem.js';
import Button from '../../components/CustomButtons/Button.js';
import Card from '../../components/Card/Card.js';
import CardFooter from '../../components/Card/CardFooter.js';
import CustomInput from '../../components/CustomInput/CustomInput.js';
import styles from 'assets/jss/material-dashboard-react/views/loginPage';
import logo from 'assets/img/trackable.png';
import Danger from '../../components/Typography/Danger.js';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import {
  getFeedback,
  updateCustomerFeedBack,
} from 'services/Feedback/CustomerFeedbackService';
import { useParams } from 'react-router-dom';
import { feedbackProperties } from 'Util/Property/FeedbackProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import Rating from '@material-ui/lab/Rating';
import UrlProperties from 'Util/Property/UrlProperties';
import { handleErrorMessage } from 'services/CommonService';

const useStyles = makeStyles(styles);

const FeedBack = (props) => {
  const { token } = useParams();
  const classes = useStyles();
  const { ...rest } = props;
  const [customerFeedback, setCustomerFeedback] = useState({});

  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const [value, setValue] = React.useState(5);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCustomerFeedBackFromToken();
  }, []);

  const getCustomerFeedBackFromToken = () => {
    getFeedback(token)
      .then((result) => {
        if (result.status === 200) {
          setCustomerFeedback(result.data);
        } else {
          handleErrorMessage(
            result.data,
            feedbackProperties.messages.error.loadData
          );
        }
      })
      .catch((error) => {
        if (error.response.data.status === 400) {
          setIsSubmitted(true);
        }
        handleErrorMessage(error, feedbackProperties.messages.error.loadData);
      });
  };

  // add login form to function state
  const [feedbackForm, setFeedbackForm] = useState({
    comment: {
      elType: 'textarea',
      label: '',
      inputProps: {
        type: 'text',
        placeholder: 'Type your Comment here',
        value: '',
        initvalue: '',
        rowsMin: 3,
        rowsMax: 20,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
    },
  });

  const [isLogingFormValid, setLoginFormValid] = useState(false);

  setTimeout(function() {
    setCardAnimation('');
  }, 700);

  let formElementArray = [];
  for (let key in feedbackForm) {
    formElementArray.push({
      id: key,
      config: feedbackForm[key],
    });
  }

  const onChangeHandeler = (event, elId) => {
    let formData = { ...feedbackForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = event.target.value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setFeedbackForm(formData);
  };

  const onFeedbackFormSubmit = async (event) => {
    event.preventDefault();
    let method = '';
    let url = '';
    let data = {};

    data['token'] = token;
    data['ratings'] = value;
    data['comments'] = feedbackForm['comment'].inputProps.value;
    data['status'] = 'RECEIVED';

    method = 'POST';
    url = UrlProperties.feedback.updateCustomerFeedBack;

    setIsLoading(true);
    await updateCustomerFeedBack(method, url, data)
      .then(async (result) => {
        //this.props.history.goBack();
        if (result.status === 200) {
          setDefaultData();
          setIsSubmitted(true);
          notyDefault({
            type: notyTypes.SUCCESS,
            text: feedbackProperties.messages.success.sent,
          });
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: feedbackProperties.messages.error.sent,
          });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: feedbackProperties.messages.error.sent,
        });
      });
    setIsLoading(false);
  };

  const setDefaultData = () => {
    let formData = { ...feedbackForm };
    for (let key in formData) {
      let elementData = formData[key]; // get data for form key
      let inputProps = { ...elementData.inputProps }; //  copy input data
      inputProps.value = formData[key].inputProps.initvalue;
      elementData.inputProps = inputProps;
      formData[key] = elementData;
    }
    setFeedbackForm(formData);
  };

  let errorMessage = null;
  if (props.error) {
    errorMessage =
      props.error.message === 'Request failed with status code 401' ? (
        <Danger>
          <p> User Name or Password is incorrect </p>{' '}
        </Danger>
      ) : (
        <Danger>
          <p> {props.error.message} </p>{' '}
        </Danger>
      );
  }

  let redirect = null;
  if (props.isAuthenticated) {
    // to redirect to dashboard this need to fixed to redirect to logout page when login again due to
    // session expiration
    redirect = <Redirect to="/"> </Redirect>;
  }

  let formElement = (
    <React.Fragment>
      {formElementArray.map((element) => {
        return (
          <CustomInput
            key={element.id}
            labelText={element.config.label}
            id={element.id}
            formControlProps={element.config.formControlProps}
            inputProps={element.config.inputProps}
            type={element.config.elType}
            value={element.config.value}
            className={element.config.className}
            changed={(event) => onChangeHandeler(event, element.id)}
          />
        );
      })}
    </React.Fragment>
  );

  return (
    <div>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <GridContainer></GridContainer>
        <div className={classes.pageHeader}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <LoadingOverlay
                  active={props.isLoading}
                  spinner
                  text="Authenticating ..."
                >
                  <Card
                    style={{ minHeight: '30rem', minWidth: '70rem' }}
                    className={classes[cardAnimaton]}
                  >
                    <form
                      onSubmit={onFeedbackFormSubmit}
                      className={classes.form}
                    >
                      <div className={classes.logo}>
                        <img
                          style={{ width: '30%' }}
                          src={logo}
                          alt="Trackable logo"
                        />
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <h3>Feedback Form</h3>{' '}
                      </div>
                      <GridContainer>
                        <GridItem xs={2} sm={2} md={2}></GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          <div className={'feedbackTable'}>
                            <GridContainer>
                              <GridItem xs={6} sm={6} md={6}>
                                Customer Name
                              </GridItem>
                              <GridItem xs={6} sm={6} md={6}>
                                {customerFeedback.customerName}
                              </GridItem>

                              <GridItem xs={6} sm={6} md={6}>
                                Sub Customer Name
                              </GridItem>
                              <GridItem xs={6} sm={6} md={6}>
                                {customerFeedback.subCustomerName}
                              </GridItem>

                              <GridItem xs={6} sm={6} md={6}>
                                Product
                              </GridItem>
                              <GridItem xs={6} sm={6} md={6}>
                                {customerFeedback.product}
                              </GridItem>

                              <GridItem xs={6} sm={6} md={6}>
                                Service Request Id
                              </GridItem>
                              <GridItem xs={6} sm={6} md={6}>
                                {customerFeedback.srId}
                              </GridItem>
                              <GridItem xs={6} sm={6} md={6}>
                                Service Request Name
                              </GridItem>
                              <GridItem xs={6} sm={6} md={6}>
                                {customerFeedback.srName}
                              </GridItem>

                              <GridItem xs={6} sm={6} md={6}>
                                Overall Rating
                              </GridItem>
                              <GridItem xs={6} sm={6} md={6}>
                                <Rating
                                  name="simple-controlled"
                                  value={value}
                                  onChange={(event, newValue) => {
                                    setValue(newValue);
                                  }}
                                />
                              </GridItem>

                              <GridItem xs={6} sm={6} md={6}>
                                Comments
                              </GridItem>
                              <GridItem xs={6} sm={6} md={6}>
                                {formElement}
                              </GridItem>
                            </GridContainer>
                          </div>
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}></GridItem>
                      </GridContainer>
                      <CardFooter className={classes.cardFooter}>
                        {!isSubmitted && (
                          <Button
                            type="submit"
                            className={classes.loginButton}
                            simple
                            color="primary"
                          >
                            Submit
                          </Button>
                        )}
                      </CardFooter>
                    </form>
                  </Card>
                </LoadingOverlay>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default FeedBack;
