import React, { useEffect, useState, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import { handleErrorMessage } from 'services/CommonService';
import { deleteStage, getStages } from 'services/stage/StageService';
import { Link } from 'react-router-dom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import generalProperties from 'Util/Property/GeneralProperties';
import Button from 'components/CustomButtons/Button.js';
import { workflowProperties } from 'Util/Property/WorkflowProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import LoadingOverlay from 'react-loading-overlay';
import { Typography } from '@material-ui/core';
import ListAltOutlinedIcon from '@material-ui/icons/MessageOutlined';

import styles from './Stages.module.css';
import Spinner from 'components/Spinner/Spinner.js';

const Stages = (props) => {
  const [permissions, setPermissions] = useState({});
  const [stages, setStages] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addWorkflow,
      PermissionProperties.viewWorkflow,
      PermissionProperties.editWorkflow,
      PermissionProperties.deleteWorkflow,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    getStagesForTable();
  }, []);

  const getStagesForTable = () => {
    setIsLoading(true);
    getStages()
      .then((result) => {
        if (result.status === 200) {
          setStages(result.data);
        } else {
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.loadStages
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, workflowProperties.messages.error.loadStages);
      });
  };
  const onStageViewHandler = (id, isEdit) => {
    console.log('--------------->', id);
    props.history.push({
      pathname: props.match.url + '/stage',
      search: '?id=' + id + '&isEdit=' + isEdit,
    });
  };

  const deleteStageHandler = (rowData) => {
    setRowData(rowData);
    setIsDeleteDialogOpen(true);
  };

  const deleteStageCloseHandler = () => {
    setIsDeleteDialogOpen(false);
  };

  const deleteConfirmHandler = async () => {
    let data = { ...rowData };
    setIsLoading(true);
    await deleteStage(data.id)
      .then((result) => {
        if (result.status == '200') {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: workflowProperties.messages.success.deleteStage,
          });
          getStages();
        } else {
          // could not delete succesfully
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.deleteStage
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(
          error,
          workflowProperties.messages.error.deleteStage
        );
      });
    deleteStageCloseHandler();
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <Dialog
                  open={isDeleteDialogOpen}
                  onClose={deleteStageCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Do you want to delete this Stage?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="sm"
                      onClick={deleteStageCloseHandler}
                      color="primary"
                    >
                      {generalProperties.cancel}
                    </Button>
                    <Button
                      size="sm"
                      onClick={deleteConfirmHandler}
                      color="primary"
                      autoFocus
                    >
                      {generalProperties.confirm}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* customer table content */}
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon style={{ fontSize: '1.2rem' }} />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Stages
                        </Typography>
                        {/* <Typography variant="body1">Manage products here</Typography> */}
                      </div>
                    }
                    tableRef={tableRef}
                    columns={[
                      { title: 'Name', field: 'stage_name' },
                      { title: 'Workflow Type', field: 'type_name' },
                      { title: 'Sub Type', field: 'sub_type_name' },
                      { title: 'Created By', field: 'created_by' },
                    ]}
                    data={stages}
                    actions={[
                      {
                        icon: 'visibility',
                        iconProps: { className: 'action-buttons view-button' },
                        tooltip: 'View stage',
                        onClick: (event, rowData) =>
                          onStageViewHandler(rowData.id, false),
                        hidden: !permissions[PermissionProperties.viewWorkflow],
                      },
                      {
                        icon: 'edit',
                        tooltip: 'Edit stage',
                        iconProps: { className: 'action-buttons edit-button' },
                        onClick: (event, rowData) =>
                          onStageViewHandler(rowData.id, true),
                        position: 'row',
                        hidden: !permissions[PermissionProperties.editWorkflow],
                      },
                      {
                        icon: 'delete',
                        tooltip: 'Delete stage',
                        iconProps: {
                          className: 'action-buttons delete-button',
                        },
                        onClick: (event, rowData) =>
                          deleteStageHandler(rowData),
                        position: 'row',
                        hidden: !permissions[
                          PermissionProperties.deleteWorkflow
                        ],
                      },
                      {
                        icon: () => (
                          <Link to={props.match.url + '/stage'}>
                            <div
                              className={
                                'generic-button-class div-button-wrapper'
                              }
                              variant="contained"
                              color="primary"
                            >
                              Add Stage
                            </div>
                          </Link>
                        ),
                        tooltip: 'Create new stage',
                        isFreeAction: true,
                        hidden: !permissions[PermissionProperties.addWorkflow],
                      },
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: false,
                      search: false,
                      maxBodyHeight: '70vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                    onRowClick={(event, rowData, toggleDetailPanel) =>
                      onStageViewHandler(rowData.token, false)
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default Stages;
