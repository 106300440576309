import React, { useState, useEffect } from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem.js';
import styles from './WorkflowBreadcrumb.module.css';
import LoadingOverlay from 'react-loading-overlay';
import Spinner from 'components/Spinner/Spinner.js';

const WorkflowBreadcrumb = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stages, setStages] = useState([]);

  useEffect(() => {
    if (typeof props.stages !== 'undefined') {
      setStages(props.stages);
    }
  }, [props.stages]);

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <ul className={styles.breadcrumb}>
              {stages.map((stage) => {
                return (
                  <li key={stage.sequence}>
                    <a>{stage.stageName}</a>
                  </li>
                );
              })}
            </ul>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default WorkflowBreadcrumb;
