import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios/axios-default';

export async  function getStageByFormAndSRCategory(form, serviceRequestCategoryId){
    return axios.get(urlProperties.stage.getStageByFormAndSRCategory+"?form="+form+"&serviceRequestCategoryId="+serviceRequestCategoryId);
}



export async  function getStageByForm(form){
    return axios.get(urlProperties.stage.getStageByForm+"?form="+form);
}

export async  function getStageByFormAndSubCategory(form, subCategory){ 
    if(typeof subCategory === 'undefined'){
        return axios.get("getStageNamesByTypeAndSubType?type="+form);
    }else{
        return axios.get("getStageNamesByTypeAndSubType?type="+form+"&subtype="+subCategory);
    }
}
 

export async  function createStage(data){
    return  await axios({
        method: "POST",
        url: "createStageName",
        data: data
    });
}

export async  function updateStage(data){
    return  await axios({
        method: "PUT",
        url: "updateStageName/"+data.id,
        data: data
    });
}


export async  function getStageById(id){
    return axios.get("getStageNames/"+id);
}


export async  function deleteStage(id){
    return axios.delete("deleteStageName/"+id);
}

export async  function getStages(){
    return axios.get("getStageNames");
}


