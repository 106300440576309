const DashboardProperties = {
  chartTypes: {
    REPETITIVE_BREAKDOWN_CHART: "REPETITIVE_BREAKDOWN_CHART",
    APPROVED_ESTIMATIONS: "APPROVED_ESTIMATIONS",
    DELAYED_ATTENTION: "DELAYED_ATTENTION",
    DELAYED_TASK: "DELAYED_TASK",
    COST_PER_JOB_PER_PRODUCT_CATEGORY: 'COST_PER_JOB_PER_PRODUCT_CATEGORY',
    COST_PER_JOB_PER_REQUEST_TYPE:'COST_PER_JOB_PER_REQUEST_TYPE',
    COST_PER_JOB_PER_TECHNICIAN: 'COST_PER_JOB_PER_TECHNICIAN',
    COMPLETION_STATUS:'COMPLETION_STATUS',
    RESOLUTION_STATUS:'RESOLUTION_STATUS',
    ATTENDING_STATUS:'ATTENDING_STATUS'
  },
  chartTitles: {
    REPETITIVE_BREAKDOWN_CHART: "Repetitive Breakdowns - by Customer",
    APPROVED_ESTIMATIONS: "Approved Estimations - by Customer",
    DELAYED_ATTENTION: "Delayed Attention - by Customer",
    DELAYED_TASK: "Delayed Task Completion - by Customer",
    COST_PER_JOB_PER_PRODUCT_CATEGORY:'Cost Per Job - Product Category',
    COST_PER_JOB_PER_REQUEST_TYPE:'Cost Per Job - Request Category',
    COST_PER_JOB_PER_TECHNICIAN:'Cost Per Job - By Technician',
    COMPLETION_STATUS:'SR Completion Status',
    RESOLUTION_STATUS:'Task Completion Status',
    ATTENDING_STATUS: "Attending Status"
  }
};
export default DashboardProperties;
