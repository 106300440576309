export const KpiProperties = {
  messages: {
    error: {
      kpi: "Could not load  Kpi's please try again",
      kpicr: 'Failed to create Kpi please try again',
      loadData: 'Could not load  Kpi please try again',
      stages: 'Could not load workflow stages',
      status: 'Could not load workflow status',
      // loadStages: "Could not load stages",
      deleteKpi: 'Could not delete KPI please try again',
      loadStage: 'Could not load stage please try again',
      stageSave: 'Could not save stage data please try again',
      srCategories: 'Could not load  SR Types please try again',
    },
    success: {
      save: ' saved successfully',
      kpi: ' Kpi created successfully',
      update: ' Kpi Updated successfully',
      delete: ' deleted successfully',
      deleteStage: ' deleted successfully',
    },
    normal: {
      deleteConfirm: 'Do you want to delete this KPI',
    },
  },
};

const stageArray = [
  { value: 'New', displayValue: 'New' },
  { value: 'Assigned', displayValue: 'Assigned' },
  { value: 'Travelling', displayValue: 'Travelling' },
  { value: 'On-Hold', displayValue: 'On Hold' },
  { value: 'Need-Further-Attention', displayValue: 'Need Further Attention' },
  { value: 'Need-workshop-attention', displayValue: 'Need workshop attention' },
];

const statusArray = [
  { value: 'New', displayValue: 'New' },
  { value: 'Assigned', displayValue: 'Assigned' },
  { value: 'In-Progress', displayValue: 'In Progress' },
  { value: 'On-Hold', displayValue: 'On Hold' },
  { value: 'Pending', displayValue: 'Pending' },
];

const units = [
  { value: 'Days', displayValue: 'Days' },
  { value: 'Hours', displayValue: 'Hours' },
  { value: 'Cost', displayValue: 'Cost' },
];

export const kpiform = () => {
  return {
    //   name: {
    //     elType: 'input',
    //     label: 'Name',
    //     inputProps: {
    //       type: 'text',
    //       placeholder: 'Name',
    //       value: '',
    //       initvalue: ''
    //     },
    //     formControlProps: {
    //       fullWidth: true
    //     },
    //     labelProps: {
    //       required: true,
    //       error: false
    //     },
    //     size: 12,
    //   },
    from_stage: {
      elType: 'select',
      label: 'Stage From',
      inputProps: {
        options: stageArray,
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isAsyncDropDown: true,
      isDisabled: false,
    },
    to_stage: {
      elType: 'select',
      label: 'Stage To',
      inputProps: {
        options: stageArray,
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isAsyncDropDown: true,
      isHide: false,
      isDisabled: false,
    },
    Value: {
      elType: 'input',
      label: 'Value',
      inputProps: {
        options: stageArray,
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isHide: false,
      isDisabled: false,
    },
    type: {
      elType: 'select',
      label: 'Unit Type',
      inputProps: {
        options: units,
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isHide: false,
      isDisabled: false,
    },
  };
};
