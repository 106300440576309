import {
  primaryColor,
  dangerColor,
  successColor,
  grayColor,
  defaultFont,
} from 'assets/jss/material-dashboard-react.js';

const customInputStyle = {
  root: {
    '& .MuiInputBase-root': {
      fontSize: 14,
    },
    '& .MuiFormLabel-root': {
      fontSize: 14,
    },
  },
  disabled: {
    '&:before': {
      backgroundColor: 'transparent !important',
    },
  },
  underline: {
    '&:hover:not($disabled):before,&:before': {
      // borderColor: grayColor[4] + ' !important',
      // borderWidth: '2px !important',
      borderBottom: '0.8px dotted ' + '#888' + ' !important',
    },
    '&:after': {
      borderColor: primaryColor[0],
    },
  },
  underlineError: {
    '&:after': {
      borderColor: dangerColor[0],
    },
  },
  underlineSuccess: {
    '&:after': {
      borderColor: successColor[0],
    },
  },

  labelRoot: {
    ...defaultFont,
    // color: grayColor[2] + ' !important',
    // fontSize: '14px !important',
    fontWeight: '400',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
  },
  labelRootError: {
    color: dangerColor[0],
  },
  // labelRootSuccess: {
  //   color: successColor[0],
  // },
  feedback: {
    position: 'absolute',
    top: '18px',
    right: '0',
    zIndex: '2',
    display: 'block',
    width: '24px',
    height: '24px',
    textAlign: 'center',
    pointerEvents: 'none',
  },
  marginTop: {
    marginTop: '16px',
  },
  formControl: {
    paddingBottom: '10px',
    margin: '10px 0 0 0',
    position: 'relative',
    verticalAlign: 'unset',
  },
  gridStyle: {
    display: 'flex',
    flexDirection: 'row',
  },

  hideArrows: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
};

export default customInputStyle;
