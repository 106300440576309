import React, { useState, useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone from 'react-dropzone'
import axios from '../../axios/axios-default';
import { notyDefault, notyTypes } from '../../components/Noty/NotyCustom';
import readXlsxFile from 'read-excel-file'
import { Typography } from '@material-ui/core';
import { customerProperties } from 'Util/Property/CustomerPorerties';
import * as moment from 'moment';
import writeXlsxFile from 'write-excel-file'
import UrlProperties from 'Util/Property/UrlProperties';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from "material-table";
import Lottie from 'react-lottie';
import animationData from '../../assets/onsiteAnimation.json';
import ReportView3 from './ReportView3';
import { title } from 'assets/jss/material-dashboard-react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

export default function ReportView2(props) {

    const { open, fileUploadClose, getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject, url , uploadLoacation, acceptFileType,type} = props;
    const [file, setFile] = React.useState([]);

    const [error, setError] = React.useState("");
    const [hasError, setHasError] = React.useState(false);
    const [dataArray, setDataArray] = React.useState([]);
    const [openSecondPreview, setOpenSecondPreview] = useState(false);
    const [newFile, setNewFile] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openFileUpload3, setOpenFileUpload3] = useState(false);
   // const [handleClose, setHandleClose] = useState(false);
    

    const baseUrl = UrlProperties.baseUrl.split(":8089")[0];
    
    const handleClose = () => {
        // setFile([]);
         fileUploadClose();
    }
    const fileUploadClose2= () => {
      setOpenFileUpload3(false);
  } 
 
    useEffect(() => {

    }, []);

    useEffect(() => {
   
    }, [file]);

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '140px',
        width: '400px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#B22900',
        borderStyle: 'dashed',
        backgroundColor: '#ffcec7',
        color: '#f73123',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const style = useMemo(() => ({
        ...baseStyle,
    }), [

    ]);

    return (
        <div>
          <ReportView3  open={openFileUpload3}  type={type} fileUploadClose={fileUploadClose2} />
            <Dialog
            maxWidth="xl"
            fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <LoadingOverlay
                    active={isLoading}
                    spinner
                    text='Loading ...'>
                    <DialogContent>
                    <b><center>
                    From:2022-06-11   To:2022-07-02
                        </center></b>
                    {
                      type =="ON_SITE_TECHNICIANS_SUMMARY_REPORT"? 
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <div className={"material-grid-wrapper"}>
                      <MaterialTable
                        title={
                          <div>
                            <Typography variant="h6" style={{ fontSize: "1rem", marginTop: "-0.7rem" }}>On Site Field Agent Report</Typography>
                          </div>
                        }
                        columns={[
                          { title: '#', field: 'num', headerStyle:{width:"10%", backgroundColor: '#e6e4df',padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'SR No', field: 'srno' , width:'5%',headerStyle:{backgroundColor: '#e6e4df', padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Priority', field: 'priority', width:'5%',headerStyle:{backgroundColor: '#e6e4df', padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'} },
                          { title: 'Customer', width:'15%',field: 'customer' , headerStyle:{backgroundColor: '#e6e4df', padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Province',width:'3%', field: 'province', headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Product', width:'15%',field: 'product',headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'SA Status',width:'14%', field: 'sastatus' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Current Stage',width:'12%', field: 'currentstage' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Last Attended',width:'5%', field: 'lastattendedby', headerStyle:{backgroundColor: '#e6e4df', padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Start Date', field: 'startdate' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Last Updated',width:'14%', field: 'lastupdated' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Age(Days)',   field: 'age' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}, 
                          render:(rowData) =><div style={{width:'100%',marginLeft:'20%',background:rowData["age"]<rowData["kpi"]?"#92D050":rowData["age"]>rowData["kpi"]?" #FF3300":"#FFC000"}}>{rowData["age"]}</div>},
                          { title: 'KPI', field: 'kpi' ,width:'10%',headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          ]}
                        data={[
                          { num: '1', srno: '001', priority: 'High', customer: 'HNB-Dehiwala' , province: 'Western', product: 'Finger scanner', sastatus: 'Under SA', currentstage: 'Complete',lastattendedby:'Tharaka', startdate:'5/1/2022', lastupdated: '5/10/2022', age: 10, kpi: 5}, 
                          { num: '2', srno: '002', priority: 'Low', customer: 'COM-Kottawa', province:'Western', product: 'Note Counter', sastatus: 'Under Warranty', currentstage: 'Customer Visit',  lastattendedby:'Layantha', startdate:'5/2/2022', lastupdated: '5/11/2022', age: 1, kpi: 2 },
                          { num: '3', srno: '003', priority: 'Mid', customer: 'SMI-Gampaha', province: 'Western', product: 'Photocopier', sastatus: 'SA Expired', currentstage: 'Inprogress', lastattendedby:'Saman', startdate:'5/3/2022',lastupdated: '5/12/2022', age: 6, kpi: 1 }
                        ]}
                        options={{
                          actionsColumnIndex: -1,
                          // selection: true,
                          exportButton: true,
                          selectionProps: rowData => {
                            // const selectable = rowData.assignedTo === null;
                            // //NOTE:: tableData object is not in documentation
                            // rowData.tableData.disabled = !selectable;
                            // return {
                            //     disabled: !selectable
                            // }
                          },
                          pageSize: 5,
                          // filtering: true,
                          //maxBodyHeight:"85vh",
                          sorting: false,
                          search: false,
                          padding: "dense",
                          maxBodyHeight: "70vh",
                      headerStyle: {
                          fontSize: '0.7rem',
                          textAlignL: "center"
                      },
                      rowStyle: {
                          fontSize: '0.6rem',
                          fontWeight: 450,
                          padding: "5rem"
                      }
                        }}
                        // options={{
                        //   exportButton: true
                        // }}
                        onRowClick={(event, rowData) => {
                          // Get your id from rowData and use with link.
                          //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
                          //event.stopPropagation();
                        }}
                      /> </div></GridItem></GridContainer>

                      : type =="Efficiency_Summary_Time"? 
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <div className={"material-grid-wrapper"}>
                        <MaterialTable
                          title={
                            <div>
                              <Typography variant="h6" style={{ fontSize: "1rem", marginTop: "-0.7rem" }}>Efficiency Summary - Time</Typography>
                            </div>
                          }    
                          columns={[
                            { title: '#', field: 'num', headerStyle:{width:"10%", backgroundColor: '#e6e4df',padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'SR No', field: 'srno' , width:'5%',headerStyle:{backgroundColor: '#e6e4df', padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Priority', field: 'priority', width:'5%',headerStyle:{backgroundColor: '#e6e4df', padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'} },
                          { title: 'Customer', width:'15%',field: 'customer' , headerStyle:{backgroundColor: '#e6e4df', padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Province',width:'3%', field: 'province', headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Product', width:'15%',field: 'product',headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Start Date',width:'5%', field: 'startdate' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Completed Date',width:'5%', field: 'completeddate', headerStyle:{backgroundColor: '#e6e4df', padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Current Stage',width:'12%', field: 'currentstage' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          //{ title: 'Start Date', field: 'startdate' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Last Updated',width:'5%', field: 'lastupdated' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Age (Days)',   field: 'age' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}, 
                          render:(rowData) =><div style={{width:'100%',marginLeft:'5%',background:rowData["age"]<rowData["kpi"]?"#92D050":rowData["age"]>rowData["kpi"]?" #FF3300":"#FFC000"}}>{rowData["age"]}</div>},
                          { title: 'KPI', field: 'kpi' ,width:'10%',headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          ]}
                          data={[
                            { num: 1, srno: '001', priority: 'High', customer: 'HNB-Dehiwala' , province: 'Western', product: 'Note Counter', startdate: '26/01/2022', completeddate: '03/02/2022', currentstage: 'Approval', lastupdated: '03/02/2022', age: 8, kpi: 12}, 
                            { num: 2, srno: '002', priority: 'Mid', customer: 'COM-Kottawa', province:'Western', product: 'Note Counter', startdate: '27/01/2022', completeddate: '', currentstage: 'Assigned', lastupdated: '02/02/2022', age: 6, kpi: 3 },
                            { num: 3, srno: '003', priority: 'Low', customer: 'SMI-Gampaha', province: 'Western', product: 'Photocopier', startdate: '25/01/2022', completeddate: '', currentstage: 'Inquiries', lastupdated: '26/02/2022', age: 1, kpi: 1 }
                          ]}
                          options={{
                            actionsColumnIndex: -1,
                            // selection: true,
                            exportButton: true,
                            selectionProps: rowData => {
                              // const selectable = rowData.assignedTo === null;
                              // //NOTE:: tableData object is not in documentation
                              // rowData.tableData.disabled = !selectable;
                              // return {
                              //     disabled: !selectable
                              // }
                            },
                            pageSize: 5,
                            // filtering: true,
                            //maxBodyHeight:"85vh",
                            sorting: false,
                            search: false,
                            padding: "dense",
                            maxBodyHeight: "70vh",
                        headerStyle: {
                            fontSize: '0.7rem',
                            textAlignL: "center"
                        },
                        rowStyle: {
                            fontSize: '0.6rem',
                            fontWeight: 450,
                            padding: "5rem"
                        }
                          }}
                          // options={{
                          //   exportButton: true
                          // }}
                          onRowClick={(event, rowData, toggleDetailPanel) => {
                          //setType1(this.type)
                          setOpenFileUpload3(true)
                        }}
                        /> </div></GridItem></GridContainer>

                    : type =="Efficiency_Summary_Count"?
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <div className={"material-grid-wrapper"}>
                        <MaterialTable
                          title={
                            <div>
                              <Typography variant="h6" style={{ fontSize: "1rem", marginTop: "-0.7rem" }}>Efficiency Summary - Count</Typography>
                            </div>
                          }    
                          columns={[
                          { title: '#', field: 'num', headerStyle:{width:"10%", backgroundColor: '#e6e4df',padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'SR No', field: 'srno' , width:'5%',headerStyle:{backgroundColor: '#e6e4df', padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Priority', field: 'priority', width:'10%',headerStyle:{backgroundColor: '#e6e4df', padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'} },
                          { title: 'Customer', width:'15%',field: 'customer' , headerStyle:{backgroundColor: '#e6e4df', padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Province',width:'3%', field: 'province', headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Product', width:'15%',field: 'product',headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'SA Status',width:'14%', field: 'sastatus' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Current Stage',width:'12%', field: 'currentstage' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Last Attended',width:'5%', field: 'lastattendedby', headerStyle:{backgroundColor: '#e6e4df', padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Start Date', field: 'startdate' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Last Updated',width:'14%', field: 'lastupdated' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          { title: 'Age (Days)',   field: 'age' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}, 
                          render:(rowData) =><div style={{width:'100%',marginLeft:'50%',background:rowData["age"]<rowData["kpi"]?"#92D050":rowData["age"]>rowData["kpi"]?" #FF3300":"#FFC000"}}>{rowData["age"]}</div>},
                          { title: 'KPI', field: 'kpi' ,width:'10%',headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          ]}
                          
                          data={[
                          { num: 1, srno: '001', priority: 'High', customer: 'HNB-Dehiwala' , province: 'Western', product: 'Finger scanner', sastatus: 'Under SA', currentstage: 'Complete',lastattendedby:'Tharaka', startdate:'5/1/2022', lastupdated: '5/10/2022', age: 10, kpi: 5}, 
                          { num: 2, srno: '002', priority: 'Low', customer: 'COM-Kottawa', province:'Western', product: 'Note Counter', sastatus: 'Under Warranty', currentstage: 'Customer Visit',  lastattendedby:'Layantha', startdate:'5/2/2022', lastupdated: '5/11/2022', age: 1, kpi: 2 },
                          { num: 3, srno: '003', priority: 'Mid', customer: 'SMI-Gampaha', province: 'Western', product: 'Photocopier', sastatus: 'SA Expired', currentstage: 'Inprogress', lastattendedby:'Saman', startdate:'5/3/2022',lastupdated: '5/12/2022', age: 6, kpi: 1 }
                        ]}
                          options={{
                            actionsColumnIndex: -1,
                            // selection: true,
                            exportButton: true,
                            selectionProps: rowData => {
                              // const selectable = rowData.assignedTo === null;
                              // //NOTE:: tableData object is not in documentation
                              // rowData.tableData.disabled = !selectable;
                              // return {
                              //     disabled: !selectable
                              // }
                            },
                            pageSize: 5,
                            // filtering: true,
                            //maxBodyHeight:"85vh",
                            sorting: false,
                            search: false,
                            padding: "dense",
                            maxBodyHeight: "70vh",
                        headerStyle: {
                            fontSize: '0.7rem',
                            textAlignL: "center"
                        },
                        rowStyle: {
                            fontSize: '0.6rem',
                            fontWeight: 450,
                            padding: "5rem"
                        }
                          }}
                          // options={{
                          //   exportButton: true
                          // }}
                          onRowClick={(event, rowData, toggleDetailPanel) => {
                            //setType1(this.type)
                            setOpenFileUpload3(true)
                          }}
                        /> </div></GridItem></GridContainer>
                        : type =="Average_field_Cost"? 
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <div className={"material-grid-wrapper"}>
                        <MaterialTable
                          title={
                            <div>
                              <Typography variant="h6" style={{ fontSize: "1rem", marginTop: "-0.7rem" }}>Average field Cost</Typography>
                            </div>
                          }
                          columns={[
                            { title: "#", field: "num" ,width:'5%', headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Employee", field: "employee" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Total Field Visits Count", field: "totalfieldvisitscount" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Total Cost Per Field Jobs", field: "totalcostperfieldjobs" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Total Cost Per Travelling", field: "totalcostpertravelling", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Total Cost Per Idling Time", field: "totalcostperidlingtime", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Total Time Cost", field: "totaltimecost", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "AVG Cost Per Field Jobs", field: "avgcostperfieldjobs", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Total Distance Travelled (KM)", field: "totaldistancetravelled", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "AVG Cost of BA/TA", field: "ba", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "AVG Cost of Meal", field: "meal", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "AVG Cost of Allowances", field: "allowances", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "AVG Fixed Cost", field: "fixedcost", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},


                          ]}
                          data={[
                            { num: '1', employee: 'Kapila', totalfieldvisitscount: 20, totalcostperfieldjobs: 2700.00 , totalcostpertravelling: 3000.00, totalcostperidlingtime: 1350.00, totaltimecost: 7050.00 , avgcostperfieldjobs: 352.50, totaldistancetravelled: 250, ba: 150.00 , meal: 700.00, allowances: 470.00, fixedcost: 3000.00}, 
                            { num: '2', employee: 'Saman', totalfieldvisitscount: 35, totalcostperfieldjobs: 2500.00, totalcostpertravelling: 1800.00, totalcostperidlingtime: 900.00, totaltimecost: 2500.00 , avgcostperfieldjobs: 148.57, totaldistancetravelled: 180},
                            { num: '3', employee: 'Eranga', totalfieldvisitscount: 20, totalcostperfieldjobs: 7300.00, totalcostpertravelling: 5000.00, totalcostperidlingtime: 1250.00, totaltimecost: 1350.00 , avgcostperfieldjobs: 677.50, totaldistancetravelled: 850 }
                          ]}
                          options={{
                            actionsColumnIndex: -1,
                            // selection: true,
                            exportButton: true,
                            selectionProps: rowData => {
                              // const selectable = rowData.assignedTo === null;
                              // //NOTE:: tableData object is not in documentation
                              // rowData.tableData.disabled = !selectable;
                              // return {
                              //     disabled: !selectable
                              // }
                            },
                            pageSize: 5,
                            // filtering: true,
                            //maxBodyHeight:"85vh",
                            sorting: false,
                            search: false,
                            padding: "dense",
                            maxBodyHeight: "70vh",
                        headerStyle: {
                            fontSize: '0.7rem',
                            textAlignL: "center"
                        },
                        rowStyle: {
                            fontSize: '0.6rem',
                            fontWeight: 450,
                            padding: "5rem"
                        }
                          }}
                          // options={{
                          //   exportButton: true
                          // }}
                          onRowClick={(event, rowData, toggleDetailPanel) => {
                            //setType1(this.type)
                            setOpenFileUpload3(true)
                          }}
                        /></div></GridItem></GridContainer>
                    
                    : null

                    }
                    </DialogContent>
                    <DialogActions>
                        {/* {uploadLoacation === "customer" ? 
                            <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
                                <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>         
                            </Button>	
                        :""}																														 */}

                        <Button className={'generic-button-class div-button-wrapper'} onClick={handleClose} color="primary" variant="contained">
                            Cancel
                        </Button>
                        {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
                            Uplaod
                        </Button> */}
                    </DialogActions>
                </LoadingOverlay>
            </Dialog>
        </div>
    );
}
