import axios from "../../axios/axios-default";

 export function getWorkflows () {
      return axios.get("getActiveWorkflowTypes");
}

export function getWorkflow (workflowId) {
    return axios.get("getWorkflow/"+workflowId);
}


export async  function createWorkflow(data){
    return  await axios({
        method: "POST",
        url: "createWorkflow",
        data: data
    });
}

export async  function updateWorkflow(data){
    return  await axios({
        method: "PUT",
        url: "updateWorkFlow/"+data.id,
        data: data
    });
}

export function deleteWorkflow (workflowId) {
    return axios.delete("deleteWorkflow/"+workflowId);
}

export function getCategoryByWorkflowType(type){
    return  axios.get("getCategoryByWorkflowType?type="+type);
}

export function getWorkflowTypes(){
    return  axios.get("getWorkflowTypes");
}