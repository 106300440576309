/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { Typography, TextField } from '@material-ui/core';
import { currencyFormatter } from '../../../../Util/Util';
import styles from './AddServiceAgreement.module.css';
import { getAddServiceAgreementForm } from 'Util/Property/ServiceAgreementProperties';
import { productServiceAgreementProperties } from 'Util/Property/ProductServiceAgreementProperties';
import LoadingOverlay from 'react-loading-overlay';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.js';
import { Form, Field } from 'react-final-form';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { getCustomerByName } from 'services/Customer/CustomerService';
import {
  getServiceAgreementsByCategory,
  getServiceAgreementTypes,
  getProductSubStatusData,
} from 'services/serviceAgreeement';
import {
  getProductByCustomer,
  getProduct,
} from 'services/ProductAndServices/ProductService';
import {
  getProductServiceAgreement,
  saveBulkProductServiceAgreements,
  updateProductServiceAgreements,
  generatePSAReport,
  getRouteOptions,
  updatePSAWorkflowStage,
} from 'services/ProductAndServices/ProductServiceAgreement';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
// import axios from '../../../axios/axios-default';
import Spinner from 'components/Spinner/Spinner.js';
import MaterialTable from 'material-table';
import WorkflowRoute from 'views/WorkflowRoute/WorkflowRoute';

const AddServiceAgreement = (props) => {
  const [formState, setformState] = useState({
    name: '',
    customer_id: '',
    agreement_type: '',
    product_category_id: '',
    product_brand_id: [],
    product_model_id: [],
    serial_number: [],
    service_agreement_id: '',
    start_date: null,
    terminate_date: null,
  });

  const [serviceAgreementForm, setServiceAgreementForm] = useState(
    getAddServiceAgreementForm()
  );

  const [serviceRequestHeading, setServiceRequestHeading] = useState(
    productServiceAgreementProperties.addPsa
  );

  const [isLoading, setIsLoadingIcon] = useState(false);
  const [loadingCount, setLoadingCount] = useState({
    count: 0,
  });
  const [asyncDataState, setAsyncDataState] = useState({
    customer_id: false,
    agreement_type: false,
    product_category_id: false,
    product_brand_id: false,
    product_model_id: false,
    serial_number: false,
    service_agreement_id: false,
    sa_module_name: false,
    product_sub_status: false,
  });

  const [serviceAgreement, setServiceAgreement] = useState();
  const [serviceAgreementId, setServiceAgreementId] = useState();
  const [productId, setProductId] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isUpcoming, setIsUpcoming] = useState();

  const [productsByCustomer, setProductsByCustomer] = useState([]);
  const [productsSeriels, setProductsSeriels] = useState([]);
  const [saModules, setSaModules] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedIdList, setSelectedIdList] = useState([]);
  const [productSubStatusList, setProductSubStatusList] = useState([]);
  const [isWfRouteDialogOpen, setIsWfRouteDialogOpen] = useState(false);
  const [routeOptions, setRouteOptions] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    let serviceAgreementId = 0;
    const query = new URLSearchParams(props.location.search);
    let queryParam = {};
    for (let param of query.entries()) {
      queryParam[param[0]] = param[1];
    }

    if (props.id && props.id > 0) {
      if (props.isEdit) {
        setIsEdit(true);
        if (queryParam['isUpcoming'] === 'true') {
          setServiceRequestHeading(
            productServiceAgreementProperties.editUpcomingPsa
          );
        } else {
          setServiceRequestHeading(productServiceAgreementProperties.editPsa);
        }
        serviceAgreementForm['service_agreement_no'].isHide = false;
        serviceAgreementForm['stage'].isHide = false;
      } else {
        setIsView(true);
        if (queryParam['isUpcoming'] === 'true') {
          setServiceRequestHeading(
            productServiceAgreementProperties.viewUpcomingPsa
          );
        } else {
          setServiceRequestHeading(productServiceAgreementProperties.viewPsa);
        }
        serviceAgreementForm['service_agreement_no'].isHide = true;
        serviceAgreementForm['stage'].isHide = true;
        serviceAgreementForm['customer_id'].readOnly = true;
        serviceAgreementForm['service_agreement_type'].readOnly = true;
        serviceAgreementForm['product_category_id'].readOnly = true;
        serviceAgreementForm['product_brand_id'].readOnly = true;
        serviceAgreementForm['product_model_id'].readOnly = true;
        serviceAgreementForm['start_date'].readOnly = true;
        serviceAgreementForm['terminate_date'].readOnly = true;
        serviceAgreementForm['sa_module_name'].readOnly = true;
      }
      serviceAgreementId = props.id;
    } else if (props.location) {
      if (typeof queryParam['id'] != 'undefined') {
        serviceAgreementId = queryParam['id'];
        setIsUpcoming(queryParam['isUpcoming'] === 'true');
        if (queryParam['isEdit'] === 'true') {
          setIsEdit(true);
          if (queryParam['isUpcoming'] === 'true') {
            setServiceRequestHeading(
              productServiceAgreementProperties.editUpcomingPsa
            );
          } else {
            setServiceRequestHeading(productServiceAgreementProperties.editPsa);
          }
          serviceAgreementForm['service_agreement_no'].isHide = false;
          serviceAgreementForm['stage'].isHide = false;
          serviceAgreementForm['customer_id'].readOnly = true;
          serviceAgreementForm['product_category_id'].readOnly = true;
          serviceAgreementForm['product_brand_id'].readOnly = true;
          serviceAgreementForm['product_model_id'].readOnly = true;
          // setServiceRequestHeading(serviceRequestProperties.editServiceRequest);
        } else {
          setIsView(true);
          if (queryParam['isUpcoming'] === 'true') {
            setServiceRequestHeading(
              productServiceAgreementProperties.viewUpcomingPsa
            );
          } else {
            setServiceRequestHeading(productServiceAgreementProperties.viewPsa);
          }
          serviceAgreementForm['service_agreement_no'].isHide = false;
          serviceAgreementForm['stage'].isHide = false;
          serviceAgreementForm['customer_id'].readOnly = true;
          serviceAgreementForm['service_agreement_type'].readOnly = true;
          serviceAgreementForm['product_category_id'].readOnly = true;
          serviceAgreementForm['product_brand_id'].readOnly = true;
          serviceAgreementForm['product_model_id'].readOnly = true;
          serviceAgreementForm['start_date'].readOnly = true;
          serviceAgreementForm['terminate_date'].readOnly = true;
          serviceAgreementForm['sa_module_name'].readOnly = true;
        }
      } else {
        serviceAgreementForm['service_agreement_no'].isHide = true;
        serviceAgreementForm['stage'].isHide = true;
      }
    }
    getBackGroundData();

    if (serviceAgreementId > 0) {
      setServiceAgreementId(serviceAgreementId);
      getRouteOptionsHandler(serviceAgreementId);
      getServiceAgreementData(
        serviceAgreementId,
        queryParam['isUpcoming'] === 'true'
      );
    } else {
      if (typeof queryParam['productId'] != 'undefined') {
        setProductId(queryParam['productId']);
        serviceAgreementForm['customer_id'].readOnly = true;
        serviceAgreementForm['product_category_id'].readOnly = true;
        serviceAgreementForm['product_brand_id'].readOnly = true;
        serviceAgreementForm['product_model_id'].readOnly = true;
      }
    }
  }, []);

  useEffect(() => {
    getDataToTable();
  }, [productsSeriels]);

  useEffect(() => {
    if (productId != null) {
      getProductDataByProductId();
    }
  }, [productId]);

  const getRouteOptionsHandler = (psaId) => {
    getRouteOptions(psaId)
      .then((result) => {
        if (result.status === 200) {
          setRouteOptions(result.data);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text:
              productServiceAgreementProperties.messages.error
                .loadServiceAgreementTypes,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text:
            productServiceAgreementProperties.messages.error
              .loadServiceAgreementTypes,
        });
      });
  };

  const preventFormSubmitFromEnter = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
    }
  };

  let formElementArray = [];
  for (let key in serviceAgreementForm) {
    formElementArray.push({
      id: key,
      config: serviceAgreementForm[key],
    });
  }
  const setIsLoading = (val) => {
    if (val) {
      loadingCount.count += 1;
    } else if (loadingCount.count > 0) {
      loadingCount.count -= 1;
    }
    if (loadingCount.count === 0) {
      setIsLoadingIcon(false);
      let formData = { ...serviceAgreementForm };
      setServiceAgreementForm(formData);
    } else {
      setIsLoadingIcon(true);
    }
  };

  const getProductDataByProductId = async () => {
    setIsLoading(true);
    try {
      const result = await getProduct(productId);
      if (result.status === 200) {
        if (result.data != null) {
          const productList = [];
          const customerOptions = [];
          customerOptions.push({
            value: result.data['customerId'],
            displayValue: result.data['customerName'],
          });
          productList.push(result.data);
          getProductsDataByCustomer(
            productList,
            undefined,
            undefined,
            customerOptions
          );
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const setDataToServiceAgreementForm = (
    key,
    options,
    isFirstTime,
    optionValue
  ) => {
    let serviceAgreementFormData = { ...serviceAgreementForm };
    let formData = serviceAgreementFormData[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== '') {
      defaultValue = inputProps.initvalue;
    } else if (optionValue !== undefined) {
      // default we sould not select any thing for some drop downs
      defaultValue = optionValue;
    } else {
      // defaultValue = options.length > 0 ? options[0].value : '';
      if (key === 'product_category_id') {
        defaultValue = options.length > 0 ? options[0].value : '';
      } else if (key === 'product_brand_id' || key === 'product_model_id') {
        defaultValue =
          options.length > 1
            ? 'All'
            : options.length > 0
            ? options[0].value
            : '';
      } else {
        defaultValue = options.length > 0 ? options[0].value : '';
      }
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    serviceAgreementFormData[key] = formData;

    setServiceAgreementForm(serviceAgreementFormData);
    return defaultValue;
  };

  const getBackGroundData = (isUserPriority) => {
    getServiceAgreementTypeData();
    getSubStatusData();
  };

  const getServiceAgreementData = async (saId, isUpcoming) => {
    setIsLoading(true);
    await getProductServiceAgreement(saId, isUpcoming)
      .then((result) => {
        setServiceAgreement(result.data);
        applyServiceAgreementData(result.data, isUpcoming);
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: productServiceAgreementProperties.messages.error.loadData,
        });
        setIsLoading(false);
      });
  };

  const applyServiceAgreementData = async (
    serviceAgreementData,
    isUpcoming
  ) => {
    let serviceAgreementFormClone = JSON.parse(
      JSON.stringify(serviceAgreementForm)
    );

    // let categoryId;
    // let brandId;
    // let modelId;
    // if (serviceAgreeementData[productListKey] != null) {
    //   serviceAgreeementData[productListKey].map((productDto) => {
    //     if (categoryId === undefined) {
    //       categoryId = productDto.product.productCategoryId;
    //     }
    //     if (brandId === undefined) {
    //       brandId = productDto.product.productBrandId;
    //     }
    //     if (modelId === undefined) {
    //       modelId = productDto.product.productModelId;
    //     }
    //   });
    // }

    for (let key in serviceAgreementFormClone) {
      let value = '';
      if (key === 'service_agreement_no') {
        if (isUpcoming) {
          value = serviceAgreementData['psaId'];
        } else {
          value = serviceAgreementData['id'];
        }
        setDataToServiceAgreementForm(key, null, false, value);
      }
      if (key === 'stage') {
        setDataToServiceAgreementForm(
          'stage',
          null,
          false,
          serviceAgreementData['stage_name']
        );
      }
      if (key === 'customer_id') {
        value = serviceAgreementData['customer_id'];
        if (value) {
          let customerOptions = [];
          customerOptions.push({
            value: serviceAgreementData['customer_id'],
            displayValue: serviceAgreementData['customerName'],
          });
          setDataToServiceAgreementForm('customer_id', customerOptions, false);
          await customerChangeHandler(value, serviceAgreementData, isUpcoming);
        }
      }
      if (key === 'service_agreement_type') {
        // value = serviceAgreeementData['customer_id'];
      }
      if (key === 'start_date') {
        value = serviceAgreementData['issueDate'];
        setDataToServiceAgreementForm(key, null, false, value);
      }
      if (key === 'terminate_date') {
        value = serviceAgreementData['terminationDate'];
        setDataToServiceAgreementForm(key, null, false, value);
      }
    }

    setServiceAgreementForm(serviceAgreementFormClone);
    setIsLoading(false);
  };

  const getServiceAgreementTypeData = () => {
    const saTypes = localStorage.getItem('saTypes');
    if (saTypes != null) {
      setDataToServiceAgreementForm(
        'service_agreement_type',
        JSON.parse(saTypes),
        true
      );
    } else {
      setIsLoading(true);
      getServiceAgreementTypes()
        .then((result) => {
          asyncDataState.sa_module_name = true;
          if (result.status === 200) {
            setIsLoading(false);
            let saTypesOptions = [];
            result.data.map((sa) =>
              saTypesOptions.push({
                value: sa.id,
                displayValue: sa.name,
              })
            );

            let defaultVal = setDataToServiceAgreementForm(
              'service_agreement_type',
              saTypesOptions,
              true
              // null
            );
            localStorage.setItem('saTypes', JSON.stringify(saTypesOptions));
          } else {
            setIsLoading(false);
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : productServiceAgreementProperties.messages.error
                    .loadServiceAgreementTypes,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text:
              productServiceAgreementProperties.messages.error
                .loadServiceAgreementTypes,
          });
        });
    }
  };

  const getSubStatusData = () => {
    const productSubStatus = localStorage.getItem('productSubStatus');
    if (productSubStatus != null) {
      setProductSubStatusList(JSON.parse(productSubStatus));
    } else {
      setIsLoading(true);
      getProductSubStatusData()
        .then((result) => {
          asyncDataState.product_sub_status = true;
          if (result.status === 200) {
            let prodSubStatusOptions = [];
            result.data.map((status) =>
              prodSubStatusOptions.push({
                value: status.id,
                displayValue: status.name,
              })
            );
            localStorage.setItem(
              'productSubStatus',
              JSON.stringify(prodSubStatusOptions)
            );
            setProductSubStatusList(prodSubStatusOptions);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : productServiceAgreementProperties.messages.error
                    .loadProductSubStatuses,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text:
              productServiceAgreementProperties.messages.error
                .loadProductSubStatuses,
          });
        });
    }
  };

  const getServiceAgreementModuleData = (cateGoryId, saModuleId) => {
    setIsLoading(true);

    getServiceAgreementsByCategory(cateGoryId)
      .then((result) => {
        asyncDataState.sa_module_name = true;
        if (result.status === 200) {
          let saOptions = [];
          result.data.map((sa) =>
            saOptions.push({
              value: sa.id,
              displayValue: sa.name,
            })
          );

          setSaModules(result.data);

          let defaultVal = setDataToServiceAgreementForm(
            'sa_module_name',
            saOptions,
            false,
            saModuleId ? saModuleId : undefined
          );

          saModuleChangeHandler(result.data, defaultVal);
          setIsLoading(false);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productServiceAgreementProperties.messages.error
                  .loadServiceAgreementModules,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text:
            productServiceAgreementProperties.messages.error
              .loadServiceAgreementModules,
        });
        setIsLoading(false);
      });

    // getRequestTypeCategories(isUserPriority);
  };

  const generatePSAReportHandler = () => {
    setIsLoading(true);
    generatePSAReport(serviceAgreementId)
      .then((result) => {
        asyncDataState.product_sub_status = true;
        if (result.status === 200) {
          setIsLoading(false);
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Service Agreement Report for SA-${serviceAgreementId}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productServiceAgreementProperties.messages.error.reportGenerate,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productServiceAgreementProperties.messages.error.reportGenerate,
        });
      });
  };

  const customerChangeHandler = async (value, saData, isUpcoming) => {
    setIsLoading(true);
    try {
      const result = await getProductByCustomer(value);
      if (result.data.length > 0) {
        getProductsDataByCustomer(result.data, saData, isUpcoming);
      } else {
        clearServiceAgreementForm(true);
        notyDefault({
          type: notyTypes.ERROR,
          text: 'Selected customer has no products!',
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const categoryChangeHandler = (value, prodArr, brandArr) => {
    let productArr = prodArr ? prodArr : productsByCustomer;
    let brandOptions = [];
    productArr.map((prod) => {
      if (value === prod.productCategoryId) {
        const brandExists = brandOptions.find(
          (el) => el.value === prod.productBrandId
        );
        if (!brandExists) {
          brandOptions.push({
            value: prod.productBrandId,
            displayValue: prod.productBrandName,
          });
        }
      }
    });

    if (brandOptions.length > 1) {
      brandOptions.push({
        value: 'All',
        displayValue: 'All',
      });
    }

    if (brandArr === undefined) {
      let brandId = setDataToServiceAgreementForm(
        'product_brand_id',
        brandOptions,
        false
      );
      return brandId;
    }

    let brandId = setDataToServiceAgreementForm(
      'product_brand_id',
      brandOptions,
      false,
      brandArr.length === 1 ? brandArr[0] : undefined
    );

    return brandId;
  };

  const brandChangeHandler = (value, prodArr, modelArr) => {
    let productArr = prodArr ? prodArr : productsByCustomer;

    let modelOptions = [];
    productArr.map((prod) => {
      if (value === 'All') {
        let brandOptions =
          serviceAgreementForm['product_brand_id'].inputProps.options;

        brandOptions.map((option) => {
          if (prod.productBrandId === option.value) {
            const modelExists = modelOptions.find(
              (el) => el.value === prod.productModelId
            );
            if (!modelExists) {
              modelOptions.push({
                value: prod.productModelId,
                displayValue: prod.productModelName,
              });
            }
          }
        });
      } else {
        if (value === prod.productBrandId) {
          const modelExists = modelOptions.find(
            (el) => el.value === prod.productModelId
          );
          if (!modelExists) {
            modelOptions.push({
              value: prod.productModelId,
              displayValue: prod.productModelName,
            });
          }
        }
      }
    });

    if (modelOptions.length > 1) {
      modelOptions.push({
        value: 'All',
        displayValue: 'All',
      });
    }

    if (modelArr === undefined) {
      let modelId = setDataToServiceAgreementForm(
        'product_model_id',
        modelOptions,
        false
      );
      return modelId;
    }

    let modelId = setDataToServiceAgreementForm(
      'product_model_id',
      modelOptions,
      false,
      modelArr.length === 1 ? modelArr[0] : undefined
    );

    return modelId;
  };

  const modelChangeHandler = (prodArr, saData) => {
    let productArr = prodArr ? prodArr : productsByCustomer;
    let filteredProducts = [...productArr];
    let categoryId =
      serviceAgreementForm['product_category_id'].inputProps.value;
    let brandId = serviceAgreementForm['product_brand_id'].inputProps.value;
    let modelId = serviceAgreementForm['product_model_id'].inputProps.value;

    if (categoryId) {
      filteredProducts = filteredProducts.filter(
        (product) => product.productCategoryId === categoryId
      );
    }
    if (brandId) {
      if (brandId !== 'All') {
        filteredProducts = filteredProducts.filter(
          (product) => product.productBrandId === brandId
        );
      }
    }
    if (modelId) {
      if (modelId !== 'All') {
        filteredProducts = filteredProducts.filter(
          (product) => product.productModelId === modelId
        );
      }
    }

    setProductsSeriels(filteredProducts);

    let serialOptions = [];

    filteredProducts.map((product) => {
      serialOptions.push({
        value: product.serial,
        displayValue: product.serial,
      });
    });

    setDataToServiceAgreementForm(
      'serial_number',
      serialOptions,
      false,
      // srData && srData.serial_number
      null
    );

    const selected = filteredProducts.find(
      (product) =>
        product.serial ===
        serviceAgreementForm['serial_number'].inputProps.value
    );
  };

  const saModuleChangeHandler = (saModuleList, saValue) => {
    const serviceAgreementFormClone = JSON.parse(
      JSON.stringify(serviceAgreementForm)
    );
    const list = saModuleList ? saModuleList : saModules;
    const value = saValue
      ? saValue
      : serviceAgreementFormClone['sa_module_name'].inputProps.value;
    const saModule = list.find((el) => el.id === value);

    if (saModule) {
      serviceAgreementFormClone['service_agreement_type'].inputProps.value =
        saModule.type_id;

      setDataToServiceAgreementForm(
        'service_agreement_type',
        serviceAgreementFormClone['service_agreement_type'].inputProps.options,
        false,
        saModule.type_id ? saModule.type_id : undefined
      );

      const startDateVal =
        serviceAgreementFormClone['start_date'].inputProps.value;

      if (startDateVal) {
        let terminateDate = new Date(startDateVal);
        terminateDate.setMonth(terminateDate.getMonth() + saModule.duration);

        let formData = { ...serviceAgreementForm };
        let elementData = formData['terminate_date'];
        let inputProps = { ...elementData.inputProps }; //  copy input data
        inputProps.value = terminateDate;
        elementData.inputProps = inputProps;
        formData['terminate_date'] = elementData;
        setServiceAgreementForm(formData);
      }
    }
  };

  const getProductsDataByCustomer = (
    productData,
    saData,
    isUpcoming,
    customerOptions
  ) => {
    const prodArr = [];
    productData.map((data) => {
      prodArr.push(data);
    });
    setProductsByCustomer(prodArr);

    setDataToServiceAgreementForm(
      'customer_id',
      customerOptions
        ? customerOptions
        : serviceAgreementForm['customer_id'].inputProps.options,
      false,
      saData ? saData.customer_id : prodArr[0].customerId
    );

    let cateOptions = [];
    prodArr.map((prod) => {
      const cateExists = cateOptions.find(
        (el) => el.value === prod.productCategoryId
      );
      if (!cateExists) {
        cateOptions.push({
          value: prod.productCategoryId,
          displayValue: prod.productCategoryName,
        });
      }
    });

    let categoryId;
    let brandIdArr = [];
    let modelIdArr = [];

    let saModuleId = saData !== undefined && saData['serviceAgreementId'];

    const prodListKey = isUpcoming ? 'upcomingProductList' : 'productList';

    if (saData !== undefined && saData[prodListKey] != null) {
      saData[prodListKey].map((productDto) => {
        if (categoryId === undefined) {
          categoryId = productDto.product.productCategoryId;
        }
        const brandExists = brandIdArr.find(
          (el) => el === productDto.product.productBrandId
        );
        if (!brandExists) {
          brandIdArr.push(productDto.product.productBrandId);
        }
        const modelExists = modelIdArr.find(
          (el) => el === productDto.product.productModelId
        );
        if (!modelExists) {
          modelIdArr.push(productDto.product.productModelId);
        }
      });
    }

    const cateGoryId = setDataToServiceAgreementForm(
      'product_category_id',
      cateOptions,
      false,
      saData ? categoryId : undefined
    );

    if (cateGoryId) {
      getServiceAgreementModuleData(cateGoryId, saModuleId);
    }

    const brandId = categoryChangeHandler(
      saData ? categoryId : prodArr[0].productCategoryId,
      prodArr,
      brandIdArr
    );

    brandChangeHandler(
      saData ? brandId : prodArr[0].productBrandId,
      prodArr,
      modelIdArr
    );
    modelChangeHandler(prodArr, saData);
  };

  const onChangeHandeler = async (event, elId, meta) => {
    let value;
    let formData = { ...serviceAgreementForm };
    let elementData = formData[elId]; // get data for form key
    if (elementData.elType == 'date') {
      value = event.toDate();
    } else {
      value = event.target.value;
    }

    if (elId === 'start_date') {
      const saModule = saModules.find(
        (el) => el.id === formData['sa_module_name'].inputProps.value
      );
      if (saModule) {
        let terminateDate = new Date(value);
        terminateDate.setMonth(terminateDate.getMonth() + saModule.duration);
        formData['terminate_date'].inputProps.value = terminateDate;
      }
    }

    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;

    setServiceAgreementForm(formData);

    if (elId === 'customer_id') {
      await customerChangeHandler(value);
    }
    if (elId === 'product_category_id') {
      let brandId = categoryChangeHandler(value);
      brandChangeHandler(brandId);
      modelChangeHandler();
      getServiceAgreementModuleData(value);
    }
    if (elId === 'product_brand_id') {
      brandChangeHandler(value);
      modelChangeHandler();
    }
    if (elId === 'product_model_id') {
      modelChangeHandler();
    }
    if (elId === 'sa_module_name') {
      saModuleChangeHandler();
    }
  };

  const getDataToTable = () => {
    const tableDataArr = [];
    let saProductList;
    if (serviceAgreement) {
      saProductList = isUpcoming
        ? serviceAgreement['upcomingProductList']
        : serviceAgreement['productList'];
    }

    productsSeriels.map((product, index) => {
      if (saProductList) {
        const existingProduct = saProductList.find(
          (saProd) => saProd.product.id === product.id
        );

        if (existingProduct) {
          tableDataArr.push({
            id: index + 1,
            product_id: product.id,
            serial_no: product.serial,
            sub_status: {
              value: product.productSubStatusId,
              displayValue: product.productSubStatusName,
            },
            product_category: product.productCategoryName,
            product_brand: product.productBrandName,
            product_model: product.productModelName,
            old_sa_value: existingProduct.sa_price
              ? existingProduct.sa_price
              : 0,
            new_sa_value: existingProduct.sa_price
              ? existingProduct.sa_price
              : 0,
            start_date: product.issueDate,
            terminate_date: product.terminationDate,
          });
        }
      } else {
        tableDataArr.push({
          id: index + 1,
          product_id: product.id,
          serial_no: product.serial,
          sub_status: {
            value: product.productSubStatusId,
            displayValue: product.productSubStatusName,
          },
          product_category: product.productCategoryName,
          product_brand: product.productBrandName,
          product_model: product.productModelName,
          old_sa_value: product.price ? product.price : 0,
          new_sa_value: product.price ? product.price : 0,
          start_date: product.issueDate,
          terminate_date: product.terminationDate,
        });
      }
    });
    setTableData(tableDataArr);
  };

  const createBulkProductServiceAgreements = (data) => {
    setIsLoading(true);
    saveBulkProductServiceAgreements(data)
      .then((result) => {
        asyncDataState.product_sub_status = true;
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: productServiceAgreementProperties.messages.success.saveData,
          });
          clearSelection();
          setIsLoading(false);
          // props.history.goBack();
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productServiceAgreementProperties.messages.error.saveData,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productServiceAgreementProperties.messages.error.saveData,
        });
      });
  };

  const updateProductServiceAgreementData = (data) => {
    setIsLoading(true);
    updateProductServiceAgreements(serviceAgreementId, data, isUpcoming)
      .then((result) => {
        asyncDataState.product_sub_status = true;
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: productServiceAgreementProperties.messages.success.saveData,
          });
          clearSelection();
          setIsLoading(false);
          // props.history.goBack();
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productServiceAgreementProperties.messages.error.saveData,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: productServiceAgreementProperties.messages.error.saveData,
        });
        setIsLoading(false);
      });
  };

  const updatePSAWorkflowStageHandler = (routeData) => {
    const data = {};
    for (let key in routeData) {
      data[key] = routeData[key];
    }
    setIsLoading(true);
    updatePSAWorkflowStage(serviceAgreementId, data)
      .then((result) => {
        asyncDataState.product_sub_status = true;
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: productServiceAgreementProperties.messages.success.saveData,
          });
          clearSelection();
          setIsLoading(false);
          // props.history.goBack();
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productServiceAgreementProperties.messages.error.saveData,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: productServiceAgreementProperties.messages.error.saveData,
        });
        setIsLoading(false);
      });
  };

  const cellStyle = {
    textAlign: 'center',
    fontSize: '12px',
    color: '#555',
    fontWeight: '450',
  };

  const onSavePSAHandler = (routeData) => {
    let psaId;
    let issueDate;
    let terminationDate;
    let saModuleId;
    let saType;
    let customerId;

    formElementArray.map((el) => {
      if (el.id === 'service_agreement_no') {
        psaId = el.config?.inputProps?.value;
      }
      if (el.id === 'customer_id') {
        customerId = el.config?.inputProps?.value;
      }
      if (el.id === 'start_date') {
        issueDate = el.config?.inputProps?.value;
      }
      if (el.id === 'terminate_date') {
        terminationDate = el.config?.inputProps?.value;
      }
      if (el.id === 'sa_module_name') {
        saModuleId = el.config?.inputProps?.value;
      }
      if (el.id === 'service_agreement_type') {
        saType = el.config?.inputProps?.value;
      }
    });

    const assignUserId =
      localStorage.getItem('userId') &&
      parseInt(localStorage.getItem('userId'));

    const psaProductDtoList = [];

    if (isEdit) {
      tableData.map((product) => {
        const prodDto = {
          productId: product.product_id,
          price: product.new_sa_value,
          subStatusId: product.sub_status.value,
        };
        psaProductDtoList.push(prodDto);
      });
    } else {
      selectedIdList.map((product) => {
        const prodDto = {
          productId: product.product_id,
          price: product.new_sa_value,
          subStatusId: product.sub_status.value,
        };
        psaProductDtoList.push(prodDto);
      });
    }

    if (
      !customerId ||
      !saModuleId ||
      !saType ||
      !issueDate ||
      !terminationDate
    ) {
      const errorMsgForm = `Please select ${
        !customerId ? 'Customer Name,' : ''
      } ${!saModuleId ? 'SA Module,' : ''} ${!saType ? 'SA Type,' : ''} ${
        !issueDate ? 'Start Date,' : ''
      } ${!terminationDate ? 'Terminate Date,' : ''}!`;

      notyDefault({
        type: notyTypes.ERROR,
        text: errorMsgForm,
      });
      return;
    }

    if (issueDate >= terminationDate) {
      notyDefault({
        type: notyTypes.ERROR,
        text: 'Please select valid date range!',
      });
      return;
    }

    if (psaProductDtoList.length === 0) {
      notyDefault({
        type: notyTypes.ERROR,
        text: 'Please select at least one product!',
      });
      return;
    }

    const requestBody = {
      serviceAgreementId: saModuleId,
      issueDate,
      terminationDate,
      active: true,
      assign_user_id: assignUserId,
      customer_id: customerId,
      saType,
      psaProductDtoList,
    };

    if (isUpcoming) {
      requestBody['psaId'] = psaId;
    }

    if (isEdit === true) {
      updateProductServiceAgreementData(requestBody);
    } else {
      createBulkProductServiceAgreements(requestBody);
    }
  };

  const clearSelection = () => {
    // Should check that the element is available to prevent errors
    if (tableRef?.current) {
      tableRef.current.onAllSelected(false);
    }
  };

  const clearServiceAgreementForm = (isCusOptClear) => {
    setDataToServiceAgreementForm('product_category_id', [], false);
    setDataToServiceAgreementForm('product_brand_id', [], false);
    setDataToServiceAgreementForm('product_model_id', [], false);
    setDataToServiceAgreementForm('serial_number', [], false);
    setDataToServiceAgreementForm('sa_module_name', [], false);
    if (!isCusOptClear) {
      setProductsByCustomer([]);
      setDataToServiceAgreementForm('customer_id', [], false);
    }
    setProductsSeriels([]);
    setSelectedIdList([]);
    clearSelection();
  };

  const routeHandler = () => {
    setIsWfRouteDialogOpen(true);
  };

  const routeSubmitHandler = (routeData) => {
    if (routeData != null && routeData.currentStage != null) {
      updatePSAWorkflowStageHandler(routeData);
      setIsWfRouteDialogOpen(false);
    }
  };

  const routeCloseHandler = () => {
    setIsWfRouteDialogOpen(false);
  };

  const serviceAgreementsTable = () => {
    return (
      <Card>
        <MaterialTable
          tableRef={tableRef}
          title={''}
          localization={{
            toolbar: {
              nRowsSelected: '',
            },
          }}
          columns={[
            {
              title: '#',
              field: 'id',
              editable: 'never',
              width: '10%',
              cellStyle: cellStyle,
            },
            {
              title: 'Product Id',
              field: 'product_id',
              editable: 'never',
              cellStyle: cellStyle,
            },
            {
              title: 'Serial No',
              field: 'serial_no',
              editable: 'never',
              cellStyle: cellStyle,
            },

            {
              title: 'Product Category',
              field: 'product_category',
              editable: 'never',
              cellStyle: cellStyle,
            },
            {
              title: 'Product Brand',
              field: 'product_brand',
              editable: 'never',
              cellStyle: cellStyle,
            },
            {
              title: 'Product Model',
              field: 'product_model',
              editable: 'never',
              cellStyle: cellStyle,
            },
            {
              title: 'Sub Status',
              field: 'sub_status',
              width: '200px',

              editComponent: (props, index) => (
                <div style={{ position: 'relative' }}>
                  <CustomInput
                    labelText={''}
                    id={''}
                    inputProps={{
                      ...{
                        options: productSubStatusList,
                        value: props.value.value,
                        initvalue: productSubStatusList[0].value,
                        disabled: false,
                      },
                    }}
                    type={'select'}
                    formControlProps={{ fullWidth: true }}
                    changed={(event, value) => {
                      const subStatus = productSubStatusList.find(
                        (status) => status.value === event.target.value
                      );

                      if (subStatus) {
                        const productClone = productsSeriels.map((product) =>
                          product.id === props.rowData.product_id
                            ? {
                                ...product,
                                productSubStatusId: subStatus.value,
                                productSubStatusName: subStatus.displayValue,
                              }
                            : product
                        );

                        setProductsSeriels(productClone);
                      }
                    }}
                  />
                </div>
              ),
              cellStyle: { ...cellStyle, minWidth: 100, maxWidth: 100 },
              render: (data) => <div>{data['sub_status'].displayValue}</div>,
            },
            {
              title: 'Old SA Value',
              field: 'old_sa_value',
              editable: 'never',
              // filterField: 'stageFilter',
              render: (data) => currencyFormatter(data.old_sa_value),
              cellStyle: cellStyle,
            },
            {
              title: 'New SA Value',
              field: 'new_sa_value',
              editComponent: (props, index) => (
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  value={props.value}
                  name="new_sa_value"
                  InputProps={{
                    className: styles.adjustmentInput,
                  }}
                  onChange={(e) => {
                    props.onChange(e.target.value);
                    // this.onChangeTableInput(e, props.rowData);
                  }}
                />
              ),
              render: (data) => currencyFormatter(data.new_sa_value),
              cellStyle: { ...cellStyle, minWidth: 70, maxWidth: 70 },
            },
          ]}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const updatedTableData = tableData.map((rowData) =>
                  rowData.product_id === oldData.product_id
                    ? {
                        ...rowData,
                        new_sa_value: parseInt(newData.new_sa_value),
                      }
                    : rowData
                );
                setTableData(updatedTableData);
                clearSelection();
                setSelectedIdList([]);
                resolve();
              }),
            isEditHidden: (rowData) => isView,
          }}
          components={{
            // eslint-disable-next-line react/display-name
            OverlayLoading: () => (
              <div className={styles.loadingOverlay}>
                <Spinner />
              </div>
            ),
          }}
          data={tableData}
          onSelectionChange={(rows) => {
            setSelectedIdList(rows);
          }}
          options={{
            actionsColumnIndex: -1,
            selection: isView || isEdit ? false : true,
            // filtering: true,
            maxBodyHeight: '70vh',
            sorting: false,
            search: false,
            headerStyle: {
              fontSize: '0.7rem',
              backgroundColor: '#e6e4df',
              textAlign: 'center',
            },
            rowStyle: {
              fontSize: '12px',
              textAlign: 'center',
              fontWeight: 450,
              padding: '5rem',
            },
          }}
        />
      </Card>
    );
  };

  const searchCustomer = (element) => {
    const value = element.config.inputProps.tempValue;
    if (value.length > 0) {
      setIsLoading(true);
      clearServiceAgreementForm();
      getCustomerByName(value)
        .then((result) => {
          asyncDataState.customer_id = true;
          if (result.status === 200) {
            if (result.data.length > 0) {
              let customerOptions = [];
              result.data.map((customer) =>
                customerOptions.push({
                  value: customer.id,
                  displayValue: customer.name + ' ' + `(${customer.address})`,
                })
              );

              let defaultVal = setDataToServiceAgreementForm(
                'customer_id',
                customerOptions,
                true,
                null
              );
            }
            setIsLoading(false);
          } else {
            clearServiceAgreementForm();
            setIsLoading(false);
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : productServiceAgreementProperties.messages.error
                    .loadCustomers,
            });
          }
        })
        .catch((error) => {
          clearServiceAgreementForm();
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text:
              productServiceAgreementProperties.messages.error.loadCustomers,
          });
        });
      element.config.inputProps.tempValue = '';
    }
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <WorkflowRoute
          isWfRouteDialogOpen={isWfRouteDialogOpen}
          routeOptions={routeOptions}
          routeSubmitHandler={routeSubmitHandler}
          routeCloseHandler={routeCloseHandler}
        />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <div className="generic-form-container">
                <Form
                  onSubmit={onSavePSAHandler}
                  initialValues={formState}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    valid,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      onKeyDown={preventFormSubmitFromEnter}
                    >
                      <CardBody>
                        <div
                          className={'generic-form-header-wrapper'}
                          style={{
                            marginBottom: '30px',
                            padding: 0,
                          }}
                        >
                          <div
                            className={'generic-page-title'}
                            style={{
                              padding: 0,
                            }}
                          >
                            <span className={'generic-page-title-icon'}>
                              <AssignmentTurnedInIcon />
                            </span>
                            <Typography
                              variant="h6"
                              style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                            >
                              {serviceRequestHeading}
                            </Typography>
                          </div>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <Button
                                class="generic-button-class div-button-wrapper"
                                variant="contained"
                                onClick={() => {
                                  generatePSAReportHandler();
                                }}
                                color="primary"
                                style={{
                                  float: 'right',
                                  marginLeft: '5px',
                                  marginRight: '5px',
                                }}
                              >
                                Print
                              </Button>

                              <Button
                                class="generic-button-class div-button-wrapper"
                                variant="contained"
                                onClick={() => {
                                  routeHandler();
                                }}
                                color="primary"
                                style={{
                                  float: 'right',
                                  marginLeft: '5px',
                                  marginRight: '5px',
                                }}
                              >
                                Route
                              </Button>

                              {!isView && (
                                <Button
                                  class="generic-button-class div-button-wrapper"
                                  variant="contained"
                                  type="submit"
                                  color="primary"
                                  style={{
                                    float: 'right',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                  }}
                                >
                                  Save
                                </Button>
                              )}
                            </GridItem>
                          </GridContainer>
                        </div>
                        <GridContainer>
                          {formElementArray
                            .sort((a, b) =>
                              isEdit || isView
                                ? a.config.idt1 - b.config.idt1
                                : a.config.idt2 - b.config.idt2
                            )
                            .map((element) => {
                              if (!element.config.isHide) {
                                let mdVal = element.config.size;
                                return (
                                  <React.Fragment key={element.id + '_frag'}>
                                    <GridItem
                                      key={element.id}
                                      xs={12}
                                      sm={12}
                                      md={mdVal}
                                    >
                                      {element.id === 'comments' ? (
                                        <div> Comments</div>
                                      ) : (
                                        ''
                                      )}
                                      <Field name={element.id}>
                                        {({ input, meta, options, value }) => (
                                          <div style={{ position: 'relative' }}>
                                            <CustomInput
                                              labelText={element.config.label}
                                              id={element.id}
                                              inputProps={{
                                                ...input,
                                                ...element.config.inputProps,
                                                readOnly:
                                                  element.config.readOnly &&
                                                  element.config.readOnly,
                                              }}
                                              type={element.config.elType}
                                              formControlProps={
                                                element.config.formControlProps
                                              }
                                              adornmentText={
                                                element.config.adornmentText
                                              }
                                              adornmentPosition={
                                                element.config.adornmentPosition
                                              }
                                              changed={(event, value) => {
                                                input.onChange(event);
                                                onChangeHandeler(
                                                  event,
                                                  element.id,
                                                  meta
                                                );
                                              }}
                                              onSelectInputChange={(event) => {
                                                element.config.inputProps.tempValue =
                                                  event.target.value;
                                                if (
                                                  event &&
                                                  (event.key === 'Enter' ||
                                                    event.keyCode === 13)
                                                ) {
                                                  if (
                                                    element.id === 'customer_id'
                                                  ) {
                                                    searchCustomer(element);
                                                  }
                                                }
                                              }}
                                              onAdornmentClick={
                                                element.id === 'customer_id'
                                                  ? () => {
                                                      searchCustomer(element);
                                                    }
                                                  : undefined
                                              }
                                              labelProps={{
                                                ...element.config.labelProps,
                                                error:
                                                  meta.error && meta.touched,
                                              }}
                                            />
                                            {meta.error && meta.touched && (
                                              <span
                                                className={styles.formError}
                                              >
                                                {meta.error}
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </Field>
                                    </GridItem>
                                    {element.id === 'estimated_time_minutes' ? (
                                      <GridItem
                                        key={element.id + '_minute'}
                                        xs={12}
                                        sm={12}
                                        md={8}
                                      ></GridItem>
                                    ) : (
                                      ''
                                    )}
                                  </React.Fragment>
                                );
                              }
                            })}
                        </GridContainer>
                      </CardBody>
                    </form>
                  )}
                />
              </div>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {serviceAgreementsTable()}
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default AddServiceAgreement;
