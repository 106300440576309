import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios/axios-default';
import { estimationProperties } from 'Util/Property/EstimationProperties';

export async function getAllEstimations() {
  try {
    return axios.get(urlProperties.estimation.getEstimations);
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
  } catch (error) {
    console.log(estimationProperties.messages.error.loadAll);
  }
}

export async function saveEstimation(method, url, data) {
  try {
    return await axios({
      method: method,
      url: url,
      data: data,
    });
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
  } catch (error) {
    console.log(estimationProperties.messages.error.saveData);
  }
}

export async function getEstimation(id) {
  try {
    return axios.get(urlProperties.estimation.getEstimation + '/' + id);
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
  } catch (error) {
    console.log(estimationProperties.messages.error.loadData);
  }
}

export async function deleteEstimation(id) {
  try {
    return axios.delete(urlProperties.estimation.deleteEstimations + '/' + id);
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
  } catch (error) {
    console.log(estimationProperties.messages.error.delete);
  }
}

export async function getEstimationByCustomer(customerId) {
  try {
    return axios.get(
      urlProperties.estimation.getEstimationByCustId + '/' + customerId
    );
    //return axios.get(urlProperties.estimation.getCustomerEstimations);
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
  } catch (error) {
    console.log(estimationProperties.messages.error.loadData);
  }
}

export async function getEstimationHistory(estimationId) {
  return axios.get(
    urlProperties.estimation.getEstimationHistory + '/' + estimationId
  );
}

export function getRouteOptions(id) {
  return axios.get('checkEstimationRouteOptions/' + id);
}

export async function generateEstimationReport(id) {
  try {
    return axios({
      url: `${urlProperties.estimation.reportGenerate}/${id}`,
      method: 'GET',
      responseType: 'blob',
    });
  } catch (error) {
    console.log(estimationProperties.messages.error.reportGenerate);
  }
}
