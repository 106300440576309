import * as ActionTypes from './searchActionType';

export const openSearch = (id) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SEARCH_OPEN });
  };
};

export const closeSearch = (id) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SEARCH_CLOSE });
  };
};
