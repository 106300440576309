import urlProperties from "Util/Property/UrlProperties";
import axios from "axios/axios-default";
import { estimationProperties } from "Util/Property/EstimationProperties";

const baseUrl = urlProperties.baseUrl;
export async function getOnDutyEmpCount() {
  try {
    return axios.get(urlProperties.dashboard.getOnDutyEmployees);
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
  } catch (error) {
    console.log(estimationProperties.messages.error.loadAll);
  }
}

export async function getIdleEmpCount() {
  try {
    return axios.get(urlProperties.dashboard.getIdleEmployees);
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
  } catch (error) {
    console.log(estimationProperties.messages.error.loadAll);
  }
}

export async function getCurrentUserLocations() {
  return axios.get(urlProperties.dashboard.getCurrentUserLocations);
}

export async function getRepetitiveBreakdown(startDate, endDate, limit) {
  return axios.get(
    `${baseUrl}getRepetitiveBreakdown?startDate=${startDate}&endDate=${endDate}&limit=${limit}`
  );
}

export async function getApprovedEstimations(startDate, endDate, limit) {
  return axios.get(
    `${baseUrl}getApprovedEstimations?startDate=${startDate}&endDate=${endDate}&limit=${limit}`
  );
}
export async function getCostPerJobByMachine(startDate, endDate, limit,srType) {
  return axios.get(
    `${baseUrl}getCostPerJobByMachine?srType=${srType}&startDate=${startDate}&endDate=${endDate}&limit=${limit}`
  );
}
export async function getCostPerJobByRequestType(startDate, endDate, limit) {
  return axios.get(
    `${baseUrl}getCostPerJobByRequestType?startDate=${startDate}&endDate=${endDate}&limit=${limit}`
  );
}
export async function getCostPerJobByTechnician(startDate, endDate, limit) {
  return axios.get(
    `${baseUrl}getCostPerJobByTechnician?startDate=${startDate}&endDate=${endDate}&limit=${limit}`
  );
}

export async function getAttendingStatus(
  startDate,
  endDate,
  srTypeID,
  limit
) {
  return axios.get(
    `${baseUrl}getAttendingStatus?startDate=${startDate}&endDate=${endDate}&sRTypeId=${srTypeID}`
  );
}
export async function getSRCompletionStatus(
  startDate,
  endDate,
  srTypeID,
  limit
) {
  return axios.get(
    `${baseUrl}getSRCompletionStatus?startDate=${startDate}&endDate=${endDate}&sRTypeId=${srTypeID}`
  );
}
export async function getTaskCompletionStatus(
  startDate,
  endDate,
  srTypeID,
  limit
) {
  return axios.get(
    `${baseUrl}getTaskCompletionStatus?startDate=${startDate}&endDate=${endDate}&sRTypeId=${srTypeID}`
  );
}
export async function getDelayedAttention(
  startDate,
  endDate,
  limit
) {
  return axios.get(
    `${baseUrl}getDelayedAttention?startDate=${startDate}&endDate=${endDate}&limit=${limit}`
  );
}
export async function getDelayedTaskCompletion(
  startDate,
  endDate,
  limit
) {
  return axios.get(
    `${baseUrl}getDelayedTaskCompletion?startDate=${startDate}&endDate=${endDate}&limit=${limit}`
  );
}

export async function getTileConfigsByRoleId(roleId) {
  return axios.get(
    `${baseUrl}getHighlightConfigsByRoleId/${roleId}`
  );
}
