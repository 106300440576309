import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import {
  trainingProperties,
  getTrainingForm,
} from 'Util/Property/TraningProperties';
import LoadingOverlay from 'react-loading-overlay';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.js';
import { Form, Field } from 'react-final-form';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import CustomInput from 'components/CustomInput/CustomInput.js';
import UrlProperties from 'Util/Property/UrlProperties';
import * as trainingService from 'services/Training/TrainingService';
import styles from './Training.module.css';
import { Typography } from '@material-ui/core';
import ListAltOutlinedIcon from '@material-ui/icons/MessageOutlined';
import { getAllUsers } from 'services/User/UserService';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';

const Training = (props) => {
  const [trainingForm, setTrainingForm] = useState(getTrainingForm());
  const [trainingHeading, setTrainingHeading] = useState(
    trainingProperties.composeMessage
  );
  const [isLoading, setIsLoading] = useState(false);
  const [notifiactionId, setNotifiactionId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [allUserIds, setAllUserIds] = useState([]);
  const [permissions, setPermissions] = useState({});

  const [asyncDataState, setAsyncDataState] = useState({
    userIdList: false,
  });

  useEffect(() => {}, []);

  useEffect(() => {
    let notId = -1;

    if (props.id && props.id > 0) {
      if (props.isEdit) {
        setIsEdit(true);
      } else {
        setIsView(true);
      }
      notId = props.id;
      setTrainingHeading(trainingProperties.viewMessage);
    } else if (props.location) {
      const query = new URLSearchParams(props.location.search);
      let queryParam = {};
      for (let param of query.entries()) {
        queryParam[param[0]] = param[1];
      }
      if (typeof queryParam['id'] != 'undefined') {
        notId = queryParam['id'];
        setTrainingHeading(trainingProperties.viewMessage);
        if (queryParam['isEdit'] === 'true') {
          setIsEdit(true);
          setTrainingHeading(trainingProperties.viewMessage);
        } else {
          setIsView(true);
        }
      }
    }
    if (notId > -1) {
      // this is for view and edit Service Request
      //getServiceRequestData(serviceRequestId);
      //setServiceRequestId(serviceRequestId);
      setNotifiactionId(notId);
      getNotifiactionData(notId);
    } else {
      // this is loading for add new Service Request
      getBackGroundData();
    }
  }, [props.id]);

  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addTraining,
      PermissionProperties.editTraining,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  const getNotifiactionData = async (serviceRequestId) => {
    setIsLoading(true);
    await trainingService
      .viewNotification(serviceRequestId)
      .then((result) => {
        let form = { ...trainingForm };
        for (let key in form) {
          let value = result.data[key];
          if (key === 'userIdList') {
            let users = result.data['userList'];
            let displayString = users[0];
            for (let i = 1; i < users.length; i++) {
              displayString += ' ,' + users[i];
            }
            form[key].inputProps.value = displayString;
          } else {
            form[key].inputProps.value = value;
          }
        }
        setTrainingForm(form);
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: trainingProperties.messages.error.loadData,
        });
        setIsLoading(false);
      });
    // getBackGroundData();
    setIsLoading(false);
  };

  const getBackGroundData = () => {
    setIsLoading(true);
    getAllUsersForNotification();
  };

  const getAllUsersForNotification = () => {
    getAllUsers()
      .then((result) => {
        if (result.status === 200) {
          let userOptions = [];
          let userIdList = [];
          userOptions.push({ value: 0, displayValue: 'All Employees' });
          result.data.map((user) => {
            userIdList.push(user.id);
            userOptions.push({ value: user.id, displayValue: user.user_name });
          });
          setAllUserIds(userIdList);
          setDataToNotificationForm('userIdList', userOptions, true, true);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : trainingProperties.messages.error.loadUsers,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: trainingProperties.messages.error.loadUsers,
        });
        setIsLoading(false);
      });
  };

  const onChangeHandeler = (event, elId) => {
    let value = event.target.value;

    let formData = { ...trainingForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = event.target.value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setTrainingForm(formData);
  };

  const onMessageSend = async (values) => {
    //TODO
    let method = '';
    let url = '';
    let data = {};
    let tForm = { ...trainingForm };
    for (let key in tForm) {
      let val = tForm[key].inputProps.value;
      if (val !== '') {
        data[key] = val;
      } else {
        data[key] = 0;
      }
    }
    let userIds = tForm['userIdList'].inputProps.value;
    if (isContainAllEmployees(userIds)) {
      data['userIdList'] = allUserIds;
    }

    method = 'POST';
    url = UrlProperties.training.createNotification;

    setIsLoading(true);
    await trainingService
      .createNotification(method, url, data)
      .then((result) => {
        //this.props.history.goBack();
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: trainingProperties.messages.success.sent,
          });
          if (notifiactionId == 0) {
            setDefaultData();
          }
          setIsLoading(false);
          props.history.goBack();
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: trainingProperties.messages.error.sent,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: trainingProperties.messages.error.sent,
        });
      });
  };

  const isContainAllEmployees = (userIds) => {
    for (let id in userIds) {
      if (userIds[id] === 0) {
        return true;
      }
    }
    return false;
  };

  /**
   * set default form data after a form submission or adding new user
   */
  const setDefaultData = () => {
    let formData = { ...trainingForm };
    let defaultData = { ...getTrainingForm() };
    for (let key in defaultData) {
      let elementData = formData[key]; // get data for form key
      let inputProps = { ...elementData.inputProps }; //  copy input data
      inputProps.value = defaultData[key].inputProps.initvalue;
      elementData.inputProps = inputProps;
      formData[key] = elementData;
    }
    setTrainingForm(formData);
  };

  /**
   *
   * @param {*} key
   * @param {*} options
   * save api data to Service Request form
   */

  const setDataToNotificationForm = (key, options, isFirstTime, isMultiple) => {
    // let serviceRequestFormData = { ...serviceRequestForm };
    let formData = trainingForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== '') {
      defaultValue = inputProps.initvalue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : '';
    }
    if (isMultiple) {
      let arr = [];
      defaultValue = arr;
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    trainingForm[key] = formData;
    //setServiceRequestForm(serviceRequestFormData);
    checkForStateUpdate();
    return defaultValue;
  };

  /**
   * check for state update if criterias met then update the state
   */
  const checkForStateUpdate = (isFirstTime) => {
    let status = true;
    for (let key in asyncDataState) {
      if (asyncDataState[key] === false) {
        status = false;
        return false;
      }
    }
    if (status) {
      let formData = { ...trainingForm };
      setTrainingForm(formData);
    }
  };

  /**
   *  arrange data to populate Form UI
   */
  let formElementArray = [];
  for (let key in trainingForm) {
    formElementArray.push({
      id: key,
      config: trainingForm[key],
    });
  }

  return (
    <div>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <div className="generic-form-container">
                {!isEdit ? (
                  <div className={'generic-form-header-wrapper'}>
                    <div className={'generic-page-title'}>
                      <span className={'generic-page-title-icon'}>
                        <ListAltOutlinedIcon />
                      </span>
                      <Typography
                        className={'generic-header-text'}
                        variant="h6"
                        style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                      >
                        Compose New Message
                      </Typography>
                      {/* <Typography variant="body1" >{trainingHeading}</Typography> */}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <Form
                  onSubmit={onMessageSend}
                  validate={(values, ss) => {
                    const errors = {};

                    return errors;
                  }}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    valid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <CardBody>
                        <GridContainer>
                          {formElementArray.map((element) => {
                            if (!element.config.isHide) {
                              let mdVal = element.config.size;
                              return (
                                <GridItem
                                  key={element.id}
                                  xs={12}
                                  sm={12}
                                  md={mdVal}
                                >
                                  <Field name={element.id}>
                                    {({ input, meta, options, value }) => (
                                      <div style={{ position: 'relative' }}>
                                        <CustomInput
                                          labelText={element.config.label}
                                          id={element.id}
                                          inputProps={{
                                            ...input,
                                            ...element.config.inputProps,
                                            readOnly: isView ? true : false,
                                          }}
                                          type={
                                            element.id === 'userIdList' &&
                                            isView
                                              ? 'input'
                                              : element.config.elType
                                          }
                                          formControlProps={
                                            element.config.formControlProps
                                          }
                                          adornmentText={
                                            element.config.adornmentText
                                          }
                                          adornmentPosition={
                                            element.config.adornmentPosition
                                          }
                                          multiple={element.config.multiple}
                                          changed={(event, value) => {
                                            input.onChange(event);
                                            onChangeHandeler(event, element.id);
                                          }}
                                          labelProps={{
                                            ...element.config.labelProps,
                                            error: meta.error && meta.touched,
                                          }}
                                        />
                                        {meta.error && meta.touched && (
                                          <span className={styles.formError}>
                                            {meta.error}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </GridItem>
                              );
                            }
                          })}
                        </GridContainer>
                      </CardBody>
                      <CardFooter>
                        {!isView &&
                        (permissions[PermissionProperties.addTraining] ||
                          permissions[PermissionProperties.editTraining]) ? (
                          <Button
                            type="submit"
                            disabled={submitting || !valid}
                            color="primary"
                            autoFocus
                          >
                            Send
                          </Button>
                        ) : (
                          ''
                        )}
                      </CardFooter>
                    </form>
                  )}
                />
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default Training;
