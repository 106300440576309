import * as actionTypes from '../actions/actionTypes';


let initState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    role: "",
    permissions: []
}

const reducer = (state = initState, actions) => {

    switch (actions.type) {
        case actionTypes.AUTH_START:
            let updatedState = { ...state };
            updatedState.error = null;
            updatedState.loading = true;
            return updatedState;
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                token: actions.authData.access_token,
                userId: actions.authData.localId,
                role: actions.authData.roleId,
                permissions: actions.authData.permissions,
                error: null,
                loading: false
            };
        case actionTypes.AUTH_FAILED:
            return {
                ...state,
                loading: false,
                error: actions.error
            }
        case actionTypes.AUTH_LOGOUT: {
            return {
                ...state,
                loading: false,
                error: null,
                userId: null,
                token: null,
                role: "",
                permissions: []
            }
        }
        default:
            return state;
    }
}

export default reducer;