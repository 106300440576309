export const ReportAndDashboardProperties = {
  messages: {
    error: {
      users: 'Could not load  Users. please try again',
      createNew: 'Could not Create New Tile. please try again',
      delete: 'Could not delete Tile config please try again',
      loadAll: 'Could not load Tile configs please try again',
      update: 'Could not update Tile. please try again',
      // workflowTypes: "Could not load workflow types",
      // workflowSubCategories: "Could not load workflow sub categories",
      // stages:"Could not load workflow stages",
      // status:"Could not load workflow status",
      loadData: 'Could not load tile configurations',
      // deleteStage: "Could not delete Stage please try again",
      // loadStage:"Could not load stage please try again",
      serviceRequesstTypes:
        'Could not Load Service Request Types please try again',
      stages: 'Could not Load Stages please try again',
      categories: 'Could not Load Product categories please try again',
    },
    success: {
      createNew: 'Tile created successfully',
      delete: 'Tile config deleted successfully',
      update: 'Tile Updated successfully',
      // deleteStage: "Stage deleted successfully"
    },
    normal: {
      deleteConfirm: 'Do you want to delete this Tile',
    },
  },
};

const stageArray = [
  { value: 'New', displayValue: 'New' },
  { value: 'Assigned', displayValue: 'Assigned' },
  { value: 'Travelling', displayValue: 'Travelling' },
  { value: 'On-Hold', displayValue: 'On Hold' },
  { value: 'Need-Further-Attention', displayValue: 'Need Further Attention' },
  { value: 'Need-workshop-attention', displayValue: 'Need workshop attention' },
];

const columns = [
  { value: '1', displayValue: '1' },
  { value: '2', displayValue: '2' },
  { value: '3', displayValue: '3' },
  { value: '4', displayValue: '4' },
];
const rows = [
  { value: '1', displayValue: '1' },
  { value: '2', displayValue: '2' },
  { value: '3', displayValue: '3' },
];
const reportsTypes = [
  { value: '1', displayValue: 'SR Type Wise' },
  { value: '2', displayValue: 'Product Wise' },
  { value: '3', displayValue: 'Employee Wise' },
];

// export const TilesForm = () => {
//   return {
//   //   name: {
//   //     elType: 'input',
//   //     label: 'Name',
//   //     inputProps: {
//   //       type: 'text',
//   //       placeholder: 'Name',
//   //       value: '',
//   //       initvalue: ''
//   //     },
//   //     formControlProps: {
//   //       fullWidth: true
//   //     },
//   //     labelProps: {
//   //       required: true,
//   //       error: false
//   //     },
//   //     size: 12,
//   //   },
//   userRole: {
//     elType: 'select',
//     label: 'User Role',
//     inputProps: {
//       options: [],
//       value: "",
//       initvalue: ""
//     },
//     formControlProps: {
//       fullWidth: true
//     },
//     size: 6,
//     isAsyncDropDown: true,
//     isHide:false,
//     isDisabled:false
//   },

//     // to_stage: {
//     //   elType: 'select',
//     //   label: 'Row',
//     //   inputProps: {
//     //     options: rows,
//     //     value: "",
//     //     initvalue: ""
//     //   },
//     //   formControlProps: {
//     //     fullWidth: true
//     //   },
//     //   size: 3,
//     //   isAsyncDropDown: true,
//     //   isHide:false,
//     //   isDisabled:false
//     // },
//     // column: {
//     //   elType: 'select',
//     //   label: 'Column',
//     //   inputProps: {
//     //     options: columns,
//     //     value: "",
//     //     initvalue: ""
//     //   },
//     //   formControlProps: {
//     //     fullWidth: true
//     //   },
//     //   size: 3,
//     //   isAsyncDropDown: true,
//     //   isHide:false,
//     //   isDisabled:false
//     // },
//     tile_heading: {
//       elType: 'input',
//       label: 'Tile Heading',
//       inputProps: {
//         options: [],
//         value: "",
//         initvalue: ""
//       },
//       formControlProps: {
//         fullWidth: true
//       },
//       size: 6,
//       isAsyncDropDown: true,
//       isDisabled:false,
//     },
//     report_type: {
//       elType: 'select',
//       label: 'First Report Type',
//       inputProps: {
//         options: reportsTypes,
//         value: "",
//         initvalue: ""
//       },
//       formControlProps: {
//         fullWidth: true
//       },
//       size: 6,
//       isAsyncDropDown: true,
//       isHide:false,
//       isDisabled:false
//     }
//   //   type: {
//   //     elType: 'select',
//   //     label: 'Unit Type',
//   //     inputProps: {
//   //       options: [],
//   //       value: "",
//   //       initvalue: ""
//   //     },
//   //     formControlProps: {
//   //       fullWidth: true
//   //     },
//   //     size: 6,
//   //     isAsyncDropDown: true,
//   //     isHide:false,
//   //     isDisabled:false
//   //   },

// }

// }
