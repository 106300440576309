import axios from '../../axios/axios-default';
import UrlProperties from 'Util/Property/UrlProperties';

const baseUrl = UrlProperties.baseUrl;
export default {
    getAllActiveFuelRates() {
        return axios.get(`${baseUrl}getActiveFuelAllowanceRate`);
    },
    getFuelRateById(id) {
        return axios.get(`${baseUrl}getFuelAllowanceRate/${id}`);
    },
    getActiveVehicleTypes() {
        return axios.get(`${baseUrl}${UrlProperties.user.getActiveVehicleTypes}`);
    },
    deleteFuelRateById(id) {
        return axios.delete(`${baseUrl}deleteFuelAllowanceRate/${id}`);
    },
    createFuelRate(data) {
        return axios.post(`${baseUrl}createFuelAllowanceRate`,data);
    },
    updateFuelRate(data,id) {
        return axios.put(`${baseUrl}updateFuelAllowanceRate/${id}`,data);
    }
};
