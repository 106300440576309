import React, { useEffect, useState, useRef } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Typography } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import generalProperties from "../../Util/Property/GeneralProperties";
import {
  estimationProperties,
  stages
} from "Util/Property/EstimationProperties";
import { notyDefault, notyTypes } from "components/Noty/NotyCustom";
import { checkPermissoinListAvailable } from "Util/Permission/CheckPermission";
import PermissionProperties from "Util/Property/PermissionProperties";
import {
  getEstimationByCustomer,
  deleteEstimation
} from "services/Estimaion/EstimationService";
import LoadingOverlay from "react-loading-overlay";
import urlProperties from "Util/Property/UrlProperties";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import {buildFilterObject} from "../../Util/Util";

const WareHouse = props => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState({});
  const [permissions, setPermissions] = useState({});
  const [estimations, setEstimatons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const tableRef = useRef(null);
  useEffect(() => {
    let customerId = 0;

    if (props.id && props.id > 0) {
      customerId = props.id;
    } else if (props.location) {
      const query = new URLSearchParams(props.location.search);
      let queryParam = {};
      for (let param of query.entries()) {
        queryParam[param[0]] = param[1];
      }
      if (typeof queryParam["id"] != "undefined") {
        customerId = queryParam["id"];
      }
    }
    let permissionArray = [
      PermissionProperties.addEstimation,
      PermissionProperties.viewEstimation,
      PermissionProperties.deleteEstimation,
      PermissionProperties.editEstimation
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    getEstimationsMappedToACustomer(customerId);
    setSelectedCustomerId(customerId);
  }, []);

  const getEstimationsMappedToACustomer = customerId => {
    if (customerId > 0) {
      setIsLoading(true);
      getEstimationByCustomer(customerId)
        .then(result => {
          if (result.status === 200) {
            setIsLoading(false);
            setEstimatons(result.data);
          } else {
            setIsLoading(false);
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : estimationProperties.messages.error.loadAll
            });
          }
        })
        .catch(error => {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: estimationProperties.messages.error.loadAll
          });
        });
    } else {
    }
  };

  const onEstimationEditHandler = (id, isEdit, customerId = 0) => {
    let url = props.match.url;
    if (selectedCustomerId > 0) {
      url += "/warehouse/estimation";
    } else {
      url += "/estimation";
    }
    props.history.push({
      pathname: url,
      search: "?id=" + id + "&isEdit=" + isEdit + "&customerId=" + customerId
    });
  };

  const deleteProductDialogCloseHandler = () => {
    setDeleteDialogOpen(false);
  };

  const deleteProductDialogOpenHandler = () => {
    setDeleteDialogOpen(true);
  };

  const deleteConfirmHandler = () => {
    let data = { ...deleteRowData };
    deleteEstimation(data.id)
      .then(result => {
        if (result.status == "200") {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: estimationProperties.messages.success.delete
          });
          getEstimationsMappedToACustomer(selectedCustomerId);
        } else {
          // could not delete succesfully
          notyDefault({ type: notyTypes.ERROR, text: result.data });
        }
      })
      .catch(error => {
        notyDefault({
          type: notyTypes.ERROR,
          text: estimationProperties.messages.error.delete
        });
      });

    deleteProductDialogCloseHandler();
  };

  const deleteEstimationHandler = rowData => {
    setDeleteRowData(rowData);
    deleteProductDialogOpenHandler();
  };

  const getStageName = stage => {
    for (let stg in stages) {
      if (stages[stg].value === stage) {
        return stages[stg].displayValue;
      }
    }
  };
  return (
    <div>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              {/* {
                            permissions[PermissionProperties.addEstimation] ?
                                <div className={styles.AddProductDiv}>
                                    <Link to={selectedCustomerId > 0? props.match.url + "/estimations/estimation?customerId="+selectedCustomerId :  props.match.url + "/estimation"}>
                                        <Button className={styles.AddProductButton} variant="contained" color="primary">{estimationProperties.addEstimation}</Button>
                                    </Link>
                                </div> : ""
                        } */}
              <CardBody>
                {/* customer delete confirm dialogBox */}

                <Dialog
                  open={isDeleteDialogOpen}
                  onClose={deleteProductDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {estimationProperties.messages.normal.deleteConfirm}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="sm"
                      onClick={deleteProductDialogCloseHandler}
                      color="primary"
                    >
                      {generalProperties.cancel}
                    </Button>
                    <Button
                      size="sm"
                      onClick={deleteConfirmHandler}
                      color="primary"
                      autoFocus
                    >
                      {generalProperties.confirm}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* customer table content */}
                <div className={"material-grid-wrapper"}>
                  <MaterialTable
                    title={
                      <div>
                        <span className={"grid-title-icon"}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography variant="h6">Estimations</Typography>
                        <Typography variant="body1">
                          Please add your estimations
                        </Typography>
                      </div>
                    }
                    tableRef={tableRef}
                    columns={[
                      { title: "ID", field: "id" },
                      { title: "Customer", field: "customer_name" },
                      { title: "Contact No", field: "contact_number" },
                      { title: "Fax No", field: "fax_number" },
                      {
                        title: "Service Request",
                        field: "service_request_name"
                      },
                      { title: "Status", field: "status", filterField:'status_filter' },
                      {
                        title: "stage",
                        field: "stage",
                        filterField: 'stage_filter',
                        render: rowData =>
                          rowData.stage ? getStageName(rowData.stage) : "-"
                      }
                    ]}
                    data={query =>
                      new Promise((resolve, reject) => {
                        const filterDto = buildFilterObject(query);
                        if (selectedCustomerId > 0) {
                          filterDto["customerId"] = selectedCustomerId;
                        }
                        let url =
                          urlProperties.baseUrl +
                          urlProperties.estimation.filterEstimations +
                          "?";
                        url += "perPage=" + query.pageSize;
                        url += "&page=" + query.page;
                        url +=
                          "&sortColumn=" +
                          (typeof query.orderBy === "undefined"
                            ? "name"
                            : query.orderBy.field);
                        url += "&sortOrder=" + query.orderDirection;
                        url += "&search=" + query.search;
                        fetch(url, {
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                            "Content-Type": "application/json"
                          },
                          method: "POST",
                          body: JSON.stringify(filterDto)
                        })
                          .then(response => response.json())
                          .then(result => {
                            resolve({
                              data: result.data,
                              page: result.page,
                              totalCount: result.total
                            });
                          });
                      })
                    }
                    actions={[
                      {
                        icon: "visibility",
                        tooltip: "View More Details",
                        iconProps: { className: "action-buttons view-button" },
                        onClick: (event, rowData) =>
                          onEstimationEditHandler(
                            rowData.id,
                            false,
                            rowData.customer_id
                          )
                      },
                      {
                        icon: "edit",
                        tooltip: "Edit Estimation",
                        iconProps: { className: "action-buttons edit-button" },
                        onClick: (event, rowData) =>
                          onEstimationEditHandler(
                            rowData.id,
                            true,
                            rowData.customer_id
                          )
                      },
                      {
                        icon: () => (
                          <Button
                            href={
                              selectedCustomerId > 0
                                ? props.match.url +
                                  "/estimations/estimation?customerId=" +
                                  selectedCustomerId
                                : props.match.url + "/estimation"
                            }
                            variant="contained"
                            color="primary"
                          >
                            {estimationProperties.addEstimation}
                          </Button>
                        ),
                        tooltip: `${estimationProperties.addEstimation}`,
                        isFreeAction: true,
                        hidden: !permissions[PermissionProperties.addEstimation]
                      }
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: false,
                      search: false
                    }}
                    onRowClick={(event, rowData, toggleDetailPanel) =>
                      onEstimationEditHandler(
                        rowData.id,
                        false,
                        rowData.customer_id
                      )
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

// const mapStateToProps = state => {
//     return {
//         custsomerArray: state.customer.customers
//     };
// };

// const mapDispatchToProps = dispatch => {
//     return {
//         getCustomersHandler: (customers) => dispatch(actions.getCustomers(customers))
//     }
// }

export default WareHouse;
