const generalProperties = {
  confirm: 'Confirm',
  cancel: 'Cancel',
  save: 'Save',
  close: 'Close',
  history: 'History',
  pleaseWait: 'Please Wait',
  emptyField: 'This field is required',
  validEmail: 'Please enter a valid email',
  active: 'Active',
  inactive: 'Inactive',
  add: 'Add',
  edit: 'Edit',
  delete: 'Delete',
  roleAndPermissions: 'Role And Permissions',
  userMangement: 'User Management',
  customerUserMangement: 'Customer User Management',
  productAttributes: 'Product Attributes',
  serviceAgrements: 'Service Agreements',
  change: 'Change',
  formConfiguration: 'Form Configuration',
  serviceRequests: 'Service Requests',
  checkList: 'CheckLists',
  priorityConfiguration: 'Priority Configuration',
  claims: 'Claims',
  workflows: 'Workflows',
  category: 'Category',
  expenseCategories: 'Expense Categories',
  expenseRule: 'Expense Rules',
  update: 'Update',
  dashbordAndReports: 'Reports & Dashboard',
  kpi: 'KPI Configuration',
  leave: 'Leave Configuration',
  message: {
    success: {
      dataSave: 'Data saved successfully',
      delete: 'Data deleted successfully',
    },
    error: {
      dataSave: 'Could not save data please try again...',
      loadData: 'Could not load data please try again...',
      categroryNotFound: 'Please add active Category to proceed',
      brandNotFound: 'Please add active Brand to proceed',
      delete: 'Data could not deleted please try again',
      loadHistory: 'Could not load history',
      routeData: 'Could not load route data',
    },
  },
  productAttribute: {
    category: 'Category',
    brand: 'Brand',
    model: 'Model',
    subStatus: 'Sub Status',
    parts: 'Parts',
    addNewCategory: 'Add New Category',
    addNewBrand: 'Add New Brand',
    addNewModel: 'Add New Model',
    addNewSubStatus: 'Add Sub Status',
    addNewPart: 'Add Part',
    editCategory: 'Edit Category',
    editBrand: 'Edit Brand',
    editModel: 'Edit Model',
    deleteCategory: 'Delete Category',
    deleteBrand: 'Delete Brand',
    deleteModel: 'Delete Model',
    makeActive: 'Make Active',
    makeInactive: 'Make Inactive',
    message: {
      error: {
        makeCategoryActive: 'Please make Category status active to continue',
        makeBrandActive: 'please make Brand status active to continue',
        modelActive: 'please make Model status active to continue',
        statusToAcitve: 'Please make {} active to continue',
      },
      alert: {
        deleteAttribute: 'Do you want to make inactive this {} ?',
        activeAttribute: 'Do you want to make active this {} ?',
      },
    },
  },
  route: 'Route',
};

export default generalProperties;
