import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styles from './CustomSearchableDropDown.module.css';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { RiSearchLine } from 'react-icons/ri';

const useStyles = makeStyles((theme) => ({
  options: {
    fontSize: '14px',
  },
}));
const CustomSearchableDropDown = (props) => {
  const {
    labelText,
    id,
    inputProps,
    changed,
    disabled,
    multiple,
    onInputChange,
    labelProps,
    onAdornmentClick,
    onChangeTextfield,
    freeSolo,
    customSearchable = false,
  } = props;

  const classes = useStyles();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (multiple) {
      let allVal = [];
      if (typeof inputProps.value === 'object') {
        inputProps.value.map((ip) => {
          let arr = inputProps.options.filter((op) => op.value === ip);
          allVal = [...allVal, ...arr];
        });
        setValue(allVal);
      }
    } else {
      let arr = inputProps.options.filter(
        (op) => op.value === inputProps.value
      );

      if (arr.length !== 0) {
        setValue(arr[0]);
      } else {
        setValue('');
      }
    }
  }, [inputProps.value, inputProps.options]);

  const inputClassess = classNames({
    [' ' + styles.readOnly]: inputProps.readOnly,
  });
  let enableclearable = false;
  if (inputProps.enableclearable) {
    enableclearable = true;
  }
  const onInputTextChange = (e) => {
    if (customSearchable && onInputChange) {
      onInputChange(e);
    }
  };

  function addSearchIconToEndAdornment(endAdornment) {
    const children = React.Children.toArray(endAdornment.props.children);
    if (onAdornmentClick) {
      children.push(
        <RiSearchLine
          style={{ cursor: 'pointer' }}
          onClick={onAdornmentClick}
          size="15px"
        />
      );
    }

    return React.cloneElement(endAdornment, {}, children);
  }

  return multiple ? (
    <Autocomplete
      multiple
      id={id}
      limitTags={3}
      autoComplete="off"
      options={inputProps.options}
      disableCloseOnSelect
      getOptionLabel={(option) => {
        if (option.displayValue) {
          return option.displayValue;
        } else {
          return option;
        }
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            className={classes.options}
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.displayValue ? option.displayValue : option}
        </React.Fragment>
      )}
      classes={{
        input: inputClassess,
      }}
      onChange={(event, newValue) => {
        setValue(newValue);
        let values = [];
        if (newValue !== null) {
          newValue.map((val) => values.push(val.value));
        }
        let data = {
          target: {
            value: values,
          },
        };

        changed(data);
      }}
      value={[...value]}
      disabled={inputProps.readOnly || disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          autoComplete="off"
          label={labelText}
          InputLabelProps={
            labelProps ? labelProps : { style: { fontSize: 14 } }
          }
          InputProps={{
            ...params.InputProps,
            style: { fontSize: 14 },
          }}
        />
      )}
    />
  ) : (
    <Autocomplete
      id={id}
      freeSolo={freeSolo}
      // autoSelect={freeSolo && true}
      options={inputProps.options}
      getOptionLabel={(option) => {
        if (option.displayValue) {
          return option.displayValue;
        } else {
          return option;
        }
      }}
      onChange={(event, newValue) => {
        setValue(newValue);
        let data = {
          target: {
            value: newValue !== null ? newValue.value : '',
          },
        };
        changed(data);
      }}
      getOptionSelected={(option, value) => {
        return value.displayValue === option.displayValue || value === '';
      }}
      value={value}
      disabled={inputProps.readOnly || disabled}
      classes={{
        input: inputClassess,
      }}
      disableClearable={!enableclearable}
      onKeyUp={(e) => onInputTextChange(e)}
      renderOption={(props, option) => (
        <Typography className={classes.options}>
          {props.displayValue}
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={labelText}
          autoComplete="off"
          margin="normal"
          onChange={onChangeTextfield && onChangeTextfield}
          InputLabelProps={
            labelProps ? labelProps : { style: { fontSize: 14 } }
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: addSearchIconToEndAdornment(
              params.InputProps.endAdornment
            ),
            style: { fontSize: 14 },
          }}
        />
      )}
    />
  );
};

export default CustomSearchableDropDown;
