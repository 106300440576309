import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import styles from './Kpi.module.css';
import Spinner from 'components/Spinner/Spinner.js';

export default function KpiTableBodycount(props) {
  const { useState } = React;
  const { existData, isFirstTime } = props;

  const [columns, setColumns] = useState([
    {
      title: 'Stage',
      field: 'to_stage_name',
      editable: 'never',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Period',
      field: 'period',
      editable: 'never',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Low',
      field: 'low_value',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Mid ',
      field: 'mid_value',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'High ',
      field: 'high_value',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Critical ',
      field: 'critical_value',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
  ]);

  const [data, setData] = useState([]);
  const [stagesArraybySRType, setStagesArraybySRType] = useState('');

  const stagetoTypesArray = [
    { id: '1', val: 'Complete' },
    { id: '2', val: 'Invoiced' },
    { id: '3', val: 'Get order' },
  ];

  useEffect(() => {
    // console.log("data.........",rows)
    props.RowData(data);
    setStagesArraybySRType(props.stagesArr);
  });

  useEffect(() => {
    if (isFirstTime === false) {
      const FinalStages = [];
      if (existData.length !== 0) {
        for (var i = 0; i < existData.kpis.length; i++) {
          FinalStages.push({
            sequence: existData.kpis[i].sequence,
            to_stage_name: existData.kpis[i].to_stage_name,
            to_stage_id: existData.kpis[i].to_stage_id,
            period: existData.kpis[i].period,
            low_value: existData.kpis[i].low_value,
            mid_value: existData.kpis[i].mid_value,
            high_value: existData.kpis[i].high_value,
            critical_value: existData.kpis[i].critical_value,
          });
        }
      }

      setData(FinalStages);
    }
  }, [existData]);

  useEffect(() => {
    if (isFirstTime === true) {
      const FinalStages = [];
      const stageRange = [];

      for (var i = 0; i < stagesArraybySRType.length; i++) {
        stageRange.push({
          id: i + 1,
          stage: stagesArraybySRType[i].val,
          stage_id: stagesArraybySRType[i].stageId,
        });
      }

      stageRange.map((item) => {
        setData(
          FinalStages.push({
            sequence: item.id,
            to_stage_name: item.stage,
            to_stage_id: item.stage_id,
            period: 'Day',
            low_value: 0,
            mid_value: 0,
            high_value: 0,
            critical_value: 0,
          })
        );
      });
      setData(FinalStages);

      // stagetoTypesArray.map((item)=>{
      //     setData(
      //         FinalStages.push({"id":item.id,'stageTo':item.val,'period':"Day", 'low':120, 'mid':120, 'high':120, 'critical':120})
      //     )

      // })
      //  setData(FinalStages);
    }
  }, [stagesArraybySRType]);

  return (
    <MaterialTable
      columns={columns}
      data={data}
      style={{ height: '100%' }}
      options={{
        // actionsColumnIndex: -1,
        filtering: false,
        sorting: false,
        search: false,
        toolbar: false,
        paging: false,
        maxBodyHeight: '70vh',
        headerStyle: {
          fontSize: '0.7rem',
          textAlignL: 'center',
        },
        rowStyle: {
          fontSize: '0.6rem',
          fontWeight: 450,
          padding: '5rem',
        },
      }}
      components={{
        OverlayLoading: () => (
          <div className={styles.loadingOverlay}>
            <Spinner />
          </div>
        ),
      }}
      editable={{
        // onRowAdd: newData =>
        // new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //     setData([...data, newData]);

        //     resolve();
        //     }, 1000)
        // }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              setData([...dataUpdate]);

              resolve();
            }, 1000);
          }),
        // onRowDelete: oldData =>
        // new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //     const dataDelete = [...data];
        //     const index = oldData.tableData.id;
        //     dataDelete.splice(index, 1);
        //     setData([...dataDelete]);

        //     resolve()
        //     }, 1000)
        // }),
      }}
    />
  );
}
