import React, { Component } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import * as actions from '../../../../store/actions/index';
import { connect } from 'react-redux';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import generalProperties from '../../../../Util/Property/GeneralProperties';
import { notyDefault, notyTypes } from '../../../../components/Noty/NotyCustom';
import { AccountBalanceRounded } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import PermissionProperties from '../../../../Util/Property/PermissionProperties';
import { checkPermissoinListAvailable } from '../../../../Util/Permission/CheckPermission';
import fuelRateProperties from '../../../../Util/Property/FuelRateProperty';
import { Link } from 'react-router-dom';
import styles from './FuelRate.module.css';
import Spinner from 'components/Spinner/Spinner.js';

class FuelRate extends Component {
  state = {
    isDeleteDialogOpen: false,
    deleteId: null,
    permissions: {},
  };

  onExpenseCategoryEditHandler = (id, isEdit) => {
    //TODO permissions to handle later
    // let permissionAvailable;
    // if (isEdit) {
    //   permissionAvailable = this.state.permissions[
    //     PermissionProperties.editExpenseCategories
    //   ];
    // } else {
    //   permissionAvailable = this.state.permissions[
    //     PermissionProperties.viewExpenseCategories
    //   ];
    // }
    // if (!permissionAvailable) {
    //   return;
    // }
    const viewType = isEdit ? 'edit' : 'view';
    this.props.history.push(
      `${this.props.match.url}/fuelRateDetails/${id}/${viewType}`
    );
  };

  deleteFuelRateDialogCloseHandler = () => {
    this.setState({
      isDeleteDialogOpen: false,
      deleteId: null,
    });
  };

  deleteFuelRateDialogOpenHandler(id) {
    this.setState({
      isDeleteDialogOpen: true,
    });
  }

  deleteConfirmHandler = () => {
    const { deleteFuelRate } = this.props;
    const { deleteId } = this.state;
    deleteId && deleteFuelRate(deleteId);

    this.deleteFuelRateDialogCloseHandler();
  };

  deleteFuelRateHandler = (id) => {
    this.setState({
      deleteId: id,
    });
    this.deleteFuelRateDialogOpenHandler();
  };

  componentDidMount() {
    this.props.geAllFuelRates();
    let permissionArray = [];

    this.setState({
      permissions: checkPermissoinListAvailable(permissionArray),
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      deleteFuelRateSuccess,
      deleteFuelRateInProgress,
      geAllFuelRates,
      deleteFuelRateError,
    } = this.props;
    if (
      deleteFuelRateSuccess &&
      prevProps.deleteFuelRateInProgress !== deleteFuelRateInProgress
    ) {
      if (deleteFuelRateError) {
        notyDefault({
          type: notyTypes.ERROR,
          text: fuelRateProperties.messages.error.deleteFuelRate,
        });
      } else {
        notyDefault({
          type: notyTypes.SUCCESS,
          text: fuelRateProperties.messages.success.deleteFuelRate,
        });
      }
      geAllFuelRates();
    }
  }

  render() {
    const { fuelRatesList, fuelRatesListLoading, match = {} } = this.props;
    const { url } = match;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                {/* expense category delete confirm dialogBox */}

                <Dialog
                  open={this.state.isDeleteDialogOpen}
                  onClose={this.deleteFuelRateDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {fuelRateProperties.messages.normal.deleteFuelRate}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="sm"
                      onClick={this.deleteFuelRateDialogCloseHandler}
                      color="primary"
                    >
                      {generalProperties.cancel}
                    </Button>
                    <Button
                      size="sm"
                      onClick={this.deleteConfirmHandler}
                      color="primary"
                      autoFocus
                    >
                      {generalProperties.confirm}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* expenses category table content */}
                <div className={'material-grid-wrapper '}>
                  <MaterialTable
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <AccountBalanceRounded />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Fuel Rates
                        </Typography>
                      </div>
                    }
                    tableRef={this.tableRef}
                    columns={[
                      { title: 'Fuel Rate Name', field: 'name' },
                      { title: 'Ownership Type', field: 'ownershipType' },
                      { title: 'Vehicle Type', field: 'vehicleType.name' },
                      { title: 'Rate', field: 'rate' },
                      {
                        title: 'Status',
                        field: 'is_active',
                        render: (rowData) =>
                          rowData.is_active ? 'Active' : 'Inactive',
                      },
                    ]}
                    data={fuelRatesList}
                    isLoading={fuelRatesListLoading}
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                    actions={[
                      {
                        icon: 'read_more',
                        tooltip: 'View More Details',
                        iconProps: { className: 'action-buttons view-button' },
                        onClick: (event, rowData) =>
                          this.onExpenseCategoryEditHandler(rowData.id, false),
                        // hidden: !this.state.permissions[
                        //   PermissionProperties.viewExpenseCategories
                        // ]
                      },

                      {
                        icon: 'edit',
                        tooltip: 'Edit Fuel Rate',
                        iconProps: { className: 'action-buttons edit-button' },
                        onClick: (event, rowData) =>
                          this.onExpenseCategoryEditHandler(rowData.id, true),
                        // hidden: !this.state.permissions[
                        //   PermissionProperties.editExpenseCategories
                        // ]
                      },

                      (rowData) => ({
                        icon: 'delete',
                        tooltip: 'Deactivate Fuel Rate',
                        iconProps: {
                          className: 'action-buttons delete-button',
                        },
                        onClick: (event, rowData) =>
                          this.deleteFuelRateHandler(rowData.id),
                        // hidden: !this.state.permissions[
                        //   PermissionProperties.deleteExpenseCategories
                        // ]
                      }),
                      {
                        icon: () => (
                          <Link to={this.props.match.url + '/addFuelRate'}>
                            <div
                              className={
                                'generic-button-class div-button-wrapper'
                              }
                              style={{ borderRadius: '0.5rem' }}
                              variant="contained"
                              color="primary"
                            >
                              Add New Fuel Rate
                            </div>
                          </Link>
                        ),
                        tooltip: 'Add New Fuel Rate',
                        isFreeAction: true,
                        // hidden: !this.state.permissions[
                        //   PermissionProperties.addExpenseCategories
                        // ]
                      },
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: false,
                      search: false,
                      maxBodyHeight: '70vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                    onRowClick={(event, rowData, toggleDetailPanel) =>
                      this.onExpenseCategoryEditHandler(rowData.id, false)
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { FuelRatesReducer } = state;
  const { deleteFuelRate, fuelRatesList } = FuelRatesReducer;
  return {
    fuelRatesList: fuelRatesList.data,
    fuelRatesListLoading: fuelRatesList.isFetching,
    deleteFuelRateInProgress: deleteFuelRate.inProgress,
    deleteFuelRateSuccess: deleteFuelRate.success,
    deleteFuelRateError: deleteFuelRate.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    geAllFuelRates: () => dispatch(actions.geAllFuelRates()),
    deleteFuelRate: (id) => dispatch(actions.deleteFuelRate(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FuelRate);
