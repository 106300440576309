export const productServiceAgreementProperties = {
  productServiceAgreements: 'Product Service Agreements',
  upcomingProductServiceAgreements: 'Upcoming Product Service Agreements',
  addPsa: 'Add Product Service Agreement',
  editPsa: 'Edit Product Service Agreement',
  viewPsa: 'View Product Service Agreement',
  addUpcomingPsa: 'Add Upcoming Product Service Agreement',
  viewUpcomingPsa: 'View Upcoming Product Service Agreement',
  editUpcomingPsa: 'Edit Upcoming Product Service Agreement',
  generateBulkSa: 'Generate Upcoming PSA',
  generatePsr: 'Generate PSR',
  viewProductAndServices: 'View Service Agreement',
  editProductAndServices: 'Edit Service Agreement',
  messages: {
    error: {
      loadServiceAgreementTypes: 'Could not load service agreement types!',
      loadData:
        'Could not load Product Service Agreement data. Please try again',
      saveData:
        'Could not save Product Service Agreement data. Please try again',
      delete: 'Could not delete Service agreement. Please try again',
      loadAll: 'Could not load Service agreement. Please try again',
      loadCustomers: 'Could not load Customers',
      loadServiceAgreements: 'Could not load Service Agreements',
      loadProduct: 'Could not load product',
      terminationDate: 'Termination date should be a date after issue date',
      loadProductCategories: 'Could not load product categories!',
      loadServiceAgreementModules: 'Could not load service agreement modules!',
      loadProductBrands: 'Could not load product brands!',
      loadProductModels: 'Could not load product models!',
      loadProductSubStatuses: 'Could not load product sub statuses!',
      loadCustomer: 'Could not load Customer!',
      psrGenerate: 'Could not generated Bulk PSR!',
      reportGenerate: 'Could not generate PSA Report!',
    },
    success: {
      saveData: 'Product Service Agreement details saved successfully!',
      delete: 'Product Service Agreement deleted successfully',
      upcomingSAsaveData: 'Upcoming Service agreement deleted successfully!',
      psrGenerate: 'PSR generated successfully!',
    },
    normal: {
      deleteConfirm: 'Do you want to delete this Service Agreement?',
    },
    warning: {
      noData: 'No expiring Product Service Agreements for the given date!',
    },
  },
};

const datePickerFormat = 'MM/DD/yyyy';

const stages = [
  { value: 'New', displayValue: 'New' },
  { value: 'Print', displayValue: 'Print' },
  { value: 'Deliver', displayValue: 'Deliver' },
  { value: 'Rejected', displayValue: 'Rejected' },
  { value: 'Agreed to Pay', displayValue: 'Agreed to Pay' },
  { value: 'Half Paid', displayValue: 'Half Paid' },
  { value: 'Full Paid', displayValue: 'Full Paid' },
  { value: 'Active', displayValue: 'Active' },
  { value: 'Expired', displayValue: 'Expired' },
  { value: 'Expired - Pending PSR', displayValue: 'Expired - Pending PSR' },
];

const status = [
  { value: 'ACTIVE', displayValue: 'Active' },
  { value: 'INACTIVE', displayValue: 'Inactive' },
  { value: 'EXPIRED', displayValue: 'Expired' },
];

export const getProductServiceAgreementForm = () => {
  return {
    productId: {
      elType: 'select',
      label: 'Product',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isDisabled: true,
      doDisable: true,
    },
    serviceAgreementId: {
      elType: 'select',
      label: 'Service Agreement',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isDisabled: true,
    },
    stage_name: {
      elType: 'input',
      label: 'Stage',
      inputProps: {
        type: 'text',
        placeholder: 'Stage',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isFullWidth: false,
      isHide: false,
      isDisabled: true,
      doDisable: true,
    },
    status_name: {
      elType: 'input',
      label: 'Status',
      inputProps: {
        type: 'text',
        placeholder: 'status',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isFullWidth: false,
      isHide: false,
      isDisabled: true,
      doDisable: true,
    },
    issueDate: {
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormat,
        value: new Date(),
        label: 'Issue Date',
        initvalue: new Date(),
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      size: 6,
    },
    // terminationDate: {
    //   elType: 'date',
    //   label: '',
    //   inputProps: {
    //     format: datePickerFormat,
    //     value: new Date(),
    //     label: 'Termination Date',
    //     initvalue: new Date(),
    //     disabled: true,
    //   },
    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   isFullWidth: false,
    //   valid: true,
    //   size: 6,
    //   isDisabled: true,
    // },
    assign_user_id: {
      elType: 'select',
      label: 'Assign User',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isDisabled: true,
      doDisable: true,
    },
    stage_comment_List: {
      elType: 'textarea',
      label: '',
      inputProps: {
        type: 'text',
        placeholder: 'Workflow comments',
        value: '',
        initvalue: '',
        rowsMax: 5,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: false,
      isDisabled: true,
      doDisable: true,
    },
  };
};

export const getGenerateBulkDialogForm = () => {
  return {
    upcoming_date: {
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormat,
        value: null,
        label: '',
        initvalue: null,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      size: 6,
    },
  };
};
