import React, { useEffect, useState, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import styles from './Products.module.css';
import { Link } from 'react-router-dom';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import generalProperties from '../../Util/Property/GeneralProperties';
import {
  productProperties,
  getBulkSAForm,
} from 'Util/Property/ProductProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import {
  getProductByCustomer,
  deleteProduct,
  getProductsForBulkSA,
} from 'services/ProductAndServices/ProductService';
import LoadingOverlay from 'react-loading-overlay';
import * as moment from 'moment';
import ListAltOutlinedIcon from '@material-ui/icons/ShoppingBasket';
import { Typography } from '@material-ui/core';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import GeneralProperties from 'Util/Property/GeneralProperties';
import { Form, Field } from 'react-final-form';
import CustomInput from 'components/CustomInput/CustomInput.js';
import UrlProperties from 'Util/Property/UrlProperties';
import CardFooter from 'components/Card/CardFooter';
import { getActiveCategoryList } from 'services/Product/ProductService';
import { getActiveBrandList } from 'services/Product/ProductBrandService';
import { getActiveModelList } from 'services/Product/ModelService';
import { getAllServiceAgreements } from 'services/serviceAgreeement';
import { productServiceAgreementProperties } from 'Util/Property/ProductServiceAgreementProperties';
import { saveProductSeriveAgreement } from 'services/ProductAndServices/ProductServiceAgreement';
import { getCustomer } from 'services/Customer/CustomerService';
import { customerProperties } from 'Util/Property/CustomerPorerties';
import { handleErrorMessage } from 'services/CommonService';
import { buildFilterObject } from '../../Util/Util';
import urlProperties from 'Util/Property/UrlProperties';
import Spinner from 'components/Spinner/Spinner';

const Products = (props) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState({});
  const [permissions, setPermissions] = useState({});
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const tableRef = useRef(null);
  const [rowData, setRowData] = useState({});
  const [isBulkSADialogOpen, setIsBulkSADialogOpen] = useState(false);
  const [isView, setIsView] = useState(false);
  const [bulkSAForm, setBulkSAForm] = useState(getBulkSAForm());
  const [serviceAgreements, setServiceAgreements] = useState({});
  const [customerId, setCustomerId] = useState(0);
  const [isActiveCustomer, setIsActiveCustomer] = useState(false);

  const [asyncDataState, setAsyncDataState] = useState({
    serviceAgreementId: false,
    productCategoryId: false,
    productBrandId: false,
    productModelId: false,
    productIdList: true,
  });

  useEffect(() => {
    let custId = 0;

    if (props.id && props.id > 0) {
      custId = props.id;
    } else if (props.location) {
      const query = new URLSearchParams(props.location.search);
      let queryParam = {};
      for (let param of query.entries()) {
        queryParam[param[0]] = param[1];
      }
      if (typeof queryParam['id'] != 'undefined') {
        custId = queryParam['id'];
      }
    }
    let permissionArray = [
      PermissionProperties.addProductAndServices,
      PermissionProperties.viewProductAndServices,
      PermissionProperties.deleteProductAndServices,
      PermissionProperties.editProductAndServices,
    ];
    getCustomerForCheck(custId);
    setPermissions(checkPermissoinListAvailable(permissionArray));
    getProductsMappedToACustomer(custId);
    setSelectedCustomerId(custId);
    getBackGroundData(custId);
    setCustomerId(custId);
  }, []);

  const getCustomerForCheck = (custId) => {
    getCustomer(custId)
      .then((result) => {
        if (result.status === 200) {
          if (result.data.status === 'ACTIVE') {
            setIsActiveCustomer(true);
          }
        } else {
          handleErrorMessage(
            result.data,
            customerProperties.messages.error.loadData
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, customerProperties.messages.error.loadData);
      });
  };

  const getBackGroundData = (customerId) => {
    setIsLoading(true);
    getActiveCategoryList()
      .then((result) => {
        asyncDataState.productCategoryId = true;
        if (result.status === 200) {
          let categoryOptions = [];
          result.data.map((category) =>
            categoryOptions.push({
              value: category.id,
              displayValue: category.name,
            })
          );
          let defaultValue = setDataToBulkSAForm(
            'productCategoryId',
            categoryOptions,
            true
          );
          if (defaultValue !== '') {
            getActiveBrandListForProduct(defaultValue, true, customerId);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productProperties.messages.error.loadProductCategories,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.loadProductCategories,
        });
      });
  };

  const getActiveBrandListForProduct = (catId, isFirstTime, customerId) => {
    getActiveBrandList(catId)
      .then((result) => {
        asyncDataState.productBrandId = true;
        if (result.status === 200) {
          let brandOptions = [];
          result.data.map((brand) =>
            brandOptions.push({ value: brand.id, displayValue: brand.name })
          );
          let defaultValue = setDataToBulkSAForm(
            'productBrandId',
            brandOptions,
            isFirstTime
          );
          if (defaultValue !== '') {
            getActiveModelListForProduct(defaultValue, isFirstTime, customerId);
          } else {
            // no brands for the seleted category
            getActiveModelListForProduct(0, isFirstTime, customerId);
            asyncDataState.productModelId = false;
          }
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productProperties.messages.error.loadProductBrands,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.loadProductBrands,
        });
      });
  };

  const getActiveModelListForProduct = (brandId, isFirstTime, catId) => {
    getActiveModelList(brandId)
      .then((result) => {
        asyncDataState.productModelId = true;
        if (result.status === 200) {
          let modelOptions = [];
          result.data.map((model) =>
            modelOptions.push({ value: model.id, displayValue: model.name })
          );
          let defaultValue = setDataToBulkSAForm(
            'productModelId',
            modelOptions,
            isFirstTime
          );
          if (defaultValue !== '') {
            getAllServiceAgreementsForServiceRequest(defaultValue, isFirstTime);
            // getPorductsForBulkSAForProducts(
            //   defaultValue,
            //   'productModelId',
            //   isFirstTime,
            //   catId
            // );
          }
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productProperties.messages.error.loadProductModels,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.loadProductModels,
        });
      });
  };

  const getPorductsForBulkSAForProducts = (value, elId, isFirstTime, catId) => {
    let categoryId = bulkSAForm['productCategoryId'].inputProps.value;
    let brandId = bulkSAForm['productBrandId'].inputProps.value;
    let modelId = bulkSAForm['productModelId'].inputProps.value;
    let issueDate = bulkSAForm['issueDate'].inputProps.value;

    let custId = customerId;
    if (typeof catId !== 'undefined') {
      custId = catId;
    }
    if (elId === 'productModelId') {
      modelId = value;
    } else {
      issueDate = value;
    }
    issueDate = moment(issueDate).format('yyyy-MM-DD');
    getProductsForBulkSA(categoryId, brandId, modelId, issueDate, custId)
      .then((result) => {
        asyncDataState.productIdList = true;
        if (result.status === 200) {
          let modelOptions = [];
          result.data.map((product) =>
            modelOptions.push({
              value: product.id,
              displayValue: '' + product.id,
            })
          );
          let defaultValue = setDataToBulkSAForm(
            'productIdList',
            modelOptions,
            isFirstTime,
            true
          );
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productProperties.messages.error.loadAll,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.loadAll,
        });
      });
  };

  const getAllServiceAgreementsForServiceRequest = (modelId, isFirstTime) => {
    getAllServiceAgreements()
      .then((result) => {
        asyncDataState.serviceAgreementId = true;
        if (result.status === 200) {
          let saMap = {};
          let serviceAgreementOptions = result.data.reduce(function(
            filtered,
            option
          ) {
            if (
              option.Type === 'LABOUR_ONLY' ||
              (option.Type === 'COMPREHENSIVE' &&
                option.product_model.id === modelId)
            ) {
              var someNewValue = {
                value: option.id,
                displayValue: option.name,
              };
              filtered.push(someNewValue);
              saMap[option.id] = option;
            }
            return filtered;
          },
          []);
          setServiceAgreements(saMap);
          // result.data.map(serviceAgreement => serviceAgreementOptions.push({ "value": serviceAgreement.id, "displayValue": serviceAgreement.name }));
          let defaultValue = setDataToBulkSAForm(
            'serviceAgreementId',
            serviceAgreementOptions,
            isFirstTime
          );
          let sa = saMap[defaultValue];
          if (typeof sa !== 'undefined') {
            let duration = sa.duration;
            let issueDate = bulkSAForm['issueDate'].inputProps.value;
            let endDate = moment(issueDate).add(duration, 'M');
            bulkSAForm['terminationDate'].inputProps.value = endDate.toDate();
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productProperties.messages.error.loadServiceAgreements,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.loadServiceAgreements,
        });
      });
  };

  /**
   *
   * @param {*} key
   * @param {*} options
   * save api data to Service Request form
   */

  const setDataToBulkSAForm = (key, options, isFirstTime, isMultiple) => {
    // let serviceRequestFormData = { ...serviceRequestForm };
    let formData = bulkSAForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== '') {
      defaultValue = inputProps.initvalue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : '';
    }
    if (isMultiple) {
      let arr = [];
      defaultValue = arr;
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    bulkSAForm[key] = formData;
    //setServiceRequestForm(serviceRequestFormData);
    checkForStateUpdate();
    return defaultValue;
  };

  /**
   * check for state update if criterias met then update the state
   */
  const checkForStateUpdate = (isFirstTime) => {
    let status = true;
    for (let key in asyncDataState) {
      if (asyncDataState[key] === false) {
        status = false;
        return false;
      }
    }
    if (status) {
      let formData = { ...bulkSAForm };
      setBulkSAForm(formData);
    }
  };

  const getProductsMappedToACustomer = (customerId) => {
    setIsLoading(true);
    getProductByCustomer(customerId)
      .then((result) => {
        if (result.status === 200) {
          setIsLoading(false);
          setProducts(result.data);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productProperties.messages.error.loadAll,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.loadAll,
        });
      });
  };

  const onProductEditHandler = (id, isEdit) => {
    props.history.push({
      pathname: props.match.url + '/products/productDetails',
      search:
        '?id=' + id + '&isEdit=' + isEdit + '&customerId=' + selectedCustomerId,
    });
  };

  const deleteProductDialogCloseHandler = () => {
    setDeleteDialogOpen(false);
  };

  const deleteProductDialogOpenHandler = () => {
    setDeleteDialogOpen(true);
  };

  const deleteConfirmHandler = () => {
    let data = { ...deleteRowData };
    data.status = 'TERMINATED';
    deleteProduct(data.id)
      .then((result) => {
        if (result.status == '200') {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: productProperties.messages.success.delete,
          });
          getProductsMappedToACustomer(selectedCustomerId);
        } else {
          // could not delete succesfully
          notyDefault({ type: notyTypes.ERROR, text: result.data });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.delete,
        });
      });

    deleteProductDialogCloseHandler();
  };

  const deleteProductHandler = (rowData) => {
    setDeleteRowData(rowData);
    deleteProductDialogOpenHandler();
  };

  const addProductServiceAgreementHandler = (rows) => {
    setIsBulkSADialogOpen(true);
  };

  const bulkSADialogCloseHandler = () => {
    setIsBulkSADialogOpen(false);
  };

  const onBulkSaSubmit = async (values) => {
    //TODO
    let method = '';
    let url = '';
    let data = {};
    let productFormData = { ...bulkSAForm };
    for (let key in productFormData) {
      let val = productFormData[key].inputProps.value;
      if (val !== '') {
        data[key] = val;
      } else {
        data[key] = 0;
      }
    }

    if (data.issueDate >= data.terminationDate) {
      notyDefault({
        type: notyTypes.ERROR,
        text: productServiceAgreementProperties.messages.error.terminationDate,
      });
      return;
    }
    data['active'] = true;

    let productServiceAgreement = {
      serviceAgreementId: data['serviceAgreementId'],
    };
    let productServiceAgreements = [];
    productServiceAgreements.push(productServiceAgreement);
    method = 'POST';
    url =
      UrlProperties.productServiceAgreement.createBulkProductServiceAgreements;

    setIsLoading(true);
    await saveProductSeriveAgreement(method, url, data)
      .then((result) => {
        if (typeof result !== 'undefined') {
          if (result.status === 200) {
            notyDefault({
              type: notyTypes.SUCCESS,
              text: productServiceAgreementProperties.messages.success.saveData,
            });
            setDefaultData();
          } else {
            notyDefault({
              type: notyTypes.ERROR,
              text:
                typeof result.details !== 'undefined'
                  ? result.details
                  : productServiceAgreementProperties.messages.error.saveData,
            });
          }
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: productServiceAgreementProperties.messages.error.saveData,
        });
      });
    setIsLoading(false);
  };

  /**
   * set default form data after a form submission or adding new user
   */
  const setDefaultData = () => {
    let formData = { ...bulkSAForm };
    for (let key in formData) {
      let elementData = formData[key]; // get data for form key
      let inputProps = { ...elementData.inputProps }; //  copy input data
      inputProps.value = formData[key].inputProps.initvalue;
      elementData.inputProps = inputProps;
      formData[key] = elementData;
    }
    setBulkSAForm(formData);
  };

  const onBulkSAChangeHandler = (event, elId) => {
    let value = '';
    let formData = { ...bulkSAForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    if (elementData.elType == 'date') {
      inputProps.value = event.toDate();
      value = event.toDate();
    } else {
      inputProps.value = event.target.value;
      value = event.target.value;
    }
    if (elId === 'serviceAgreementId') {
      let sa = serviceAgreements[value];
      let duration = sa.duration;
      let issueDate = formData['issueDate'].inputProps.value;
      let endDate = moment(issueDate).add(duration, 'M');
      formData['terminationDate'].inputProps.value = endDate.toDate();
    }

    if (elId === 'issueDate') {
      let saId = formData['serviceAgreementId'].inputProps.value;
      let sa = serviceAgreements[saId];
      let duration = sa.duration;
      let endDate = moment(value).add(duration, 'M');
      formData['terminationDate'].inputProps.value = endDate.toDate();
    }
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setBulkSAForm(formData);
    if (elId === 'productCategoryId' && value !== '') {
      getActiveBrandListForProduct(value);
    }
    if (elId === 'productBrandId' && value !== '') {
      getActiveModelListForProduct(value);
    }

    if (elId === 'productModelId' && value !== '') {
      getAllServiceAgreementsForServiceRequest(value);
      getPorductsForBulkSAForProducts(value, elId);
    }
    if (elId === 'issueDate' && value !== '') {
      getPorductsForBulkSAForProducts(value, elId);
    }
  };

  let bulkSAFormElementArray = [];
  for (let key in bulkSAForm) {
    bulkSAFormElementArray.push({
      id: key,
      config: bulkSAForm[key],
    });
  }

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              {/* {
                        permissions[PermissionProperties.addProductAndServices] ?
                            <div className={styles.AddProductDiv}>
                                <Link to={props.match.url + "/products/addProduct?customerId="+selectedCustomerId}>
                                    <Button className={styles.AddProductButton} variant="contained" color="primary">{productProperties.addProduct}</Button>
                                </Link>
                            </div> : ""
                    } */}
              <CardBody>
                {/* customer delete confirm dialogBox */}

                <Dialog
                  open={isBulkSADialogOpen}
                  onClose={bulkSADialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <Card>
                      <div className={'generic-form-header-wrapper'}>
                        <div className={'generic-page-title'}>
                          <span className={'generic-page-title-icon'}>
                            <ListOutlinedIcon />
                          </span>
                          <Typography
                            className={'generic-header-text'}
                            variant="h6"
                          >
                            Add Bulk Service Agreements
                          </Typography>
                          <Typography variant="body1">
                            Please add bulk service agreements here.
                          </Typography>
                        </div>
                      </div>
                      <Form
                        onSubmit={onBulkSaSubmit}
                        validate={(values, ss) => {
                          const errors = {};
                          if (
                            bulkSAForm['productModelId'].inputProps.value === ''
                          ) {
                            errors.productModelId = 'Model id is required';
                          }
                          if (
                            bulkSAForm['serviceAgreementId'].inputProps
                              .value === ''
                          ) {
                            errors.serviceAgreementId =
                              'Service agreement is required';
                          }
                          if (
                            bulkSAForm['productIdList'].inputProps.value
                              .length <= 0
                          ) {
                            errors.productIdList = 'Products are required';
                          }

                          return errors;
                        }}
                        render={({
                          handleSubmit,
                          reset,
                          submitting,
                          pristine,
                          valid,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <CardBody>
                              <GridContainer>
                                {bulkSAFormElementArray.map((element) => {
                                  if (!element.config.isHide) {
                                    let mdVal = element.config.size;
                                    return (
                                      <GridItem
                                        key={element.id}
                                        xs={12}
                                        sm={12}
                                        md={mdVal}
                                      >
                                        {element.id === 'comments' ? (
                                          <div> Comments</div>
                                        ) : (
                                          ''
                                        )}
                                        <Field
                                          name={element.id}
                                          type={element.config.elType}
                                        >
                                          {({
                                            input,
                                            meta,
                                            options,
                                            value,
                                          }) => (
                                            <div
                                              style={{ position: 'relative' }}
                                            >
                                              <CustomInput
                                                labelText={element.config.label}
                                                id={element.id}
                                                inputProps={{
                                                  ...input,
                                                  ...element.config.inputProps,
                                                  readOnly:
                                                    isView ||
                                                    element.config.readOnly
                                                      ? true
                                                      : false,
                                                }}
                                                type={element.config.elType}
                                                formControlProps={
                                                  element.config
                                                    .formControlProps
                                                }
                                                adornmentText={
                                                  element.config.adornmentText
                                                }
                                                adornmentPosition={
                                                  element.config
                                                    .adornmentPosition
                                                }
                                                multiple={
                                                  element.config.multiple
                                                }
                                                changed={(event, value) => {
                                                  input.onChange(event);
                                                  onBulkSAChangeHandler(
                                                    event,
                                                    element.id,
                                                    meta
                                                  );
                                                }}
                                                labelProps={{
                                                  ...element.config.labelProps,
                                                  error:
                                                    meta.error && meta.touched,
                                                }}
                                              />
                                              {meta.error && meta.touched && (
                                                <span
                                                  className={styles.formError}
                                                >
                                                  {meta.error}
                                                </span>
                                              )}
                                            </div>
                                          )}
                                        </Field>
                                      </GridItem>
                                    );
                                  }
                                })}
                              </GridContainer>
                            </CardBody>
                            <CardFooter style={{ display: 'block' }}>
                              {!isView ? (
                                <Button
                                  type="submit"
                                  disabled={submitting || !valid}
                                  color="primary"
                                  autoFocus
                                >
                                  {GeneralProperties.add}
                                </Button>
                              ) : (
                                ''
                              )}
                              <Button
                                style={{ marginLeft: '20px' }}
                                onClick={bulkSADialogCloseHandler}
                                color="primary"
                                autoFocus
                              >
                                {GeneralProperties.cancel}
                              </Button>
                            </CardFooter>
                          </form>
                        )}
                      />
                    </Card>
                  </DialogContent>
                </Dialog>

                <Dialog
                  open={isDeleteDialogOpen}
                  onClose={deleteProductDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {productProperties.messages.normal.deleteConfirm}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="sm"
                      onClick={deleteProductDialogCloseHandler}
                      color="primary"
                    >
                      {generalProperties.cancel}
                    </Button>
                    <Button
                      size="sm"
                      onClick={deleteConfirmHandler}
                      color="primary"
                      autoFocus
                    >
                      {generalProperties.confirm}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* customer table content */}
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Products
                        </Typography>
                        {/* <Typography variant="body1">Manage products here</Typography> */}
                      </div>
                    }
                    tableRef={tableRef}
                    columns={[
                      { title: 'Customer', field: 'customerName' },
                      {
                        title: 'Product Id',
                        field: 'id',
                        isNumericFilter: true,
                      },
                      {
                        title: 'Product Category',
                        field: 'productCategoryName',
                      },
                      { title: 'Product Brand', field: 'productBrandName' },
                      { title: 'Product Model', field: 'productModelName' },
                      { title: 'Serial No', field: 'serial' },
                      { title: 'Sales Code', field: 'saleCode' },
                      {
                        title: 'Issue Date',
                        field: 'issueDate',
                        render: (rowData) =>
                          moment(rowData.issueDate).format('YYYY/MM/DD'),
                        filterField: 'issueDateFilter',
                      },
                    ]}
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        const filterDto = buildFilterObject(query);
                        if (customerId > 0) {
                          filterDto['customerId'] = customerId;
                        }
                        let url =
                          urlProperties.baseUrl +
                          urlProperties.product.filterProduct +
                          '?';
                        url += 'perPage=' + query.pageSize;
                        url += '&page=' + query.page;
                        url +=
                          '&sortColumn=' +
                          (typeof query.orderBy === 'undefined'
                            ? 'name'
                            : query.orderBy.field);
                        url += '&sortOrder=' + query.orderDirection;
                        url += '&search=' + query.search;
                        fetch(url, {
                          headers: {
                            Authorization:
                              'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/json',
                          },
                          method: 'POST',
                          body: JSON.stringify(filterDto),
                        })
                          .then((response) => response.json())
                          .then((result) => {
                            resolve({
                              data: result.data,
                              page: result.page,
                              totalCount: result.total,
                            });
                          });
                      })
                    }
                    actions={[
                      {
                        icon: 'read_more',
                        tooltip: 'View More Details',
                        iconProps: { className: 'action-buttons view-button' },
                        onClick: (event, rowData) =>
                          onProductEditHandler(rowData.id, false),
                        position: 'row',
                        hidden: !permissions[
                          PermissionProperties.viewProductAndServices
                        ],
                      },

                      {
                        icon: 'edit',
                        tooltip: 'Edit Product',
                        iconProps: { className: 'action-buttons edit-button' },
                        onClick: (event, rowData) =>
                          onProductEditHandler(rowData.id, true),
                        position: 'row',
                        hidden:
                          !permissions[
                            PermissionProperties.editProductAndServices
                          ] || !isActiveCustomer,
                      },

                      {
                        icon: 'delete',
                        tooltip: 'Delete Product',
                        iconProps: {
                          className: 'action-buttons delete-button',
                        },
                        onClick: (event, rowData) =>
                          deleteProductHandler(rowData),
                        position: 'row',
                        hidden:
                          !permissions[
                            PermissionProperties.deleteProductAndServices
                          ] || !isActiveCustomer,
                      },
                      {
                        icon: () => (
                          <Link
                            to={
                              props.match.url +
                              '/products/addProduct?customerId=' +
                              selectedCustomerId
                            }
                          >
                            <div
                              className={
                                'generic-button-class div-button-wrapper'
                              }
                              variant="contained"
                              color="primary"
                            >
                              {productProperties.addProduct}
                            </div>
                          </Link>
                        ),
                        tooltip: 'Add Product',
                        isFreeAction: true,
                        hidden:
                          !permissions[
                            PermissionProperties.addProductAndServices
                          ] || !isActiveCustomer,
                      },
                      {
                        icon: () => (
                          <div
                            className={
                              'generic-button-class div-button-wrapper'
                            }
                            variant="contained"
                            color="primary"
                          >
                            Add Bulk Service Agreement
                          </div>
                        ),
                        tooltip: 'Add Product Service Agreement',
                        isFreeAction: true,
                        onClick: (event, rowData) =>
                          addProductServiceAgreementHandler(rowData),
                        hidden:
                          !permissions[
                            PermissionProperties.addProductAndServices
                          ] ||
                          products.length <= 1 ||
                          !isActiveCustomer,
                      },
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: false,
                      search: false,
                      maxBodyHeight: '70vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                        backgroundColor: '#e6e4df',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                    // components={{
                    //     FilterRow: props => <input {...props} onChange={(e)=>{
                    //         console.log(e,props)}} />
                    // }}
                    onRowClick={(event, rowData, toggleDetailPanel) =>
                      onProductEditHandler(rowData.id, false)
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

// const mapStateToProps = state => {
//     return {
//         custsomerArray: state.customer.customers
//     };
// };

// const mapDispatchToProps = dispatch => {
//     return {
//         getCustomersHandler: (customers) => dispatch(actions.getCustomers(customers))
//     }
// }

export default Products;
