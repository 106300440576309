import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Hidden from '@material-ui/core/Hidden';
import Poppers from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
// @material-ui/icons
import Person from '@material-ui/icons/Person';
import Notifications from '@material-ui/icons/Notifications';
import Dashboard from '@material-ui/icons/Dashboard';

import { connect } from 'react-redux';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-dashboard-react/components/headerLinksStyle.js';
import * as actions from '../../store/actions/index';
import { withRouter } from 'react-router-dom';
import RouterProps from '../../Util/Property/RouterProperties';
import { isWareHouseUrl } from '../../Util/Util';
import ProfileData from 'components/Profile/ProfileData';
import { FaSearch } from 'react-icons/fa';
import BtnStyles from 'assets/jss/material-dashboard-react/components/buttonStyle.js';
import Avatar from '@material-ui/core/Avatar';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone from 'react-dropzone';
import { Typography } from '@material-ui/core';
import UrlProperties from 'Util/Property/UrlProperties';

const useStyles = makeStyles(styles, BtnStyles);

const AdminNavbarLinks = (props) => {
  const {
    fileUploadClose,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    url,
  } = props;
  const [file, setFile] = React.useState([]);
  const [hasError, setHasError] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const baseUrl = UrlProperties.baseUrl.split(':8089')[0];
  const classes = useStyles();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [loggedUser, setloggedUser] = useState('');
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogOut = () => {
    props.logOutHadler();
    if (isWareHouseUrl(window.location)) {
      props.history.push(RouterProps.mainDomain + '/warehouse/login');
    } else {
      props.history.push(RouterProps.mainDomain + '/login');
    }
  };

  const handleProfile = () => {
    setOpenProfile(null);
    props.history.push(RouterProps.mainDomain + '/profile');
  };
  const handleResetPassword = () => {
    setOpenProfile(null);
    props.history.push(RouterProps.mainDomain + '/resetPassword');
  };

  const getLoggedUser = (getLoggedUserName) => {
    setloggedUser(getLoggedUserName);
  };

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '140px',
    width: '400px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#B22900',
    borderStyle: 'dashed',
    backgroundColor: '#ffcec7',
    color: '#B22900',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  const handleClose = () => {
    props.closeSearch();
  };

  const handleSearch = () => {
    props.openSearch();
  };

  return (
    <div>
      <div className={classes.manager}>
        <div className={classes.buttonGroup}>
          <Button
            color={window.innerWidth > 959 ? 'transparent' : 'white'}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openProfile ? 'profile-menu-list-grow' : null}
            aria-haspopup="true"
            onClick={() => handleSearch()}
            className={classes.buttonLink}
          >
            <Avatar
              round={true}
              style={{
                width: '38px',
                height: '38px',
                border: '3px solid #d4cfcf ',
                marginTop: 0,
              }}
            >
              <FaSearch />
            </Avatar>
          </Button>

          <Button
            color={window.innerWidth > 959 ? 'transparent' : 'white'}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openProfile ? 'profile-menu-list-grow' : null}
            aria-haspopup="true"
            onClick={handleClickProfile}
            className={classes.buttonLink}
          >
            {/* <Person fontSize="large" className={classes.icons} /> */}
            <ProfileData getLoggedUserName={getLoggedUser} />

            {/* <img src={"https://www.linkpicture.com/q/def_user-removebg-preview.png"} width={70} height={70} /> */}
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Profile</p>
            </Hidden>
          </Button>
        </div>
        <div align={'center'}>
          {/* {loggedUser !== '' ? <p style={{ fontWeight:3, textAlign:'center'}}><b>Hi, {loggedUser}</b></p> : ''} */}
        </div>

        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            ' ' +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      // onClick={handleProfile}
                      className={classes.dropdownItemUser}
                    >
                      Hi {loggedUser}
                    </MenuItem>
                    <MenuItem
                      onClick={handleProfile}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      onClick={handleResetPassword}
                      className={classes.dropdownItem}
                    >
                      Reset Password
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogOut}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
};

const mapDisapthToState = (dispatch) => {
  return {
    logOutHadler: () => dispatch(actions.logOut()),
    openSearch: () => dispatch(actions.openSearch()),
    closeSearch: () => dispatch(actions.closeSearch()),
  };
};

export default withRouter(
  connect(
    null,
    mapDisapthToState
  )(AdminNavbarLinks)
);
