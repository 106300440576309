import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import Settings from '@material-ui/icons/Edit';
import Spinner from 'components/Spinner/Spinner.js';
import styles from './Kpi.module.css';

export default function KpiTableBody(props) {
  const { useState } = React;
  const { existData, isFirstTime } = props;
  const [stagesArraybySRType, setStagesArraybySRType] = useState('');
  const [kpiType, setKpiType] = useState('');
  const [columns, setColumns] = useState([
    {
      title: 'Stage From',
      field: 'from_stage_name',
      editable: 'never',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Stage To',
      field: 'to_stage_name',
      editable: 'never',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Low',
      field: 'low_value',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Mid',
      field: 'mid_value',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'High',
      field: 'high_value',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Critical',
      field: 'critical_value',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
  ]);

  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log("Prop........",props.stagesArr)
    setStagesArraybySRType(props.stagesArr);
    setKpiType(props.kpiType);
    props.RowData(data);
    // console.log("data....",data)
  });

  useEffect(() => {
    if (isFirstTime === false) {
      const FinalStages = [];
      if (existData.length !== 0) {
        for (var i = 0; i < existData.kpis.length; i++) {
          FinalStages.push({
            sequence: existData.kpis[i].sequence,
            from_stage_name: existData.kpis[i].from_stage_name,
            from_stage_id: existData.kpis[i].from_stage_id,
            to_stage_name: existData.kpis[i].to_stage_name,
            to_stage_id: existData.kpis[i].to_stage_id,
            low_value: existData.kpis[i].low_value,
            mid_value: existData.kpis[i].mid_value,
            high_value: existData.kpis[i].high_value,
            critical_value: existData.kpis[i].critical_value,
          });
        }
      }
      setData(FinalStages);
    }
  }, [existData]);

  useEffect(() => {
    if (isFirstTime === true) {
      const stageRange = [];
      const FinalStages = [];

      // for( var i=0; i<stagesArray.length-1;i++){
      //     stageRange.push({"id":i+1,"first":stagesArray[i].val,"second":stagesArray[i+1].val});
      // }
      for (var i = 0; i < stagesArraybySRType.length - 1; i++) {
        stageRange.push({
          id: i + 1,
          first: stagesArraybySRType[i].val,
          second: stagesArraybySRType[i + 1].val,
          first_id: stagesArraybySRType[i].stageId,
          second_id: stagesArraybySRType[i + 1].stageId,
        });
      }

      stageRange.map((item) => {
        setData(
          FinalStages.push({
            sequence: item.id,
            from_stage_name: item.first,
            from_stage_id: item.first_id,
            to_stage_name: item.second,
            to_stage_id: item.second_id,
            low_value: 0,
            mid_value: 0,
            high_value: 0,
            critical_value: 0,
          })
        );
      });
      setData(FinalStages);
    }
  }, [stagesArraybySRType]);

  const tableIcons = {
    Edit: () => <Settings className={'action-buttons edit-button'} />,
  };

  return (
    <MaterialTable
      // title="KPI"
      icons={tableIcons}
      columns={columns}
      data={data}
      style={{ height: '100%' }}
      components={{
        OverlayLoading: () => (
          <div className={styles.loadingOverlay}>
            <Spinner />
          </div>
        ),
      }}
      options={{
        filtering: false,
        sorting: false,
        search: false,
        toolbar: false,
        paging: false,
        pageSizeOptions: [5, 10, 20, 50, 100],
        actionsCellStyle: { alignContent: 'center' },
        maxBodyHeight: '70vh',
        headerStyle: {
          fontSize: '0.7rem',
          textAlignL: 'center',
        },
        rowStyle: {
          fontSize: '0.6rem',
          fontWeight: 450,
          padding: '5rem',
        },
      }}
      editable={{
        //   onRowAdd: newData =>
        //     new Promise((resolve, reject) => {
        //       setTimeout(() => {
        //         setData([...data, newData]);

        //         resolve();
        //       }, 1000)
        //     }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              setData([...dataUpdate]);

              resolve();
            }, 1000);
          }),
        //   onRowDelete: oldData =>
        //     new Promise((resolve, reject) => {
        //       setTimeout(() => {
        //         const dataDelete = [...data];
        //         const index = oldData.tableData.id;
        //         dataDelete.splice(index, 1);
        //         setData([...dataDelete]);

        //         resolve()
        //       }, 1000)
        //     }),
      }}
    />
  );
}
