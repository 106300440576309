import urlProperties from './UrlProperties';
const reportNames = [
  {
    value: 'ON_SITE_TECHNICIANS_SUMMARY_REPORT',
    displayValue: 'On Site Field Agent Report',
  },
  {
    value: 'Efficiency_Summary_Time',
    displayValue: 'Efficiency Summary - Time',
  },
  {
    value: 'Efficiency_Summary_Count',
    displayValue: 'Efficiency Summary - Count',
  },
  {
    value: 'Average_field_Cost',
    displayValue: 'Average field Cost',
  },
  // { value: "PERIODIC_SR_REPORT",
  // displayValue: "Periodic SR Report" },
  // {
  //   value: "MACHINE_POPULATION_REPORT",
  //   displayValue: "Machine Population Report"
  // },
  {
    value: 'SERVICE_REQUEST_DETAIL_REPORT',
    displayValue: 'Service Request Detail Report',
  },
  // {
  //   value: "PERIODIC_SERVICE_DETAIL_REPORT",
  //   displayValue: "Periodic Service Detail Report"
  // },
  // {
  //   value: "TECHNICIAN_ANALYSIS_REPORT",
  //   displayValue: "Field Agent Analysis Report"
  // },
  {
    value: 'TECHNICIAN_ROUTE_REPORT',
    displayValue: 'Field Agent Travel Routes Report',
  },
];
export const saCategories = [
  { value: 1, displayValue: 'Warranty', id: 1, name: 'WARRANTY' },
  { value: 2, displayValue: 'Labour-only', id: 2, name: 'LABOUR-ONLY' },
  {
    value: 3,
    displayValue: 'Comprehensive',
    id: 3,
    name: 'COMPREHENSIVE',
  },
];
export const reportDisabledFields = {
  PERIODIC_SR_REPORT: [
    'technicianId',
    'srCategoryId',
    'dataRangeFrom',
    'dataRangeTo',
    'date',
    'reportStages',
    'department',
  ],
  SERVICE_REQUEST_DETAIL_REPORT: ['date', 'reportStages', 'department'],
  MACHINE_POPULATION_REPORT: [
    'customerId',
    'customerName',
    'subCustomerId',
    'subCustomerName',
    'customerLocation',
    'dataRangeFrom',
    'dataRangeTo',
    'productCategoryId',
    'productCategoryName',
    'productBrandId',
    'productBrandName',
    'productModelId',
    'productModelName',
    'serial',
    'productStatus',
    'technicianId',
    'technicianName',
    'srCategoryId',
    'srCategoryName',
    'saCategoeryId',
    'saCategoryName',
    'srPriorityId',
    'srPriorityName',
    'customerIds',
    'date',
  ],
  TECHNICIAN_ANALYSIS_REPORT: [
    'customerId',
    'customerName',
    'subCustomerId',
    'subCustomerName',
    'customerLocation',
    'productCategoryId',
    'productCategoryName',
    'productBrandId',
    'productBrandName',
    'productModelId',
    'productModelName',
    'serial',
    'productStatus',
    'technicianId',
    'technicianName',
    'srCategoryId',
    'srCategoryName',
    'saCategoeryId',
    'saCategoryName',
    'srPriorityId',
    'srPriorityName',
    'customerIds',
    'date',
  ],
  PERIODIC_SERVICE_DETAIL_REPORT: [
    'dataRangeFrom',
    'dataRangeTo',
    'technicianId',
    'technicianName',
    'srCategoryId',
    'srCategoryName',
    'saCategoeryId',
    'saCategoryName',
    'srPriorityId',
    'srPriorityName',
    'customerIds',
    'date',
  ],
  TECHNICIAN_ROUTE_REPORT: [
    'customerId',
    'customerName',
    'subCustomerId',
    'subCustomerName',
    'customerLocation',
    'productCategoryId',
    'productCategoryName',
    'productBrandId',
    'productBrandName',
    'productModelId',
    'productModelName',
    'serial',
    'productStatus',
    'technicianName',
    'srCategoryId',
    'srCategoryName',
    'saCategoeryId',
    'saCategoryName',
    'srPriorityId',
    'srPriorityName',
    'customerIds',
    'dataRangeFrom',
    'dataRangeTo',
    'reportStages',
    'department',
  ],
  ON_SITE_TECHNICIANS_SUMMARY_REPORT: [
    'subCustomerId',
    'subCustomerName',
    'productBrandId',
    'productBrandName',
    'productModelId',
    'productModelName',
    'serial',
    'productStatus',
    'saCategoeryId',
    'saCategoryName',
    'customerIds',
    'srPriorityId',
  ],
  Efficiency_Summary_Time: [
    'subCustomerId',
    'subCustomerName',
    'productBrandId',
    'productBrandName',
    'productModelId',
    'productModelName',
    'serial',
    'productStatus',
    'saCategoeryId',
    'saCategoryName',
    'customerIds',
    'srPriorityId',
  ],
  Efficiency_Summary_Count: [
    'subCustomerId',
    'subCustomerName',
    'productBrandId',
    'productBrandName',
    'productModelId',
    'productModelName',
    'serial',
    'productStatus',
    'saCategoeryId',
    'saCategoryName',
    'customerIds',
    'srPriorityId',
  ],
  Average_field_Cost: [
    'subCustomerId',
    'subCustomerName',
    'productBrandId',
    'productBrandName',
    'productModelId',
    'productModelName',
    'serial',
    'productStatus',
    'saCategoeryId',
    'saCategoryName',
    'customerIds',
    'srPriorityId',
  ],
};
export const reportTypes = [
  { value: 'All', displayValue: 'All' },
  { value: 'PDF', displayValue: 'PDF' },
  {
    value: 'EXCEL',
    displayValue: 'Excel',
  },
];
const districts = [
  { value: 'COLOMBO', displayValue: 'Colombo' },
  { value: 'GAMPAHA', displayValue: 'Gampaha' },
  { value: 'KALUTARA', displayValue: 'Kalutara' },
  { value: 'JAFNA', displayValue: 'Jafna' },
  { value: 'KILINOCHCHI', displayValue: 'Kilinochchi' },
  { value: 'MANNAR', displayValue: 'Mannar' },
  { value: 'MULLAITIVU', displayValue: 'Mullaitivu' },
  { value: 'VAVUNIYA', displayValue: 'Vavuniya' },
  { value: 'MATALE', displayValue: 'Matale' },
  { value: 'KANDY', displayValue: 'Kandy' },
  { value: 'NUWARA_ELIYA', displayValue: 'Nuwara Eliya' },
  { value: 'TRINCOMALEE', displayValue: 'Trincomalee' },
  { value: 'BATTICOLOA', displayValue: 'Batticaloa' },
  { value: 'AMAPARA', displayValue: 'Ampara' },
  { value: 'ANURADHAPURA', displayValue: 'Anuradhapura' },
  { value: 'POLONNARUWA', displayValue: 'Polonnaruwa' },
  { value: 'KURUNAGALA', displayValue: 'Kurunagala' },
  { value: 'PUTTALAM', displayValue: 'Puttalam' },
  { value: 'KEGALLE', displayValue: 'Kegalle' },
  { value: 'RATHANAPURA', displayValue: 'Rathnapura' },
  { value: 'GALLE', displayValue: 'Galle' },
  { value: 'MATARA', displayValue: 'Matara' },
  { value: 'HAMBANTOTA', displayValue: 'Hambantota' },
  { value: 'BADULLA', displayValue: 'Badulla' },
  { value: 'MONARAGALA', displayValue: 'Monaragala' },
];
export const department = [{ value: 'Test1', displayValue: 'Test1' }];
let arr = [];
arr.push({ id: '-1', name: 'All' });
const srArr = `http://${urlProperties.baseUrl}:8089/api/v1/getServiceRequestCategories`;
let newArr = arr.concat(srArr);
let newArray1 = [];
const reportsProperties = {
  messages: {
    error: {
      invalidDateRange: 'Invalid Date range selected',
    },
    success: {
      reportGenerated: 'Report downloaded successfully',
    },
    normal: {
      generateClaim: 'Generate claim',
    },
  },
  reportForm: {
    reportName: {
      elType: 'select',
      label: 'Report Name',
      inputProps: {
        options: reportNames,
        width: '100%',
        value: 'ON_SITE_TECHNICIANS_SUMMARY_REPORT',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    reportType: {
      elType: 'select',
      label: 'Report Type',
      inputProps: {
        options: reportTypes,
        width: '100%',
        value: 'All',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    technicianId: {
      elType: 'select',
      label: 'Field Agent Name',
      inputProps: {
        options: [],
        width: '100%',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    customerLocation: {
      elType: 'select',
      label: 'Customer Location',
      inputProps: {
        options: districts,
        width: '100%',
        value: null,
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    customerId: {
      elType: 'select',
      label: 'Main Customer',
      inputProps: {
        options: [],
        width: '100%',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    subCustomerId: {
      elType: 'select',
      label: 'Sub Customer',
      inputProps: {
        options: [],
        width: '100%',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    srCategoryId: {
      elType: 'select',
      label: 'Service Request Category',
      inputProps: {
        options: newArr,
        width: '100%',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    reportStages: {
      elType: 'select',
      label: 'Stage',
      inputProps: {
        options: newArray1,
        width: '100%',
        value: 'Assigned',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
      //isDisabled:true,
    },
    saCategoeryId: {
      elType: 'select',
      label: 'Service Agreement Category',
      inputProps: {
        options: saCategories,
        width: '100%',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    department: {
      elType: 'select',
      label: 'Department',
      inputProps: {
        options: department,
        width: '100%',
        //value: "Test1",
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    srPriorityId: {
      elType: 'select',
      label: 'Service Request Priority',
      inputProps: {
        options: [],
        width: '100%',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    productCategoryId: {
      elType: 'select',
      label: 'Product Category',
      inputProps: {
        options: [],
        width: '100%',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    productBrandId: {
      elType: 'select',
      label: 'Product Brand',
      inputProps: {
        options: [],
        width: '100%',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    productModelId: {
      elType: 'select',
      label: 'Product Model',
      inputProps: {
        options: [],
        width: '100%',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    serial: {
      elType: 'input',
      label: 'Serial No',
      inputProps: {
        type: 'text',
        placeholder: 'Serial No',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: false,
        error: false,
      },
      isFullWidth: false,
      size: 6,
    },
    productStatus: {
      elType: 'select',
      label: 'Product Status',
      inputProps: {
        options: [
          { value: true, displayValue: 'Active' },
          { value: false, displayValue: 'InActive' },
        ],
        width: '100%',
        value: true,
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    dataRangeFrom: {
      elType: 'date',
      label: '',
      inputProps: {
        type: 'text',
        label: 'From',
        value: null,
        initvalue: '',
        format: 'MM/DD/yyyy',
        clearableDate: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
      labelProps: {
        required: false,
        error: false,
        errormessage: '',
      },
    },
    dataRangeTo: {
      elType: 'date',
      label: '',
      inputProps: {
        type: 'text',
        label: 'To',
        value: null,
        initvalue: '',
        format: 'MM/DD/yyyy',
        clearableDate: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
      labelProps: {
        required: false,
        error: false,
        errormessage: '',
      },
    },
    technicianId: {
      elType: 'select',
      label: 'Field Agent Name',
      inputProps: {
        options: [],
        width: '100%',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    date: {
      elType: 'date',
      label: '',
      inputProps: {
        format: 'MM/DD/yyyy',
        value: null,
        label: 'Date',
        initvalue: null,
        clearableDate: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      labelProps: {
        required: false,
        error: false,
        errormessage: '',
      },
    },
  },

  actionText: {
    view: 'View',
    edit: 'Edit',
    add: 'Add',
  },
  reportFields: {
    customerId: null,
    customerLocation: null,
    customerName: '',
    dataRangeFrom: null,
    dataRangeTo: null,
    productBrandId: null,
    productBrandName: '',
    productCategoryId: null,
    productCategoryName: '',
    productModelId: null,
    productModelName: '',
    productStatus: true,
    saCategoeryId: null,
    saCategoryName: '',
    serial: null,
    srCategoryId: null,
    srCategoryName: '',
    srPriorityId: null,
    srPriorityName: '',
    subCustomerId: null,
    subCustomerName: '',
    technicianId: null,
    technicianName: null,
  },
  reportDataMap: {
    customerId: 'allCustomers',
    productCategoryId: 'allProdCategories',
    productBrandId: 'allProdBrands',
    productModelId: 'allProdModels',
    srPriorityId: 'allSRPriorities',
    srCategoryId: 'allSRCategories',
    subCustomerId: 'allSubCustomers',
  },
  reportSubFieldMap: {
    customerId: ['subCustomerId', 'srPriorityId'],
    srCategoryId: ['srPriorityId'],
    productModelId: ['srPriorityId'],
    productBrandId: ['srPriorityId', 'productModelId'],
    productCategoryId: ['srPriorityId', 'productModelId', 'productBrandId'],
  },
};
export default reportsProperties;
