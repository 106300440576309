import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios/axios-default';
import { customerProperties } from 'Util/Property/CustomerPorerties';

export async function getAllCustomers() {
  try {
    return axios.get(urlProperties.getCustomers);
  } catch (error) {
    console.log(customerProperties.messages.error.loadCustomers);
  }
}

export async function getCustomer(customerId) {
  try {
    return axios.get(urlProperties.getCustomer + '/' + customerId);
  } catch (error) {
    console.log(customerProperties.messages.error.loadData);
  }
}

export async function getCustomerByName(customerName) {
  try {
    return axios.get(urlProperties.getCustomers + '/' + customerName);
  } catch (error) {
    console.log(customerProperties.messages.error.loadData);
  }
}

export async function getCustomerByContactNo(contactNo) {
  try {
    return axios.get(
      urlProperties.customer.getCustomersByContactNumber + '?phone=' + contactNo
    );
  } catch (error) {
    console.log(customerProperties.messages.error.loadData);
  }
}

export async function getCustomerHistory(customerId) {
  return axios.get(urlProperties.getCustomerHistory + '/' + customerId);
}

export async function getCustomersByTid(tid) {
  try {
    return axios.get(urlProperties.customer.getCustomersByTid + '?tid=' + tid);
  } catch (error) {
    console.log(customerProperties.messages.error.loadData);
  }
}

export async function searchCustomersTid(tid) {
  try {
    return axios.get(
      urlProperties.customer.searchCustomersTid + '?search-text=' + tid
    );
  } catch (error) {
    console.log(customerProperties.messages.error.loadData);
  }
}
