import * as ActionTypes from './reportActionTypes';
import { getAllUsers } from '../../services/User/UserService';
import ReportsService from '../../services/Reports/ReportsService';
import reportsProperties from '../../Util/Property/ReportProperty';

export const getDataForReports = (changeField, value, params) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GET_REPORT_DATA_START });
      let data = {};
      if (!changeField) {
        let allUsers = localStorage.getItem('allUsers');
        // let allCustomers = localStorage.getItem('allCustomers');
        let allSRCategories = localStorage.getItem('allSRCategories');
        let allProdCategories = localStorage.getItem('allProdCategories');

        // if (allUsers && allCustomers && allSRCategories && allProdCategories) {
        if (allUsers && allSRCategories && allProdCategories) {
          data = {
            // allCustomers: JSON.parse(allCustomers),
            allProdCategories: JSON.parse(allSRCategories),
            allUsers: JSON.parse(allUsers),
            allSRCategories: JSON.parse(allSRCategories),
          };
        } else {
          if (!allUsers) {
            allUsers = await ReportsService.getAllUserForClaims();
            localStorage.setItem('allUsers', JSON.stringify(allUsers.data));
          }
          // if (!allCustomers) {
          //   allCustomers = await ReportsService.getAllCustomers();
          //   localStorage.setItem(
          //     'allCustomers',
          //     JSON.stringify(allCustomers.data)
          //   );
          // }
          if (!allProdCategories) {
            allProdCategories = await ReportsService.getAllProductCategories();
            localStorage.setItem(
              'allProdCategories',
              JSON.stringify(allProdCategories.data)
            );
          }
          if (!allSRCategories) {
            allSRCategories = await ReportsService.getServiceRequestCategories();
            localStorage.setItem(
              'allSRCategories',
              JSON.stringify(allSRCategories.data)
            );
          }

          data = {
            // allCustomers: allCustomers.data,
            allProdCategories: allSRCategories.data,
            allUsers: allUsers.data,
            allSRCategories: allSRCategories.data,
          };
        }
      } else if (value) {
        const {
          customerId = null,
          productBrandId = null,
          productModelId = null,
          productCategoryId = null,
          srCategoryId = null,
        } = params;
        if (changeField === 'productCategoryId') {
          const allProdBrands = await ReportsService.getProductBrandsByCategory(
            value
          );
          const allProdModels =
            allProdBrands.length > 0
              ? await ReportsService.getProductModelByBrand(
                  allProdBrands.data[0].id
                )
              : { data: [] };
          let allSRPriorities = { data: [] };

          allSRPriorities = await ReportsService.getServiceRequestPriorities(
            customerId,
            value,
            productBrandId,
            productModelId,
            productCategoryId
          );
          data = {
            allProdModels: allProdModels.data,
            allProdBrands: allProdBrands.data,
            allSRPriorities: allSRPriorities.data,
          };
        }
        if (changeField === 'productBrandId') {
          const allProdModels = await ReportsService.getProductModelByBrand(
            value
          );
          const modelId =
            allProdModels.data.length > 0 ? allProdModels.data[0].id : '';
          let allSRPriorities = { data: [] };
          allSRPriorities = await ReportsService.getServiceRequestPriorities(
            customerId,
            productCategoryId,
            value,
            modelId,
            srCategoryId
          );
          data = {
            allProdModels: allProdModels.data,
            allSRPriorities: allSRPriorities.data,
          };
        }
        if (changeField === 'productModelId') {
          let allSRPriorities = { data: [] };
          allSRPriorities = await ReportsService.getServiceRequestPriorities(
            customerId,
            productCategoryId,
            productBrandId,
            value,
            srCategoryId
          );
          data = {
            allSRPriorities: allSRPriorities.data,
          };
        }
        if (changeField === 'srCategoryId') {
          let allSRPriorities = { data: [] };
          allSRPriorities = await ReportsService.getServiceRequestPriorities(
            customerId,
            productCategoryId,
            productBrandId,
            productModelId,
            value
          );
          data = {
            allSRPriorities: allSRPriorities.data,
          };
        }
        if (changeField === 'customerId') {
          let allSRPriorities = { data: [] };
          allSRPriorities = await ReportsService.getServiceRequestPriorities(
            value,
            productCategoryId,
            productBrandId,
            productModelId,
            srCategoryId
          );
          const allSubCustomers = await ReportsService.getChildCustomers(value);
          data = {
            allSRPriorities: allSRPriorities.data,
            allSubCustomers: allSubCustomers.data,
          };
        }
      }
      dispatch({
        type: ActionTypes.GET_REPORT_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_REPORT_DATA_ERROR,
        payload: error,
      });
    }
  };
};
