import React, { useState, useEffect, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader';
import styles from './Checklist.module.css';
import checklistPorperties, {
  CheckListDisabledFields,
} from 'Util/Property/CheckListProperties';
import GeneralProperties from 'Util/Property/GeneralProperties';
import LoadingOverlay from 'react-loading-overlay';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.js';
import { Form, Field } from 'react-final-form';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import CustomInput from 'components/CustomInput/CustomInput.js';
import UrlProperties from 'Util/Property/UrlProperties';
import { getActiveCategoryList } from 'services/Product/ProductService';
import { getActiveBrandList } from 'services/Product/ProductBrandService';
import { getActiveModelList } from 'services/Product/ModelService';
import * as checklistService from 'services/CheckList/ChecklistService';
import MaterialTable, { MTableAction, MTableBodyRow } from 'material-table';
import PermissionProperties from 'Util/Property/PermissionProperties';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getServiceRequestCategoryCall } from 'services/ServiceRequest/serviceRequestCategoryServices';
import { serviceRequestCategoryProperties } from 'Util/Property/ServiceRequestCategoryProperties';
import { Typography } from '@material-ui/core';
import ListAltOutlinedIcon from '@material-ui/icons/PlaylistAddCheck';
import RouterProps from '../../../../Util/Property/RouterProperties';
import Spinner from 'components/Spinner/Spinner.js';

const Checklist = (props) => {
  const [checklistForm, setChecklistForm] = useState(
    checklistPorperties.checklistForm
  );
  const [checklistHeading, setChecklistHeading] = useState(
    checklistPorperties.addChecklist
  );
  const [isLoading, setIsLoading] = useState(false);
  const [checklistId, setChecklistId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [checkItemData, setCheckItemData] = useState([]);
  const [isCreated, setIsCreated] = useState(false);
  const [dragState, setDrageState] = useState({
    row: 0,
    dropIndex: 0,
  });

  const tableRef = useRef(null);

  const [asyncDataState, setAsyncDataState] = useState({
    serviceRequestCategoryId: false,
    productCategoryId: false,
    productBrandId: false,
    productModelId: false,
    getcat3list: false,
  });

  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addChecklist,
      PermissionProperties.viewChecklist,
      PermissionProperties.editChecklist,
      PermissionProperties.deleteChecklist,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  useEffect(() => {
    let checklistId = 0;

    if (props.id && props.id > 0) {
      if (props.isEdit) {
        setIsEdit(true);
      } else {
        setIsView(true);
      }
      checklistId = props.id;
      setChecklistHeading(checklistPorperties.viewChecklist);
    } else if (props.location) {
      const query = new URLSearchParams(props.location.search);
      let queryParam = {};
      for (let param of query.entries()) {
        queryParam[param[0]] = param[1];
      }
      if (typeof queryParam['id'] != 'undefined') {
        checklistId = queryParam['id'];
        setChecklistHeading(checklistPorperties.viewChecklist);
        if (queryParam['isEdit'] === 'true') {
          setIsEdit(true);
          setChecklistHeading(checklistPorperties.editChecklist);
        } else {
          setIsView(true);
        }
      }
    }

    setChecklistId(checklistId);
    if (checklistId > 0) {
      // this is for view and edit Service Request
      //getServiceRequestData(serviceRequestId);
      //setServiceRequestId(serviceRequestId);
      getChecklistData(checklistId);
      setIsCreated(true);
    } else {
      // this is loading for add new Service Request
      getBackGroundData();
    }
  }, [props.id]);

  const getChecklistData = async (serviceRequestId) => {
    setIsLoading(true);
    await checklistService
      .getChecklist(serviceRequestId)
      .then((result) => {
        if (result.status === 200) {
          setChecklistHeading(result.data.name);
          for (let key in checklistForm) {
            let value = result.data[key];

            if (!checklistForm[key].isAsyncDropDown) {
              checklistForm[key].inputProps.value = value;
            } else {
              checklistForm[key].inputProps.initvalue = value;
            }
            if (checklistForm['category']) {
              checklistForm[key].inputProps.value = value;
            }
          }
          setCheckedItems(result.data.checkItems);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: checklistPorperties.messages.error.loadData,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: checklistPorperties.messages.error.loadData,
        });
        setIsLoading(false);
      });
    getBackGroundData();
  };

  const setCheckedItems = (checkItems) => {
    let arangedCheckedItems = [];
    for (let key in checkItems) {
      let item = checkItems[key];
      let estimatedTime = getEstimatedTimeObj(item.estimated_time);
      item.estimated_time = estimatedTime;
      arangedCheckedItems.push(item);
    }
    setCheckItemData(arangedCheckedItems);
  };

  const getBackGroundData = () => {
    setIsLoading(true);
    getCategory3(true);
    getServiceRequestCategoryCall().then((response) => {
      asyncDataState.serviceRequestCategoryId = true;
      if (!response.success) {
        notyDefault({
          type: notyTypes.ERROR,
          text: serviceRequestCategoryProperties.message.error.loadingError,
        });
      } else {
        let categoryOptions = [];
        response.data.map((category) =>
          categoryOptions.push({
            value: category.id,
            displayValue: category.name,
          })
        );
        setDataToServiceRequestForm(
          'serviceRequestCategoryId',
          categoryOptions,
          true
        );
      }
    });

    getActiveCategoryList()
      .then((result) => {
        asyncDataState.productCategoryId = true;
        if (result.status === 200) {
          let categoryOptions = [];
          result.data.map((category) =>
            categoryOptions.push({
              value: category.id,
              displayValue: category.name,
            })
          );
          let defaultValue = setDataToServiceRequestForm(
            'productCategoryId',
            categoryOptions,
            true
          );
          if (defaultValue !== '') {
            getActiveBarandListForChecklist(defaultValue, true);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : checklistPorperties.messages.error.loadProductCategories,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: checklistPorperties.messages.error.loadProductCategories,
        });
      });
  };

  const getActiveBarandListForChecklist = (catId, isFirstTime) => {
    getActiveBrandList(catId)
      .then((result) => {
        asyncDataState.productBrandId = true;
        if (result.status === 200) {
          let brandOptions = [];
          result.data.map((brand) =>
            brandOptions.push({ value: brand.id, displayValue: brand.name })
          );
          let defaultValue = setDataToServiceRequestForm(
            'productBrandId',
            brandOptions,
            isFirstTime
          );
          if (defaultValue !== '') {
            getActiveProductModelListForChecklist(defaultValue, isFirstTime);
          } else {
            // no brands for the seleted category
            getActiveProductModelListForChecklist(0, isFirstTime);
            asyncDataState.productModelId = false;
          }
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : checklistPorperties.messages.error.loadProductBrands,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: checklistPorperties.messages.error.loadProductBrands,
        });
      });
  };

  const getActiveProductModelListForChecklist = (brandId, isFirstTime) => {
    getActiveModelList(brandId)
      .then((result) => {
        asyncDataState.productModelId = true;
        if (result.status === 200) {
          let modelOptions = [];
          result.data.map((model) =>
            modelOptions.push({ value: model.id, displayValue: model.name })
          );
          setDataToServiceRequestForm(
            'productModelId',
            modelOptions,
            isFirstTime
          );
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : checklistPorperties.messages.error.loadProductModels,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: checklistPorperties.messages.error.loadProductModels,
        });
      });
  };

  const getCategory3 = (isFirstTime) => {
    setIsLoading(true);
    checklistService
      .getCategory3()
      .then((result) => {
        asyncDataState.getcat3list = true;
        if (result.status === 200) {
          let cat3Options = [];
          result.data.map((cat3) =>
            cat3Options.push({ value: cat3, displayValue: cat3 })
          );
          // let defaultValue = setDataToServiceRequestForm("category3", cat3Options,isFirstTime, true);
          // if (defaultValue !== "") {
          //
          // }
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: checklistPorperties.messages.error.loadcategory3,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: checklistPorperties.messages.error.loadcategory3,
        });
      });
  };

  const onChangeHandeler = (event, elId) => {
    let value = event.target.value;

    let formData = { ...checklistForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = event.target.value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setChecklistForm(formData);
    if (elId === 'productCategoryId') {
      setIsLoading(true);
      asyncDataState.productBrandId = false;
      asyncDataState.productModelId = false;
      getActiveBarandListForChecklist(value, false);
    }
    if (elId === 'productBrandId') {
      setIsLoading(true);
      asyncDataState.productModelId = false;
      getActiveProductModelListForChecklist(value, false);
    }
  };

  const checkAvailableAndSubmit = (method, url, data) => {
    let checklistFormData = { ...checklistForm };
    let requestCatId =
      checklistFormData['serviceRequestCategoryId'].inputProps.value;
    let product_modelId = checklistFormData['productModelId'].inputProps.value;
    let category = checklistFormData['category'].inputProps.value;

    setIsLoading(true);
    checklistService
      .AlreadyAvailableChecklist(product_modelId, requestCatId, category)
      .then((result) => {
        if (result.status === 200) {
          //if already available return true
          if (result.data === true) {
            notyDefault({
              type: notyTypes.ERROR,
              text: checklistPorperties.messages.error.alreadyAvailable,
            });
            setIsLoading(false);
          } else {
            // onChecklistSubmit();
            checkListApi(method, url, data);
            setIsLoading(false);
          }
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: checklistPorperties.messages.error.availabilitycheckError,
          });
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: checklistPorperties.messages.error.availabilitycheckError,
        });
      });
  };

  const onChecklistSubmit = async (values) => {
    //TODO
    let method = '';
    let url = '';
    let data = {};
    let checklistFormData = { ...checklistForm };
    for (let key in checklistFormData) {
      let val = checklistFormData[key].inputProps.value;
      if (val !== '') {
        data[key] = val;
      }
    }

    let checkedItemDataToSubmit = [];
    for (let key in checkItemData) {
      let val = checkItemData[key];
      val['estimated_time'] =
        typeof val['estimated_time'] !== 'undefined'
          ? val['estimated_time'].totInMin
          : 0;
      checkedItemDataToSubmit.push(val);
    }
    data['checkItems'] = checkedItemDataToSubmit;

    if (checklistId > 0) {
      data['id'] = checklistId;
      method = 'PATCH';
      url = UrlProperties.checklist.updateChecklist + '/' + checklistId;
      checkListApi(method, url, data);
    } else {
      data['id'] = 0;
      method = 'POST';
      url = UrlProperties.checklist.addChecklist;
      checkAvailableAndSubmit(method, url, data);
    }
  };

  const checkListApi = async (method, url, data) => {
    setIsLoading(true);
    await checklistService
      .saveChecklist(method, url, data)
      .then((result) => {
        //this.props.history.goBack();
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: checklistPorperties.messages.success.saveData,
          });
          if (checklistId == 0) {
            setDefaultData();
          }
          setIsLoading(false);
          props.history.goBack();
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: checklistPorperties.messages.error.saveData,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: checklistPorperties.messages.error.saveData,
        });
      });
  };

  /**
   * set default form data after a form submission or adding new user
   */
  const setDefaultData = () => {
    let formData = { ...checklistForm };
    let defaultData = { ...checklistPorperties.checklistForm };
    let elementData = formData['name']; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = defaultData['name'].inputProps.initvalue;
    elementData.inputProps = inputProps;
    formData['name'] = elementData;
    setChecklistForm(formData);
    setCheckItemData([]);
  };

  /**
   *
   * @param {*} key
   * @param {*} options
   * save api data to Service Request form
   */

  const setDataToServiceRequestForm = (key, options, isFirstTime) => {
    // let serviceRequestFormData = { ...serviceRequestForm };
    let formData = checklistForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== '') {
      defaultValue = inputProps.initvalue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : '';
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    checklistForm[key] = formData;
    //setServiceRequestForm(serviceRequestFormData);
    checkForStateUpdate();
    return defaultValue;
  };

  /**
   * check for state update if criterias met then update the state
   */
  const checkForStateUpdate = (isFirstTime) => {
    let status = true;
    for (let key in asyncDataState) {
      if (asyncDataState[key] === false) {
        status = false;
        return false;
      }
    }
    if (status) {
      let formData = { ...checklistForm };
      setChecklistForm(formData);
    }
  };
  const onCheckItemEditHandler = (id, isEdit) => {};

  const onCheckItemDeleteHandler = (rowData) => {};

  /**
   *  arrange data to populate Form UI
   */
  let formElementArray = [];
  for (let key in checklistForm) {
    formElementArray.push({
      id: key,
      config: checklistForm[key],
    });
  }

  const offsetIndex = (from, to, arr = []) => {
    if (from < to) {
      let start = arr.slice(0, from),
        between = arr.slice(from + 1, to + 1),
        end = arr.slice(to + 1);
      return [...start, ...between, arr[from], ...end];
    }
    if (from > to) {
      let start = arr.slice(0, to),
        between = arr.slice(to, from),
        end = arr.slice(from + 1);
      return [...start, arr[from], ...between, ...end];
    }
    return arr;
  };
  const reOrderRow = (from, to) => {
    let newtableData = offsetIndex(from, to, checkItemData);
    //Update react state
    setCheckItemData(newtableData);
  };

  const calcluateEstimatedTime = (type, value, totalTimeInMinutes) => {
    let estimatedTime =
      typeof totalTimeInMinutes === 'undefined' ||
      typeof totalTimeInMinutes.hrs === 'undefined'
        ? getEstimatedTimeObj(totalTimeInMinutes)
        : totalTimeInMinutes;
    let val = parseInt(value);
    if (!isNaN(val)) {
      value = '' + val;
    }
    let totInMin = 0;
    if (type === 'hrs') {
      if (!value.match('^([0-9]|[1-9][0-9]|100)$') && value !== '') {
        return estimatedTime;
      }
      totInMin =
        estimatedTime.min !== ''
          ? parseInt(estimatedTime.min) + totInMin
          : totInMin;
      if (value !== '') {
        totInMin = totInMin + parseInt(value) * 60;
      }
      estimatedTime.hrs = value;
    } else {
      if (!value.match('^([0-5]|[1-9][0-9]|59)$') && value !== '') {
        return estimatedTime;
      }
      totInMin =
        estimatedTime.hrs !== ''
          ? parseInt(estimatedTime.hrs) * 60 + totInMin
          : totInMin;
      if (value !== '') {
        totInMin = totInMin + parseInt(value);
      }
      estimatedTime.min = value;
    }
    estimatedTime['totInMin'] = totInMin;

    return estimatedTime;
  };

  let getEstimatedTimeObj = (totInMin) => {
    totInMin = typeof totInMin === 'undefined' ? 0 : totInMin;
    let hrs = parseInt(totInMin / 60);
    let min = parseInt(totInMin % 60);
    let obj = {
      hrs: hrs,
      min: min,
      totInMin: totInMin,
    };
    return obj;
  };

  const getTimeDisplayString = (totInMin) => {
    totInMin = typeof totInMin === 'undefined' ? 0 : totInMin;
    let totHours = parseInt(totInMin / 60);
    let totMinutes = totInMin % 60;
    if (totHours > 0) {
      return totHours + ' Hrs : ' + totMinutes + ' Min';
    } else {
      return totMinutes + ' Min';
    }
  };

  const validateSequence = (value, sequence) => {
    if (value == '') {
      return value;
    }
    let val = parseInt(value);
    if (!isNaN(val)) {
      value = '' + val;
    }
    if (!value.match('^([0-9]|[1-9][0-9]|100)$') && value !== '') {
      return sequence;
    }
    if (value > checkItemData.length + 1) {
      return sequence;
    }
    return value;
  };

  const isDisabledField = (fieldId) => {
    return CheckListDisabledFields['CHECKLIST'].includes(fieldId);
  };

  const setSequene = (newData, action, index) => {
    let data = [...checkItemData];
    let loopData = [...checkItemData];
    let key;
    if (action === 'add') {
      key =
        typeof newData.sequence === 'undefined' ||
        newData.sequence === '0' ||
        newData.sequence === ''
          ? checkItemData.length + 1
          : parseInt(newData.sequence);
      newData.sequence = parseInt(key);
      if (key <= data.length) {
        key = key - 1;
      }
      for (let i = key; i < data.length; i++) {
        data[i].sequence = parseInt(data[i].sequence) + 1;
      }
      data.push(newData);
    } else if (action === 'delete') {
      let key = parseInt(index);
      for (let i = key; i < loopData.length; i++) {
        data[i].sequence = loopData[i].sequence - 1;
      }
      data.splice(key - 1, 1);
    } else {
      key = newData.sequence;
      if (index < key) {
        for (let i = index; i < key; i++) {
          data[i].sequence = parseInt(data[i].sequence) - 1;
        }
      } else {
        for (let i = key - 1; i < index; i++) {
          data[i].sequence = parseInt(data[i].sequence) + 1;
        }
      }
      data[index - 1] = newData;
    }
    data.sort(sortBySequence);
    return data;
  };

  const sortBySequence = (a, b) => {
    return a.sequence - b.sequence;
  };
  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <div className="generic-form-container">
                {!isEdit ? (
                  <div className={'generic-form-header-wrapper'}>
                    <div className={'generic-page-title'}>
                      <span className={'generic-page-title-icon'}>
                        <ListAltOutlinedIcon />
                      </span>
                      <Typography
                        className={'generic-header-text'}
                        variant="h6"
                        style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                      >
                        {checklistHeading}
                      </Typography>
                      {/* <Typography variant="body1">Please create checklist here.</Typography> */}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <Form
                  onSubmit={onChecklistSubmit}
                  validate={(values, ss) => {
                    const errors = {};
                    if (checklistForm.name.inputProps.value === '') {
                      // setEror("first_name", true)
                      errors.name = GeneralProperties.emptyField;
                    }

                    if (
                      checklistForm.serviceRequestCategoryId.inputProps
                        .value === ''
                    ) {
                      errors.request_type = GeneralProperties.emptyField;
                    }

                    if (
                      checklistForm.productCategoryId.inputProps.value === ''
                    ) {
                      errors.product_category_id = GeneralProperties.emptyField;
                    }

                    if (checklistForm.productBrandId.inputProps.value === '') {
                      errors.product_brand_id = GeneralProperties.emptyField;
                    }
                    if (checklistForm.productModelId.inputProps.value === '') {
                      errors.product_model_id = GeneralProperties.emptyField;
                    }

                    return errors;
                  }}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    valid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <CardBody>
                        <GridContainer>
                          {formElementArray.map((element) => {
                            if (!element.config.isHide) {
                              let mdVal = element.config.size;
                              return (
                                <GridItem
                                  key={element.id}
                                  xs={12}
                                  sm={12}
                                  md={mdVal}
                                >
                                  <Field name={element.id}>
                                    {({ input, meta, options, value }) => (
                                      <div style={{ position: 'relative' }}>
                                        <CustomInput
                                          labelText={element.config.label}
                                          id={element.id}
                                          inputProps={{
                                            ...input,
                                            ...element.config.inputProps,
                                            readOnly: isView ? true : false,
                                            readOnly: isCreated
                                              ? isDisabledField(element.id)
                                              : '',
                                            // disabled: isDisabledField(element.id)
                                          }}
                                          // disabled={isDisabledField(element.id)}
                                          type={element.config.elType}
                                          formControlProps={
                                            element.config.formControlProps
                                          }
                                          adornmentText={
                                            element.config.adornmentText
                                          }
                                          adornmentPosition={
                                            element.config.adornmentPosition
                                          }
                                          changed={(event, value) => {
                                            input.onChange(event);
                                            onChangeHandeler(event, element.id);
                                          }}
                                          labelProps={{
                                            ...element.config.labelProps,
                                            error: meta.error && meta.touched,
                                          }}
                                        />
                                        {meta.error && meta.touched && (
                                          <span className={styles.formError}>
                                            {meta.error}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </GridItem>
                              );
                            }
                          })}
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <MaterialTable
                              components={{
                                OverlayLoading: () => (
                                  <div className={styles.loadingOverlay}>
                                    <Spinner />
                                  </div>
                                ),
                              }}
                              title={
                                <div>
                                  <br />
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontSize: '1rem',
                                      marginTop: '-0.7rem',
                                    }}
                                  >
                                    {checklistPorperties.checklist}
                                  </Typography>
                                  {/* <Typography variant="body1">Manage products here</Typography> */}
                                </div>
                              }
                              tableRef={tableRef}
                              data={checkItemData}
                              columns={[
                                {
                                  title: 'Sequence',
                                  field: 'sequence',
                                  editComponent: (props) => (
                                    <Input
                                      id="estimatedTime"
                                      inputProps={{
                                        ...{
                                          type: 'text',
                                          value:
                                            typeof props.rowData.sequence ===
                                            'undefined'
                                              ? 0
                                              : props.rowData.sequence,
                                        },
                                        readOnly: isView ? true : false,
                                      }}
                                      onChange={(event, value) => {
                                        let val = event.target.value;
                                        let dis = validateSequence(
                                          val,
                                          props.rowData.sequence
                                        );
                                        props.onChange(dis);
                                      }}
                                    />
                                  ),
                                },
                                { title: 'Check Item', field: 'name' },
                                {
                                  title: 'Estimated Time',
                                  field: 'estimated_time',
                                  render: (rowData) =>
                                    typeof rowData.estimated_time !==
                                    'undefined'
                                      ? getTimeDisplayString(
                                          rowData.estimated_time.totInMin
                                        )
                                      : 0 + ' Min',
                                  editComponent: (props) => (
                                    <React.Fragment>
                                      <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                          <Input
                                            id="estimatedTime"
                                            inputProps={{
                                              ...{
                                                type: 'text',
                                                value:
                                                  typeof props.rowData
                                                    .estimated_time !==
                                                  'undefined'
                                                    ? props.rowData
                                                        .estimated_time.hrs
                                                    : 0,
                                              },
                                              readOnly: isView ? true : false,
                                            }}
                                            onChange={(event, value) => {
                                              let val = event.target.value;
                                              let dis = calcluateEstimatedTime(
                                                'hrs',
                                                val,
                                                props.rowData.estimated_time
                                              );
                                              props.onChange(dis);
                                            }}
                                            endAdornment={
                                              <InputAdornment position="end">
                                                Hrs
                                              </InputAdornment>
                                            }
                                          />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                          <Input
                                            id="estimatedTime"
                                            inputProps={{
                                              ...{
                                                type: 'text',
                                                value:
                                                  typeof props.rowData
                                                    .estimated_time !==
                                                  'undefined'
                                                    ? props.rowData
                                                        .estimated_time.min
                                                    : 0,
                                              },
                                              readOnly: isView ? true : false,
                                            }}
                                            onChange={(event, value) => {
                                              let val = event.target.value;
                                              let dis = calcluateEstimatedTime(
                                                'min',
                                                val,
                                                props.rowData.estimated_time
                                              );
                                              props.onChange(dis);
                                            }}
                                            endAdornment={
                                              <InputAdornment position="end">
                                                Min
                                              </InputAdornment>
                                            }
                                          />
                                        </GridItem>
                                      </GridContainer>
                                    </React.Fragment>
                                  ),
                                },
                              ]}
                              components={{
                                Action: (props) =>
                                  props.action.tooltip === 'Add' ? (
                                    <Button
                                      onClick={(event) =>
                                        props.action.onClick(event, props.data)
                                      }
                                      color="primary"
                                      variant="contained"
                                      disabled={isView}
                                      style={{
                                        textTransform: 'none',
                                        marginLeft: '20px',
                                      }}
                                      size="md"
                                    >
                                      Add Check Item
                                    </Button>
                                  ) : (
                                    <MTableAction {...props} />
                                  ),
                                Row: (props) => (
                                  <MTableBodyRow
                                    {...props}
                                    draggable="true"
                                    onDragStart={(e) => {
                                      e.dataTransfer.effectAllowed = 'copy';
                                      console.log('onDragStart');
                                      dragState.row = props.data.tableData.id;
                                    }}
                                    onDragEnter={(e) => {
                                      e.dataTransfer.dropEffect = 'copy';
                                      e.preventDefault();
                                      if (
                                        props.data.tableData.id != dragState.row
                                      ) {
                                        dragState.dropIndex =
                                          props.data.tableData.id;
                                      }
                                    }}
                                    onDragEnd={(e) => {
                                      console.log(`onDragEnd`);
                                      if (dragState.dropIndex != -1) {
                                        //reodering({index: dragState.row, dropIndex: dragState.dropIndex})
                                      }
                                      dragState.row = -1;
                                      dragState.dropIndex = -1;
                                    }}
                                  />
                                ),
                              }}
                              editable={{
                                onRowAdd: (newData) =>
                                  new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                      newData['id'] = 0;
                                      let data = setSequene(newData, 'add');
                                      setCheckItemData(data);
                                      resolve();
                                    }, 1000);
                                  }),
                                onRowUpdate: (newData, oldData) =>
                                  new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                      if (
                                        newData.sequence === oldData.sequence
                                      ) {
                                        const dataUpdate = [...checkItemData];
                                        const index = oldData.tableData.id;
                                        dataUpdate[index] = newData;
                                        setCheckItemData(dataUpdate);
                                      } else {
                                        let data = setSequene(
                                          newData,
                                          'edit',
                                          oldData.sequence
                                        );
                                        setCheckItemData(data);
                                      }
                                      resolve();
                                    }, 1000);
                                  }),
                                onRowDelete: (oldData) =>
                                  new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                      // const dataDelete = [...checkItemData];
                                      // const index = oldData.tableData.id;
                                      // dataDelete.splice(index, 1);
                                      let data = setSequene(
                                        '',
                                        'delete',
                                        oldData.sequence
                                      );
                                      setCheckItemData(data);
                                      resolve();
                                    }, 1000);
                                  }),
                              }}
                              options={{
                                actionsColumnIndex: -1,
                                sorting: false,
                                search: false,
                                maxBodyHeight: '70vh',
                                headerStyle: {
                                  fontSize: '0.7rem',
                                  textAlignL: 'center',
                                },
                                rowStyle: {
                                  fontSize: '0.6rem',
                                  fontWeight: 450,
                                  padding: '5rem',
                                },
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                      <CardFooter>
                        {!isView ? (
                          <Button
                            type="submit"
                            disabled={submitting || !valid}
                            color="primary"
                            autoFocus
                          >
                            {GeneralProperties.save}
                          </Button>
                        ) : (
                          ''
                        )}
                      </CardFooter>
                    </form>
                  )}
                />
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default Checklist;
