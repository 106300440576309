const serialNumbersArray = [
  { value: '123', displayValue: 'Fridge' },
  { value: '245', displayValue: 'Ac' },
  { value: '3231', displayValue: 'Laptop' },
];

const priorityCategories = [
  { value: 'CRITICAL', displayValue: 'Critical' },
  { value: 'HIGH', displayValue: 'High' },
  { value: 'MEDIUM', displayValue: 'Medium' },
  { value: 'LOW', displayValue: 'Low' },
];

const datePickerFormat = 'MM/DD/yyyy';

export const serviceRequestProperties = {
  addServiceRequest: 'Add Service Request',
  serviceRequests: 'Service Requests',
  viewServiceRequest: 'View Service Request',
  editServiceRequest: 'Edit Service Request',
  addCheckList: 'Add Checklist',
  assign: 'Assign',
  COMMENT_END: 'CBA_COM_END',
  messages: {
    error: {
      loadData: 'Could not load Service Request data please try again!',
      saveData: 'Could not save Service Request data please try again!',
      delete: 'Could not delete Service Request please try again!',
      loadAll: 'Could not load service requests please try again!',
      loadCustomers: 'Could not load Customers!',
      loadServiceAgreements: 'Could not load Service Agreements!',
      notFoundServiceAgreements:
        'No Service Agreements found for the selected product!',
      loadProductCategories: 'Could not load product categories!',
      loadProductBrands: 'Could not load product brands!',
      loadProductModels: 'Could not load product models!',
      loadUsers: 'Could not load Users!',
      loadAccessories: 'Could not load Product Category Accessories!',
      loadEstimations: 'Could not load Estimations!',
      loadCheckLists: 'Could not load Check Lists!',
      loadProducts: 'Could not load Products!',
      loadTids: 'Could not load TIds of the customers!',
      assignUser: 'Could not assign user to Service Requests!',
      estimationOrAgreement:
        'Approved Estimation or valid Service Agreement is Required to proceed!',
      createBulk: 'Could not create Service Request Bulk please try again!',
      searchDesc: 'Failed searchDescription please try again!',
      getRouteOptionsByWorkflowStageTypeAndName:
        'Failed to get Route Options By Workflow Stage Type And Name please try again!',
      reportGenerate: 'Could not generate Service Request Report!',
      inactiveProduct: 'Selected product is INACTIVE!',
    },
    success: {
      saveData:
        'Service Request details saved successfully! Service Request Name = ',
      delete: 'Service Request deleted successfully!',
      assignUser: 'User assigned successfully!',
      createBulk: 'Service Request Bulk created successfully!',
    },
    normal: {
      deleteServiceRequest: 'Do you want to delete this service request?',
    },
  },
  initialValues: {
    name: '',
    customer_id: '',
    request_type: '',
    product_category_id: '',
    product_brand_id: '',
    product_model_id: '',
    serial_number: '123',
    estimation_id: '',
    service_agreement_id: '',
    check_list_id: '',
    estimated_time_hours: 0,
    estimated_time_minutes: 0,
    assign_user_id: '',
    status: '',
    priority: 'MEDIUM',
    comments: '',
    newComment: '',
  },
};

export const getServiceRequestForm = () => {
  return {
    /*name: {
        elType: 'input',
        label: 'Name',
        inputProps: {
          type: 'text',
          placeholder: 'Name',
          value: '',
          initvalue: ''
        },
        formControlProps: {
          fullWidth: true
        },
        labelProps: {
          required: true,
          error:false
        },
        size: 12,
      },*/
    // description: {
    //   elType: 'textarea',
    //   label: '',
    //   inputProps: {
    //     type: 'text',
    //     placeholder: 'Description',
    //     value: '',
    //     initvalue: '',
    //     rowsMin:2,
    //     rowsMax:20
    //   },
    //   formControlProps: {
    //     fullWidth: true
    //   },
    //   labelProps: {
    //     error:false
    //   },
    //   size: 12,
    //   isHide:false
    // },
    customer_id: {
      elType: 'select',
      label: 'Customer *',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        tempValue: '',
        customSearchable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
    },
    request_type: {
      elType: 'select',
      label: 'Request Type *',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
    },
    product_category_id: {
      elType: 'select',
      label: 'Product Category *',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isHide: true,
    },
    product_brand_id: {
      elType: 'select',
      label: 'Product Brand *',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isHide: true,
    },
    product_model_id: {
      elType: 'select',
      label: 'Product Model *',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isHide: true,
    },
    serial_number: {
      elType: 'select',
      label: 'Serial Number *',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        customSearchable: true,
        tempValue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isHide: true,
    },
    priority: {
      elType: 'select',
      label: 'Priority *',
      inputProps: {
        options: priorityCategories,
        value: 'MEDIUM',
        initvalue: 'MEDIUM',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isHide: true,
    },
    tid_number: {
      elType: 'select',
      label: 'TID',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        customSearchable: true,
        tempValue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isHide: true,
    },
    stage_name: {
      elType: 'input',
      label: 'Stage',
      inputProps: {
        type: 'text',
        placeholder: 'Stage',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 6,
      isHide: true,
      isDisabled: true,
      doDisable: true,
    },
    service_agreement_id: {
      elType: 'select',
      label: 'Service Agreement *',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        enableclearable: true,
      },
      formControlProps: {
        required: true,
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isHide: true,
    },
    phone: {
      elType: 'select',
      label: 'Customer Contact Number',
      inputProps: {
        options: [],
        value: {},
        initvalue: {},
        customSearchable: true,
        tempValue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      isAsyncDropDown: true,
      size: 6,
    },
    accessoryIds: {
      elType: 'select',
      label: 'Accessories',
      inputProps: {
        options: [],
        value: [],
        initvalue: [],
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      multiple: true,
      isHide: false,
      labelProps: {
        style: {
          fontSize: '14px',
        },
      },
      isHide: true,
    },
    estimated_time_hours: {
      elType: 'input',
      label: 'Estimation Time',
      inputProps: {
        type: 'text',
        value: '0',
        initvalue: '0',
      },
      adornmentText: 'Hours',
      adornmentPosition: 'end',
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isHide: false,
      size: 2,
      isHide: true,
    },
    estimated_time_minutes: {
      elType: 'input',
      inputProps: {
        type: 'text',
        value: '0',
        initvalue: '0',
      },
      adornmentText: 'Minutes',
      adornmentPosition: 'end',
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: false,
      size: 2,
      isHide: false,
      isHide: true,
    },
    // check_list_id: {
    //   elType: 'select',
    //   label: 'Check List',
    //   inputProps: {
    //     options: [],
    //     value: '',
    //     initvalue: '',
    //     enableclearable:true
    //   },
    //   formControlProps: {
    //     fullWidth: true
    //   },
    //   size: 6,
    //   isAsyncDropDown : true
    // },
    assign_user_id: {
      elType: 'select',
      label: 'Assign User',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isDisabled: true,
      doDisable: true,
    },
    planned_completion_date: {
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormat,
        value: null,
        label: 'Planned completion date',
        initvalue: null,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      isHide: true,
      size: 6,
    },
    actual_completion_date: {
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormat,
        value: null,
        label: 'Actual Completion date',
        initvalue: null,
        disabled: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      doDisable: true,
      isHide: true,
      size: 6,
    },

    trNumber: {
      elType: 'input',
      label: 'Tracking Number',
      inputProps: {
        type: 'text',
        placeholder: 'Tracking number',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 6,
      isHide: true,
    },
    invoice_no: {
      elType: 'input',
      label: 'Invoice No',
      inputProps: {
        type: 'text',
        placeholder: 'Invoice No',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 6,
      isHide: true,
    },
    invoice_date: {
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormat,
        value: null,
        label: 'Invoice Date',
        initvalue: null,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      size: 6,
      isHide: true,
    },
    payment_reference: {
      elType: 'input',
      label: 'Payment reference',
      inputProps: {
        type: 'text',
        placeholder: 'Name',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 6,
      isHide: true,
    },
    payment_date: {
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormat,
        value: null,
        label: 'Payment Date',
        initvalue: null,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      size: 6,
      isHide: true,
    },
    target_delivery_date: {
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormat,
        value: null,
        label: 'Target Delivery Date',
        initvalue: null,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      size: 6,
      isHide: true,
    },
    delivered_date: {
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormat,
        value: null,
        label: 'Delivered Date',
        initvalue: null,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      size: 6,
      isHide: true,
    },
    contact_no: {
      elType: 'input',
      label: 'Contact Number',
      inputProps: {
        type: 'text',
        placeholder: 'Contact Number',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 6,
      isHide: true,
    },
    contact_person: {
      elType: 'input',
      label: 'Contact Person',
      inputProps: {
        type: 'text',
        placeholder: 'Contact Person',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 6,
      isHide: true,
    },
    email_address: {
      elType: 'input',
      label: 'Email Address',
      inputProps: {
        type: 'text',
        placeholder: 'Email Address',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 6,
      isHide: true,
    },

    status_name: {
      elType: 'input',
      label: 'Status',
      inputProps: {
        type: 'text',
        placeholder: 'Status',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: true,
      isDisabled: true,
      doDisable: true,
    },
    comments: {
      elType: 'textarea',
      label: '',
      inputProps: {
        type: 'text',
        placeholder: 'Comments',
        value: '',
        initvalue: '',
        rowsMax: 5,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: true,
    },
    newComment: {
      elType: 'input',
      label: 'Comment',
      inputProps: {
        type: 'text',
        placeholder: 'Type your comment here',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: true,
    },
    stage_comment_List: {
      elType: 'textarea',
      label: '',
      inputProps: {
        type: 'text',
        placeholder: 'Workflow comments',
        value: '',
        initvalue: '',
        rowsMax: 5,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: true,
      readOnly: true,
    },
    product_id: {
      elType: 'input',
      label: 'Product Id',
      inputProps: {
        type: 'text',
        placeholder: 'Status',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: true,
      isDisabled: true,
      doDisable: true,
    },
  };
};
