import * as actionTypes from './actionTypes';
import axios from 'axios';
import queryString from 'querystring';
import RouterProps from '../../Util/Property/RouterProperties';
import UrlProperties from 'Util/Property/UrlProperties';
import { isWareHouseUrl } from '../../Util/Util';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import { userProperties, getUserForm } from 'Util/Property/UserProperties';
import { getUser } from 'services/User/UserService';


let timeoutHander = null;
let VEHICLE_USER_ROLE_ID = 8; 

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (authData) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    authData: authData,
  };
};

export const authFailed = (error) => {
  return {
    type: actionTypes.AUTH_FAILED,
    error: error,
  };
};

export const logOut = (props) => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('refreshTokenExpirationDate');
  localStorage.removeItem('permissions');
  localStorage.removeItem('role');
  localStorage.removeItem('userId');
  localStorage.removeItem('userCustomerId');
  localStorage.removeItem('userDepartment');
  localStorage.removeItem('userDetails');
  if (props) {
    if (isWareHouseUrl(window.location)) {
      props.history.replace({
        pathname: RouterProps.mainDomain + '/warehouse/login',
      });
    } else {
      props.history.replace({ pathname: RouterProps.mainDomain + '/login' });
    }
  }
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeOut = (expirationTime) => {
  if (timeoutHander != null) {
    clearTimeout(timeoutHander);
  }
  return (dispatch) => {
    timeoutHander = setTimeout(() => {
      let refreshExpireDate = new Date(
        localStorage.getItem('refreshTokenExpirationDate')
      );
      if (
        refreshExpireDate > new Date() &&
        localStorage.getItem('refreshToken')
      ) {
        dispatch(auth(0, 0, true));
      } else {
        dispatch(logOut());
        logOut();
      }
    }, expirationTime * 1000);
  };
};

export const setToken = async (response) => {
  let expireDate = new Date(
    new Date().getTime() + parseInt(response.data.expires_in) * 1000
  );
  let refreshExpireDate = new Date(parseInt(response.data.refresh_expires_in));
  localStorage.setItem('token', response.data.access_token);
  localStorage.setItem('expirationDate', expireDate);
  localStorage.setItem('userId', response.data.localId);
  localStorage.setItem('refreshToken', response.data.refresh_token);
  localStorage.setItem('refreshTokenExpirationDate', refreshExpireDate);
  localStorage.setItem('permissions', response.data.permissions);
  localStorage.setItem('role', response.data.roleId);
  localStorage.setItem('userId', response.data.userId);
  localStorage.setItem('userType', response.data.userType);
  localStorage.setItem(
    'userFullName',
    response.data.firstName + ' ' + response.data.lastName
  );
  localStorage.setItem('userCustomerId', response.data.customerId);
  localStorage.setItem('userDepartment', response.data.department);
  localStorage.setItem('userDetails', JSON.stringify(await getUserDetails(response.data.userId)));
};

const getUserDetails = async (userId) => {
  try {
      const result = await getUser(userId);
      if (result.status === 200) {
           return result.data;
      } else {
          return null;
      }
  } catch (error) {
      notyDefault({
          type: notyTypes.ERROR,
          text: "Cannt get user details"
      });
  }
};

export const auth = (username, password, isRefresh, failedRequest) => {
  return (dispatch) => {
    dispatch(authStart());
    authRequest(username, password, isRefresh, failedRequest)
      .then((response) => {
        setToken(response);
        let roleId = response.data.roleId;
        if(roleId === VEHICLE_USER_ROLE_ID){
          dispatch(authFailed("Not authorized !"));
        }else{
          dispatch(authSuccess(response.data));
          if (isRefresh) {
            dispatch(checkAuthTimeOut(response.data.expires_in));
          }
        }
      })
      .catch((error) => {
        dispatch(authFailed(error));
      });
  };
};

export const authRequest = (username, password, isRefresh, failedRequest) => {
  let postData = '';
  if (isRefresh) {
    postData = {
      client_id: 'f2ms-ui',
      grant_type: 'refresh_token',
      refresh_token: localStorage.getItem('refreshToken'),
    };
  } else {
    postData = {
      client_id: 'f2ms-ui',
      username: username,
      password: password,
      grant_type: 'password',
    };
  }

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return axios.post(
    UrlProperties.baseUrl + 'token',
    queryString.stringify(postData),
    config
  );
};

export const checkAuthState = (props) => {
  return (dispatch) => {
    let token = localStorage.getItem('token');
    if (token) {
      let expireDate = new Date(localStorage.getItem('expirationDate'));
      let refreshExpireDate = new Date(
        localStorage.getItem('refreshTokenExpirationDate')
      );
      let currentDate = new Date();
      if (expireDate > currentDate) {
        // let userId = localStorage.getItem("userId");
        // let authData = {
        //     idToken: token,
        //     localId: userId
        // }
        //dispatch(authSuccess(authData));   need to think about this
        let expirationTime =
          (expireDate.getTime() - new Date().getTime()) / 1000;
        dispatch(checkAuthTimeOut(expirationTime));
      } else {
        if (
          refreshExpireDate > currentDate &&
          localStorage.getItem('refreshToken')
        ) {
          dispatch(auth(0, 0, true));
        } else {
          dispatch(logOut(props));
          logOut(props);
        }
      }
    } else {
      dispatch(logOut(props));
      logOut(props);
    }
  };
};
