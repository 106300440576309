export const productProperties = {
  productAndServices: 'Product & Services',
  addProduct: 'Add Product',
  viewProductAndServices: 'View Product & Services',
  editProductAndServices: 'Edit Product & Services',
  messages: {
    error: {
      loadData: 'Could not load product data please try again',
      saveData: 'Could not save product data. please try again',
      delete: 'Could not delete product please try again',
      loadAll: 'Could not load products please try again',
      loadCustomers: 'Could not load Customers',
      loadServiceAgreements: 'Could not load Service Agreements',
      loadProductCategories: 'Could not load product categories',
      loadProductBrands: 'Could not load product brands',
      loadProductModels: 'Could not load product models',
      subStatuses: 'Could not load product sub statuses',
      updateSerial: 'Could not Update Serial Number please try again',
    },
    success: {
      saveData: 'Product details saved successfully',
      delete: 'Product deleted successfully',
      updateSerial: 'Serial Number Updated Successfully',
    },
    normal: {
      deleteConfirm: 'Do you want to delete this product',
    },
  },
};

const departmentArray = [
  { value: 'WAREHOUSE', displayValue: 'Warehouse' },
  { value: 'FINANCE', displayValue: 'Finance' },
  { value: 'IT', displayValue: 'IT' },
  { value: 'POS', displayValue: 'POS' },
  { value: 'SALES', displayValue: 'Sales' },
  { value: 'TECHNICAL', displayValue: 'Technical' },
];

const productLocationArray = [
  { value: 'CUSTOMER_PLACE', displayValue: 'Customer Place' },
  { value: 'STORE', displayValue: 'Store' },
  { value: 'WORKSHOP', displayValue: 'Workshop' },
];
const datePickerFormat = 'MM/DD/yyyy';
const datePickerFormatForSA = 'yyyy-MM-DD';

const stages = [
  { value: 'Discussion In Progress', displayValue: 'Discussion in progress' },
  { value: 'Printed', displayValue: 'Printed' },
  { value: 'Sent not Paid', displayValue: 'Sent not paid' },
  { value: 'Agreed to Pay', displayValue: 'Agreed to Pay' },
  { value: 'Paid not Full', displayValue: 'Paid not Full' },
  { value: 'Paid Full', displayValue: 'Paid Full' },
  { value: 'Expired', displayValue: 'Expired' },
];

const status = [
  { value: true, displayValue: 'Active' },
  { value: false, displayValue: 'Inactive' },
];

const route = [
  { value: 'Galle Road', displayValue: 'Galle Road' },
  { value: 'Hill Street', displayValue: 'Hill Street' },
];

const category = [
  { value: 'Option 1', displayValue: 'Option 1' },
  { value: 'Option 2', displayValue: 'Option 2' },
];

export const getProductForm = () => {
  return {
    customerId: {
      elType: 'select',
      label: 'Customer',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },

      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: true,
      isAsyncDropDown: true,
      isDisabled: true,
      doDisable: true,
    },
    productCategoryId: {
      elType: 'select',
      label: 'Product Category',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isDisabled: true,
    },
    productBrandId: {
      elType: 'select',
      label: 'Product Brand',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isDisabled: true,
    },
    productModelId: {
      elType: 'select',
      label: 'Product Model',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isDisabled: true,
    },
    serviceAgreementId: {
      elType: 'select',
      label: 'Service Agreement',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isDisabled: true,
    },
    active: {
      elType: 'select',
      label: 'Status',
      inputProps: {
        options: status,
        value: true,
        initvalue: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
    },
    productSubStatusId: {
      elType: 'select',
      label: 'Sub status',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      isAsyncDropDown: true,
      size: 12,
    },
    department: {
      elType: 'select',
      label: 'Department',
      inputProps: {
        options: departmentArray,
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: false,
      isDisabled: false,
    },
    productLocation: {
      elType: 'select',
      label: 'Product Location',
      inputProps: {
        options: productLocationArray,
        value: productLocationArray[0].value,
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isDisabled: false,
    },

    serial: {
      elType: 'input',
      label: 'Serial Number',
      inputProps: {
        type: 'text',
        placeholder: 'Serial Number',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      size: 6,
    },
    issueDate: {
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormat,
        value: new Date(),
        label: 'Issue Date',
        initvalue: new Date(),
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      size: 6,
    },
    saleCode: {
      elType: 'input',
      label: 'Sales Code',
      inputProps: {
        type: 'text',
        placeholder: 'Sales Code',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      size: 6,
      doDisable: true,
      isHide: true,
    },
    soldPrice: {
      elType: 'input',
      label: 'Sold Price',
      inputProps: {
        type: 'number',
        placeholder: 'Sold Price',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 6,
    },
    saleInvoiceNumber: {
      elType: 'input',
      label: 'Sale Invoice Number',
      inputProps: {
        type: 'text',
        placeholder: 'Sale Invoice Number',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 6,
    },
    mid: {
      elType: 'input',
      label: 'MID',
      inputProps: {
        type: 'text',
        placeholder: 'MID',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 6,
    },
    tid: {
      elType: 'input',
      label: 'TID',
      inputProps: {
        type: 'text',
        placeholder: 'TID',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 6,
    },
  };
};

export const getBulkSAForm = () => {
  return {
    productCategoryId: {
      elType: 'select',
      label: 'Product Category',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isDisabled: true,
    },
    productBrandId: {
      elType: 'select',
      label: 'Product Brand',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isDisabled: true,
    },
    productModelId: {
      elType: 'select',
      label: 'Product Model',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isDisabled: true,
    },
    serviceAgreementId: {
      elType: 'select',
      label: 'Service Agreement',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isDisabled: true,
    },
    stage: {
      elType: 'select',
      label: 'Stage',
      inputProps: {
        options: stages,
        value: 'Discussion In Progress',
        initvalue: 'Discussion In Progress',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
    },
    productIdList: {
      elType: 'select',
      label: 'Products',
      inputProps: {
        options: [],
        value: [],
        initvalue: [],
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isAsyncDropDown: true,
      isDisabled: true,
      multiple: true,
    },
    issueDate: {
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormatForSA,
        value: new Date(),
        label: 'Commence Date',
        initvalue: new Date(),
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      size: 6,
    },
    terminationDate: {
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormatForSA,
        value: new Date(),
        label: 'Termination Date',
        initvalue: new Date(),
        disabled: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      size: 6,
      isDisabled: true,
    },
  };
};

export const getBulkSRForm = () => {
  return {
    assign_user_id: {
      elType: 'select',
      label: 'Assign To',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
    },
    planned_completion_date: {
      elType: 'date',
      label: '',
      inputProps: {
        type: 'text',
        label: 'Planned Date',
        value: null,
        initvalue: '',
        format: datePickerFormatForSA,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      size: 6,
    },
  };
};
