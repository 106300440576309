import React, { Component } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import styles from './ExpenseRules.module.css';
import { Link } from 'react-router-dom';
import * as actions from '../../../../store/actions/index';
import { connect } from 'react-redux';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import generalProperties from '../../../../Util/Property/GeneralProperties';
import claimsSettingsProperties from '../../../../Util/Property/ClaimsSettingsProperty';
import { notyDefault, notyTypes } from '../../../../components/Noty/NotyCustom';
import { AccountBalanceRounded } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import PermissionProperties from '../../../../Util/Property/PermissionProperties';
import { checkPermissoinListAvailable } from '../../../../Util/Permission/CheckPermission';
import Spinner from 'components/Spinner/Spinner.js';

class ExpenseRules extends Component {
  state = {
    isDeleteDialogOpen: false,
    deleteId: null,
    deleteData: null,
    permissions: {},
  };

  onExpenseCategoryEditHandler = (id, isEdit) => {
    let permissionAvailable;
    if (isEdit) {
      permissionAvailable = this.state.permissions[
        PermissionProperties.editExpenseRule
      ];
    } else {
      permissionAvailable = this.state.permissions[
        PermissionProperties.viewExpenseRule
      ];
    }
    if (!permissionAvailable) {
      return;
    }
    const viewType = isEdit ? 'edit' : 'view';
    this.props.history.push(
      `${this.props.match.url}/expenseRulesDetails/${id}/${viewType}`
    );
  };

  deleteExpenseCategoryDialogCloseHandler = () => {
    this.setState({
      isDeleteDialogOpen: false,
      deleteId: null,
      deleteData: null,
    });
  };

  deleteExpenseRuleDialogOpenHandler(id) {
    this.setState({
      isDeleteDialogOpen: true,
    });
  }

  deleteConfirmHandler = () => {
    const { deleteExpenseRule } = this.props;
    const { deleteId, deleteData } = this.state;
    deleteData['isActive'] = false;
    deleteId && deleteExpenseRule(deleteId, deleteData);

    this.deleteExpenseCategoryDialogCloseHandler();
  };

  deleteExpenseCategoryHandler = (id, deleteData) => {
    this.setState({
      deleteId: id,
      deleteData: deleteData,
    });
    this.deleteExpenseCategoryDialogOpenHandler();
  };

  deleteExpenseCategoryDialogOpenHandler(id) {
    this.setState({
      isDeleteDialogOpen: true,
    });
  }
  componentDidMount() {
    this.props.getAllExpenseRules();
    let permissionArray = [
      PermissionProperties.addExpenseRule,
      PermissionProperties.viewExpenseRule,
      PermissionProperties.deleteExpenseRule,
      PermissionProperties.editExpenseRule,
    ];

    this.setState({
      permissions: checkPermissoinListAvailable(permissionArray),
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      deleteExpenseRuleSuccess,
      deleteExpenseRuleInProgress,
      getAllExpenseRules,
      deleteExpenseRuleError,
    } = this.props;
    if (
      deleteExpenseRuleSuccess &&
      prevProps.deleteExpenseRuleInProgress !== deleteExpenseRuleInProgress
    ) {
      if (deleteExpenseRuleError) {
        notyDefault({
          type: notyTypes.ERROR,
          text: claimsSettingsProperties.messages.error.deleteExpenseRule,
        });
      } else {
        notyDefault({
          type: notyTypes.SUCCESS,
          text: claimsSettingsProperties.messages.success.deleteExpenseRule,
        });
      }
      getAllExpenseRules();
    }
  }

  render() {
    const {
      claimExpenseRuleList,
      claimExpenseRuleListLoading,
      match = {},
    } = this.props;
    const { url } = match;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                {/* expense category delete confirm dialogBox */}

                <Dialog
                  open={this.state.isDeleteDialogOpen}
                  onClose={this.deleteExpenseCategoryDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {
                        claimsSettingsProperties.messages.normal
                          .deleteExpenseRule
                      }
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <div className={'material-grid-wrapper '}>
                      <Button
                        size="sm"
                        variant="contained"
                        onClick={this.deleteExpenseCategoryDialogCloseHandler}
                        color="primary"
                      >
                        {generalProperties.cancel}
                      </Button>
                      <Button
                        size="sm"
                        variant="contained"
                        onClick={this.deleteConfirmHandler}
                        color="primary"
                        autoFocus
                      >
                        {generalProperties.confirm}
                      </Button>
                    </div>
                  </DialogActions>
                </Dialog>

                {/* expenses category table content */}
                <div className={'material-grid-wrapper '}>
                  <MaterialTable
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <AccountBalanceRounded />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Expense Rules
                        </Typography>
                      </div>
                    }
                    tableRef={this.tableRef}
                    columns={[
                      { title: 'Expense Rule Name', field: 'name' },
                      { title: 'Allowance Type', field: 'allowanceTypeName' },
                      {
                        title: 'Status',
                        field: 'isActive',
                        render: (rowData) =>
                          rowData.isActive ? 'Active' : 'Inactive',
                      },
                    ]}
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                    data={claimExpenseRuleList}
                    isLoading={claimExpenseRuleListLoading}
                    actions={[
                      (rowData) => ({
                        icon: 'read_more',
                        tooltip: 'View More Details',
                        iconProps: { className: 'action-buttons view-button' },
                        onClick: (event, rowData) =>
                          this.onExpenseCategoryEditHandler(rowData.id, false),
                        hidden: !this.state.permissions[
                          PermissionProperties.viewExpenseRule
                        ],
                      }),

                      (rowData) => ({
                        icon: 'edit',
                        tooltip: 'Edit Expense Rule',
                        iconProps: { className: 'action-buttons edit-button' },
                        onClick: (event, rowData) =>
                          this.onExpenseCategoryEditHandler(rowData.id, true),
                        hidden: !this.state.permissions[
                          PermissionProperties.editExpenseRule
                        ],
                      }),

                      (rowData) => ({
                        icon: 'delete',
                        tooltip: 'Deactivate Expense Rule',
                        iconProps: {
                          className: 'action-buttons delete-button',
                        },
                        onClick: (event, rowData) =>
                          this.deleteExpenseCategoryHandler(
                            rowData.id,
                            rowData
                          ),
                        hidden: !this.state.permissions[
                          PermissionProperties.deleteExpenseRule
                        ],
                      }),
                      {
                        icon: () => (
                          <Link to={this.props.match.url + '/addExpenseRule'}>
                            <div
                              className={
                                'generic-button-class div-button-wrapper'
                              }
                              style={{ borderRadius: '0.5rem' }}
                              variant="contained"
                              color="primary"
                            >
                              Add New Expense Rule
                            </div>
                          </Link>
                        ),
                        tooltip: 'Add New Expense Rule',
                        isFreeAction: true,
                        hidden: !this.state.permissions[
                          PermissionProperties.addExpenseRule
                        ],
                      },
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: false,
                      search: false,
                      maxBodyHeight: '70vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                    onRowClick={(event, rowData, toggleDetailPanel) =>
                      this.onExpenseCategoryEditHandler(rowData.id, false)
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { claimRules } = state;
  const { deleteExpenseRule, claimExpenseRuleList } = claimRules;
  return {
    claimExpenseRuleList: claimExpenseRuleList.data,
    claimExpenseRuleListLoading: claimExpenseRuleList.isFetching,
    deleteExpenseRuleInProgress: deleteExpenseRule.inProgress,
    deleteExpenseRuleSuccess: deleteExpenseRule.success,
    deleteExpenseRuleError: deleteExpenseRule.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllExpenseRules: () => dispatch(actions.getAllExpenseRules()),
    getExpenseRuleById: (id) => dispatch(actions.getExpenseRuleById(id)),
    deleteExpenseRule: (id, data) =>
      dispatch(actions.deleteExpenseRule(id, data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpenseRules);
