import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
//import PerfectScrollbar from "perfect-scrollbar";
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Navbar from 'components/Navbars/Navbar.js';
//import Footer from "components/Footer/Footer.js";
import Sidebar from 'components/Sidebar/Sidebar.js';
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import * as routes from 'routes.js';
import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { FiSearch } from 'react-icons/fi';
import bgImage from 'assets/img/sidebar-2.png';
import logo from 'assets/img/reactlogo.png';
import RouterProps from '../Util/Property/RouterProperties';
import * as actions from '../store/actions/index';
import { checkPermissionAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import { connect } from 'react-redux';
import {
  customerUserDashboard,
  getCustomerUserDashboard,
  getWareHouseUserDashboard,
} from 'routes.js';
import { isCustomerUser, isWareHouseUrl } from '../Util/Util';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import { FaSearch } from 'react-icons/fa';
import Avatar from '@material-ui/core/Avatar';
import SearchDialog from 'components/SearchDialog/SearchDialog';
const redirectDefault = RouterProps.mainDomain + '/dashboard';

let ps;

const seachtypes = [
  {
    value: 'SR_TYPE_WISE',
    displayValue: 'SR Type Wise',
  },
  {
    value: 'PRODUCT_WISE',
    displayValue: 'Product Wise',
  },
  {
    value: 'EMPLOYEE_WISE',
    displayValue: 'Employee Wise',
  },
  {
    value: 'DATE_WISE',
    displayValue: 'Date Wise',
  },
];

const useStyles = makeStyles(styles);

const Admin = (props) => {
  let routesForLoggedInUser;
  let dashboardRoutesForLoggedInUser;
  if (isWareHouseUrl(props.location)) {
    routesForLoggedInUser = routes.wareHouseRoutes;
    dashboardRoutesForLoggedInUser = getWareHouseUserDashboard();
  } else if (isCustomerUser()) {
    routesForLoggedInUser = routes.customerUserRouts;
    dashboardRoutesForLoggedInUser = getCustomerUserDashboard();
  } else {
    routesForLoggedInUser = routes.allRouts;
    dashboardRoutesForLoggedInUser = routes.dashboardRoutes;
  }
  const switchRoutes = (
    <Switch>
      {// side bar routes
      routesForLoggedInUser.map((prop, key) => {
        if (
          prop.layout === RouterProps.mainDomain &&
          (prop.isDefaultPath ||
            checkPermissionAvailable(
              PermissionProperties.urlProperties[prop.path]
            ))
        ) {
          /// check for permissions
          return (
            <Route
              exact
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from={RouterProps.mainDomain} to={redirectDefault} />
    </Switch>
  );

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState('blue');
  const [fixedClasses, setFixedClasses] = React.useState('dropdown show');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isChangePermissions, setisChangePermissions] = useState(false);
  const [isMiniSidebar, setIsMiniSidebar] = useState(false);

  // React.useEffect(() => {
  //   window.addEventListener('resize', resizeFunction);

  //   // // Specify how to clean up after this effect:
  //   // return function cleanup() {
  //   //   if (navigator.platform.indexOf('Win') > -1) {
  //   //     ps.destroy();
  //   //   }
  //   //   window.removeEventListener('resize', resizeFunction);
  //   // };
  // });

  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === 'dropdown') {
      setFixedClasses('dropdown show');
    } else {
      setFixedClasses('dropdown');
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== '/admin/maps';
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 860) {
      handleSidebar(true);
    }
  };

  const handleSidebar = (val) => {
    setIsMiniSidebar(val);
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    mainPanel.current.scrollTo(0, 0);

    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(mainPanel.current, {
    //     suppressScrollX: true,
    //     suppressScrollY: false
    //   });
    //   document.body.style.overflow = "hidden";
    // }
    // window.addEventListener("resize", resizeFunction);
    // // Specify how to clean up after this effect:
    // return function cleanup() {
    //   if (navigator.platform.indexOf("Win") > -1) {
    //     ps.destroy();
    //   }
    //   window.removeEventListener("resize", resizeFunction);
    // };
  }, [mainPanel]);

  React.useEffect(() => {
    const ismini = localStorage.getItem('isMiniActive');
    if (ismini === '1') {
      setIsMiniSidebar(true);
    } else {
      setIsMiniSidebar(false);
    }
  }, []);

  React.useEffect(() => {
    setisChangePermissions(true);
  }, [props.permissions]);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={dashboardRoutesForLoggedInUser}
        logoText={''}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        isMiniSidebar={isMiniSidebar}
        {...props}
      />
      {isMiniSidebar ? (
        <div className={classes.mainPanelMini} ref={mainPanel}>
          <Navbar
            routes={routesForLoggedInUser}
            handleDrawerToggle={handleDrawerToggle}
            handleSidebar={handleSidebar}
            {...props}
          />
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
        </div>
      ) : (
        <div className={classes.mainPanel} ref={mainPanel}>
          <Navbar
            routes={routesForLoggedInUser}
            handleDrawerToggle={handleDrawerToggle}
            handleSidebar={handleSidebar}
            {...props}
          />
          <br />
          <SearchDialog />
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps)(Admin);
