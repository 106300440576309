import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import GeneralProperties from '../../Util/Property/GeneralProperties';
import Card from 'components/Card/Card.js';
import LoadingOverlay from 'react-loading-overlay';
import {
  getUserPasswordResetForm,
  userProperties,
} from '../../Util/Property/UserProperties';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import CustomInput from '../../components/CustomInput/CustomInput';
import styles from '../Users/CustomerUserProfile/User.module.css';
import CardFooter from '../../components/Card/CardFooter';
import Button from '../../components/CustomButtons/Button';
import axios from '../../axios/axios-default';
import UrlProperties from '../../Util/Property/UrlProperties';
import { notyDefault, notyTypes } from '../../components/Noty/NotyCustom';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { Typography } from '@material-ui/core';

const ResetPassword = () => {
  const [passwordForm, setPasswordForm] = useState(getUserPasswordResetForm());
  const [permissions, setPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isStateChanged, setIsStateChanged] = useState(false);

  const onPasswordSubmit = async (values, form) => {
    setIsLoading(true);
    const userId = localStorage.getItem('userId');
    let data = {
      id: userId,
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    await axios
      .put(`${UrlProperties.user.changePassword}`, data)
      .then((result) => {
        //this.props.history.goBack();
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: userProperties.messages.success.changePassword,
          });
        } else {
          notyDefault({ type: notyTypes.SUCCESS, text: result.data.message });
        }
        let formData = { ...passwordForm };
        for (let key in formData) {
          let elementData = formData[key];
          let inputProps = { ...elementData.inputProps };
          inputProps.value = '';
          elementData.inputProps = inputProps;
          formData[key] = elementData;
        }
        setPasswordForm(formData);
        Object.keys(values).forEach((key) => {
          form.change(key, undefined);
          form.resetFieldState(key);
        });
      })
      .catch((error) => {
        const { response = {} } = error || {};
        const { data = {} } = response || {};
        const { details = '' } = data || {};
        notyDefault({
          type: notyTypes.ERROR,
          text: details || userProperties.messages.error.changePassword,
        });
      });
    setIsLoading(false);
  };

  let formElementArray = [];
  for (let key in passwordForm) {
    formElementArray.push({
      id: key,
      config: passwordForm[key],
    });
  }

  const onChangeHandeler = (event, elId) => {
    let formData = { ...passwordForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = event.target.value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setPasswordForm(formData);
  };

  return (
    <div style={{ width: '50%' }}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={10}>
          <LoadingOverlay active={isLoading} spinner text="Loading ...">
            <Card>
              <div className={'generic-form-header-wrapper'}>
                <div className={'generic-page-title'}>
                  <span className={'generic-page-title-icon'}>
                    <LockOutlined />
                  </span>
                  <Typography className={'generic-header-text'} variant="h6">
                    Reset Password
                  </Typography>
                  <Typography variant="body1">
                    Reset your Password here
                  </Typography>
                </div>
              </div>
              <Form
                onSubmit={onPasswordSubmit}
                validate={(values) => {
                  const errors = {};
                  if (!values.oldPassword) {
                    errors.oldPassword = GeneralProperties.emptyField;
                  }
                  if (!values.newPassword) {
                    errors.newPassword = GeneralProperties.emptyField;
                  }

                  if (!values.confirmNewPassword) {
                    errors.confirmNewPassword = GeneralProperties.emptyField;
                  }

                  if (values.newPassword && values.confirmNewPassword) {
                    if (values.newPassword !== values.confirmNewPassword) {
                      errors.confirmNewPassword =
                        userProperties.passwordMismatch;
                    }
                  }
                  return errors;
                }}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  valid,
                }) => (
                  <form
                    onSubmit={(e) => {
                      handleSubmit(e).then(() => {
                        form.reset();
                      });
                    }}
                  >
                    <CardBody>
                      <GridContainer>
                        {formElementArray.map((element) => {
                          return (
                            <GridItem key={element.id} xs={12} sm={12} md={12}>
                              <Field name={element.id}>
                                {({ input, meta, options, value }) => (
                                  <div style={{ position: 'relative' }}>
                                    <CustomInput
                                      labelText={element.config.label}
                                      id={element.id}
                                      inputProps={{
                                        ...input,
                                        ...element.config.inputProps,
                                      }}
                                      type={element.config.elType}
                                      formControlProps={
                                        element.config.formControlProps
                                      }
                                      changed={(event, value) => {
                                        input.onChange(event);
                                        onChangeHandeler(event, element.id);
                                      }}
                                      labelProps={{
                                        ...element.config.labelProps,
                                        error: meta.error && meta.touched,
                                      }}
                                    />
                                    {meta.error && meta.touched && (
                                      <span className={styles.formError}>
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </GridItem>
                          );
                        })}
                      </GridContainer>
                    </CardBody>
                    <CardFooter>
                      <Button
                        type="submit"
                        disabled={submitting || !valid || pristine}
                        color="primary"
                        autoFocus
                      >
                        {GeneralProperties.change}
                      </Button>
                    </CardFooter>
                  </form>
                )}
              />
            </Card>
          </LoadingOverlay>
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default ResetPassword;
