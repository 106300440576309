import React from 'react';
import User from 'views/Users/User/User';

const Profile = (props) => {
  return (
    <React.Fragment>
      <User
        id={localStorage.getItem('userId')}
        isEdit={false}
        isProfile={true}
      />
    </React.Fragment>
  );
};

export default Profile;
