import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { InputLabel } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        maxWidth: "100%",
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function DropDownSelection(props) {
    const { frequency, setFrequency, dropDownData ,labelforDropDwon,disabledVal, isRequired} = props
    const classes = useStyles();
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const [isDisabled,setIsDiabled] = useState(false);

    useEffect(()=>{
        setIsDiabled(disabledVal);
    })

    // const frequencyArray = [
    //     { "id": "1", "name": "Daily" },
    //     { "id": "2", "name": "Weekly" },
    //     { "id": "3", "name": "Monthly" },
    //     { "id": "4", "name": "Yearly" }
    // ]
    const handleChange = (event) => {
        setFrequency(event.target.value);
    };


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        },
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "center"
        },
        variant: "menu"
    };



    return (
        <div style={{ width: "100%" }}>
            <FormControl className={classes.formControl}>
            <InputLabel id="mutiple-select-label">{labelforDropDwon}</InputLabel>
             
                <Select
                    disabled={isDisabled}
                    labelId="mutiple-select-label"
                    id="demo-simple-select"
                    value={frequency}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                    required={isRequired}
                >
                    {dropDownData.map((el) => (
                        <MenuItem key={el.id} value={el.name}>{el.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>

        </div>
    );
}
