import React, { useState, useEffect, useRef } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "./WarehouseEstimation.module.css";
import {
  estimationProperties,
  getEstimationForm,
  getPartForm
} from "Util/Property/EstimationProperties";
import GeneralProperties from "Util/Property/GeneralProperties";
import LoadingOverlay from "react-loading-overlay";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button.js";
import { Form, Field } from "react-final-form";
import { notyDefault, notyTypes } from "components/Noty/NotyCustom";
import CustomInput from "components/CustomInput/CustomInput.js";
import UrlProperties from "Util/Property/UrlProperties";
import * as EstimationService from "services/Estimaion/EstimationService";
import MaterialTable, { MTableToolbar } from "material-table";
import PermissionProperties from "Util/Property/PermissionProperties";
import { checkPermissoinListAvailable } from "Util/Permission/CheckPermission";
import { getServiceRequestByNotCompleted } from "services/ServiceRequest/ServiceRequestService";
import { serviceRequestProperties } from "Util/Property/ServiceRequestProperties";
import { getAllCustomers } from "services/Customer/CustomerService";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Checkbox from "@material-ui/core/Checkbox";
import * as moment from "moment";
import { TextField, Typography } from "@material-ui/core";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import { getActiveBrandList } from "services/Product/ProductBrandService";
import { getActiveModelList } from "services/Product/ModelService";
import { getActiveCategoryList } from "services/Product/ProductService";
import {
  getAllProducts,
  getProduct,
  getProductsByCustomerAndModel
} from "../../../services/ProductAndServices/ProductService";
import generalProperties from "Util/Property/GeneralProperties";
import History from "views/History/History";
import { handleErrorMessage } from "services/CommonService";

const WarehouseEstimation = props => {
  const [isPartDialogOpen, setIsPartDialogOpen] = useState(false);
  const [estimationForm, setEstimationForm] = useState(getEstimationForm());
  const [partForm, setPartForm] = useState(getPartForm());
  const [estimationHeading, setEstimationHeading] = useState(
    estimationProperties.addEstimation
  );
  const [estimationSubHeading, setEstimationSubHeading] = useState(
    estimationProperties.createEstimation
  );
  const [isLoading, setIsLoadingIcon] = useState(false);
  const [estimationId, setEstimationId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [currentStatus, setCurrentStatus] = useState();
  const [statusPopupOpen, setStatusPopupOpen] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [partHeading, setPartHeading] = useState("Add Part");
  const [partData, setPartData] = useState([]);

  const [partDataRows, setPartDataRows] = useState([]);
  const [deleteRowData, setDeleteRowData] = useState({});
  const [partButton, setPartButton] = useState(generalProperties.add);
  const [loadingCount, setLoadingCount] = useState({
    count: 0
  });

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const tableRef = useRef(null);
  const [oldComments, setOldComments] = useState("");

  const [asyncDataState, setAsyncDataState] = useState({
    //service_request_id: false,
    customer_id: false,
    product_id: false,
    productCategoryId: false,
    productBrandId: false,
    productModelId: false
  });
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);

  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addEstimation,
      PermissionProperties.viewEstimation,
      PermissionProperties.editEstimation,
      PermissionProperties.deleteEstimation,
      PermissionProperties.viewHistory
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  const setIsLoading = val => {
    if (val) {
      loadingCount.count += 1;
    } else if (loadingCount.count > 0) {
      loadingCount.count -= 1;
    }

    if (loadingCount.count === 0) {
      setIsLoadingIcon(false);
      let formData = { ...estimationForm };
      setEstimationForm(formData);
    } else {
      setIsLoadingIcon(true);
    }
  };
  useEffect(() => {
    let estId = 0;
    let customerId = 0;

    const query = new URLSearchParams(props.location.search);
    let queryParam = {};
    for (let param of query.entries()) {
      queryParam[param[0]] = param[1];
    }
    customerId = queryParam["customerId"];

    if (props.id && props.id > 0) {
      if (props.isEdit) {
        setIsEdit(true);
      } else {
        setIsView(true);
      }
      estId = props.id;
      customerId = props.customerId;
      setEstimationHeading(estimationProperties.viewEstimation);
      setEstimationSubHeading(estimationProperties.manageEstimationHere);
    } else if (props.location) {
      if (typeof queryParam["id"] != "undefined") {
        estId = queryParam["id"];
        setEstimationHeading(estimationProperties.viewEstimation);
        setEstimationSubHeading(estimationProperties.manageEstimationHere);
        if (queryParam["isEdit"] === "true") {
          setIsEdit(true);
          setEstimationHeading(estimationProperties.editEstimation);
        } else {
          setIsView(true);
        }
      }
    }
    if (customerId > 0) {
      setCustomerInitValue(customerId);
    }
    setEstimationId(estId);
    if (estId > 0) {
      // this is for view and edit Service Request
      //getServiceRequestData(serviceRequestId);
      //setServiceRequestId(serviceRequestId);
      getEstimationData(estId, customerId);
    } else {
      // this is loading for add new Service Request
      estimationForm["created_by"].isHide = true;
      estimationForm["created_at"].isHide = true;
      estimationForm["comment"].isHide = true;
      getBackGroundData(customerId);
      !estId && getDefaultProductData(true);
    }
  }, [props.id]);

  const getDefaultProductData = isFirstTime => {
    setIsLoading(true);
    getActiveCategoryList()
      .then(result => {
        asyncDataState.productCategoryId = true;
        if (result.status === 200) {
          let categoryOptions = [];
          result.data.map(category =>
            categoryOptions.push({
              value: category.id,
              displayValue: category.name
            })
          );
          let defaultValue = setDataToEstimationForm(
            "productCategoryId",
            categoryOptions,
            true
          );
          if (defaultValue) {
            getActiveBrandListForEstimations(defaultValue, true);
          } else {
            setDataToEstimationForm("product_id", [], isFirstTime);
            setDataToEstimationForm("productModelId", [], isFirstTime);
            setDataToEstimationForm("productBrandId", [], isFirstTime);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : estimationProperties.messages.error.loadProductCategories
          });
        }
      })
      .catch(error => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: estimationProperties.messages.error.loadProductCategories
        });
      });
  };
  const setCustomerInitValue = customerId => {
    estimationForm["customer_id"].inputProps.initvalue = parseInt(customerId);
    estimationForm["customer_id"].readOnly = true;
  };
  const getActiveBrandListForEstimations = (catId, isFirstTime) => {
    setIsLoading(true);
    getActiveBrandList(catId)
      .then(result => {
        asyncDataState.productBrandId = true;
        if (result.status === 200) {
          let brandOptions = [];
          result.data.map(brand =>
            brandOptions.push({ value: brand.id, displayValue: brand.name })
          );
          let defaultValue = setDataToEstimationForm(
            "productBrandId",
            brandOptions,
            isFirstTime
          );
          if (defaultValue) {
            getActiveProductModelListForEstimations(defaultValue, isFirstTime);
          } else {
            // no brands for the seleted category
            setDataToEstimationForm("product_id", [], isFirstTime);
            setDataToEstimationForm("productModelId", [], isFirstTime);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : estimationProperties.messages.error.loadProductBrands
          });
        }
      })
      .catch(error => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: estimationProperties.messages.error.loadProductBrands
        });
      });
  };

  const isEmpty = element => {
    return element === "" || typeof element === "undefined";
  };

  const getActiveProductModelListForEstimations = (brandId, isFirstTime) => {
    setIsLoading(true);
    getActiveModelList(brandId)
      .then(result => {
        asyncDataState.productModelId = true;
        if (result.status === 200) {
          let modelOptions = [];
          result.data.map(model =>
            modelOptions.push({ value: model.id, displayValue: model.name })
          );
          let defaultValue = setDataToEstimationForm(
            "productModelId",
            modelOptions,
            isFirstTime
          );
          if (defaultValue) {
            getProductListForEstimations(
              defaultValue,
              "productModelId",
              isFirstTime
            );
          } else {
            // no Models for the seleted Brand
            setDataToEstimationForm("product_id", [], isFirstTime);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : estimationProperties.messages.error.loadProducts
          });
        }
      })
      .catch(error => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: estimationProperties.messages.error.loadProductModels
        });
      });
  };

  const getProductListForEstimations = (value, elId, isFirstTime) => {
    let modelId = "";
    let customerId = "";
    if (elId === "productModelId") {
      modelId = value;
      customerId = estimationForm.customer_id.inputProps.value;
    } else if (elId === "customer_id") {
      customerId = value;
      modelId = estimationForm.productModelId.inputProps.value;
    }

    if (isEmpty(customerId) || isEmpty(modelId)) {
      return;
    }
    setIsLoading(true);
    getProductsByCustomerAndModel(customerId, modelId)
      .then(result => {
        asyncDataState.product_id = true;
        if (result.status === 200) {
          let productOptions = [];
          result.data.map(product => {
            if (product.active) {
              productOptions.push({
                value: product.id,
                displayValue: product.serial
              });
            }
          });
          setDataToEstimationForm("product_id", productOptions, isFirstTime);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : estimationProperties.messages.error.loadProductBrands
          });
        }
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: estimationProperties.messages.error.loadProductBrands
        });
      });
  };

  const getEstimationData = async (estId, customerId) => {
    setIsLoading(true);
    await EstimationService.getEstimation(estId)
      .then(result => {
        let productId = null;
        if (result.status === 200) {
          setEstimationHeading("Est-" + result.data.id);
          for (let key in estimationForm) {
            let value = result.data[key];
            if (key === "created_at") {
              value = moment(value).format("YYYY/MM/DD");
            }
            if (key === "status") {
              setCurrentStatus(value);
            }

            if (key === "comment") {
              if (value !== "" && value !== null && value) {
                setOldComments(value);
                let comments = value.split(estimationProperties.COMMENT_END);
                value = "";
                for (let key in comments) {
                  value += comments[key] + "\n";
                }
              }
            }
            if (key === "product_id") {
              productId = value;
            }
            if (value === null) {
              value = "";
            }
            if (!estimationForm[key].isAsyncDropDown) {
              estimationForm[key].inputProps.value = value;
            } else {
              estimationForm[key].inputProps.initvalue = value;
            }
          }
          if (productId !== null) {
            getProductData(productId, customerId);
          } else {
            getBackGroundData(customerId);
            getDefaultProductData();
          }
          setPartItems(result.data.parts);
          setIsLoading(false);
        } else {
          handleErrorMessage(
            result.data,
            estimationProperties.messages.error.loadData
          );
          notyDefault({
            type: notyTypes.ERROR,
            text: estimationProperties.messages.error.loadData
          });
          setIsLoading(false);
        }
      })
      .catch(error => {
        handleErrorMessage(error, estimationProperties.messages.error.loadData);
        setIsLoading(false);
      });
  };

  const getProductData = async (productId, customerId) => {
    setIsLoading(true);
    await getProduct(productId)
      .then(result => {
        if (result.status === 200) {
          estimationForm["productCategoryId"].inputProps.initvalue =
            result.data.productCategoryId;
          estimationForm["productBrandId"].inputProps.initvalue =
            result.data.productBrandId;
          estimationForm["productModelId"].inputProps.initvalue =
            result.data.productModelId;
          getBackGroundData(customerId);
          getDefaultProductData();
        } else {
          handleErrorMessage(
            result.data,
            estimationProperties.messages.error.loadData
          );
        }
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        handleErrorMessage(error, estimationProperties.messages.error.loadData);
      });
  };
  const setPartItems = parts => {
    if (parts) {
      checkforTotalPriceColumn(parts);
    }
  };

  const getBackGroundData = customerId => {
    getCustomersForEstimation(true);
  };

  const getServiceRequestByNotCompletedForEstimation = (
    customerId,
    isFirstTime
  ) => {
    setIsLoading(true);

    getServiceRequestByNotCompleted(customerId)
      .then(result => {
        asyncDataState.service_request_id = true;
        if (result.status === 200) {
          let serviceRequests = [];
          result.data.map(serviceRequest =>
            serviceRequests.push({
              value: serviceRequest.id,
              displayValue: serviceRequest.name
            })
          );
          let defaultVal = setDataToEstimationForm(
            "service_request_id",
            serviceRequests,
            isFirstTime
          );
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : serviceRequestProperties.messages.error.loadData
          });
        }

        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: serviceRequestProperties.messages.error.loadData
        });
      });
  };

  const getCustomersForEstimation = () => {
    setIsLoading(true);
    getAllCustomers()
      .then(result => {
        asyncDataState.customer_id = true;
        if (result.status === 200) {
          let customerOptions = [];
          result.data.map(customer =>
            customerOptions.push({
              value: customer.id,
              displayValue: customer.name
            })
          );
          let defaultVal = setDataToEstimationForm(
            "customer_id",
            customerOptions,
            true
          );
          getServiceRequestByNotCompletedForEstimation(defaultVal, true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : serviceRequestProperties.messages.error.loadCustomers
          });
        }
      })
      .catch(error => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: serviceRequestProperties.messages.error.loadCustomers
        });
      });
  };

  const onChangeHandeler = (event, elId) => {
    let value = event.target.value;

    let formData = { ...estimationForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = event.target.value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    if (elId === "customer_id") {
      formData["service_request_id"].inputProps.value = "";
      getServiceRequestByNotCompletedForEstimation(value);
      getProductListForEstimations(value, "customer_id", false);
    }
    setEstimationForm(formData);
    if (elId === "productCategoryId") {
      asyncDataState.productBrandId = false;
      asyncDataState.productModelId = false;
      getActiveBrandListForEstimations(value, false);
    }
    if (elId === "productBrandId") {
      asyncDataState.productModelId = false;
      getActiveProductModelListForEstimations(value, false);
    }
    if (elId === "productModelId") {
      asyncDataState.product_id = false;
      getProductListForEstimations(value, "productModelId", false);
    }
  };

  const onPartChangeHandeler = (event, elId) => {
    let value = event.target.value;
    let formData = { ...partForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data

    if (elId === "quantity") {
      let val = parseInt(value);
      if (isNaN(val) && value !== "") {
        value = formData["quantity"].inputProps.value;
      } else if (value !== "") {
        value = val;
      }
      let totPrice = parseInt(value) * formData["unit_price"].inputProps.value;
      formData["total_price"].inputProps.value =
        totPrice === "undefined" || isNaN(totPrice) ? 0 : totPrice;
    }
    if (elId === "taken_from_ware_house" || elId === "return_to_ware_house") {
      let val = parseInt(value);
      if (isNaN(val) && value !== "") {
        value = formData[elId].inputProps.value;
      } else if (value !== "") {
        value = val;
      }
    }
    if (elId === "unit_price") {
      if (!value.match("^[0-9]+(.[0-9]{1,2})?$")) {
        let val = value;
        val += "0";
        if (!val.match("^[0-9]+(.[0-9]{1,2})?$")) {
          value = formData[elId].inputProps.value;
        }
      }
      let totPrice = parseFloat(value) * formData["quantity"].inputProps.value;
      formData["total_price"].inputProps.value =
        totPrice === "undefined" || isNaN(totPrice) ? 0 : totPrice;
    }
    if (elId === "availability") {
      value = event.target.checked;
    }
    inputProps.value = value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setPartForm(formData);
  };

  const onEstimationSubmit = e => {
    const estStatus = estimationForm.status.inputProps.value;
    const srId = estimationForm.service_request_id.inputProps.value;
    if (estStatus !== currentStatus && estStatus === "APPROVED" && !srId) {
      setStatusPopupOpen(true);
    } else {
      onEstimationSave();
    }
  };
  const onEstimationSave = async () => {
    setStatusPopupOpen(false);
    let method = "";
    let url = "";
    let data = {};
    let checklistFormData = { ...estimationForm };
    for (let key in checklistFormData) {
      let val = checklistFormData[key].inputProps.value;
      if (
        key !== "productCategoryId" &&
        key !== "productBrandId" &&
        key !== "productModelId"
      ) {
        if (val !== "") {
          data[key] = val;
        }
      }
    }

    let partItemDataToSubmit = [...partData];

    data["parts"] = partItemDataToSubmit;

    if (estimationId > 0) {
      data["id"] = estimationId;
      method = "PATCH";
      url = UrlProperties.estimation.updateEstimations + "/" + estimationId;
    } else {
      data["id"] = 0;
      method = "POST";
      url = UrlProperties.estimation.createEstimations;
    }

    setIsLoading(true);
    await EstimationService.saveEstimation(method, url, data)
      .then(result => {
        //this.props.history.goBack();
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: estimationProperties.messages.success.saveData
          });
          if (estimationId == 0) {
            setDefaultData();
          }
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: estimationProperties.messages.error.saveData
          });
        }
      })
      .catch(error => {
        notyDefault({
          type: notyTypes.ERROR,
          text: estimationProperties.messages.error.saveData
        });
      });
    setIsLoading(false);
  };

  /**
   * set default form data after a form submission or adding new user
   */
  const setDefaultData = () => {
    let formData = { ...estimationForm };
    for (let key in formData) {
      if (key !== "customer_id" && key !== "service_request_id") {
        let elementData = formData[key];
        let inputProps = { ...elementData.inputProps }; //  copy input data
        inputProps.value = inputProps.initvalue;
        elementData.inputProps = inputProps;
        formData[key] = elementData;
      }
    }
    setEstimationForm(formData);
    setPartData([]);
    setPartDataRows([]);
  };

  /**
   *
   * @param {*} key
   * @param {*} options
   * save api data to Service Request form
   */

  const setDataToEstimationForm = (key, options, isFirstTime) => {
    // let serviceRequestFormData = { ...serviceRequestForm };
    let formData = estimationForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== "") {
      defaultValue = inputProps.initvalue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : "";
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    estimationForm[key] = formData;
    //setServiceRequestForm(serviceRequestFormData);
    //checkForStateUpdate(isFirstTime);
    return defaultValue;
  };

  /**
   * check for state update if criterias met then update the state
   */
  const checkForStateUpdate = isFirstTime => {
    let status = true;
    for (let key in asyncDataState) {
      if (asyncDataState[key] === false) {
        status = false;
      }
    }
    if (status || isFirstTime) {
      let formData = { ...estimationForm };
      setEstimationForm(formData);
    }
  };
  const onCheckItemEditHandler = (id, isEdit) => {};

  const onCheckItemDeleteHandler = rowData => {};

  /**
   *  arrange data to populate Form UI
   */
  let formElementArray = [];
  for (let key in estimationForm) {
    formElementArray.push({
      id: key,
      config: estimationForm[key]
    });
  }

  const offsetIndex = (from, to, arr = []) => {
    if (from < to) {
      let start = arr.slice(0, from),
        between = arr.slice(from + 1, to + 1),
        end = arr.slice(to + 1);
      return [...start, ...between, arr[from], ...end];
    }
    if (from > to) {
      let start = arr.slice(0, to),
        between = arr.slice(to, from),
        end = arr.slice(from + 1);
      return [...start, arr[from], ...between, ...end];
    }
    return arr;
  };
  const reOrderRow = (from, to) => {
    let newtableData = offsetIndex(from, to, partData);
    //Update react state
    setPartData(newtableData);
  };

  const partDialogCloseHandler = () => {
    setIsPartDialogOpen(false);
  };

  const togglePartDialog = isOpen => {
    setIsPartDialogOpen(isOpen);
  };

  const onPartSubmit = () => {
    let formData = { ...partForm };
    let part = {};
    for (let key in formData) {
      part[key] = formData[key].inputProps.value;
    }
    let partDataToModified = [...partData];
    if (parseInt(part["partId"]) === 0) {
      part["partId"] = Math.floor(Math.random() * 999999);
      partDataToModified.push(part);
    } else {
      // data is modifying
      let partId = part["partId"];
      for (let key in partDataToModified) {
        if (partDataToModified[key].partId === partId) {
          partDataToModified[key] = part;
        }
      }
    }
    checkforTotalPriceColumn(partDataToModified);
    //setPartData(partDataToModified);
    togglePartDialog(false);
    setPartForm(getPartForm);
  };

  const editPart = rowData => {
    let formData = { ...partForm };
    for (let key in formData) {
      formData[key].inputProps.value = rowData[key];
      if (key === "return_to_ware_house" || key === "taken_from_ware_house") {
        formData[key].inputProps.disabled = false;
      }
    }
    setPartForm(formData);
    setPartHeading("Edit Part");
    setPartButton(generalProperties.update);
    togglePartDialog(true);
  };

  const deletePart = rowData => {
    setDeleteRowData(rowData);
    deletePartDialogOpenHandler();
  };

  const deleteParttDialogCloseHandler = () => {
    setDeleteDialogOpen(false);
  };

  const deletePartDialogOpenHandler = () => {
    setDeleteDialogOpen(true);
  };

  const deleteConfirmHandler = () => {
    let data = { ...deleteRowData };
    let partDataToModified = [...partData];
    for (let key in partDataToModified) {
      if (partDataToModified[key].partId === data.partId) {
        partDataToModified.splice(key);
      }
    }
    checkforTotalPriceColumn(partDataToModified);
    // setPartData(partDataToModified);
    notyDefault({
      type: notyTypes.SUCCESS,
      text: estimationProperties.messages.success.removePart
    });
    deleteParttDialogCloseHandler();
  };

  const checkforTotalPriceColumn = partData => {
    let total = 0;
    for (let key in partData) {
      total += partData[key].total_price;
    }
    let allRows = [...partData];
    if (total > 0) {
      let totalRow = {
        unit_price: "Total",
        total_price: total,
        isTotal: true
      };

      allRows.push(totalRow);
    }
    setPartData(partData);
    setPartDataRows(allRows);
  };

  const histroyDialogCloseHandler = () => {
    setIsHistoryDialogOpen(false);
  };

  const changePartValue = (partId, elId, e) => {
    const part = partData.find(({ id }) => id === partId);
    part[elId] = parseFloat(e.target.value);
    checkforTotalPriceColumn(partData);
  };
  const historyDialogOpenHandler = () => {
    setIsHistoryDialogOpen(true);
  };
  /**
   *  arrange data to populate Form UI
   */
  let partFormElementArray = [];
  for (let key in partForm) {
    partFormElementArray.push({
      id: key,
      config: partForm[key]
    });
  }
  return (
    <div>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Dialog
              open={isDeleteDialogOpen}
              onClose={deleteParttDialogCloseHandler}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {estimationProperties.messages.normal.deletePartConfirm}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  size="sm"
                  onClick={deleteParttDialogCloseHandler}
                  color="primary"
                >
                  {GeneralProperties.cancel}
                </Button>
                <Button
                  size="sm"
                  onClick={deleteConfirmHandler}
                  color="primary"
                  autoFocus
                >
                  {GeneralProperties.confirm}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={statusPopupOpen}
              onClose={() => {
                setStatusPopupOpen(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This estimation is not associated with a service request
                  currently, if you continue a new service request will be
                  created for this estimation
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  size="sm"
                  onClick={() => {
                    setStatusPopupOpen(false);
                  }}
                  color="primary"
                >
                  {generalProperties.cancel}
                </Button>
                <Button
                  size="sm"
                  onClick={onEstimationSave}
                  color="primary"
                  autoFocus
                >
                  {generalProperties.confirm}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              maxWidth="lg"
              fullWidth={true}
              open={isHistoryDialogOpen}
              onClose={histroyDialogCloseHandler}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                {estimationId > 0 ? (
                  <History
                    method={EstimationService.getEstimationHistory}
                    id={estimationId}
                  />
                ) : (
                  ""
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  size="sm"
                  onClick={histroyDialogCloseHandler}
                  color="primary"
                >
                  {generalProperties.close}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={isPartDialogOpen}
              onClose={partDialogCloseHandler}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <Card>
                  <div className={"generic-form-header-wrapper"}>
                    <div className={"generic-page-title"}>
                      <span className={"generic-page-title-icon"}>
                        <ListOutlinedIcon />
                      </span>
                      <Typography
                        className={"generic-header-text"}
                        variant="h6"
                      >
                        Parts
                      </Typography>
                      <Typography variant="body1">
                        Manage parts here.
                      </Typography>
                    </div>
                  </div>
                  <Form
                    onSubmit={onPartSubmit}
                    validate={(values, ss) => {
                      const errors = {};
                      if (partForm.part_number.inputProps.value === "") {
                        // setEror("first_name", true)
                        errors.part_number = GeneralProperties.emptyField;
                      }

                      if (partForm.quantity.inputProps.value === "") {
                        // setEror("first_name", true)
                        errors.quantity = GeneralProperties.emptyField;
                      }

                      if (partForm.unit_price.inputProps.value === "") {
                        // setEror("first_name", true)
                        errors.unit_price = GeneralProperties.emptyField;
                      }

                      return errors;
                    }}
                    render={({
                      handleSubmit,
                      reset,
                      submitting,
                      pristine,
                      valid
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <CardBody>
                          <GridContainer>
                            {partFormElementArray.map(element => {
                              if (!element.config.isHide) {
                                let mdVal = element.config.size;
                                return (
                                  <GridItem
                                    key={element.id}
                                    xs={12}
                                    sm={12}
                                    md={mdVal}
                                  >
                                    {element.id === "comments" ? (
                                      <div> Comments</div>
                                    ) : (
                                      ""
                                    )}
                                    <Field
                                      name={element.id}
                                      type={element.config.elType}
                                    >
                                      {({ input, meta, options, value }) => (
                                        <div style={{ position: "relative" }}>
                                          <CustomInput
                                            labelText={element.config.label}
                                            id={element.id}
                                            inputProps={{
                                              ...input,
                                              ...element.config.inputProps,
                                              readOnly:
                                                isView ||
                                                element.config.readOnly
                                                  ? true
                                                  : false
                                            }}
                                            type={element.config.elType}
                                            formControlProps={
                                              element.config.formControlProps
                                            }
                                            adornmentText={
                                              element.config.adornmentText
                                            }
                                            adornmentPosition={
                                              element.config.adornmentPosition
                                            }
                                            changed={(event, value) => {
                                              input.onChange(event);
                                              onPartChangeHandeler(
                                                event,
                                                element.id,
                                                meta
                                              );
                                            }}
                                            labelProps={{
                                              ...element.config.labelProps,
                                              error: meta.error && meta.touched
                                            }}
                                          />
                                          {meta.error && meta.touched && (
                                            <span className={styles.formError}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </GridItem>
                                );
                              }
                            })}
                          </GridContainer>
                        </CardBody>
                        <CardFooter style={{ display: "block" }}>
                          {!isView ? (
                            <Button
                              type="submit"
                              disabled={submitting || !valid}
                              color="primary"
                              autoFocus
                            >
                              {partButton}
                            </Button>
                          ) : (
                            ""
                          )}
                          <Button
                            style={{ marginLeft: "20px" }}
                            onClick={partDialogCloseHandler}
                            color="primary"
                            autoFocus
                          >
                            {GeneralProperties.cancel}
                          </Button>
                        </CardFooter>
                      </form>
                    )}
                  />
                </Card>
              </DialogContent>
            </Dialog>
            <Card>
              <div className="generic-form-container">
                <div className={"generic-form-header-wrapper"}>
                  <div className={"generic-page-title"}>
                    <span className={"generic-page-title-icon"}>
                      <ListAltOutlinedIcon />
                    </span>
                    <Typography className={"generic-header-text"} variant="h6">
                      {estimationHeading}
                    </Typography>
                    <Typography variant="body1">
                      {estimationSubHeading}
                    </Typography>
                  </div>

                  {estimationId > 0 &&
                  permissions[PermissionProperties.viewHistory] ? (
                    <div style={{ float: "right" }}>
                      <Button
                        onClick={historyDialogOpenHandler}
                        color="primary"
                        autoFocus
                      >
                        {generalProperties.history}
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <Form
                  onSubmit={e => onEstimationSubmit(e)}
                  validate={(values, ss) => {
                    const errors = {};
                    if (estimationForm.contact_number.inputProps.value === "") {
                      // setEror("first_name", true)
                      errors.contactNumber = GeneralProperties.emptyField;
                    }
                    if (
                      estimationForm.productCategoryId.inputProps.value === ""
                    ) {
                      // setEror("first_name", true)
                      errors.productCategoryId = GeneralProperties.emptyField;
                    }
                    if (estimationForm.productBrandId.inputProps.value === "") {
                      // setEror("first_name", true)
                      errors.productBrandId = GeneralProperties.emptyField;
                    }
                    if (estimationForm.productModelId.inputProps.value === "") {
                      // setEror("first_name", true)
                      errors.productModelId = GeneralProperties.emptyField;
                    }
                    if (estimationForm.requested_by.inputProps.value === "") {
                      // setEror("first_name", true)
                      errors.requested_by = GeneralProperties.emptyField;
                    }
                    if (estimationForm.product_id.inputProps.value === "") {
                      // setEror("first_name", true)
                      errors.product_id = GeneralProperties.emptyField;
                    }
                    return errors;
                  }}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    valid
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <CardBody>
                        <GridContainer>
                          {formElementArray.map(element => {
                            if (!element.config.isHide) {
                              let mdVal = element.config.size;
                              return (
                                <GridItem
                                  key={element.id}
                                  xs={12}
                                  sm={12}
                                  md={mdVal}
                                >
                                  <Field name={element.id}>
                                    {({ input, meta, options, value }) => (
                                      <div style={{ position: "relative" }}>
                                        <CustomInput
                                          labelText={element.config.label}
                                          id={element.id}
                                          inputProps={{
                                            ...input,
                                            ...element.config.inputProps,
                                            readOnly:
                                              isView ||
                                                element.config.readOnly ||
                                                element.id !== "newComment"
                                          }}
                                          type={element.config.elType}
                                          formControlProps={
                                            element.config.formControlProps
                                          }
                                          adornmentText={
                                            element.config.adornmentText
                                          }
                                          adornmentPosition={
                                            element.config.adornmentPosition
                                          }
                                          changed={(event, value) => {
                                            input.onChange(event);
                                            onChangeHandeler(event, element.id);
                                          }}
                                          labelProps={{
                                            ...element.config.labelProps,
                                            error: meta.error && meta.touched
                                          }}
                                        />
                                        {meta.error && meta.touched && (
                                          <span className={styles.formError}>
                                            {meta.error}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </GridItem>
                              );
                            }
                          })}
                        </GridContainer>
                        <GridContainer>
                          <GridItem
                            style={{ marginTop: "25px" }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <MaterialTable
                              title={estimationProperties.parts}
                              tableRef={tableRef}
                              data={partDataRows}
                              columns={[
                                {
                                  title: "Part No",
                                  field: "part_number"
                                },
                                {
                                  title: "Description",
                                  field: "part_descrition"
                                },
                                {
                                  title: "Quantity",
                                  field: "quantity"
                                },
                                {
                                  title: "Taken From WareHouse",
                                  field: "taken_from_ware_house",
                                  render: rowData =>
                                    rowData.isTotal ? (
                                      ""
                                    ) : (
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        type="number"
                                        size="small"
                                        value={rowData.taken_from_ware_house}
                                        name="adjustment"
                                        onChange={e => {
                                          changePartValue(
                                            rowData.id,
                                            "taken_from_ware_house",
                                            e
                                          );
                                        }}
                                      />
                                    )
                                },
                                {
                                  title: "Return To WareHouse",
                                  field: "return_to_ware_house",
                                  render: rowData => {
                                    const isDepartmentUser =
                                      localStorage.getItem("userDepartment") ===
                                      "WAREHOUSE";
                                    return rowData.isTotal ? (
                                      ""
                                    ) : isDepartmentUser ? (
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        value={rowData.return_to_ware_house}
                                        name="adjustment"
                                        onChange={e => {
                                          changePartValue(
                                            rowData.id,
                                            "return_to_ware_house",
                                            e
                                          );
                                        }}
                                      />
                                    ) : (
                                        rowData.return_to_ware_house
                                    );
                                  }
                                },
                                {
                                  title: "Unit Price",
                                  field: "unit_price"
                                },
                                {
                                  title: "Total Price",
                                  field: "total_price"
                                },
                                {
                                  title: "Availability",
                                  field: "availability",
                                  render: rowData =>
                                    rowData.isTotal ? (
                                      ""
                                    ) : (
                                      <Checkbox
                                        checked={rowData.availability}
                                        name="checkedB"
                                        color="primary"
                                        readOnly
                                      />
                                    )
                                }
                              ]}
                              components={{
                                Toolbar: props => (
                                  <GridContainer>
                                    <GridItem xs={10} sm={10} md={10}>
                                      <MTableToolbar {...props} />
                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2}>
                                      <Button
                                        onClick={event => {
                                          setPartHeading("Add Part");
                                          let formData = { ...partForm };
                                          formData[
                                            "taken_from_ware_house"
                                          ].inputProps.disabled = true;
                                          formData[
                                            "return_to_ware_house"
                                          ].inputProps.disabled = true;
                                          setPartForm(formData);
                                          setPartButton(generalProperties.add);
                                          togglePartDialog(true);
                                        }}
                                        color="primary"
                                        variant="contained"
                                        disabled={isView}
                                        style={{
                                          textTransform: "none",
                                          marginTop: "17px",
                                          float: "right",
                                          marginRight: "20px"
                                        }}
                                      >
                                        Add Part
                                      </Button>
                                    </GridItem>
                                  </GridContainer>
                                )
                              }}
                              actions={[
                                rowData => ({
                                  icon: "edit",
                                  tooltip: "Edit part",
                                  onClick: (event, rowData) =>
                                    editPart(rowData, true),
                                  hidden:
                                    !permissions[
                                      PermissionProperties.editEstimation
                                    ] ||
                                    rowData.isTotal ||
                                    isView
                                }),

                                rowData => ({
                                  icon: "delete",
                                  tooltip: "Delete part",
                                  onClick: (event, rowData) =>
                                    deletePart(rowData),
                                  hidden:
                                    !permissions[
                                      PermissionProperties.editEstimation
                                    ] ||
                                    rowData.isTotal ||
                                    isView
                                })
                              ]}
                              options={{
                                actionsColumnIndex: -1,
                                sorting: false,
                                paging: false,
                                minBodyHeight: 200,
                                filtering: true,
                                search: false
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                      <CardFooter>
                        {!isView ? (
                          <Button
                            type="submit"
                            disabled={submitting || !valid}
                            color="primary"
                            autoFocus
                          >
                            {GeneralProperties.save}
                          </Button>
                        ) : (
                          ""
                        )}
                      </CardFooter>
                    </form>
                  )}
                />
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default WarehouseEstimation;
