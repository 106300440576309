import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import styles from './Kpi.module.css';
import Spinner from 'components/Spinner/Spinner.js';

export default function KpiTableBodycost(props) {
  const { useState } = React;
  const { existData, isFirstTime } = props;

  const [columns, setColumns] = useState([
    {
      title: 'Cost Type',
      field: 'cost_type',
      editable: 'never',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Period',
      field: 'period',
      editable: 'never',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Low ',
      field: 'low_value',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Mid ',
      field: 'mid_value',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'High ',
      field: 'high_value',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Critical ',
      field: 'critical_value',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
  ]);

  const [data, setData] = useState([]);

  const costTypesArray = [
    { id: '1', val: 'Travel' },
    { id: '2', val: 'BA/TA' },
    { id: '3', val: 'Meal' },
    { id: '4', val: 'Fixed' },
  ];

  useEffect(() => {
    // console.log("data.......",rows)
    props.RowData(data);
  });

  useEffect(() => {
    if (isFirstTime === false) {
      const FinalStages = [];
      if (existData.length !== 0) {
        for (var i = 0; i < existData.kpis.length; i++) {
          FinalStages.push({
            sequence: existData.kpis[i].sequence,
            cost_type: existData.kpis[i].cost_type,
            period: existData.kpis[i].period,
            low_value: existData.kpis[i].low_value,
            mid_value: existData.kpis[i].mid_value,
            high_value: existData.kpis[i].high_value,
            critical_value: existData.kpis[i].critical_value,
          });
        }
      }
      setData(FinalStages);
    }
  }, [existData]);

  useEffect(() => {
    if (isFirstTime === true) {
      const FinalStages = [];
      // for( var i=0; i<stagesArray.length-1;i++){
      //     stageRange.push({"id":i+1,"first":stagesArray[i].val,"second":stagesArray[i+1].val});
      // }

      costTypesArray.map((item) => {
        setData(
          FinalStages.push({
            sequence: item.id,
            cost_type: item.val,
            period: 'Day',
            low_value: 0,
            mid_value: 0,
            high_value: 0,
            critical_value: 0,
          })
        );
      });
      setData(FinalStages);
    }
  }, []);

  return (
    <div>
      <MaterialTable
        columns={columns}
        data={data}
        style={{ height: '100%' }}
        components={{
          OverlayLoading: () => (
            <div className={styles.loadingOverlay}>
              <Spinner />
            </div>
          ),
        }}
        options={{
          // actionsColumnIndex: -1,
          filtering: false,
          sorting: false,
          search: false,
          toolbar: false,
          paging: false,
          maxBodyHeight: '70vh',
          headerStyle: {
            fontSize: '0.7rem',
            textAlignL: 'center',
          },
          rowStyle: {
            fontSize: '0.6rem',
            fontWeight: 450,
            padding: '5rem',
          },
        }}
        editable={{
          // onRowAdd: newData =>
          // new Promise((resolve, reject) => {
          //     setTimeout(() => {
          //     setData([...data, newData]);

          //     resolve();
          //     }, 1000)
          // }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setData([...dataUpdate]);

                resolve();
              }, 1000);
            }),
          // onRowDelete: oldData =>
          // new Promise((resolve, reject) => {
          //     setTimeout(() => {
          //     const dataDelete = [...data];
          //     const index = oldData.tableData.id;
          //     dataDelete.splice(index, 1);
          //     setData([...dataDelete]);

          //     resolve()
          //     }, 1000)
          // }),
        }}
      />
    </div>
  );
}
