import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios/axios-default';
import {trainingProperties} from 'Util/Property/TraningProperties';


export async  function createNotification(method, url, data){
    try{
        return  await axios({
            method: method,
            url: url,
            data: data
        });
              // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    }catch (error ) {
        console.log(trainingProperties.messages.error.sent);
    }

}


export async  function viewNotification(id){
    try{
      
        return axios.get(urlProperties.training.viewNotification+"/"+id)
              // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    }catch (error ) {
        console.log(trainingProperties.messages.error.loadData);
    }

}


export async  function updateNotificationStatus(id){
    try{
      
        return axios.delete(urlProperties.training.updateNotificationStatus)
              // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    }catch (error ) {
        console.log(trainingProperties.messages.error.delete);
    }

}


export async  function getUserComment(id){
   
    return axios.get(urlProperties.training.getUserComment+"/"+id)
   
}

