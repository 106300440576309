import React, { useEffect, useState, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import axios from 'axios';
import UrlProperties from 'Util/Property/UrlProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import { userProperties, getUserForm } from 'Util/Property/UserProperties';
import LoadingOverlay from 'react-loading-overlay';
import Card from 'components/Card/Card';
import { Typography } from '@material-ui/core';
import HighlightsChartDetails from './HighlightsChartDetails';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Viewmore3 from './Viewmore3';
import HighlightsChartDetails2 from './HighlightsChartDetails2';

export default function Viewmore2(props) {
  const [images, setImages] = React.useState([]);
  const [imageApi, setImageApi] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openthirdPreview, setOpenthirdPreview] = useState(false);
  const {
    open,
    viewMoreClose,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    title,
    SubTitle,
    tileBody,
    categoryType,
    action,
    tab2Data,
  } = props;

  const handleClose = () => {
    viewMoreClose();
    setImageApi(false);
  };

  // const fileUploadClose = () => {
  //     setOpenthirdPreview(false);
  //  }

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '140px',
    width: '400px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#B22900',
    borderStyle: 'dashed',
    backgroundColor: '#ffcec7',
    color: '#B22900',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  useEffect(() => {
    // userData(srID);
  }, [open]);
  useEffect(() => {
    // userData(srID);
    tab2Data.forEach((obj) => {
      Object.entries(obj).forEach(([key, val]) => {
        if (key !== 'third_table') {
          obj[key] = val == null ? '-' : obj[key];
        }
      });
    });
    console.log(tab2Data);
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="lg"
        //width={1200}
        //maxHeight="lg"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogContent>
          <center>
            <b>{title}</b>
          </center>
          {/* <Typography variant="body1">{SubTitle}</Typography> */}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <LoadingOverlay active={isLoading} spinner text="Loading ...">
                <HighlightsChartDetails2
                  chartTitle={SubTitle}
                  // method={getMoreChartDetails}
                  tileStage={'2'}
                  chartType={tileBody}
                  categoryType={categoryType}
                  action={action}
                  tab2Data={tab2Data}
                />
              </LoadingOverlay>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button
            className={'generic-button-class div-button-wrapper'}
            onClick={handleClose}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
