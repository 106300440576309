const urlProperties = {
  '/users': ['PERM_USER'],
  '/customers': ['PERM_CUSTOMER'],
  '/products': ['PERM_PRODUCT'],
  '/settings': ['PERM_GLOBAL_SETTINGS'],
  '/customers/addCustomer': ['PERM_CUSTOMER_ADD'],
  '/settings/roleAndPermissions': ['PERM_ROLE_AND_PERMISSION'],
  '/settings/productAttributes': ['PERM_PRODUCT_ATTRIBUTE'],
  '/settings/productAttributes/productCategory': ['PERM_PRODUCT_ATTRIBUTE'],
  '/settings/kpi': ['PERM_KPI'],
  '/settings/leave': [
    'PERM_LEAVE_SETTINGS',
    'PERM_LEAVE_SETTINGS_ADD',
    'PERM_LEAVE_SETTINGS_DELETE',
  ],
  '/settings/reportsAndDashboards': ['PERM_REPORT_GENERATE'],
  '/settings/reportsAndDashboards/reportsAndDashboard': [
    'PERM_REPORT_GENERATE',
  ],
  '/users/user': ['PERM_USER_VIEW', 'PERM_USER_ADD', 'PERM_USER_EDIT'],
  '/settings/serviceAgreements': ['PERM_SERVICE_AGREEMENT'],
  '/settings/serviceAgreements/serviceAgreement': [
    'PERM_SERVICE_AGREEMENT_ADD',
    'PERM_SERVICE_AGREEMENT_VIEW',
    'PERM_SERVICE_AGREEMENT_EDIT',
  ],
  '/serviceRequests': ['PERM_SERVICE_REQUEST'],
  '/routePlanner': ['PERM_SERVICE_REQUEST'],
  '/serviceRequests/serviceRequest': [
    'PERM_SERVICE_REQUEST_VIEW',
    'PERM_SERVICE_REQUEST_ADD',
    'PERM_SERVICE_REQUEST_EDIT',
  ],
  '/settings/serviceRequestsSettings': ['PERM_SERVICE_REQUEST_SETTING'],
  '/settings/serviceRequestsSettings/checklist': [
    'PERM_CHECK_LIST_ADD',
    'PERM_CHECK_LIST_VIEW',
  ],
  '/settings/serviceRequestsSettings/priorityConfiguration': [
    'PERM_PRIORITY_CONFIGURATION_ADD',
    'PERM_PRIORITY_CONFIGURATION_VIEW',
    'PERM_PRIORITY_CONFIGURATION_EDIT',
  ],
  '/customers/customerDetails': ['PERM_CUSTOMER_VIEW'],
  '/customer-dashboard': ['PERM_CUSTOMER_VIEW'],
  '/customers/customerDetails/products': ['PERM_PRODUCT_&_SERVICE_VIEW'],
  '/customers/customerDetails/products/productDetails': [
    'PERM_PRODUCT_&_SERVICE_VIEW',
  ],
  '/customers/customerDetails/products/addProduct': [
    'PERM_PRODUCT_&_SERVICE_ADD',
  ],
  '/customers/customerDetails/estimations': ['PERM_ESTIMATION'],
  '/customers/customerDetails/estimations/estimation': [
    'PERM_ESTIMATION_ADD',
    'PERM_ESTIMATION_VIEW',
    'PERM_ESTIMATION_EDIT',
  ],
  '/estimations': ['PERM_ESTIMATION'],
  '/estimations/estimation': [
    'PERM_ESTIMATION_ADD',
    'PERM_ESTIMATION_VIEW',
    'PERM_ESTIMATION_EDIT',
  ],
  '/customers/customerDetails/serviceRequests/serviceRequest': [
    'PERM_SERVICE_REQUEST_VIEW',
    'PERM_SERVICE_REQUEST_ADD',
    'PERM_SERVICE_REQUEST_EDIT',
  ],
  '/customers/customerDetails/products/productDetails/serviceAgreement': [
    'PERM_PRODUCT_&_SERVICE_ADD',
    'PERM_PRODUCT_&_SERVICE_VIEW',
    'PERM_PRODUCT_&_SERVICE_EDIT',
  ],
  '/customers/customerDetails/products/productDetails/serviceRequests/serviceRequest': [
    'PERM_PRODUCT_&_SERVICE_ADD',
    'PERM_PRODUCT_&_SERVICE_VIEW',
    'PERM_PRODUCT_&_SERVICE_EDIT',
  ],
  '/customers/customerDetails/products/productDetails/estimations/estimation': [
    'PERM_PRODUCT_&_SERVICE_ADD',
    'PERM_PRODUCT_&_SERVICE_VIEW',
    'PERM_PRODUCT_&_SERVICE_EDIT',
  ],
  '/messages': ['PERM_TRAINING'],
  '/messages/training': [
    'PERM_TRAINING_ADD',
    'PERM_TRAINING_VIEW',
    'PERM_TRAINING_EDIT',
  ],
  '/messages/usercomment': [
    'PERM_TRAINING_ADD',
    'PERM_TRAINING_VIEW',
    'PERM_TRAINING_EDIT',
  ],
  '/reports/reports': ['PERM_REPORT_GENERATE'],
  '/reports/technicianroute': ['PERM_REPORT_GENERATE'],
  '/productServiceAgreements': [
    'PERM_PRODUCT_&_SERVICE_ADD',
    'PERM_PRODUCT_&_SERVICE_VIEW',
    'PERM_PRODUCT_&_SERVICE_EDIT',
  ],
  '/productServiceAgreements/serviceAgreement': [
    'PERM_PRODUCT_&_SERVICE_ADD',
    'PERM_PRODUCT_&_SERVICE_VIEW',
    'PERM_PRODUCT_&_SERVICE_EDIT',
  ],
  '/settings/workflows': ['PERM_WORKFLOW'],
  '/settings/workflows/workflow': [
    'PERM_WORKFLOW_ADD, PERM_WORKFLOW_VIEW, PERM_WORKFLOW_EDIT',
  ],
  '/settings/workflows/stage': [
    'PERM_WORKFLOW_ADD, PERM_WORKFLOW_VIEW, PERM_WORKFLOW_EDIT',
  ],

  '/leave': ['PERM_LEAVE'],
  '/reports': ['PERM_REPORT_GENERATE'],
  '/claims': ['PERM_CLAIMS'],
  //    "/claims": ["PERM_CLAIMS, PERM_CLAIMS_VIEW,PERM_CLAIMS_ADD,PERM_CLAIMS_EDIT,PERM_CLAIMS_DELETE"],
};
const PermissionProperties = {
  urlProperties: urlProperties,
  addCustomer: 'PERM_CUSTOMER_ADD',
  editCustomer: 'PERM_CUSTOMER_EDIT',
  deleteCustomer: 'PERM_CUSTOMER_DELETE',
  viewCustomer: 'PERM_CUSTOMER_VIEW',
  addUser: 'PERM_USER_ADD',
  editUser: 'PERM_USER_EDIT',
  deleteUser: 'PERM_USER_DELETE',
  viewUser: 'PERM_USER_VIEW',
  passwordChange: 'PERM_USER_PASSWORD_CHANGE',
  viewProductAttribute: 'PERM_PRODUCT_ATTRIBUTE_VIEW',
  addProductAttribute: 'PERM_PRODUCT_ATTRIBUTE_ADD',
  editProductAttribute: 'PERM_PRODUCT_ATTRIBUTE_EDIT',
  deleteProductAttribute: 'PERM_PRODUCT_ATTRIBUTE_DELETE',
  viewRoleAndPermissions: 'PERM_ROLE_AND_PERMISSION_VIEW',
  editRoleAndPermissions: 'PERM_ROLE_AND_PERMISSION_EDIT',
  addRoleAndPermissions: 'PERM_ROLE_AND_PERMISSION_ADD',
  deleteRoleAndPermissions: 'PERM_ROLE_AND_PERMISSION_DELETE',
  viewServiceAgreement: 'PERM_SERVICE_AGREEMENT_VIEW',
  addServiceAgreement: 'PERM_SERVICE_AGREEMENT_ADD',
  editServiceAgreement: 'PERM_SERVICE_AGREEMENT_EDIT',
  deleteServiceAgreement: 'PERM_SERVICE_AGREEMENT_DELETE',
  addServiceRequest: 'PERM_SERVICE_REQUEST_ADD',
  viewServiceRequest: 'PERM_SERVICE_REQUEST_VIEW',
  editServiceRequest: 'PERM_SERVICE_REQUEST_EDIT',
  deleteServiceRequest: 'PERM_SERVICE_REQUEST_DELETE',
  addCheckList: 'PERM_CHECK_LIST_ADD',
  viewCheckList: 'PERM_CHECK_LIST_VIEW',
  editCheckList: 'PERM_CHECK_LIST_EDIT',
  deleteCheckList: 'PERM_CHECK_LIST_DELETE',
  addPriorityConfiguration: 'PERM_PRIORITY_CONFIGURATION_ADD',
  viewPriorityConfiguration: 'PERM_PRIORITY_CONFIGURATION_VIEW',
  editPriorityConfiguration: 'PERM_PRIORITY_CONFIGURATION_EDIT',
  deletePriorityConfiguration: 'PERM_PRIORITY_CONFIGURATION_DELETE',
  addServiceRequestCategory: 'PERM_SERVICE_REQUEST_CATEGORY_ADD',
  viewServiceRequestCategory: 'PERM_SERVICE_REQUEST_CATEGORY_VIEW',
  editServiceRequestCategory: 'PERM_SERVICE_REQUEST_CATEGORY_EDIT',
  deleteServiceRequestCategory: 'PERM_SERVICE_REQUEST_CATEGORY_DELETE',
  viewProductAndServices: 'PERM_PRODUCT_&_SERVICE_VIEW',
  addProductAndServices: 'PERM_PRODUCT_&_SERVICE_ADD',
  editProductAndServices: 'PERM_PRODUCT_&_SERVICE_EDIT',
  deleteProductAndServices: 'PERM_PRODUCT_&_SERVICE_DELETE',
  viewEstimation: 'PERM_ESTIMATION_VIEW',
  addEstimation: 'PERM_ESTIMATION_ADD',
  editEstimation: 'PERM_ESTIMATION_EDIT',
  deleteEstimation: 'PERM_ESTIMATION_DELETE',
  viewExpenseCategories: 'PERM_EXPENSE_CATEGORY_VIEW',
  editExpenseCategories: 'PERM_EXPENSE_CATEGORY_EDIT',
  addExpenseCategories: 'PERM_EXPENSE_CATEGORY_ADD',
  deleteExpenseCategories: 'PERM_EXPENSE_CATEGORY_DELETE',
  viewExpenseRule: 'PERM_EXPENSE_RULE_VIEW',
  editExpenseRule: 'PERM_EXPENSE_RULE_ADD',
  addExpenseRule: 'PERM_EXPENSE_RULE_EDIT',
  deleteExpenseRule: 'PERM_EXPENSE_RULE_DELETE',
  viewClaims: 'PERM_CLAIMS_VIEW',
  addClaims: 'PERM_CLAIMS_ADD',
  editClaims: 'PERM_CLAIMS_EDIT',
  deleteClaims: 'PERM_CLAIMS_DELETE',
  viewTraining: 'PERM_TRAINING_VIEW',
  editTraining: 'PERM_TRAINING_EDIT',
  deleteTraining: 'PERM_TRAINING_DELETE',
  addTraining: 'PERM_TRAINING_ADD',
  productAttributeSetting: 'PERM_PRODUCT_ATTRIBUTE',
  serviceAgreementSetting: 'PERM_SERVICE_AGREEMENT',
  serviceRequestSetting: 'PERM_SERVICE_REQUEST_SETTING',
  claimSetting: 'PERM_CLAIM_SETTING',
  viewHistory: 'PERM_HISTORY_VIEW',
  viewWorkflow: 'PERM_WORKFLOW_VIEW',
  editWorkflow: 'PERM_WORKFLOW_EDIT',
  deleteWorkflow: 'PERM_WORKFLOW_DELETE',
  addWorkflow: 'PERM_WORKFLOW_ADD',
  workflows: 'PERM_WORKFLOW',
  addKpi: 'PERM_KPI_ADD',
  viewKpi: 'PERM_KPI_VIEW',
  deleteKpi: 'PERM_KPI_DELETE',
  editKpi: 'PERM_KPI_EDIT',
  kpi: 'PERM_KPI',
  leaveSetting: 'PERM_LEAVE_SETTINGS',
  addleaveSetting: 'PERM_LEAVE_SETTINGS_ADD',
  deleteleaveSetting: 'PERM_LEAVE_SETTINGS_DELETE',
};
export default PermissionProperties;
