
export const GET_CLAIMS_EXPENSES_LIST_START ="GET_CLAIMS_EXPENSES_LIST_START";
export const GET_CLAIMS_EXPENSES_LIST_SUCCESS ="GET_CLAIMS_EXPENSES_LIST_SUCCESS";
export const GET_CLAIMS_EXPENSES_LIST_FAILED ="GET_CLAIMS_EXPENSES_LIST_FAILED";

export const GET_CLAIMS_EXPENSE_BY_ID_START ="GET_CLAIMS_EXPENSE_BY_ID_START";
export const GET_CLAIMS_EXPENSES_BY_ID_SUCCESS ="GET_CLAIMS_EXPENSES_BY_ID_SUCCESS";
export const GET_CLAIMS_EXPENSES_BY_ID_FAILED ="GET_CLAIMS_EXPENSES_BY_ID_FAILED";

export const CREATE_CLAIMS_EXPENSE_START ="CREATE_CLAIMS_EXPENSE_START";
export const CREATE_CLAIMS_EXPENSE_SUCCESS ="CREATE_CLAIMS_EXPENSE_SUCCESS";
export const CREATE_CLAIMS_EXPENSE_FAILED ="CREATE_CLAIMS_EXPENSE_FAILED";

export const DELETE_CLAIMS_EXPENSE_START ="DELETE_CLAIMS_EXPENSE_START";
export const DELETE_CLAIMS_EXPENSE_SUCCESS ="DELETE_CLAIMS_EXPENSE_SUCCESS";
export const DELETE_CLAIMS_EXPENSE_FAILED ="DELETE_CLAIMS_EXPENSE_FAILED";

export const UPDATE_CLAIMS_EXPENSE_START ="UPDATE_CLAIMS_EXPENSE_START";
export const UPDATE_CLAIMS_EXPENSE_SUCCESS ="UPDATE_CLAIMS_EXPENSE_SUCCESS";
export const UPDATE_CLAIMS_EXPENSE_FAILED ="UPDATE_CLAIMS_EXPENSE_FAILED";