import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import LoadingOverlay from 'react-loading-overlay';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import reprotservice from 'services/Reports/ReportsService';
import { useParams } from 'react-router-dom';
import Map from 'views/Maps/Map';
import { location } from 'views/Maps/MarkerIcons';
import noMapData from 'assets/img/no map data.png';
import { handleErrorMessage } from 'services/CommonService';

const TechnicainRouteReport = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isJourneyExists, setIsJourneyExists] = useState(true);
  const { technicianId, date } = useParams();
  const [journeyData, setJourneyData] = useState(null);
  const [centerLocation, setCenterLocation] = useState({});

  useEffect(() => {
    if (technicianId !== null && technicianId > 0 && date !== null) {
      getTechnicianRouteInfoForMap(date, technicianId);
    }
  }, []);

  const getTechnicianRouteInfoForMap = (date, techId) => {
    setIsLoading(true);
    reprotservice
      .getTechnicianJourneyRoutes(date, techId)
      .then((result) => {
        if (result.status === 200) {
          // console.log("Data-",result.data)
          let jnData = {};
          let locations = [];
          //set on the way locations
          let customerList = result.data.customerList;
          if (customerList !== null) {
            for (let i in customerList) {
              let home = customerList[i];

              let locationObj = {
                icon: location,
                markerPosition: {
                  lat: home.customerLatitude,
                  lng: home.customerLongitude,
                },
                fillColor: '#2314cd',
                label: home.customerName,
                id: home.id,
                scale: 1.5,
              };
              locations.push(locationObj);
            }
          }
          if (result.data.homeLatitude !== null) {
            // let locationObj = {
            //     icon: location,
            //     markerPosition: { lat: result.data.homeLatitude, lng: result.data.homeLongitude },
            //     fillColor: "#2314cd",
            //     label: result.data.userName+" home",
            //     id: result.data.userId,
            //     scale: 1.5
            // };
            // locations.push(locationObj);
          }
          if (locations.length > 0) {
            jnData['locations'] = locations;
          }
          let journeyList = result.data.journeyGroupBySrList;
          // if(journeyList.length > 0){
          //     let firstJourny = journeyList[0].journeyDtoList;
          //     let centerLoc = {
          //         lat:firstJourny[0].startLatitude,
          //         lng:firstJourny[0].startLongitude
          //     }
          //     setCenterLocation(centerLoc);
          //     jnData["directions"] = journeyList;
          // }
          if (journeyList.length > 0) {
            for (let j in journeyList) {
              if (journeyList[j].journeyDtoList != null) {
                let firstJourny = journeyList[j].journeyDtoList;
                for (let k in firstJourny) {
                  if (
                    firstJourny[k].startLatitude != null &&
                    firstJourny[k].startLongitude != null
                  ) {
                    let centerLoc = {
                      lat: firstJourny[0].startLatitude,
                      lng: firstJourny[0].startLongitude,
                    };
                    setCenterLocation(centerLoc);
                    jnData['directions'] = journeyList;
                  }
                }
              }
            }
          }
          setJourneyData(jnData);
        } else {
          //   handleErrorMessage(
          //     result.data,
          //     'Could not load Techinician Journey details'
          //   );
          setIsJourneyExists(false);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response.status === 404) {
          setIsJourneyExists(false);
        } else {
          handleErrorMessage(
            error,
            'Could not load Techinician Journey details'
          );
        }
        // setIsJourneyExists(false);
      });
  };

  const placeSelectHandler = () => {};

  return (
    <div style={{}}>
      {isJourneyExists ? (
        <LoadingOverlay active={isLoading} spinner text="Loading ...">
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
              Technician Travel Routes Report
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              {/* {console.log("JOURY DATA__________",journeyData)} */}
              {journeyData !== null ? (
                <Map
                  center={centerLocation}
                  height="600px"
                  zoom={15}
                  placeHandler={placeSelectHandler}
                  markers={journeyData.locations}
                  disableInfo={true}
                  directions={journeyData.directions}
                />
              ) : (
                ''
              )}
            </GridItem>
          </GridContainer>
        </LoadingOverlay>
      ) : (
        <div
          style={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={noMapData} width="150" />
          <div
            style={{
              display: 'flex',
            }}
          >
            <h4>Technician with &nbsp;</h4>
            <span>
              <h4
                style={{
                  color: 'red',
                }}
              >
                {`User-ID - ${technicianId}`}
              </h4>
            </span>
            <h4> &nbsp; had not started a journey on &nbsp;</h4>
            <span>
              <h4
                style={{
                  color: 'red',
                }}
              >
                {date}
              </h4>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TechnicainRouteReport;
