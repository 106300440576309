import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styles from "../Style/Style"
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import BuildIcon from '@material-ui/icons/Build';
const useStyles = makeStyles(styles);
export default function SkeletonTypeFIve(props) {

    const { colorCode, iconName, title, subTitle, hasSub } = props;
    const classes = useStyles();
    return (
        <div>
            <Paper className={classes.paper}>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <div style={{ backgroundColor: '#fff', width: "3rem", height: "3rem" }}>
                            <Skeleton variant="rectangular" style={{ width: "3rem", height: "3rem" }} />
                        </div>
                    </Grid>
                    <Grid item xs={6} className={classes.topRight}>
                        {/* <Typography noWrap className={classes.title} >{title}</Typography> */}
                        <Skeleton variant="text" />
                        <Grid container spacing={2} style={{ marginTop: "1rem" }}>

                            <Grid item xs={2}  >
                                <Grid container spacing={1}  >
                                    <Grid item xs={12}  >
                                        <Skeleton variant="rectangular" style={{ width: '1.2rem', height: '1.7rem', marginTop: '1.5rem' }} />

                                    </Grid>
                                    <Grid item xs={12}   >

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={7} >
                                <Grid container spacing={1}  >
                                    <Grid item xs={12}  >
                                        <Skeleton variant="rectangular" style={{ width: '5rem', height: '5rem', marginBottom: '2rem' }} />

                                    </Grid>
                                    <Grid item xs={12}   >

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={2}  >
                                <Grid container spacing={1}  >
                                    <Grid item xs={12}   >
                                        <Skeleton variant="rectangular" style={{ width: '1.2rem', height: '1.7rem', marginTop: '1.5rem' }} />
                                    </Grid>
                                    <Grid item xs={12}  >
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>



            </Paper>
        </div>
    );
}
