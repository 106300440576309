/* eslint-disable react/display-name */
import React, { useEffect, useState, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import { Typography } from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader';
import MaterialTable, { OverlayLoading , MTableAction  } from 'material-table';
import styles from './RoutePlanner.module.css';
import Button from 'components/CustomButtons/Button.js';
import urlProperties from 'Util/Property/UrlProperties';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import LoadingOverlay from 'react-loading-overlay';
import {
  getEstimatedTimeForPlannedDate,
} from 'services/ServiceRequest/ServiceRequestService';
import * as moment from 'moment';
import { buildFilterObject } from '../../../Util/Util';
import UrlProperties from 'Util/Property/UrlProperties';
import RouterProps from '../../../Util/Property/RouterProperties';
import Spinner from 'components/Spinner/Spinner';
import OverflowTooltip from 'components/OverflowTooltip/OverflowTooltip';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton , TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteOutline } from '@material-ui/icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DoneIcon from '@material-ui/icons/Done';
import BulkSR from 'components/BulkSR/BulkSR';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '95%',
    marginTop: '5.3%',
    marginLeft: '1.5%',
  },
  container: {
    height: 500, 
    overflow: 'auto', // Enable overflow scrolling
  },
  dataRow: {
    height: 40, 
  },
  tableCell: {
    fontSize: '12px', 
    overflow: 'hidden', // Ensure overflow is hidden
    textOverflow: 'ellipsis', // Add ellipsis for overflowed text
    whiteSpace: 'nowrap', // Prevent text from wrapping
    maxWidth: 150, 
  },
  cellContent: {
    overflow: 'hidden', // Hide any overflow content
    textOverflow: 'ellipsis', // Add ellipsis for overflowed text
    whiteSpace: 'nowrap', // Prevent text from wrapping
  },
}));
  
  const RoutePlanner = (props) => {
  const classes = useStyles();
  const columns = [
    { field: 'name', title: 'Name', minWidth: 100 },
    { field: 'district', title: 'District', minWidth: 100 },
    { field: 'address', title: 'Address', minWidth: 100 },
  ];
  
  const baseUrl = UrlProperties.baseUrl.split(':8089')[0];
  const [permissions, setPermissions] = useState({});
  const [selectedOutlets, setSelectedOutlets] = useState([]);
  const [assignValues, setAssignValues] = useState({
    estimatedTimeHours: 0,
    estimatedTimeMinutes: 0,
    offsetTimeHours: 0,
    offsetTimeMinutes: 0,
    totalEstimatedTimeHours: 0,
    totalEstimatedTimeMinutes: 0,
    totalTimeInMinutes: 0,
    assignToOptions: [],
    assignTo: '',
    scheduledDate: null,
    assignUserDetails: {
      jobCount: null,
      totalHours: null,
      messageAlert: '',
    },
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState({
    page: 1,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState(false);
  const srTableRef = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openSRBulkUpload, setOpenSRBulkUpload] = useState(false);
  const [vehicleId, setVehicleId] = useState(null);

  const setLoadingState = (state) => {
    setIsLoading(state);
  };

  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addServiceRequest,
      PermissionProperties.viewServiceRequest,
      PermissionProperties.editServiceRequest,
      PermissionProperties.deleteServiceRequest,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  useEffect(() => {
    getAssignUserDetails(assignValues);
  }, [assignValues.assignTo, assignValues.scheduledDate]);

  const rowSelectionChangeHandler = (rows) => {
    setSelectedOutlets(rows);
    setSelectedRows(rows);
  };

  const getAssignUserDetails = (assignValueData) => {
    const { assignTo, scheduledDate } = assignValueData;
    if (assignTo && scheduledDate) {
      const scheduledDateClone = { ...scheduledDate };
      const formattedScheduledDate = moment(scheduledDateClone).format(
        'YYYY-MM-DD'
      );
      getEstimatedTimeForPlannedDate(assignTo, formattedScheduledDate)
        .then((result) => {
          if (result.status === 200) {
            let existingAssignValues = { ...assignValues };
            existingAssignValues.assignUserDetails = {
              jobCount: result.data.totalJobCount,
              totalHours: result.data.totalEstimatedTime,
              messageAlert: result.data.messageAlert || '',
            };
            setAssignValues(existingAssignValues);
          } else {
            console.log(result);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onPaginationProperyChange = (val, key) => {
    let tData = { ...tableData };
    tData[key] = val;
    setTableData(tData);
  };

  const test = (rowData) => {
    console.log('TEST')
    console.log('selectedRows',rowData)
  }

  const addSelection = (rowData) => {   
    const exists = selectedOutlets.some(outlet => outlet.id === rowData.id);
    if (!exists) {
      setSelectedOutlets([...selectedOutlets, rowData]);
    }
  };

  const handleRemove = (rowToRemove) => {
    setSelectedOutlets((prevSelectedOutlets) => 
      prevSelectedOutlets.filter((row) => row !== rowToRemove)
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const getIcon = (rowData) => {
    const isSelected = selectedOutlets.find(outlet => outlet.id === rowData.id);
    return isSelected ? <DoneIcon style={{ color: 'green' }} /> : <AddCircleOutlineIcon style={{ color: 'blue' }} />;
  };

  const getTooltipMessage = (rowData) => {
    const isSelected = selectedOutlets.find(outlet => outlet.id === rowData.id);
    return isSelected ? 'Outlet added !' : 'Add Outlet';
 };

 const generateRandomId = () => {
    return Math.floor(Math.random()*10000-0+1)+0;
  }

  const customerTable = ()=>{
    return(
      <MaterialTable
      components={{
        OverlayLoading: () => (
          <div className={styles.loadingOverlay}>
            <Spinner />
          </div>
        ),
      }}
      title={
        <div>
          <span className={'grid-title-icon'}>
            <AssignmentTurnedInIcon
              style={{ fontSize: '1.2rem' }}
            />
          </span>
          <Typography
            variant="h6"
            style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
          >
            Outlets
          </Typography>
        </div>
      }
      tableRef={srTableRef}
      columns={[
        { title: 'Customer Name', field: 'name' },
        { title: 'Province', field: 'province' },
        { title: 'District', field: 'district' },
        {
          title: 'Address',
          field: 'address',
          cellStyle: {
            width: 200,
            minWidth: 200,
          },
        },
      ]}
      data={(query) =>
        new Promise((resolve, reject) => {
          const filterDto = buildFilterObject(query);
          filterDto["vehicle_user_id"] = vehicleId;
          // filterDto["customer_type"] = "CHILD";
          let url =urlProperties.baseUrl + urlProperties.filterAllCustomers + '?';
          url += 'perPage=' + query.pageSize;
          url += '&page=' + query.page;
          url += '&sortColumn=' +
            (typeof query.orderBy === 'undefined'
              ? 'id'
              : query.orderBy.field);
          url += '&sortOrder=' + query.orderDirection;
          url += '&search=' + query.search;
          fetch(url, {
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(filterDto),
          })
            .then((response) => response.json())
            .then((result) => {
              resolve({
                data:vehicleId ? result.data : [],
                page:vehicleId ? result.page : 0,
                totalCount:vehicleId ? result.total : 0,
              });
            });
        })
      }
      actions={[
        rowData => ({
            icon: ()=>getIcon(rowData),
            tooltip: getTooltipMessage(rowData),
            onClick: (event, rowData) => addSelection(rowData),
            position: 'row'
          })
      ]}
      options={{
        columnsButton: true,
        actionsColumnIndex: -1,
        selection: false,
        pageSize: tableData.pageSize,
        filtering: true,
        sorting: false,
        search: false,
        pageSizeOptions: [5, 10, 20, 50],
        maxBodyHeight: '70vh',
        headerStyle: {
          backgroundColor: '#e6e4df',
          fontSize: '0.7rem',
          textAlignL: 'center',
        },
        rowStyle: {
          fontSize: '0.6rem',
          fontWeight: 450,
          padding: '5rem',
        },
      }}
      onSelectionChange={(rows) =>
        rowSelectionChangeHandler(rows)
      }
      onChangeRowsPerPage={(pageSize) =>
        onPaginationProperyChange(pageSize, 'pageSize')
      }
      onChangePage={(page, pageSize) =>
        onPaginationProperyChange(page, 'page')
      }
    />
    )
  }

  const closeBulkUploadModal = () => {
    setOpenSRBulkUpload(false);
  };

  const bulkUploadSrModel = () => {
    return (
        <BulkSR
        open={true}
        SRBulkUploadClose={closeBulkUploadModal}
        selectedRowsofCustomers={selectedOutlets}
        setVehicle={setVehicleId}
        routePlannerTableRef={srTableRef}
        setSelectedOutlets={setSelectedOutlets}
        selectedOutlets={selectedOutlets}
        setIsLoading={setLoadingState}
      />
    )
  }

  const selectedCustomerTable = ()=>{
    return (
        <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead style={{ height: 80 }}>
              <TableRow>
                {columns.map((column) => (
                  !column.hidden && (
                    <TableCell
                      key={column.field}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.title}
                    </TableCell>
                  )
                ))}
                <TableCell key={generateRandomId()} align="center">Actions</TableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedOutlets && selectedOutlets.length > 0 && selectedOutlets
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code} className={classes.dataRow}>
                      {columns.map((column) => {
                        if (column.hidden) return null; // Skip rendering if column is hidden
                        const value = row[column.field];
                        return (
                          <TableCell key={column.field} align={column.align} className={classes.tableCell}>
                            <Tooltip title={value}>
                              <div className={classes.cellContent}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </div>
                            </Tooltip>
                          </TableCell>
                        );
                      })}
                      <TableCell align="center" key={row.id}>
                        <IconButton onClick={() => handleRemove(row)}>
                          <DeleteOutline style={{ color: 'red' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={selectedOutlets.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    )
  }

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
                {bulkUploadSrModel()}
              <CardBody>
                <div className={'material-grid-wrapper'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {customerTable()}
                        {selectedCustomerTable()}
                    </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default RoutePlanner;
