import React, { Component } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import { getVehicleTypes } from '../../../../../store/actions/index';
import LoadingOverlay from 'react-loading-overlay';
import {
  notyDefault,
  notyTypes,
} from '../../../../../components/Noty/NotyCustom';
import generalProperties from 'Util/Property/GeneralProperties';
import fuelRateProperties from 'Util/Property/FuelRateProperty';
import Grid from '@material-ui/core/Grid';
import { PermContactCalendarRounded } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import PermissionProperties from '../../../../../Util/Property/PermissionProperties';
import { checkPermissoinListAvailable } from '../../../../../Util/Permission/CheckPermission';
import Spinner from 'components/Spinner/Spinner.js';

class FuelRateForm extends Component {
  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  state = {
    fuelRateForm: { ...fuelRateProperties.FuelRatesForm },
    id: null,
    formIsValid: false,
    isLoading: true,
    permissions: {},
  };

  checkValidity(value, rules) {
    let returnObj = {
      isValid: true,
      errormessage: '',
    };
    if (typeof rules == 'undefined') {
      return returnObj;
    }

    if (rules.required) {
      if (!value) {
        returnObj.isValid = false;
        returnObj.errormessage = generalProperties.emptyField;
      }
    }
    return returnObj;
  }

  onChangeHandler = (event, elId) => {
    let formData = { ...this.state.fuelRateForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    if (elId !== 'is_active') {
      let validityObj = this.checkValidity(
        event.target.value,
        elementData.validations
      );
      formData[elId].inputProps.value = event.target.value;
      elementData.valid = validityObj.isValid;
      if (typeof elementData.labelProps !== 'undefined') {
        elementData.labelProps.error = !elementData.valid;
        elementData.labelProps.errormessage = validityObj.errormessage;
        elementData.touched = true;
      }
    } else {
      formData[elId].inputProps.value = !formData[elId].inputProps.value;
    }
    let isFormValid = true;
    for (let key in this.state.fuelRateForm) {
      isFormValid = this.state.fuelRateForm[key].valid && isFormValid;
    }
    this.setState({
      fuelRateForm: formData,
      formIsValid: isFormValid,
    });
  };

  saveDetails = () => {
    const { fuelRateForm, id } = this.state;
    const { createFuelRate, updateFuelRate } = this.props;
    const data = {
      name: fuelRateForm['name'].inputProps.value,
      is_active: fuelRateForm['is_active'].inputProps.value,
      ownershipType: fuelRateForm['ownershipType'].inputProps.value,
      vehicleType: {
        id: fuelRateForm['vehicleType'].inputProps.value,
      },
      rate: fuelRateForm['rate'].inputProps.value,
    };
    if (id) {
      updateFuelRate(data, id);
    } else {
      createFuelRate(data);
    }
  };

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { id, type } = params;
    this.props.getVehicleTypes();
    if (id) {
      this.props.getFuelRateById(id);
    } else {
      const { fuelRateForm } = this.state;
      this.setState({
        fuelRateForm: fuelRateForm,
        id: null,
      });
    }
    if (type === fuelRateProperties.actionType.edit) {
      this.setState({
        formIsValid: true,
      });
    }
    let permissionArray = [];

    this.setState({
      permissions: checkPermissoinListAvailable(permissionArray),
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      claimExpenseCategoryObject,
      createInProgress,
      history,
      createFailed,
      activeVehicleTypesLoading,
      activeVehicleTypesError,
      activeVehicleTypes,
    } = this.props;
    if (
      prevProps.claimExpenseCategoryObject !== claimExpenseCategoryObject &&
      claimExpenseCategoryObject !== {}
    ) {
      const { fuelRateForm } = this.state;
      fuelRateForm['name'].inputProps.value =
        claimExpenseCategoryObject.name || '';
      fuelRateForm['is_active'].inputProps.value =
        claimExpenseCategoryObject.is_active;
      fuelRateForm['vehicleType'].inputProps.value =
        claimExpenseCategoryObject.vehicleType.id;
      fuelRateForm['ownershipType'].inputProps.value =
        claimExpenseCategoryObject.ownershipType;
      fuelRateForm['rate'].inputProps.value = claimExpenseCategoryObject.rate;
      this.setState({
        fuelRateForm: fuelRateForm,
        id: claimExpenseCategoryObject.id,
      });
    }
    if (!createInProgress && prevProps.createInProgress !== createInProgress) {
      if (createFailed) {
        notyDefault({
          type: notyTypes.ERROR,
          text: fuelRateProperties.messages.error.saveData,
        });
      } else {
        notyDefault({
          type: notyTypes.SUCCESS,
          text: fuelRateProperties.messages.success.saveData,
        });
      }
      history.push('/trackable/settings/claimsSettings');
    }
    if (
      activeVehicleTypesLoading !== prevProps.activeVehicleTypesLoading &&
      !activeVehicleTypesLoading
    ) {
      if (activeVehicleTypesError) {
        notyDefault({
          type: notyTypes.ERROR,
          text: 'Error fetching vehicle type data',
        });
      } else {
        let VehicleTypeOptions = [];
        activeVehicleTypes.map((vehicleType) =>
          VehicleTypeOptions.push({
            value: vehicleType.id,
            displayValue: vehicleType.name,
          })
        );
        const { fuelRateForm } = this.state;
        fuelRateForm['vehicleType'].inputProps.options = VehicleTypeOptions;
        this.setState({
          fuelRateForm: fuelRateForm,
        });
      }
    }
  }

  render() {
    const { match } = this.props;
    const { params } = match;
    const { type = '', id } = params || {};
    let titleText = fuelRateProperties.actionText.add;
    if (type) {
      titleText =
        type === fuelRateProperties.actionType.view
          ? fuelRateProperties.actionText.view
          : fuelRateProperties.actionText.edit;
    }
    let formElementArray = [];
    for (let key in this.state.fuelRateForm) {
      formElementArray.push({
        id: key,
        config: this.state.fuelRateForm[key],
      });
    }
    let submitButton = (
      <Button
        disabled={!this.state.formIsValid}
        onClick={this.saveDetails}
        type="submit"
        color="primary"
      >
        Save
      </Button>
    );
    //TODO permisions to handle later
    // if (type === fuelRateProperties.actionType.view) {
    //   submitButton = null;
    // }
    // if (
    //   id &&
    //   !this.state.permissions[PermissionProperties.editExpenseCategories]
    // ) {
    //   submitButton = null;
    // }
    // if (
    //   !id &&
    //   !this.state.permissions[PermissionProperties.addExpenseCategories]
    // ) {
    //   submitButton = null;
    // }

    return (
      <div>
        <LoadingOverlay active={false} spinner={<Spinner />} text="Loading ...">
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
              <Card>
                <div className="generic-form-container">
                  <CardBody>
                    <div className={`material-grid-wrapper`}>
                      <GridContainer>
                        <Grid spacing={3} md={6}>
                          <span className={'grid-title-icon'}>
                            <PermContactCalendarRounded />
                          </span>
                          <Typography
                            variant="h6"
                            style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                          >
                            Fuel Rates Management
                          </Typography>
                          <Typography variant="body1">
                            {/* {"Please add new fuel rate."} */}
                          </Typography>
                        </Grid>
                      </GridContainer>
                    </div>
                    <GridContainer>
                      {formElementArray.map((element) => {
                        if (!element.config.isHide) {
                          let mdVal = 12;
                          if (!element.config.isFullWidth) {
                            mdVal = 6;
                          }
                          return (
                            <GridItem
                              key={element.id}
                              xs={12}
                              sm={12}
                              md={mdVal}
                            >
                              <CustomInput
                                labelText={element.config.label}
                                id={element.id}
                                formControlProps={
                                  element.config.formControlProps
                                }
                                inputProps={{
                                  ...element.config.inputProps,
                                  readOnly:
                                    type === fuelRateProperties.actionType.view,
                                }}
                                type={element.config.elType}
                                value={element.config.value}
                                changed={(event) =>
                                  this.onChangeHandler(event, element.id)
                                }
                                error={
                                  !element.config.valid &&
                                  element.config.touched
                                }
                                labelProps={element.config.labelProps}
                                gridStyle={element.gridStyle}
                              />
                            </GridItem>
                          );
                        }
                      })}
                    </GridContainer>
                  </CardBody>
                  <CardFooter>{submitButton}</CardFooter>
                </div>
              </Card>
            </GridItem>
          </GridContainer>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { FuelRatesReducer } = state;
  const {
    fuelRateObject,
    createFuelRate,
    activeVehicleTypes,
  } = FuelRatesReducer;
  return {
    claimExpenseCategoryObject: fuelRateObject.data,
    claimExpenseCategoryObjectLoading: fuelRateObject.isFetching,
    createInProgress: createFuelRate.inProgress,
    createFailed: createFuelRate.error,
    activeVehicleTypes: activeVehicleTypes.data,
    activeVehicleTypesLoading: activeVehicleTypes.inProgress,
    activeVehicleTypesError: activeVehicleTypes.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFuelRateById: (id) => dispatch(actions.getFuelRateById(id)),
    createFuelRate: (data) => dispatch(actions.createFuelRate(data)),
    updateFuelRate: (data, id) => dispatch(actions.updateFuelRate(data, id)),
    getVehicleTypes: () => dispatch(getVehicleTypes()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FuelRateForm);
