import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../Style/Style';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import HourglassEmptyIcon from '../../../assets/img/highlights/HourglassEmptyIcon.png';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import CommuteIcon from '@material-ui/icons/Commute';
const useStyles = makeStyles(styles);
export default function HighlightsTypeOne(props) {
  const { colorCode, iconName, title, subTitle, hasSub } = props;
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <div
            style={{ backgroundColor: '#fff', width: '3rem', height: '3rem' }}
          >
            <Skeleton
              variant="rectangular"
              style={{ width: '3rem', height: '3rem' }}
            />
          </div>
        </Grid>
        <Grid item xs={6} className={classes.topRight}>
          {/* <Typography noWrap className={classes.title} >{title}</Typography> */}
          <Skeleton variant="text" className={classes.title1} />
          <Skeleton variant="text" className={classes.title1} />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.secondRow}>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}>
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.titleBox}>
              <Skeleton variant="text" className={classes.title2} />
            </Grid>
            <Grid item xs={6} className={classes.titleBox}>
              <Skeleton variant="text" className={classes.title2} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Skeleton variant="text" className={classes.title2} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.titleBox}>
              <Skeleton variant="text" className={classes.title2} />
            </Grid>
            <Grid item xs={6} className={classes.titleBox}>
              <Skeleton variant="text" className={classes.title2} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Skeleton variant="text" className={classes.title2} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3} style={{ marginBottom: '2rem' }}>
          <Grid container spacing={1}>
            <Grid item xs={6} className={classes.titleBox}>
              <Skeleton variant="text" className={classes.title2} />
            </Grid>
            <Grid item xs={6} className={classes.titleBox}>
              <Skeleton variant="text" className={classes.title2} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Skeleton variant="text" className={classes.title2} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
