import React, { useEffect, useState, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import Button from 'components/CustomButtons/Button.js';
import urlProperties from '../../Util/Property/UrlProperties';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import generalProperties from '../../Util/Property/GeneralProperties';
import { userProperties } from 'Util/Property/UserProperties';
import axios from '../../axios/axios-default';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import { buildFilterObject } from '../../Util/Util';
//import ListAltOutlinedIcon from '@material-ui/icons/FormatListBulletedSharp';
import ListAltOutlinedIcon from '@material-ui/icons/PeopleAlt';
import { Typography } from '@material-ui/core';
import Spinner from 'components/Spinner/Spinner';
import styles from './Users.module.css';

const Users = (props) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState({});
  const [permissions, setPermissions] = useState({});

  const tableRef = useRef(null);
  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addUser,
      PermissionProperties.viewUser,
      PermissionProperties.deleteUser,
      PermissionProperties.editUser,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  const onUserEditHandler = (rowData, isEdit) => {
    props.history.push({
      pathname: props.match.url + '/user',
      selectedUser: rowData,
      search:
        '?id=' + rowData.id + '&isEdit=' + isEdit + '&isCustomerUser=false',
    });
  };

  const deleteUserDialogCloseHandler = () => {
    setDeleteDialogOpen(false);
  };

  const deleteUserDialogOpenHandler = () => {
    setDeleteDialogOpen(true);
  };

  const deleteConfirmHandler = () => {
    let data = { ...deleteRowData };
    data.status = 'TERMINATED';
    axios
      .put('updateUser/' + data.id, data)
      .then((result) => {
        if (result.status == '200') {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: userProperties.messages.success.deleteUser,
          });
          tableRef.current.onQueryChange();
        } else {
          // could not delete succesfully
          notyDefault({ type: notyTypes.ERROR, text: result.data });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: userProperties.messages.error.deleteUser,
        });
      });

    deleteUserDialogCloseHandler();
  };

  const terminateConfirmHandler = () => {
    let data = { ...deleteRowData };
    console.log('--------------> data', data);
    axios
      .patch('terminateUser/' + data.id)
      .then((result) => {
        if (result.status == '200') {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: userProperties.messages.success.deleteUser,
          });
          tableRef.current.onQueryChange();
        } else {
          // could not delete succesfully
          notyDefault({ type: notyTypes.ERROR, text: result.data });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: userProperties.messages.error.deleteUser,
        });
      });

    deleteUserDialogCloseHandler();
  };

  const deleteUserHandler = (rowData) => {
    setDeleteRowData(rowData);
    deleteUserDialogOpenHandler();
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {/* {
                        permissions[PermissionProperties.addUser] ?
                            <div className={styles.AddUserDiv}>
                                <Link to={props.match.url + "/user"}>
                                    <Button className={styles.AddUserButton} variant="contained" color="primary">{userProperties.addNewUser}</Button>
                                </Link>
                            </div> : ""
                    } */}
            <CardBody>
              {/* customer delete confirm dialogBox */}

              <Dialog
                open={isDeleteDialogOpen}
                onClose={deleteUserDialogCloseHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {userProperties.messages.normal.deleteCustomer}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    size="sm"
                    onClick={deleteUserDialogCloseHandler}
                    color="primary"
                  >
                    {generalProperties.cancel}
                  </Button>
                  <Button
                    size="sm"
                    onClick={terminateConfirmHandler}
                    color="primary"
                    autoFocus
                  >
                    {generalProperties.confirm}
                  </Button>
                </DialogActions>
              </Dialog>

              {/* customer table content */}
              <div className={'material-grid-wrapper'}>
                <MaterialTable
                  title={
                    <div>
                      <span className={'grid-title-icon'}>
                        <ListAltOutlinedIcon />
                      </span>
                      <Typography
                        variant="h6"
                        style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                      >
                        {userProperties.users}
                      </Typography>
                      {/* <Typography variant="body1">Manage Service Agreements for products</Typography> */}
                    </div>
                  }
                  tableRef={tableRef}
                  components={{
                    OverlayLoading: () => (
                      <div className={styles.loadingOverlay}>
                        <Spinner />
                      </div>
                    ),
                  }}
                  columns={[
                    { title: 'First Name', field: 'first_name' },
                    { title: 'Last Name', field: 'last_name' },
                    { title: 'Username', field: 'user_name' },
                    {
                      title: 'Role',
                      field: 'role',
                      render: (rowData) =>
                        rowData.role ? rowData.role.name : '',
                      filterField: 'role.name',
                    },
                    {
                      title: 'Status',
                      field: 'status',
                      filterField: 'status_filter',
                    },
                    { title: 'Address', field: 'address' },
                    { title: 'Email', field: 'email' },
                    { title: 'Phone No', field: 'phone' },
                  ]}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      let url =
                        urlProperties.baseUrl + urlProperties.filterUsers + '?';
                      url += 'perPage=' + query.pageSize;
                      url += '&page=' + query.page;
                      url +=
                        '&sortColumn=' +
                        (typeof query.orderBy === 'undefined'
                          ? 'first_Name'
                          : query.orderBy.field);
                      url += '&sortOrder=' + query.orderDirection;
                      url += '&search=' + (query.search || '');
                      fetch(url, {
                        headers: {
                          Authorization:
                            'Bearer ' + localStorage.getItem('token'),
                          'Content-Type': 'application/json',
                        },
                        method: 'POST',
                        body: JSON.stringify(buildFilterObject(query)),
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          resolve({
                            data: result.data,
                            page: result.page,
                            totalCount: result.total,
                          });
                        });
                    })
                  }
                  actions={[
                    permissions[PermissionProperties.viewUser]
                      ? {
                          icon: 'visibility',
                          iconProps: {
                            className: 'action-buttons view-button',
                          },
                          tooltip: 'View More Details',
                          onClick: (event, rowData) =>
                            onUserEditHandler(rowData, false),
                        }
                      : '',
                    permissions[PermissionProperties.editUser]
                      ? {
                          icon: 'edit',
                          iconProps: {
                            className: 'action-buttons edit-button',
                          },
                          tooltip: 'Edit User',
                          onClick: (event, rowData) =>
                            onUserEditHandler(rowData, true),
                        }
                      : '',
                    permissions[PermissionProperties.deleteUser]
                      ? (rowData) => ({
                          icon: 'clear',
                          iconProps: {
                            className: 'action-buttons delete-button',
                          },
                          tooltip: 'Terminate User',
                          onClick: (event, rowData) =>
                            deleteUserHandler(rowData),
                        })
                      : '',
                    permissions[PermissionProperties.addUser]
                      ? {
                          icon: () => (
                            <Link
                              to={
                                props.match.url + '/user?isCustomerUser=false'
                              }
                            >
                              <div
                                className={
                                  'generic-button-class div-button-wrapper'
                                }
                                variant="contained"
                                color="primary"
                              >
                                {userProperties.addNewUser}{' '}
                              </div>
                            </Link>
                          ),
                          tooltip: 'Add New User',
                          isFreeAction: true,
                        }
                      : '',
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    maxBodyHeight: '55vh',
                    sorting: false,
                    search: false,
                    headerStyle: {
                      backgroundColor: '#e6e4df',
                      fontSize: '0.7rem',
                      textAlignL: 'center',
                    },
                    rowStyle: {
                      fontSize: '0.6rem',
                      fontWeight: 450,
                      padding: '5rem',
                    },
                  }}
                  onRowClick={(event, rowData, toggleDetailPanel) =>
                    onUserEditHandler(rowData, false)
                  }
                />
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

// const mapStateToProps = state => {
//     return {
//         custsomerArray: state.customer.customers
//     };
// };

// const mapDispatchToProps = dispatch => {
//     return {
//         getCustomersHandler: (customers) => dispatch(actions.getCustomers(customers))
//     }
// }

export default Users;
