import React, { useEffect, useState, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import styles from './Kpi.module.css';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'react-image-gallery/styles/css/image-gallery.css';
import { KpiProperties } from 'Util/Property/KpiProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import LoadingOverlay from 'react-loading-overlay';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import KpiTableBody from './TableBody';
import TableCell from '@material-ui/core/TableCell';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import DropDownSelection from 'components/DropDown/DropDown';
import { getServiceRequestCategoryCall } from 'services/ServiceRequest/serviceRequestCategoryServices';
import KpiTableBodycost from './TableBodycost';
import KpiTableBodycount from './TableBodycount';
import {
  getKpi,
  createBulkKpis,
  getActiveWorkflowByTypeAndSrTypeId,
} from 'services/Kpi/KpiServices';
import DialogContentText from '@material-ui/core/DialogContentText';
import generalProperties from 'Util/Property/GeneralProperties';

import Spinner from 'components/Spinner/Spinner.js';

export default function KpiTable(props) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    open,
    viewMoreClose,
    isEdit,
    serviceRequestCategoriesList,
    kpiID,
    title,
    isFirstTimeAdd,
    kpiData,
  } = props;

  const [rows, setRows] = React.useState([]);
  const [kpiArray, setKpiArray] = useState([]);
  const [frequency, setFrequency] = useState('');
  const [serviceRequestCategories, setServiceRequestCategories] = useState([]);
  const [SelectedValueSr, setSelectedValueSr] = useState([]);
  const [srTypeID, setSrTypeID] = useState('');
  const [selectedSrTypeId, setSelectedSrTypeId] = useState('');
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [stagesArrayResp, setStagesArrayResp] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [unitsList, setUnitsList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [databySrTypeAndKpiType, setDatabySrTypeAndKpiType] = useState([]);
  const [
    isAlreadyAvailableDialogOpen,
    setAlreadyAvailableDialogOpen,
  ] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    selectTableCell: {
      width: 60,
    },
    tableCell: {
      width: 130,
      height: 40,
    },
    input: {
      width: 130,
      height: 40,
    },
  }));

  const CustomTableCell = ({ row, name, onChange }) => {
    const classes = useStyles();
    const { isEditMode } = row;
    return (
      <TableCell align="left" className={classes.tableCell}>
        {isEditMode ? (
          <Input
            value={row[name]}
            name={name}
            onChange={(e) => onChange(e, row)}
            className={classes.input}
          />
        ) : (
          row[name]
        )}
      </TableCell>
    );
  };

  const handleClose = () => {
    viewMoreClose();
  };

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '400px',
    width: '650px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#B22900',
    borderStyle: 'dashed',
    backgroundColor: '#ffcec7',
    color: '#B22900',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const frequencyArrays = [
    { id: '1', name: 'Time' },
    { id: '2', name: 'Cost' },
    { id: '3', name: 'Count' },
  ];

  const UnitsArrayTime = [
    { id: '1', name: 'DAYS' },
    // { id: '2', name: 'HOURS' },
  ];

  const UnitsArraysCount = [
    { id: '1', name: 'NUMBER' },
    { id: '2', name: 'PERCENTAGE' },
  ];

  const UnitsArraysCost = [{ id: '1', name: 'LKR' }];

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  useEffect(() => {
    setKpiArray(frequencyArrays);
    setServiceRequestCategories(serviceRequestCategoriesList);
    // console.log(".........",isFirstTimeAdd)

    if (isFirstTimeAdd !== true) {
      setIsDisabled(true);
      getKpiDataByKpiTypeAndSrType(kpiData[0].kpiType, kpiData[0].srType);
    } else {
      // setKpiArray(frequencyArrays);
      setIsDisabled(false);
      getServiceRequestCategories();
    }
  }, [open]);

  useEffect(() => {
    // console.log("...........",SelectedValueSr)
    // console.log(".........",srTypeID)
  });

  useEffect(() => {
    setSrTypeID('');
    setSelectedSrTypeId('');
    setStagesArrayResp([]);
    setSelectedValueSr([]);
    setSelectedUnit([]);
    setUnits();
  }, [frequency]);

  useEffect(() => {
    // setSrTypeID(selectedSrTypeId);
    setSrTypeID(selectedSrTypeId);
    if (isFirstTime) {
      if (SelectedValueSr.length !== 0 && srTypeID !== '' && frequency !== '') {
        getActiveWorkFlow(srTypeID);
      }
    }
  }, [SelectedValueSr]);

  const setUnits = () => {
    if (frequency === 'Time') {
      setUnitsList(UnitsArrayTime);
    } else if (frequency === 'Cost') {
      setUnitsList(UnitsArraysCost);
    } else if (frequency === 'Count') {
      setUnitsList(UnitsArraysCount);
    } else {
      setUnitsList([]);
    }
  };

  const [previous, setPrevious] = React.useState({});
  const classes = useStyles();

  const onToggleEditMode = (id) => {
    setRows((state) => {
      return rows.map((row) => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const getKpiDataByKpiTypeAndSrType = async (kpiType, srTypeID) => {
    setIsLoading(true);
    await getKpi(kpiType, srTypeID)
      .then((result) => {
        if (result.status === 200) {
          // console.log("Kpi data by srtype and kpiType.....................",result.data)
          setDatabySrTypeAndKpiType(result.data);
          setFrequency(result.data.kpi_type);
          setSelectedValueSr(result.data.sr_category_Name);
          setSrTypeID(result.data.sr_category_id);
          setSelectedSrTypeId(result.data.sr_category_id);
          setSelectedUnit(result.data.kpi_unit);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: KpiProperties.messages.error.loadData,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: KpiProperties.messages.error.loadData,
        });
      });
  };

  const getKpiDataByKpiTypeAndSrTypeCheck = async (kpiType, srTypeID) => {
    setIsLoading(true);
    await getKpi(kpiType, srTypeID)
      .then((result) => {
        if (result.status === 200) {
          console.log(
            'Kpi data by srtype and kpiType.....................',
            result.data.kpis.length
          );
          if (isFirstTimeAdd === true && result.data.kpis.length !== 0) {
            // notyDefault({ type: notyTypes.ERROR, text: "Already available"});
            setAlreadyAvailableDialogOpen(true);
          } else {
            handleSubmit();
          }
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: KpiProperties.messages.error.loadData,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: KpiProperties.messages.error.loadData,
        });
      });
  };

  const getServiceRequestCategories = async () => {
    setIsLoading(true);
    await getServiceRequestCategoryCall()
      .then((result) => {
        if (result.success === true) {
          setServiceRequestCategories(result.data);
          setIsLoading(false);
          setIsFirstTime(true);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: KpiProperties.messages.error.srCategories,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: KpiProperties.messages.error.srCategories,
        });
      });
  };

  const getSelectedSRTypeID = (srType) => {
    serviceRequestCategories.map((type) => {
      if (type.name === srType) {
        setSrTypeID(type.id);
        setSelectedSrTypeId(type.id);
      }
    });
  };

  const handleRowdata = (dataa) => {
    setRows(dataa);
  };

  const handleSubmitclick = () => {
    setIsLoading(true);
    getKpiDataByKpiTypeAndSrTypeCheck(frequency, srTypeID);
    // handleSubmit();
  };

  const handleSubmit = async () => {
    let kpidata = { ...rows };
    let data = {};
    let newKpiArray = {};
    let arr = [];

    for (let key in kpidata) {
      // delete kpidata[key].tableData;
      arr.push(kpidata[key]);
    }

    // for (let key in kpidata) {
    //     delete kpidata[key].id
    // }

    data['kpi_type'] = frequency;
    data['sr_category_id'] = srTypeID;

    const bata = arr.find((row) => row.cost_type === 'BA/TA');
    if (bata != null) {
      bata.cost_type = 'BATA';
    }

    data['kpis'] = arr;
    data['kpi_unit'] = selectedUnit;

    // cost_type: "BA/TA
    await createBulkKpis(data)
      .then((result) => {
        if (typeof result !== 'undefined') {
          if (result.status === 200) {
            if (isFirstTimeAdd === true) {
              notyDefault({
                type: notyTypes.SUCCESS,
                text: KpiProperties.messages.success.kpi,
              });
            } else {
              notyDefault({
                type: notyTypes.SUCCESS,
                text: KpiProperties.messages.success.update,
              });
            }
            handleClose();
          } else {
            notyDefault({
              type: notyTypes.ERROR,
              text: KpiProperties.messages.error.kpicr,
            });
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: KpiProperties.messages.error.kpicr,
        });
      });
    setIsLoading(false);
  };

  const AlreadyAvailableCloseHandler = () => {
    setAlreadyAvailableDialogOpen(false);
  };

  const AlreadyAvailableConfirmHandler = () => {
    handleSubmit();
    setAlreadyAvailableDialogOpen(false);
  };

  const handlekpyType = (frequency) => {
    setFrequency(frequency);
    // console.log("KPI Type..................",frequency);
  };

  const handleSelectedSrType = (srTypes) => {
    setSelectedValueSr(srTypes);
    getSelectedSRTypeID(srTypes);
    // console.log("Selected SR type..................",srTypes);
  };

  const handleSelectedUnit = (unit) => {
    setSelectedUnit(unit);
    //  console.log("Selected Unit ..................",unit);
  };

  const getActiveWorkFlow = async (srID) => {
    setIsLoading(true);
    await getActiveWorkflowByTypeAndSrTypeId(srID)
      .then((result) => {
        // console.log("DATA FROM -----------------------", result.data.stages_list);
        let StagesList = result.data.stages_list;
        let stagesArray = [];
        StagesList.map((stg) => {
          stagesArray.push({
            id: stg.id,
            val: stg.stage_name,
            stageId: stg.stage_id,
          });
        });
        setStagesArrayResp(stagesArray);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: KpiProperties.messages.error.stages,
        });
      });
  };

  return (
    <div>
      <Dialog
        //  fullScreen
        open={open}
        // maxWidth="700"
        // width={700}
        maxWidth="md"
        maxHeight="lg"
        // maxHeight="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <Dialog
          open={isAlreadyAvailableDialogOpen}
          onClose={AlreadyAvailableCloseHandler}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This KPI's are already available. Please check or click confirm to
              replace from new vales.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="sm"
              className={'generic-button-class div-button-wrapper'}
              onClick={AlreadyAvailableCloseHandler}
              color="primary"
              variant="contained"
            >
              {generalProperties.cancel}
            </Button>
            <Button
              size="sm"
              className={'generic-button-class div-button-wrapper'}
              onClick={AlreadyAvailableConfirmHandler}
              color="primary"
              variant="contained"
              autoFocus
            >
              {generalProperties.confirm}
            </Button>
          </DialogActions>
        </Dialog>

        <GridContainer>
          <GridItem xs={3}>
            <DialogTitle
              style={{ color: '#f73123', height: '32px' }}
              id="alert-dialog-title"
            >
              {title}
            </DialogTitle>
          </GridItem>
          <GridItem xs={7}>
            <DialogActions>
              {/* <Button className={'generic-button-class div-button-wrapper'} onClick={handleClose} color="primary" variant="contained">
                                Cancel
                            </Button> */}
            </DialogActions>
            <GridContainer>
              <GridItem
                style={{ marginLeft: '0.0rem', marginTop: '0rem' }}
                xs={12}
                sm={12}
                md={3}
              >
                <DropDownSelection
                  labelforDropDwon={'KPI Type'}
                  dropDownData={kpiArray}
                  frequency={frequency}
                  setFrequency={handlekpyType}
                  disabledVal={isDisabled}
                />
              </GridItem>
              <GridItem
                style={{ marginLeft: '0.0rem', marginTop: '0rem' }}
                xs={12}
                sm={12}
                md={3}
              >
                <DropDownSelection
                  labelforDropDwon={'SR Type'}
                  dropDownData={serviceRequestCategories}
                  frequency={SelectedValueSr}
                  setFrequency={handleSelectedSrType}
                  disabledVal={isDisabled}
                />
              </GridItem>
              <GridItem
                style={{ marginLeft: '0.0rem', marginTop: '0rem' }}
                xs={12}
                sm={12}
                md={3}
              >
                <DropDownSelection
                  labelforDropDwon={'Unit'}
                  dropDownData={unitsList}
                  frequency={selectedUnit}
                  setFrequency={handleSelectedUnit}
                  disabledVal={isEdit || isFirstTimeAdd ? false : true}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={2}>
            <DialogActions>
              {isEdit || isFirstTimeAdd ? (
                // <Button className={'generic-button-class div-button-wrapper'} onClick={handleSubmitclick} disabled={frequency===''|| (srTypeID==='' && frequency !=='Cost') || selectedUnit.length===0 || isLoading===true} color="primary" variant="contained">
                <Button
                  className={'generic-button-class div-button-wrapper'}
                  onClick={handleSubmitclick}
                  disabled={
                    frequency === '' ||
                    srTypeID === '' ||
                    selectedUnit.length === 0 ||
                    isLoading === true
                  }
                  color="primary"
                  variant="contained"
                >
                  Save
                </Button>
              ) : (
                ''
              )}
              {/* <Button className={'generic-button-class div-button-wrapper'} onClick={handleSubmit} color="primary" variant="contained">
                                Save
                            </Button> */}
              <Button
                className={'generic-button-class div-button-wrapper'}
                onClick={handleClose}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
            </DialogActions>
          </GridItem>
        </GridContainer>
        <DialogContent style={{ height: '600px' }}>
          <LoadingOverlay
            active={isLoading}
            spinner={<Spinner />}
            text="Loading ..."
          >
            {frequency === 'Time' ? (
              <KpiTableBody
                RowData={handleRowdata}
                stagesArr={stagesArrayResp}
                srID={srTypeID}
                kpiType={frequency}
                existData={databySrTypeAndKpiType}
                isFirstTime={isFirstTimeAdd}
              />
            ) : frequency === 'Cost' ? (
              <KpiTableBodycost
                RowData={handleRowdata}
                existData={databySrTypeAndKpiType}
                isFirstTime={isFirstTimeAdd}
              />
            ) : (
              <KpiTableBodycount
                RowData={handleRowdata}
                stagesArr={stagesArrayResp}
                existData={databySrTypeAndKpiType}
                isFirstTime={isFirstTimeAdd}
              />
            )}
          </LoadingOverlay>
          {/* </DialogContent> */}
          {/* <DialogActions>

                    <Button className={'generic-button-class div-button-wrapper'} onClick={handleSubmit} color="primary" variant="contained">
                        Save
                    </Button>
                    <Button className={'generic-button-class div-button-wrapper'} onClick={handleClose} color="primary" variant="contained">
                                Cancel
                    </Button>
           
                </DialogActions> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}
