import axios from 'axios';
import * as actions from '../store/actions/index';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import UrlProperties from 'Util/Property/UrlProperties';

const axiosDefault = axios.create({
    baseURL :     UrlProperties.baseUrl  //'http://localhost:8081/cba/' uat =  138.201.99.190
})
   

const refreshAuthLogic = async failedRequest => { 
    
    try {
        const tokenRefreshResponse = await axiosDefault
            .post('/auth/login/', {
                username: process.env.USER,
                password: process.env.PASS,
                skipAuthRefresh: true
            });
        failedRequest.response.config.headers.Authorization = `Token ${tokenRefreshResponse.data.token}`;
        return await Promise.resolve();
    } catch (error) {
        console.log(error);
    }
}

function getAuthToken() {
   let token = localStorage.getItem("token");
   if(token){
       return token;
   }else{
       return dispatch => dispatch(actions.logOut());
   }
}


axiosDefault.interceptors.request.use((request) => {
    console.log(`Requesting ${request.url}`);
    const token = getAuthToken();
    if (token) {
        request.headers.Authorization = 'Bearer '+token;
    }
    return request;
});
 
// createAuthRefreshInterceptor(axiosDefault, refreshAuthLogic); 
createAuthRefreshInterceptor(axiosDefault, refreshAuthLogic, { skipWhileRefreshing: true });

export default axiosDefault;
