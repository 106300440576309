import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios/axios-default';

export async function getLeaves() {
  try {
    // return await axios.get(
    //   'http://localhost:8089/api/v1/getLeavesGroupedByCurrentSupervisor'
    // );
    return await axios.get(urlProperties.leave.getLeaves);
  } catch (error) {
    console.log('Get Leaves Error ', error);
  }
}

export async function updateLeave(leave) {
  try {
    // return await axios.put(
    //   `http://localhost:8089/api/v1/updateLeaves/${leave.id}`,
    //   leave
    // );
    return await axios.put(
      `${urlProperties.leave.updateLeaves}/${leave.id}`,
      leave
    );
  } catch (error) {
    console.log('Leave Update Error ', error);
  }
}

export async function sendNotificatiion(leave) {
  try {
    return await axios.post(urlProperties.leave.notifyUserLeave, leave);
  } catch (error) {
    console.log('Sending Leave Notification Error ', error);
  }
}

export async function sendLeaveResponse(list) {
  list.forEach(async (leave) => {
    await updateLeave(leave);
    // await sendNotificatiion(leave);
  });
}
