import axios from '../../axios/axios-default';
import UrlProperties from 'Util/Property/UrlProperties';

const baseUrl = UrlProperties.baseUrl;
export default {
  getAllClaimsRules() {
    return axios.get(`${baseUrl}getClaimRules`);
  },
  getAllowanceTypes() {
    return axios.get(`${baseUrl}getActiveAllowanceTypes`);
  },
  getProductCategories() {
    return axios.get(`${baseUrl}getProductCategoriesByStatus/true`);
  },
  getMealTypes() {
    return axios.get(`${baseUrl}getActiveMealTypes`);
  },
  getSRTypes() {
    return axios.get(`${baseUrl}getServiceRequestCategories`);
  },
  getClaimsRuleById(id) {
    return axios.get(`${baseUrl}getClaimRule/${id}`);
  },
  deleteClaimsRuleById(id, data) {
    return axios.patch(`${baseUrl}updateClaimRule/${id}`, data);
  },
  createClaimsRule(data) {
    return axios.post(`${baseUrl}createClaimRule`, data);
  },
  updateClaimsRule(data, id) {
    return axios.patch(`${baseUrl}updateClaimRule/${id}`, data);
  },
};
