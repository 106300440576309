import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';

export default function ImageView(props) {
  const baseImage = props.baseImage;

  return (
    <div>
      <Avatar
        src={
          baseImage == '' || typeof baseImage === 'undefined' ? ' ' : baseImage
          // : `data:image/jpeg;base64,${baseImage}`
          // } round={true} style={{width:'150px',height:'150px'}}/>
        }
        round={true}
        style={{
          width: '170px',
          height: '170px',
          border: '4px solid #d4cfcf ',
        }}
      />
      {/* 
        <img 
            src={baseImage == '' || typeof baseImage === 'undefined'? 
            "https://www.linkpicture.com/q/def_user-removebg-preview.png"
                :baseImage
                } 
            width={'90%'} 
            height={'90%'} 
            style={{borderRadius:'180%'}}
            // style={{borderRadius:'15%', border: '1px solid #B22900'}}
        />  */}
    </div>
  );
}
