import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios/axios-default';
import { appendPagination } from '../../Util/Util';

export async function getAllProducts() {
  return axios.get(urlProperties.product.getProducts);
}

/**
 * service request save and update
 */

export async function saveProduct(method, url, data) {
  return await axios({
    method: method,
    url: url,
    data: data,
  });
}

export async function getProduct(id) {
  return axios.get(urlProperties.product.getProduct + '/' + id);
}

export async function getProductBySerial(serial) {
  return axios.get(
    urlProperties.product.searchProductsBySerial + '?serial=' + serial
  );
}

export async function getProductByTid(tid) {
  return axios.get(urlProperties.product.searchProductsByTid + '?tid=' + tid);
}

export async function deleteProduct(id) {
  return axios.delete(urlProperties.product.deleteProduct + '/' + id);
}

export async function getProductByCustomer(customerId) {
  console.log(urlProperties.product.getCustomerProducts + '/' + customerId);
  return axios.get(
    urlProperties.product.getCustomerProducts + '/' + customerId
  );
}

export async function getProductsByCustomerAndModel(customerId, modelId) {
  return axios.get(
    urlProperties.product.getProductsByCustomerAndModel +
      '?customerId=' +
      customerId +
      '&modelId=' +
      modelId
  );
}

export async function getProductsForBulkSA(
  categoryId,
  brandId,
  modelId,
  date,
  catId
) {
  return axios.get(
    urlProperties.product.getProductsForBulkSA +
      '?categoryId=' +
      categoryId +
      '&brandId=' +
      brandId +
      '&modelId=' +
      modelId +
      '&issueDate=' +
      date +
      '&customerId=' +
      catId
  );
}

export async function getAllProductSubStatuses() {
  return axios.get(urlProperties.product.getProductSubStatuses);
}

export async function getAllProductAccessoriesByProductCategory(
  productCategoryId
) {
  return axios.get(
    urlProperties.product.getAccessoriesByProductCategory +
      '/' +
      productCategoryId
  );
}
