import React, { useState, useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone from 'react-dropzone';
import axios from '../../../axios/axios-default';
import { notyDefault, notyTypes } from '../../Noty/NotyCustom';
import readXlsxFile from 'read-excel-file';
import { Typography } from '@material-ui/core';
import { customerProperties } from 'Util/Property/CustomerPorerties';
import * as moment from 'moment';
import writeXlsxFile from 'write-excel-file';
import UrlProperties from 'Util/Property/UrlProperties';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import Lottie from 'react-lottie';
import animationData from '../../../assets/onsiteAnimation.json';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import reportsProperties from 'Util/Property/OpeReportProperty';

import ExportIcon from '../../../assets/img/icons/export.svg';
import './Reports.css';
import { getCustomerForm } from '../../../Util/Property/CustomerPorerties';

import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function ReportView3(props) {
  const {
    open,
    fileUploadClose,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    url,
    uploadLoacation,
    acceptFileType,
    type,
    tableData,
    startDate,
    endDate,
  } = props;
  const [file, setFile] = React.useState([]);

  const [error, setError] = React.useState('');
  const [hasError, setHasError] = React.useState(false);
  const [dataArray, setDataArray] = React.useState([]);
  const [openSecondPreview, setOpenSecondPreview] = useState(false);
  const [newFile, setNewFile] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openFileUpload4, setOpenFileUpload4] = useState(false);
  // const [handleClose, setHandleClose] = useState(false);
  const [tableCols = [], setTableCols] = useState([]);
  const [spreedSheetData = [], setSpreedSheetData] = useState([]);

  const baseUrl = UrlProperties.baseUrl.split(':8089')[0];

  const current = new Date();
  const date = `${current.getDate()}-${current.getMonth() +
    1}-${current.getFullYear()}`;

  const handleClose = () => {
    fileUploadClose();
  };

  const fileUploadClose3 = () => {
    setOpenFileUpload4(false);
  };
  const rowRender = (rowData, key) => {
    if (key === 'gap') {
      return (
        <div
          style={{
            // padding: '0.5rem',
            background:
              rowData['kpi'].value != null && rowData['gap'].value != null
                ? rowData['kpi'].value > rowData['gap'].value
                  ? '#92D050'
                  : rowData['kpi'].value < rowData['gap'].value
                  ? '#FF3300'
                  : rowData['kpi'].value === rowData['gap'].value
                  ? '#FFC000'
                  : null
                : null,
          }}
        >
          {rowData['gap'].value}
        </div>
      );
    }
    return (
      <div
        style={{
          padding: '0.5rem',
        }}
      >
        {key === 'province'
          ? getProvince(rowData[key].value)
          : rowData[key].value}
      </div>
    );
  };
  useEffect(() => {
    const colsArr = [];
    if (tableData.length !== 0) {
      Object.entries(tableData[0]).forEach(([key, value]) => {
        const _val = reportsProperties.reqFieldsMap[key];
        if (typeof _val !== 'undefined') {
          return colsArr.push({
            id: tableData[0][key].id,
            title: reportsProperties.reqFieldsMap[key]['table'],
            field: key,
            headerStyle: {
              backgroundColor: '#e6e4df',
              // padding: '5px',
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
              margin: '0',
              padding: '0',
            },
            render: (rowData) => {
              return rowRender(rowData, key);
            },
          });
        }
      });
    }
    colsArr.sort(function(a, b) {
      return a.id - b.id;
    });
    const sDataArr = [];
    const sData = exportTable(tableData);
    sDataArr.push(sData);
    setSpreedSheetData(sDataArr);
    setTableCols(colsArr);
  }, []);
  const getProvince = (key) => {
    const obj = getCustomerForm().province.inputProps.options;
    const index = obj.findIndex((el) => el.value === key);
    if (obj[index] !== undefined) {
      return obj[index].displayValue;
    }
  };
  const borders = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' },
  };

  const alignment = {
    horizontal: 'center',
  };
  const exportTable = (tableData) => {
    const columnsArr = [];
    const dataArr = [];
    tableData.forEach((obj, id) => {
      const arr = [];

      let sortedObj = Object.entries(obj).sort((a, b) => a[1].id - b[1].id);
      sortedObj.forEach((el) => {
        const val = reportsProperties.reqFieldsMap[el[0]];

        if (val !== undefined) {
          if (
            columnsArr.filter((__el) => __el.title === val['table']).length ===
            0
          ) {
            columnsArr.push({
              title: val['table'],
              style: {
                alignment: { ...alignment, wrapText: true, vertical: 'top' },
                border: borders,
                font: { sz: '12', bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ceedf2' } },
              },
            });
          }

          let style;
          if (el[0] === 'gap') {
            if (obj['kpi'].value !== null && el[1].value !== null) {
              if (obj['kpi'].value > el[1].value) {
                style = {
                  fill: { patternType: 'solid', fgColor: { rgb: '92D050' } },
                };
              } else if (obj['kpi'].value < el[1].value) {
                style = {
                  fill: { patternType: 'solid', fgColor: { rgb: 'FF3300' } },
                };
              } else if (obj['kpi'].value === el[1].value) {
                style = {
                  fill: { patternType: 'solid', fgColor: { rgb: 'FFC000' } },
                };
              }
            }
          }
          arr.push({
            value: el[1].value !== null ? el[1].value : '',
            style: style
              ? { ...style, alignment: alignment, border: borders }
              : { alignment: alignment, border: borders },
          });
        }
      });

      dataArr.push(arr);
    });

    return {
      columns: columnsArr,
      data: dataArr,
    };
  };

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '140px',
    width: '400px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#B22900',
    borderStyle: 'dashed',
    backgroundColor: '#ffcec7',
    color: '#f73123',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  return (
    <div>
      <Dialog
        maxWidth={
          tableCols.length <= 5 ? 'sm' : tableCols.length <= 9 ? 'md' : 'lg'
        }
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <LoadingOverlay active={isLoading} spinner text="Loading ...">
          <DialogContent>
            <div className={'align-title'}>
              <div style={{ width: '10px', height: '10px' }}></div>
              <b>
                From: {`${startDate}     `} To: {endDate}
              </b>
              {spreedSheetData.length !== 0 ? (
                <div>
                  <ExcelFile
                    filename={`${type.displayValue}-${date}-p4`}
                    element={
                      <button className="export-button">
                        <img
                          src={ExportIcon}
                          alt="test"
                          className="export-icon"
                        />
                      </button>
                    }
                  >
                    <ExcelSheet dataSet={spreedSheetData} name="Organization" />
                  </ExcelFile>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    title={
                      <div>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          {type.displayValue}
                        </Typography>
                      </div>
                    }
                    columns={tableCols}
                    data={tableData}
                    options={{
                      actionsColumnIndex: -1,
                      exportButton: false,
                      selectionProps: (rowData) => {},
                      pageSize: 5,
                      sorting: false,
                      search: false,
                      padding: 'dense',
                      maxBodyHeight: '40vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        lineHeight: 100,
                        // padding: '5rem',
                      },
                    }}
                    onRowClick={(event, rowData, toggleDetailPanel) => {}}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button
              className={'generic-button-class div-button-wrapper'}
              onClick={handleClose}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>
          </DialogActions>
        </LoadingOverlay>
      </Dialog>
    </div>
  );

  // return (
  //   <div>
  //     <ReportView4
  //       open={openFileUpload4}
  //       type={type}
  //       fileUploadClose={fileUploadClose3}
  //     />
  //     <Dialog
  //       maxWidth="xl"
  //       fullWidth={true}
  //       open={open}
  //       onClose={handleClose}
  //       aria-labelledby="alert-dialog-title"
  //       aria-describedby="alert-dialog-description"
  //     >
  //       <LoadingOverlay active={isLoading} spinner text="Loading ...">
  //         <DialogContent>
  //           <b>
  //             <center>From:2022-06-11 To:2022-07-02</center>
  //           </b>
  //           {type == 'Efficiency_Summary_Time' ? (
  //             <GridContainer>
  //               <GridItem xs={12} sm={12} md={12}>
  //                 <div className={'material-grid-wrapper'}>
  //                   <MaterialTable
  //                     title={
  //                       <div>
  //                         <Typography
  //                           variant="h6"
  //                           style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
  //                         >
  //                           Efficiency Summary - Time
  //                         </Typography>
  //                       </div>
  //                     }
  //                     columns={[
  //                       {
  //                         title: '#',
  //                         field: 'num',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'SR Name',
  //                         width: '12%',
  //                         field: 'srname',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Customer',
  //                         width: '12%',
  //                         field: 'customer',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Stage',
  //                         width: '12%',
  //                         field: 'stage',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Attended By',
  //                         width: '12%',
  //                         field: 'attendedby',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Attended Date',
  //                         width: '12%',
  //                         field: 'attendeddate',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Attended Time',
  //                         width: '12%',
  //                         field: 'attendedtime',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Milestone Gap (Days)',
  //                         width: '15%',
  //                         field: 'milestonegap',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                         render: (rowData) => (
  //                           <div
  //                             style={{
  //                               width: '20%',
  //                               marginLeft: '40%',
  //                               background:
  //                                 rowData['milestonegap'] < rowData['kpi']
  //                                   ? '#92D050'
  //                                   : rowData['milestonegap'] > rowData['kpi']
  //                                   ? ' #FF3300'
  //                                   : '#FFC000',
  //                             }}
  //                           >
  //                             {rowData['milestonegap']}
  //                           </div>
  //                         ),
  //                       },
  //                       {
  //                         title: 'KPI (Days)',
  //                         width: '12%',
  //                         field: 'kpi',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                     ]}
  //                     data={[
  //                       {
  //                         num: '1',
  //                         srname: 'SR-001/Sales',
  //                         customer: 'HNB-Dehiwala',
  //                         stage: 'Inquiries',
  //                         attendedby: 'Shalomi',
  //                         attendeddate: '2021-01-26',
  //                         attendedtime: '14:51:04',
  //                         milestonegap: 0,
  //                         kpi: 0,
  //                       },
  //                       {
  //                         num: '2',
  //                         srname: 'SR-001/Sales',
  //                         customer: 'HNB-Dehiwala',
  //                         stage: 'Assigned',
  //                         attendedby: 'Shalomi',
  //                         attendeddate: '2021-01-27',
  //                         attendedtime: '15:51:04',
  //                         milestonegap: 1,
  //                         kpi: 2,
  //                       },
  //                       {
  //                         num: '3',
  //                         srname: 'SR-001/Sales',
  //                         customer: 'HNB-Dehiwala',
  //                         stage: 'Customer Visit',
  //                         attendedby: 'Layantha',
  //                         attendeddate: '2021-01-27',
  //                         attendedtime: '16:51:04',
  //                         milestonegap: 0,
  //                         kpi: 1,
  //                       },
  //                       {
  //                         num: '4',
  //                         srname: 'SR-001/Sales',
  //                         customer: 'HNB-Dehiwala',
  //                         stage: 'Demo',
  //                         attendedby: 'Layantha',
  //                         attendeddate: '2021-01-27',
  //                         attendedtime: '17:51:04',
  //                         milestonegap: 0,
  //                         kpi: 0,
  //                       },
  //                       {
  //                         num: '5',
  //                         srname: 'SR-001/Sales',
  //                         customer: 'HNB-Dehiwala',
  //                         stage: 'Assigned',
  //                         attendedby: 'Tharaka',
  //                         attendeddate: '2021-01-30',
  //                         attendedtime: '18:51:04',
  //                         milestonegap: 3,
  //                         kpi: 2,
  //                       },
  //                       {
  //                         num: '6',
  //                         srname: 'SR-001/Sales',
  //                         customer: 'HNB-Dehiwala',
  //                         stage: 'Customer Visit',
  //                         attendedby: 'Tharaka',
  //                         attendeddate: '2021-01-30',
  //                         attendedtime: '19:51:04',
  //                         milestonegap: 0,
  //                         kpi: 1,
  //                       },
  //                       {
  //                         num: '7',
  //                         srname: 'SR-001/Sales',
  //                         customer: 'HNB-Dehiwala',
  //                         stage: 'Demo',
  //                         attendedby: 'Tharaka',
  //                         attendeddate: '2021-01-30',
  //                         attendedtime: '20:51:04',
  //                         milestonegap: 0,
  //                         kpi: 0,
  //                       },
  //                       {
  //                         num: '8',
  //                         srname: 'SR-001/Sales',
  //                         customer: 'HNB-Dehiwala',
  //                         stage: 'Get Order',
  //                         attendedby: 'Tharaka',
  //                         attendeddate: '2021-02-02',
  //                         attendedtime: '21:51:04',
  //                         milestonegap: 3,
  //                         kpi: 2,
  //                       },
  //                       {
  //                         num: '9',
  //                         srname: 'SR-001/Sales',
  //                         customer: 'HNB-Dehiwala',
  //                         stage: 'Approval Process 1',
  //                         attendedby: 'Enosh',
  //                         attendeddate: '2021-02-02',
  //                         attendedtime: '22:51:04',
  //                         milestonegap: 1,
  //                         kpi: 1,
  //                       },
  //                       {
  //                         num: '10',
  //                         srname: 'SR-001/Sales',
  //                         customer: 'HNB-Dehiwala',
  //                         stage: 'Approval Process 2',
  //                         attendedby: 'Enosh',
  //                         attendeddate: '2021-02-03',
  //                         attendedtime: '23:51:04',
  //                         milestonegap: 0,
  //                         kpi: 0,
  //                       },
  //                     ]}
  //                     options={{
  //                       actionsColumnIndex: -1,
  //                       // selection: true,
  //                       exportButton: true,
  //                       selectionProps: (rowData) => {
  //                         // const selectable = rowData.assignedTo === null;
  //                         // //NOTE:: tableData object is not in documentation
  //                         // rowData.tableData.disabled = !selectable;
  //                         // return {
  //                         //     disabled: !selectable
  //                         // }
  //                       },
  //                       pageSize: 5,
  //                       // filtering: true,
  //                       //maxBodyHeight:"85vh",
  //                       sorting: false,
  //                       search: false,
  //                       padding: 'dense',
  //                       maxBodyHeight: '70vh',
  //                       headerStyle: {
  //                         fontSize: '0.7rem',
  //                         textAlignL: 'center',
  //                       },
  //                       rowStyle: {
  //                         fontSize: '0.6rem',
  //                         fontWeight: 450,
  //                         padding: '5rem',
  //                       },
  //                     }}
  //                     // options={{
  //                     //   exportButton: true
  //                     // }}
  //                     onRowClick={(event, rowData) => {
  //                       // Get your id from rowData and use with link.
  //                       //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
  //                       //event.stopPropagation();
  //                     }}
  //                   />{' '}
  //                 </div>
  //               </GridItem>
  //             </GridContainer>
  //           ) : type == 'Efficiency_Summary_Count' ? (
  //             <GridContainer>
  //               <GridItem xs={12} sm={12} md={12}>
  //                 <div className={'material-grid-wrapper'}>
  //                   <MaterialTable
  //                     title={
  //                       <div>
  //                         <Typography
  //                           variant="h6"
  //                           style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
  //                         >
  //                           Efficiency Summary - Count
  //                         </Typography>
  //                       </div>
  //                     }
  //                     columns={[
  //                       {
  //                         title: '#',
  //                         width: '1%',
  //                         field: 'num',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Stage',
  //                         field: 'stage',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Attended Time',
  //                         field: 'attendedTime',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Attended By',
  //                         field: 'attendedBy',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'KPI',
  //                         field: 'kpi',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Gap',
  //                         field: 'gap',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },

  //                       {
  //                         title: 'SR Name',
  //                         field: 'srName',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Customer Name',
  //                         field: 'customerName',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Attended Date',
  //                         field: 'attendedDate',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                     ]}
  //                     data={tableData}
  //                     options={{
  //                       actionsColumnIndex: -1,
  //                       // selection: true,
  //                       exportButton: true,
  //                       selectionProps: (rowData) => {
  //                         // const selectable = rowData.assignedTo === null;
  //                         // //NOTE:: tableData object is not in documentation
  //                         // rowData.tableData.disabled = !selectable;
  //                         // return {
  //                         //     disabled: !selectable
  //                         // }
  //                       },
  //                       pageSize: 5,
  //                       // filtering: true,
  //                       //maxBodyHeight:"85vh",
  //                       sorting: false,
  //                       search: false,
  //                       padding: 'dense',
  //                       maxBodyHeight: '70vh',
  //                       headerStyle: {
  //                         fontSize: '0.7rem',
  //                         textAlignL: 'center',
  //                       },
  //                       rowStyle: {
  //                         fontSize: '0.6rem',
  //                         fontWeight: 450,
  //                         padding: '5rem',
  //                       },
  //                     }}
  //                     // options={{
  //                     //   exportButton: true
  //                     // }}
  //                     onRowClick={(event, rowData) => {
  //                       // Get your id from rowData and use with link.
  //                       // window.open(this.props.match.url+`/onSiteUserDetail/${rowData.fieldAgent}/${rowData.assignDate}`, "_blank")
  //                       //event.stopPropagation();
  //                     }}
  //                   />{' '}
  //                 </div>
  //               </GridItem>
  //             </GridContainer>
  //           ) : type == 'Average_field_Cost' ? (
  //             <GridContainer>
  //               <GridItem xs={12} sm={12} md={12}>
  //                 <div className={'material-grid-wrapper'}>
  //                   <MaterialTable
  //                     title={
  //                       <div>
  //                         <Typography
  //                           variant="h6"
  //                           style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
  //                         >
  //                           Average field Cost
  //                         </Typography>
  //                       </div>
  //                     }
  //                     columns={[
  //                       {
  //                         title: '#',
  //                         field: 'num',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Date',
  //                         field: 'date',
  //                         width: '12%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Total Field Visits Count',
  //                         width: '14%',
  //                         field: 'totalfieldvisitscount',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Cost Of Travelling',
  //                         width: '12%',
  //                         field: 'costoftravelling',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Cost of BA/TA',
  //                         width: '12%',
  //                         field: 'ba',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Cost of Meal',
  //                         width: '12%',
  //                         field: 'meal',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Cost of Allowances',
  //                         width: '12%',
  //                         field: 'allowances',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Fixed Cost',
  //                         width: '12%',
  //                         field: 'fixedcost',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Total Fixed Cost',
  //                         width: '12%',
  //                         field: 'totalfixedcost',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                     ]}
  //                     data={[
  //                       {
  //                         num: '1',
  //                         date: '1/1/2022',
  //                         totalfieldvisitscount: 3,
  //                         costoftravelling: 250.0,
  //                         ba: 150.0,
  //                         meal: 250.0,
  //                         allowances: 200.0,
  //                         fixedcost: 1000.0,
  //                         totalfixedcost: 1850.0,
  //                       },
  //                       {
  //                         num: '2',
  //                         date: '1/2/2022',
  //                         totalfieldvisitscount: 10,
  //                         costoftravelling: 150.0,
  //                         ba: '-',
  //                         meal: 200.0,
  //                         allowances: 120.0,
  //                         fixedcost: 1000.0,
  //                         totalfixedcost: 1470.0,
  //                       },
  //                       {
  //                         num: '3',
  //                         date: '1/3/2022',
  //                         totalfieldvisitscount: 7,
  //                         costoftravelling: 300.0,
  //                         ba: '-',
  //                         meal: 250.0,
  //                         allowances: 150.0,
  //                         fixedcost: 1000.0,
  //                         totalfixedcost: 1700.0,
  //                       },
  //                     ]}
  //                     options={{
  //                       actionsColumnIndex: -1,
  //                       // selection: true,
  //                       exportButton: true,
  //                       selectionProps: (rowData) => {
  //                         // const selectable = rowData.assignedTo === null;
  //                         // //NOTE:: tableData object is not in documentation
  //                         // rowData.tableData.disabled = !selectable;
  //                         // return {
  //                         //     disabled: !selectable
  //                         // }
  //                       },
  //                       pageSize: 5,
  //                       // filtering: true,
  //                       //maxBodyHeight:"85vh",
  //                       sorting: false,
  //                       search: false,
  //                       padding: 'dense',
  //                       maxBodyHeight: '70vh',
  //                       headerStyle: {
  //                         fontSize: '0.7rem',
  //                         textAlignL: 'center',
  //                       },
  //                       rowStyle: {
  //                         fontSize: '0.6rem',
  //                         fontWeight: 450,
  //                         padding: '5rem',
  //                       },
  //                     }}
  //                     // options={{
  //                     //   exportButton: true
  //                     // }}
  //                     onRowClick={(event, rowData, toggleDetailPanel) => {
  //                       //setType1(this.type)
  //                       setOpenFileUpload4(true);
  //                     }}
  //                   />
  //                 </div>
  //               </GridItem>
  //             </GridContainer>
  //           ) : null}
  //         </DialogContent>
  //         <DialogActions>
  //           {/* {uploadLoacation === "customer" ?
  //                           <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
  //                               <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>
  //                           </Button>
  //                       :""}																														 */}

  //           <Button
  //             className={'generic-button-class div-button-wrapper'}
  //             onClick={handleClose}
  //             color="primary"
  //             variant="contained"
  //           >
  //             Cancel
  //           </Button>
  //           {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
  //                           Uplaod
  //                       </Button> */}
  //         </DialogActions>
  //       </LoadingOverlay>
  //     </Dialog>
  //   </div>
  // );
}
