export const workflowRouteProperties = {
  workflowRoute: 'Workflow Route',
  viewWorkflow: 'View Workflow',
  createWorkflow: 'Create Workflow',
  messages: {
    error: {
      loadUsers: 'Could not load  users. Please try again',
      save: 'Could not save Workflow Route. Please try again',
      users: 'Could not load users for allowed roles. Please try again',
    },
    success: {
      save: 'Workflow Route saved successfully',
    },
    normal: {
      deleteConfirm: 'Do you want to delete this Workflow?',
    },
  },
};

export const getWorkflowRouteForm = () => {
  return {
    stage_name: {
      elType: 'select',
      label: 'Stages',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
    },
    status: {
      elType: 'select',
      label: 'Status',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isHide: true,
    },
    assign_user_id: {
      elType: 'select',
      label: 'Assign User',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
    },
    serial_number: {
      elType: 'input',
      label: 'Serial Number',
      inputProps: {
        type: 'text',
        placeholder: 'Serial Number',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        // required: true,
        error: false,
      },
      size: 12,
      isHide: true,
    },
    planned_completion_date: {
      elType: 'date',
      label: '',
      inputProps: {
        format: 'yyyy-MM-DD',
        value: new Date(),
        label: 'Target Completion Date',
        initvalue: new Date(),
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      // isDisabled: true,
      size: 6,
    },
    stage_comment: {
      elType: 'textarea',
      label: '',
      inputProps: {
        type: 'text',
        placeholder: 'Comment',
        value: '',
        initvalue: '',
        rowsMax: 5,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: false,
    },
  };
};

export const getWorkflowSRForm = () => {
  return {
    srId: {
      elType: 'input',
      label: 'SR Id',
      inputProps: {
        type: 'text',
        placeholder: 'SR Id',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      size: 4,
      isDisabled: true,
    },
    stage_name: {
      elType: 'select',
      label: 'Stages',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 4,
    },
    status: {
      elType: 'select',
      label: 'Status',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 4,
      isHide: false,
    },
    stageComment: {
      elType: 'textarea',
      label: '',
      inputProps: {
        type: 'text',
        placeholder: 'Comment',
        value: '',
        initvalue: '',
        rowsMax: 5,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: false,
    },
  };
};
