/* eslint-disable react/display-name */
import React, { useEffect, useState, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import styles from './ProductServiceAgreement.module.css';
import { Link } from 'react-router-dom';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import generalProperties from 'Util/Property/GeneralProperties';
import {
  productServiceAgreementProperties,
  getGenerateBulkDialogForm,
} from 'Util/Property/ProductServiceAgreementProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import LoadingOverlay from 'react-loading-overlay';
import * as moment from 'moment';
import Spinner from 'components/Spinner/Spinner';
import {
  getProductServiceAgreementsOfProduct,
  deleteProductServiceAgreement,
  getExpiringProductServiceAgreements,
  createBulkUpcomingPSA,
  generateBulkPSR,
} from 'services/ProductAndServices/ProductServiceAgreement';
import ListAltOutlinedIcon from '@material-ui/icons/FormatListBulletedSharp';
import { Typography } from '@material-ui/core';
import { getProduct } from 'services/ProductAndServices/ProductService';
import { productProperties } from 'Util/Property/ProductProperties';
import { getCustomer } from 'services/Customer/CustomerService';
import { customerProperties } from 'Util/Property/CustomerPorerties';
import { handleErrorMessage } from 'services/CommonService';
import urlProperties from 'Util/Property/UrlProperties';
import { buildFilterObject } from '../../../Util/Util';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from 'components/TabPanels/FullWidthTabPanel';
import AddServiceAgreement from './AddServiceAgreement/AddServiceAgreement';
import { makeStyles } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInput.js';

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: '#e6e4df',
    height: '10px',
    top: '45px',
  },
  tabsWrapper: {
    background: '#e6e4df',
  },
}));
const ProductServiceAgreements = (props) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isPSRBulkDialogOpen, setPSRBulkDialogOpen] = useState(false);
  const [isSABulkDialogOpen, setSABulkDialogOpen] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState({});
  const [permissions, setPermissions] = useState({});
  const [productServiceAgreements, setProductServiceAgreements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const [productId, setProductId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const satableRef = useRef(null);
  const saUpcomingtableRef = useRef(null);
  const [isAcitveProduct, setIsActiveProduct] = useState(false);
  const [isActiveCustomer, setIsActiveCustomer] = useState(false);
  const [dateInputValue, setDateInputValue] = useState(null);
  const [selectedIdList, setSelectedIdList] = useState([]);

  const classes = useStyles();

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let prodId = 0;
    let customerId = 0;

    const query = new URLSearchParams(props.location.search);
    let queryParam = {};
    for (let param of query.entries()) {
      queryParam[param[0]] = param[1];
    }
    customerId = queryParam['customerId'];

    if (props.id && props.id > 0) {
      if (props.isEdit) {
        setIsEdit(true);
      } else {
        setIsView(true);
      }
      prodId = props.id;
      customerId = props.customerId;
    } else if (props.location) {
      if (typeof queryParam['id'] != 'undefined') {
        prodId = queryParam['id'];
        if (queryParam['isEdit'] === 'true') {
          setIsEdit(true);
        } else {
          setIsView(true);
        }
      }
    }
    let permissionArray = [
      PermissionProperties.addProductAndServices,
      PermissionProperties.viewProductAndServices,
      PermissionProperties.deleteProductAndServices,
      PermissionProperties.editProductAndServices,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    if (prodId > 0) {
      getCustomerForCheck(customerId);
      setProductId(prodId);
      getProductData(prodId);
      setSelectedCustomerId(customerId);
    } else {
      setIsActiveCustomer(true);
    }
  }, [props.id]);

  const getCustomerForCheck = (custId) => {
    getCustomer(custId)
      .then((result) => {
        if (result.status === 200) {
          if (result.data.status === 'ACTIVE') {
            setIsActiveCustomer(true);
          }
        } else {
          handleErrorMessage(
            result.data,
            customerProperties.messages.error.loadData
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, customerProperties.messages.error.loadData);
      });
  };

  const getProductData = (productId) => {
    getProduct(productId)
      .then((result) => {
        setIsActiveProduct(result.data.active);
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.loadData,
        });
      });
  };

  const createBulkUpcomingProductServiceAgreement = async (date, idList) => {
    setIsLoading(true);
    const body = {
      pasIds: idList,
    };

    try {
      const result = await createBulkUpcomingPSA(date, body);

      if (result.status === 200) {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.SUCCESS,
          text:
            productServiceAgreementProperties.messages.success
              .upcomingSAsaveData,
        });
        saUpcomingtableRef.current.onQueryChange();
      } else {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: result.data
            ? result.data.message
            : productServiceAgreementProperties.messages.error.saveData,
        });
      }
    } catch (error) {
      setIsLoading(false);
      notyDefault({
        type: notyTypes.ERROR,
        text: error?.response?.data?.details
          ? error.response.data.details
          : productServiceAgreementProperties.messages.error.saveData,
      });
    }
  };

  const getExpiringProductServiceAgreementsUntil = (date) => {
    setIsLoading(true);
    getExpiringProductServiceAgreements(date)
      .then((result) => {
        if (result.status === 200) {
          setIsLoading(false);
          if (result.data.length > 0) {
            setProductServiceAgreements(result.data);
          } else {
            notyDefault({
              type: notyTypes.WARNING,
              text: productServiceAgreementProperties.messages.warning.noData,
            });
          }
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productServiceAgreementProperties.messages.error.loadAll,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productServiceAgreementProperties.messages.error.loadAll,
        });
      });
  };

  const onProductEditHandler = (id, prodId, isEdit, isUpcoming) => {
    let search = '?id=' + id + '&isEdit=' + isEdit;
    if (isUpcoming) {
      search = search + '&isUpcoming=' + isUpcoming;
    }
    props.history.push({
      pathname: props.match.url + '/serviceAgreement',
      search: search,
    });
  };

  const showListTableDialogCloseHandler = () => {
    setDateInputValue(null);
    setProductServiceAgreements([]);
  };

  const deleteProductDialogCloseHandler = () => {
    setDeleteDialogOpen(false);
  };

  const deleteProductDialogOpenHandler = () => {
    setDeleteDialogOpen(true);
  };

  const saBulkDialogOpenHandler = () => {
    setSABulkDialogOpen(true);
  };
  const saBulkDialogCloseHandler = () => {
    setSABulkDialogOpen(false);
    setProductServiceAgreements([]);
  };

  const psrBulkDialogOpenHandler = () => {
    setPSRBulkDialogOpen(true);
  };
  const psrBulkDialogCloseHandler = () => {
    setPSRBulkDialogOpen(false);
    setProductServiceAgreements([]);
  };

  const deleteConfirmHandler = () => {
    let data = { ...deleteRowData };
    deleteProductServiceAgreement(data.id)
      .then((result) => {
        if (result.status == '200') {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: productServiceAgreementProperties.messages.success.delete,
          });
          satableRef.current.onQueryChange();
        } else {
          // could not delete succesfully
          notyDefault({ type: notyTypes.ERROR, text: result.data });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: productServiceAgreementProperties.messages.error.delete,
        });
      });

    deleteProductDialogCloseHandler();
  };

  const generateBulkPSRHandler = async () => {
    setIsLoading(true);
    const date = dateInputValue.locale('en').format('YYYY-MM-DD');
    try {
      const result = await generateBulkPSR(date);

      if (result.status === 200) {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.SUCCESS,
          text: productServiceAgreementProperties.messages.success.psrGenerate,
        });
        psrBulkDialogCloseHandler();
      } else {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: result.data
            ? result.data.message
            : productServiceAgreementProperties.messages.error.psrGenerate,
        });
      }
    } catch (error) {
      setIsLoading(false);
      notyDefault({
        type: notyTypes.ERROR,
        text: error?.response?.data?.details
          ? error.response.data.details
          : productServiceAgreementProperties.messages.error.psrGenerate,
      });
    }
  };

  const deleteProductHandler = (rowData) => {
    setDeleteRowData(rowData);
    deleteProductDialogOpenHandler();
  };

  const upcomingPSAShowListHandler = () => {
    if (dateInputValue != null) {
      const date = dateInputValue.locale('en').format('YYYY-MM-DD');
      getExpiringProductServiceAgreementsUntil(date);
      saBulkDialogCloseHandler();
    }
  };

  const createBulkUpcomingPSAHandler = () => {
    if (dateInputValue != null && selectedIdList.length > 0) {
      const date = dateInputValue.locale('en').format('YYYY-MM-DD');
      const idList = selectedIdList.map((obj) => obj.id);
      createBulkUpcomingProductServiceAgreement(date, idList);
      showListTableDialogCloseHandler();
    }
  };

  const getTableColumns = (type) => {
    return [
      {
        title: type === 'upcoming sa' ? 'PSA Id' : '#',
        field: 'id',
        width: '10%',
        render: (rowData) =>
          type === 'upcoming sa' ? rowData.psaId : rowData.id,
      },
      {
        title: 'SA No',
        field: 'serviceAgreementId',
        render: (rowData) => `SA - ${rowData.serviceAgreementId}`,
      },
      {
        title: 'Service Agreement',
        field: 'serviceAgreementName',
      },
      {
        title: 'Customer',
        field: 'customerName',
      },
      {
        title: 'Product',
        field: 'productCategoryName',
      },
      {
        title: 'Issue Date',
        field: 'issueDate',
        render: (rowData) => moment(rowData.issueDate).format('YYYY/MM/DD'),
        filterField: 'issueDateFilter',
      },
      {
        title: 'Termination Date',
        field: 'terminationDate',
        render: (rowData) =>
          moment(rowData.terminationDate).format('YYYY/MM/DD'),
        filterField: 'terminationDateFilter',
      },
      {
        title: 'SA Value',
        field: 'price',
        render: (rowData) => (rowData.price ? `Rs.${rowData.price}.00` : '-'),
      },
      {
        title: 'Stage',
        field: 'stageName',
        filterField: 'stageFilter',
      },
    ];
  };

  const serviceAgreementListTab = () => {
    return (
      <MaterialTable
        title={
          <div>
            <span className={'grid-title-icon'}>
              <ListAltOutlinedIcon />
            </span>
            <Typography
              variant="h6"
              style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
            >
              {productServiceAgreementProperties.productServiceAgreements}
            </Typography>
          </div>
        }
        tableRef={satableRef}
        columns={getTableColumns('sa')}
        components={{
          OverlayLoading: () => (
            <div className={styles.loadingOverlay}>
              <Spinner />
            </div>
          ),
        }}
        data={(query) =>
          new Promise((resolve, reject) => {
            const filterDto = buildFilterObject(query);
            if (productId > 0) {
              filterDto['productIdFilter'] = productId;
            }
            let url =
              urlProperties.baseUrl +
              urlProperties.productServiceAgreement
                .filterProductServiceAgreement +
              '?';
            url += 'perPage=' + query.pageSize;
            url += '&page=' + query.page;
            url +=
              '&sortColumn=' +
              (typeof query.orderBy === 'undefined'
                ? 'id'
                : query.orderBy.field);
            url += '&sortOrder=' + query.orderDirection;
            url += '&search=' + query.search;
            fetch(url, {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
              },
              method: 'POST',
              body: JSON.stringify(filterDto),
            })
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data,
                  page: result.page,
                  totalCount: result.total,
                });
              });
          })
        }
        actions={[
          {
            icon: 'read_more',
            iconProps: { className: 'action-buttons view-button' },
            tooltip: 'View More Details',
            onClick: (event, rowData) =>
              onProductEditHandler(rowData.id, rowData.productId, false),
            hidden: !permissions[PermissionProperties.viewProductAndServices],
          },

          {
            icon: 'edit',
            tooltip: 'Edit Service Agreement',
            iconProps: { className: 'action-buttons edit-button' },
            onClick: (event, rowData) =>
              onProductEditHandler(rowData.id, rowData.productId, true),
            hidden:
              !permissions[PermissionProperties.editProductAndServices] ||
              !isActiveCustomer,
          },

          (rowData) => ({
            icon: 'delete',
            tooltip: 'Delete Service Agreement',
            iconProps: {
              className: 'action-buttons delete-button',
            },
            onClick: (event, rowData) => deleteProductHandler(rowData),
            hidden:
              !permissions[PermissionProperties.deleteProductAndServices] ||
              !isActiveCustomer,
          }),
          {
            icon: () => (
              <div onClick={psrBulkDialogOpenHandler}>
                <Button
                  className={styles.AddProductButton}
                  variant="contained"
                  color="primary"
                >
                  {productServiceAgreementProperties.generatePsr}
                </Button>
              </div>
            ),
            tooltip: productServiceAgreementProperties.generatePsr,
            isFreeAction: true,
            // hidden:
            //   !permissions[PermissionProperties.addProductAndServices] ||
            //   !isAcitveProduct ||
            //   !isActiveCustomer,
          },
          {
            icon: () => (
              <Link
                to={
                  props.id != null
                    ? props.match.url +
                      '/serviceAgreement?productId=' +
                      props.id
                    : props.match.url + '/serviceAgreement'
                }
              >
                <Button
                  className={styles.AddProductButton}
                  variant="contained"
                  color="primary"
                >
                  {productServiceAgreementProperties.addPsa}
                </Button>
              </Link>
            ),
            tooltip: 'Add Product Service Agreement',
            isFreeAction: true,
            // hidden:
            //   !permissions[PermissionProperties.addProductAndServices] ||
            //   !isAcitveProduct ||
            //   !isActiveCustomer,
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          filtering: true,
          maxBodyHeight: '70vh',
          sorting: false,
          search: false,
          headerStyle: {
            fontSize: '0.7rem',
            backgroundColor: '#e6e4df',
            textAlignL: 'center',
          },
          rowStyle: {
            fontSize: '0.6rem',
            fontWeight: 450,
            padding: '5rem',
          },
        }}
        onRowClick={(event, rowData, toggleDetailPanel) =>
          onProductEditHandler(rowData.id, rowData.productId, false)
        }
      />
    );
  };

  const upcomingServiceAgreementListTab = () => {
    return (
      <MaterialTable
        title={
          <div>
            <span className={'grid-title-icon'}>
              <ListAltOutlinedIcon />
            </span>
            <Typography
              variant="h6"
              style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
            >
              {
                productServiceAgreementProperties.upcomingProductServiceAgreements
              }
            </Typography>
          </div>
        }
        tableRef={saUpcomingtableRef}
        columns={getTableColumns('upcoming sa')}
        components={{
          OverlayLoading: () => (
            <div className={styles.loadingOverlay}>
              <Spinner />
            </div>
          ),
        }}
        data={(query) =>
          new Promise((resolve, reject) => {
            const filterDto = buildFilterObject(query);
            if (productId > 0) {
              filterDto['productId'] = productId;
            }
            let url =
              urlProperties.baseUrl +
              urlProperties.productServiceAgreement
                .filterUpcomingProductServiceAgreement +
              '?';
            url += 'perPage=' + query.pageSize;
            url += '&page=' + query.page;
            url +=
              '&sortColumn=' +
              (typeof query.orderBy === 'undefined'
                ? 'id'
                : query.orderBy.field);
            url += '&sortOrder=' + query.orderDirection;
            url += '&search=' + query.search;
            fetch(url, {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
              },
              method: 'POST',
              body: JSON.stringify(filterDto),
            })
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data,
                  page: result.page,
                  totalCount: result.total,
                });
              });
          })
        }
        actions={[
          {
            icon: 'read_more',
            iconProps: { className: 'action-buttons view-button' },
            tooltip: 'View More Details',
            onClick: (event, rowData) =>
              onProductEditHandler(rowData.id, rowData.productId, false, true),
            hidden: !permissions[PermissionProperties.viewProductAndServices],
          },

          {
            icon: 'edit',
            tooltip: 'Edit Service Agreement',
            iconProps: { className: 'action-buttons edit-button' },
            onClick: (event, rowData) =>
              onProductEditHandler(rowData.id, rowData.productId, true, true),
            hidden:
              !permissions[PermissionProperties.editProductAndServices] ||
              !isActiveCustomer,
          },

          (rowData) => ({
            icon: 'delete',
            tooltip: 'Delete Service Agreement',
            iconProps: {
              className: 'action-buttons delete-button',
            },
            onClick: (event, rowData) => deleteProductHandler(rowData),
            hidden:
              !permissions[PermissionProperties.deleteProductAndServices] ||
              !isActiveCustomer,
          }),
          {
            icon: () => (
              <div onClick={saBulkDialogOpenHandler}>
                <Button
                  className={styles.AddProductButton}
                  variant="contained"
                  color="primary"
                  onClick={saBulkDialogOpenHandler}
                >
                  {productServiceAgreementProperties.generateBulkSa}
                </Button>
              </div>
            ),
            tooltip: productServiceAgreementProperties.generateBulkSa,
            isFreeAction: true,
            // hidden:
            //   !permissions[PermissionProperties.addProductAndServices] ||
            //   !isAcitveProduct ||
            //   !isActiveCustomer,
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          filtering: false,
          maxBodyHeight: '70vh',
          sorting: false,
          search: false,
          headerStyle: {
            fontSize: '0.7rem',
            backgroundColor: '#e6e4df',
            textAlignL: 'center',
          },
          rowStyle: {
            fontSize: '0.6rem',
            fontWeight: 450,
            padding: '5rem',
          },
        }}
        onRowClick={(event, rowData, toggleDetailPanel) =>
          onProductEditHandler(rowData.id, rowData.productId, false, true)
        }
      />
    );
  };

  const showListDialog = (productServiceAgreements) => {
    const open =
      productServiceAgreements != null && productServiceAgreements.length > 0;
    return (
      <Dialog
        open={open}
        onClose={showListTableDialogCloseHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ marginTop: '4px' }}>
                <CardBody>
                  <div className={'material-grid-wrapper'}>
                    <MaterialTable
                      title={
                        <div>
                          <span className={'grid-title-icon'}>
                            <ListAltOutlinedIcon />
                          </span>
                          <Typography
                            variant="h6"
                            style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                          >
                            Expiring Product Service Agreements
                          </Typography>
                        </div>
                      }
                      columns={getTableColumns('expiring sa')}
                      components={{
                        OverlayLoading: () => (
                          <div className={styles.loadingOverlay}>
                            <Spinner />
                          </div>
                        ),
                      }}
                      data={productServiceAgreements}
                      actions={[
                        {
                          tooltip:
                            productServiceAgreementProperties.generateBulkSa,
                          icon: () => (
                            <div>
                              <Button
                                className={styles.AddProductButton}
                                variant="contained"
                                color="primary"
                                onClick={createBulkUpcomingPSAHandler}
                              >
                                {
                                  productServiceAgreementProperties.generateBulkSa
                                }
                              </Button>
                            </div>
                          ),
                        },
                      ]}
                      onSelectionChange={(rows) => {
                        setSelectedIdList(rows);
                      }}
                      options={{
                        actionsColumnIndex: -1,
                        filtering: true,
                        maxBodyHeight: '70vh',
                        selection: true,
                        sorting: false,
                        search: false,
                        paging: false,
                        headerStyle: {
                          fontSize: '0.7rem',
                          backgroundColor: '#e6e4df',
                          textAlignL: 'center',
                        },
                        rowStyle: {
                          fontSize: '0.6rem',
                          fontWeight: 450,
                          padding: '5rem',
                        },
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  const bulkGenerateDialog = (type) => {
    return (
      <Dialog
        open={type === 'upcoming' ? isSABulkDialogOpen : isPSRBulkDialogOpen}
        onClose={
          type === 'upcoming'
            ? saBulkDialogCloseHandler
            : psrBulkDialogCloseHandler
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <CustomInput
            inputProps={{
              format: 'MM/DD/yyyy',
              value: dateInputValue,
              label:
                type === 'upcoming'
                  ? 'Upcoming SA unitil'
                  : 'Upcoming PSR unitil',
              initvalue: null,
            }}
            type={'date'}
            formControlProps={{ fullWidth: true }}
            changed={(event, value) => {
              setDateInputValue(event);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            size="sm"
            onClick={
              type === 'upcoming'
                ? saBulkDialogCloseHandler
                : psrBulkDialogCloseHandler
            }
            color="primary"
          >
            {generalProperties.cancel}
          </Button>
          {type === 'upcoming' && (
            <Button
              size="sm"
              onClick={upcomingPSAShowListHandler}
              color="primary"
              autoFocus
            >
              {'Show List'}
            </Button>
          )}
          {type === 'psr' && (
            <Button
              size="sm"
              onClick={generateBulkPSRHandler}
              color="primary"
              autoFocus
            >
              {'Generate PSR'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={
          <div className={styles.loadingOverlay}>
            <Spinner />
          </div>
        }
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              {/* {
                        permissions[PermissionProperties.addProductAndServices] ?
                            <div className={styles.AddProductDiv}>
                              
                            </div> : ""
                    } */}
              <CardBody>
                {/* customer delete confirm dialogBox */}
                <Dialog
                  open={isDeleteDialogOpen}
                  onClose={deleteProductDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {
                        productServiceAgreementProperties.messages.normal
                          .deleteConfirm
                      }
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="sm"
                      onClick={deleteProductDialogCloseHandler}
                      color="primary"
                    >
                      {generalProperties.cancel}
                    </Button>
                    <Button
                      size="sm"
                      onClick={deleteConfirmHandler}
                      color="primary"
                      autoFocus
                    >
                      {generalProperties.confirm}
                    </Button>
                  </DialogActions>
                </Dialog>
                {bulkGenerateDialog('upcoming')}
                {bulkGenerateDialog('psr')}
                {productServiceAgreements != null &&
                  productServiceAgreements.length > 0 &&
                  showListDialog(productServiceAgreements)}
                {/* customer table content */}
                {productId > 0 ? (
                  <div className={'material-grid-wrapper'}>
                    {serviceAgreementListTab()}
                  </div>
                ) : (
                  <div className={'material-grid-wrapper'}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      classes={{
                        indicator: classes.indicator,
                      }}
                      className={classes.tabsWrapper}
                      textColor="primary"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                    >
                      <Tab
                        label={
                          productServiceAgreementProperties.productServiceAgreements
                        }
                        {...a11yProps(0)}
                      />
                      <Tab
                        label={
                          productServiceAgreementProperties.upcomingProductServiceAgreements
                        }
                        {...a11yProps(1)}
                      />
                    </Tabs>
                    <FullWidthTabPanel value={value} index={0}>
                      {serviceAgreementListTab()}
                    </FullWidthTabPanel>
                    <FullWidthTabPanel value={value} index={1}>
                      {upcomingServiceAgreementListTab()}
                    </FullWidthTabPanel>
                  </div>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

// const mapStateToProps = state => {
//     return {
//         custsomerArray: state.customer.customers
//     };
// };

// const mapDispatchToProps = dispatch => {
//     return {
//         getCustomersHandler: (customers) => dispatch(actions.getCustomers(customers))
//     }
// }

export default ProductServiceAgreements;
