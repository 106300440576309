import React, { useEffect, useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import styles from 'assets/jss/material-dashboard-react/views/loginPage';
import LoadingOverlay from 'react-loading-overlay';
import {
  getFeedback,
  updateCustomerFeedBack,
} from 'services/Feedback/FeedbackService';
import { feedbackProperties } from 'Util/Property/FeedbackProperties';
import Rating from '@material-ui/lab/Rating';
import { handleErrorMessage } from 'services/CommonService';
import { Typography } from '@material-ui/core';
import ListAltOutlinedIcon from '@material-ui/icons/MessageOutlined';
import Button from '../../../../components/CustomButtons/Button';
import GeneralProperties from '../../../../Util/Property/GeneralProperties';
import { notyDefault, notyTypes } from '../../../../components/Noty/NotyCustom';
import UrlProperties from '../../../../Util/Property/UrlProperties';
import Spinner from 'components/Spinner/Spinner';

const useStyles = makeStyles(styles);

const CustomerFeedback = (props) => {
  const classes = useStyles();
  const [customerFeedback, setCustomerFeedback] = useState({});

  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    let tkn = 0;
    const query = new URLSearchParams(props.location.search);
    let queryParam = {};
    for (let param of query.entries()) {
      queryParam[param[0]] = param[1];
    }
    setIsEdit(queryParam['isEdit'] === 'true');
    console.log('RRRRRRRRRRRRRRRRRRRR', props.id);
    if (props.id && props.id > 0) {
      tkn = props.id;
    } else if (props.location) {
      if (typeof queryParam['id'] != 'undefined') {
        tkn = queryParam['id'];
      }
    }
    getCustomerFeedBackFromToken(tkn);
  }, []);

  const getCustomerFeedBackFromToken = (tk) => {
    getFeedback(tk)
      .then((result) => {
        if (result.status === 200) {
          setCustomerFeedback(result.data);
          setValue(result.data.rating);
          let formData = { ...feedbackForm };
          let elementData = formData.comments; // get data for form key
          let inputProps = { ...elementData.inputProps }; //  copy input data
          inputProps.value = result.data.comments || '';
          elementData.inputProps = inputProps;
          formData.comments = elementData;
          setFeedbackForm(formData);
        } else {
          handleErrorMessage(
            result.data,
            feedbackProperties.messages.error.loadData
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, feedbackProperties.messages.error.loadData);
      });
  };

  // add login form to function state
  const [feedbackForm, setFeedbackForm] = useState({
    comments: {
      elType: 'textarea',
      label: '',
      inputProps: {
        type: 'text',
        placeholder: 'Type your Comment here',
        value: '',
        initvalue: '',
        rowsMin: 3,
        rowsMax: 20,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
    },
  });

  let formElementArray = [];
  for (let key in feedbackForm) {
    formElementArray.push({
      id: key,
      config: feedbackForm[key],
    });
  }

  const onChangeHandeler = (event, elId) => {
    let formData = { ...feedbackForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = event.target.value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setFeedbackForm(formData);
  };

  const onFeedbackFormSubmit = async (event) => {
    event.preventDefault();
    let method = '';
    let url = '';
    let data = {};

    data['token'] = customerFeedback.token;
    data['ratings'] = value;
    data['comments'] = feedbackForm['comments'].inputProps.value;
    data['status'] = 'RECEIVED';

    method = 'POST';
    url = UrlProperties.feedback.updateCustomerFeedBack;

    setIsLoading(true);
    await updateCustomerFeedBack(method, url, data)
      .then((result) => {
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: feedbackProperties.messages.success.sent,
          });
          setIsLoading(false);
          props.history.goBack();
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: feedbackProperties.messages.error.sent,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: feedbackProperties.messages.error.sent,
        });
        setIsLoading(false);
      });
  };

  const isEditEnabledUser = () => {
    return (
      parseInt(localStorage.getItem('userCustomerId')) ===
        customerFeedback.customerId &&
      customerFeedback.feedbackStatus !== 'RECEIVED'
    );
  };

  let formElement = (
    <React.Fragment>
      {formElementArray.map((element) => {
        return (
          <CustomInput
            key={element.id}
            labelText={element.config.label}
            id={element.id}
            formControlProps={element.config.formControlProps}
            inputProps={{
              ...element.config.inputProps,
              // readOnly={!isEdit && !isEditEnabledUser()}
              readOnly: !isEdit,
            }}
            type={element.config.elType}
            value={element.config.value}
            className={element.config.className}
            changed={(event) => onChangeHandeler(event, element.id)}
          />
        );
      })}
    </React.Fragment>
  );

  const tabelCellStyle = {
    fontWeight: '500',
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <div className="generic-form-container">
                <div className={'generic-form-header-wrapper'}>
                  <div className={'generic-page-title'}>
                    <span className={'generic-page-title-icon'}>
                      <ListAltOutlinedIcon />
                    </span>
                    <Typography className={'generic-header-text'} variant="h6">
                      {feedbackProperties.viewFeedback}
                    </Typography>
                    {/* <Typography variant="body1">customer feedback</Typography> */}
                  </div>
                </div>

                <GridContainer
                  style={{
                    paddingLeft: '40px',
                    paddingRight: '20px',
                  }}
                >
                  <GridItem xs={12} sm={12} md={12}>
                    <LoadingOverlay
                      active={props.isLoading}
                      spinner={<Spinner />}
                      text="Authenticating ..."
                    >
                      {/* <Card
                        style={{
                          minHeight: '30rem',
                          minWidth: '70rem',
                          paddingTop: '20px',
                        }}
                      ></Card> */}
                      <form
                        onSubmit={onFeedbackFormSubmit}
                        className={classes.form}
                      >
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <div className={'feedbackTable'}>
                              <GridContainer
                                style={{
                                  border: '1px solid #333',
                                  padding: '5px 10px !important',
                                }}
                              >
                                <GridItem xs={6} sm={6} md={6}>
                                  <span style={tabelCellStyle}>
                                    Customer Name
                                  </span>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                  <span style={tabelCellStyle}>
                                    {customerFeedback.customerName}
                                  </span>
                                </GridItem>

                                <GridItem xs={6} sm={6} md={6}>
                                  <span style={tabelCellStyle}>
                                    Sub Customer Name
                                  </span>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                  <span style={tabelCellStyle}>
                                    {customerFeedback.subCustomerName}
                                  </span>
                                </GridItem>

                                <GridItem xs={6} sm={6} md={6}>
                                  <span style={tabelCellStyle}>Product</span>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                  <span style={tabelCellStyle}>
                                    {customerFeedback.product}
                                  </span>
                                </GridItem>

                                <GridItem xs={6} sm={6} md={6}>
                                  <span style={tabelCellStyle}>SR ID</span>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                  <span style={tabelCellStyle}>
                                    {customerFeedback.srId}
                                  </span>
                                </GridItem>

                                <GridItem xs={6} sm={6} md={6}>
                                  <span style={tabelCellStyle}>
                                    Overall Rating
                                  </span>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                  <Rating
                                    name="simple-controlled"
                                    value={value}
                                    onChange={(event, newValue) => {
                                      setValue(newValue);
                                    }}
                                    // disabled={!isEdit && !isEditEnabledUser()}
                                    disabled={!isEdit}
                                  />
                                </GridItem>

                                <GridItem xs={6} sm={6} md={6}>
                                  <span style={tabelCellStyle}>Comments</span>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                  <span style={tabelCellStyle}>
                                    {formElement}
                                  </span>
                                </GridItem>
                              </GridContainer>
                            </div>
                          </GridItem>
                          <GridItem xs={10} sm={10} md={12}>
                            {/* {isEdit && isEditEnabledUser() ? ( */}
                            {isEdit ? (
                              <Button
                                style={{
                                  float: 'right',
                                  marginTop: '20px',
                                  marginRight: '0',
                                }}
                                type="submit"
                                disabled={isLoading}
                                color="primary"
                                autoFocus
                              >
                                {GeneralProperties.save}
                              </Button>
                            ) : (
                              ''
                            )}
                          </GridItem>
                          <GridItem xs={2} sm={2} md={2}></GridItem>
                        </GridContainer>
                      </form>
                    </LoadingOverlay>
                  </GridItem>
                </GridContainer>
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default CustomerFeedback;
