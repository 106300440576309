import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios/axios-default';
import checklistProperties from 'Util/Property/CheckListProperties'

export async  function getAllCheckLists(){
    try{
      
        return axios.get(urlProperties.checklist.getCheckLists)
              // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    }catch (error ) {
        console.log(checklistProperties.messages.error.loadAll);
    }

}

/**
 * service request save and update 
 */

export async  function saveChecklist(method, url, data){
    try{
      
        return  await axios({
            method: method,
            url: url,
            data: data
        });
              // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    }catch (error ) {
        console.log(checklistProperties.messages.error.saveData);
    }

}




export async  function getChecklist(id){
    try{
      
        return axios.get(urlProperties.checklist.getChecklist+"/"+id)
              // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    }catch (error ) {
        console.log(checklistProperties.messages.error.loadData);
    }

}


export async  function deleteChecklist(id){
    try{
      
        return axios.delete(urlProperties.checklist.deleteChecklist+"/"+id)
              // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    }catch (error ) {
        console.log(checklistProperties.messages.error.delete);
    }

}


export async function getCheckListsByModelIdAndSRTypeId(modelId, srTypeId, category){
    try{
            return axios.get(urlProperties.checklist.getCheckListsByModelIdAndSRTypeId+"?model_id="+modelId+"&sr_type_id="+srTypeId+"&category="+category)
              // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    }catch (error ) {
        console.log(checklistProperties.messages.error.loadData);
    }

}

export async  function getCategory3(){
    try{
      
        return axios.get(urlProperties.categorylist.category3)
    }catch (error ) {
        console.log(checklistProperties.messages.error.loadData);
    }


}


export async  function AlreadyAvailableChecklist(modelId, srTypeId, category){
    try{
      
        // return axios.get(urlProperties.checklist.AlreadyAvailableChecklist) 
        return axios.get(urlProperties.checklist.AlreadyAvailableChecklist+"?model_id="+modelId+"&sr_type_id="+srTypeId+"&category="+category)
    }catch (error ) {
        // console.log(checklistProperties.messages.error.loadData);
    }


}
