import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import styles from './Kpi.module.css';
import LoadingOverlay from 'react-loading-overlay';
import { Typography } from '@material-ui/core';
import { kpiform } from 'Util/Property/KpiProperties';
import Button from 'components/CustomButtons/Button.js';
import { Form, Field } from 'react-final-form';
import SpeedIcon from '@material-ui/icons/Speed';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import generalProperties from 'Util/Property/GeneralProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import { getKpis, DeleteKpi } from 'services/Kpi/KpiServices';
import KpiTable from './KpiTable';
import { KpiProperties } from 'Util/Property/KpiProperties';
import { getServiceRequestCategoryCall } from 'services/ServiceRequest/serviceRequestCategoryServices';
import PermissionProperties from 'Util/Property/PermissionProperties';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import Spinner from 'components/Spinner/Spinner.js';

const useStyles = makeStyles(styles);

const Kpi = (props) => {
  const [workflowForm, setWorkflowForm] = useState(kpiform());
  const [stageForm, setStageForm] = useState(kpiform());
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isStageView, setIsStageView] = useState(false);
  const [permissions, setPermissions] = useState({});
  const tableRef = useRef(null);
  const [isStageDialogOpen, setIsStageDialogOpen] = useState(false);
  const [stageButton, setStageButton] = useState(generalProperties.add);
  const [loadingCount, setLoadingCount] = useState({
    count: 0,
  });
  const [isLoading, setIsLoadingIcon] = useState(false);
  const [stages, setStages] = useState([]);
  const [isStageAdd, setIsStageAdd] = useState(true);
  const [rowData, setRowData] = useState({});
  const [allRoles, setAllRoles] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [stageNames, setStageNames] = useState([]);
  const [statusNames, setStatusNames] = useState([]);
  const [openKpiTable, setOpenKpiTable] = useState(false);
  const [kpiIDforview, setKpiIDforview] = useState('');
  const [kpiDataforViewAndEdit, setKpiDataForViewAndEdit] = useState([]);
  const [kpiDataForDelete, setKpiDataForDelete] = useState([]);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [getAllKpis, setGetAllKpis] = useState([]);
  const [serviceRequestCategories, setServiceRequestCategories] = useState([]);

  // const testArray = [
  //     {
  //         'f1':'Time',
  //         'f2':'Breakdown' ,
  //         'f3':'Assign' ,
  //         'f4':'Complete',
  //         'f5':'Hrs',
  //         'f6':'-',
  //         'f7':'-',
  //      },
  //      {
  //         'f1':'Time',
  //         'f2':'Service' ,
  //         'f3':'Assign' ,
  //         'f4':'Complete',
  //         'f5':'Hrs',
  //         'f6':'-',
  //         'f7':'-',
  //      },
  //     {
  //         'f1':'Count',
  //         'f2':'Breakdown' ,
  //         'f3':'-' ,
  //         'f4':'Complete',
  //         'f5':'No',
  //         'f6':'-',
  //         'f7':'Day',

  //     },
  //     {
  //         'f1':'Cost',
  //         'f2':'Breakdown' ,
  //         'f3':'-' ,
  //         'f4':'-',
  //         'f5':'Rs',
  //         'f6':'Job',
  //         'f7':'Day',

  //     },
  //     ]

  useEffect(() => {
    getKPIS();
    getServiceRequestCategories();

    let permissionArray = [
      PermissionProperties.addKpi,
      PermissionProperties.viewKpi,
      PermissionProperties.editKpi,
      PermissionProperties.deleteKpi,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  const setIsLoading = (val) => {
    if (val) {
      loadingCount.count += 1;
    } else if (loadingCount.count > 0) {
      loadingCount.count -= 1;
    }

    if (loadingCount.count === 0) {
      setIsLoadingIcon(false);
      let formData = { ...workflowForm };
      setWorkflowForm(formData);
      let formData1 = { ...stageForm };
      setStageForm(formData1);
    } else {
      setIsLoadingIcon(true);
    }
  };

  const getKPIS = () => {
    setIsLoading(true);
    getKpis()
      .then((result) => {
        if (result.status === 200) {
          // console.log("ALL KPIS BY GROUPED.",result.data)
          setGetAllKpis(result.data);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: KpiProperties.messages.error.kpi,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: KpiProperties.messages.error.kpi,
        });
      });
  };

  const DeleteKpiByKpiTypeAndSrTypeID = (kpiType, srTypeId) => {
    setIsLoading(true);
    DeleteKpi(kpiType, srTypeId)
      .then((result) => {
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: KpiProperties.messages.success.delete,
          });
          getKPIS();
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: KpiProperties.messages.error.deleteKpi,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: KpiProperties.messages.error.deleteKpi,
        });
      });
  };

  const getServiceRequestCategories = async () => {
    setIsLoading(true);
    await getServiceRequestCategoryCall()
      .then((result) => {
        if (result.success === true) {
          setServiceRequestCategories(result.data);
          setIsLoading(false);
          // setIsFirstTime(true);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: KpiProperties.messages.error.srCategories,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: KpiProperties.messages.error.srCategories,
        });
      });
  };

  let formElementArray = [];
  for (let key in workflowForm) {
    formElementArray.push({
      id: key,
      config: workflowForm[key],
    });
  }

  const onWorflowSubmit = async (event) => {};

  const stageCloseHander = () => {
    setIsStageDialogOpen(false);
  };

  const onStageDeleteHandler = (rowData) => {
    let dataArr = [];
    dataArr.push({ kpiType: rowData.kpi_type, srType: rowData.sr_category_id });
    setKpiDataForDelete(dataArr);
    setIsDeleteDialogOpen(true);
  };

  const deleteStageCloseHandler = () => {
    setIsDeleteDialogOpen(false);
  };

  const deleteConfirmHandler = () => {
    DeleteKpiByKpiTypeAndSrTypeID(
      kpiDataForDelete[0].kpiType,
      kpiDataForDelete[0].srType
    );
    setIsDeleteDialogOpen(false);
  };

  let stageFormElementArray = [];
  for (let key in stageForm) {
    stageFormElementArray.push({
      id: key,
      config: stageForm[key],
    });
  }

  const onClickhandle = () => {
    setIsFirstTime(true);
    setOpenKpiTable(true);
  };

  const viewMoreClose = () => {
    setOpenKpiTable(false);
    //if need refrech remove if condition here
    if (isEdit === true) {
      getKPIS();
    }

    setIsEdit(false);
    setIsFirstTime(false);
  };

  const ViewKpiHandler = (data, status) => {
    let dataArr = [];
    dataArr.push({ kpiType: data.kpi_type, srType: data.sr_category_id });
    setKpiDataForViewAndEdit(dataArr);
    setKpiIDforview(data.f1);
    setOpenKpiTable(true);
  };

  const EditKpiHandler = (data, status) => {
    let dataArr = [];
    dataArr.push({ kpiType: data.kpi_type, srType: data.sr_category_id });
    setKpiDataForViewAndEdit(dataArr);
    setKpiIDforview(data.f1);
    setOpenKpiTable(true);
    setIsEdit(status);
  };

  return (
    <div>
      {openKpiTable === true ? (
        <KpiTable
          open={openKpiTable}
          viewMoreClose={viewMoreClose}
          title={'KPI Values'}
          kpiID={kpiIDforview}
          kpiData={kpiDataforViewAndEdit}
          isEdit={isEdit}
          isFirstTimeAdd={isFirstTime}
          serviceRequestCategoriesList={serviceRequestCategories}
        />
      ) : (
        ''
      )}
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <Dialog
                open={isDeleteDialogOpen}
                onClose={deleteStageCloseHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Do you want to delete this KPI?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    size="sm"
                    onClick={deleteStageCloseHandler}
                    color="primary"
                  >
                    {generalProperties.cancel}
                  </Button>
                  <Button
                    size="sm"
                    onClick={deleteConfirmHandler}
                    color="primary"
                    autoFocus
                  >
                    {generalProperties.confirm}
                  </Button>
                </DialogActions>
              </Dialog>

              <div className="generic-form-container">
                <Dialog
                  open={isStageDialogOpen}
                  onClose={stageCloseHander}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <Card>
                      <div className={'generic-form-header-wrapper'}>
                        <div className={'generic-page-title'}>
                          <span className={'generic-page-title-icon'}>
                            <ListOutlinedIcon />
                          </span>
                          <Typography
                            className={'generic-header-text'}
                            variant="h6"
                          >
                            Stages
                          </Typography>
                          <Typography variant="body1">
                            Manage stages here.
                          </Typography>
                        </div>
                      </div>
                    </Card>
                  </DialogContent>
                </Dialog>

                <Form
                  onSubmit={onWorflowSubmit}
                  validate={(values, ss) => {
                    const errors = {};

                    if (stages.length === 0) {
                      // setEror("first_name", true)
                      errors.stages = generalProperties.emptyField;
                    }
                    return errors;
                  }}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    valid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <div className={'material-grid-wrapper'}>
                              <MaterialTable
                                components={{
                                  OverlayLoading: () => (
                                    <div className={styles.loadingOverlay}>
                                      <Spinner />
                                    </div>
                                  ),
                                }}
                                title={
                                  <div>
                                    <span className={'grid-title-icon'}>
                                      <SpeedIcon
                                        style={{ fontSize: '1.2rem' }}
                                      />
                                    </span>
                                    <Typography
                                      variant="h6"
                                      style={{
                                        fontSize: '1rem',
                                        marginTop: '-0.7rem',
                                      }}
                                    >
                                      KPI'S
                                    </Typography>
                                    {/* <Typography variant="body1">Manage products here</Typography> */}
                                  </div>
                                }
                                tableRef={tableRef}
                                columns={[
                                  { title: 'KPI Type', field: 'kpi_type' },
                                  {
                                    title: 'SR Type',
                                    field: 'sr_category_Name',
                                  },
                                  { title: 'Unit', field: 'kpi_unit' },
                                  // { title: 'Period', field: 'period'},
                                ]}
                                data={
                                  getAllKpis
                                  // testArray
                                }
                                actions={[
                                  {
                                    icon: 'visibility',
                                    tooltip: 'View KPI',
                                    iconProps: {
                                      className: 'action-buttons view-button',
                                    },
                                    tooltip: 'View More Details',
                                    onClick: (event, rowData) =>
                                      ViewKpiHandler(rowData, false),
                                    hidden: !permissions[
                                      PermissionProperties.viewKpi
                                    ],
                                  },
                                  {
                                    icon: 'edit',
                                    tooltip: 'Edit KPI',
                                    iconProps: {
                                      className: 'action-buttons edit-button',
                                    },
                                    onClick: (event, rowData) =>
                                      EditKpiHandler(rowData, true),
                                    position: 'row',
                                    hidden: isView,
                                    hidden: !permissions[
                                      PermissionProperties.editKpi
                                    ],
                                  },

                                  {
                                    icon: 'delete',
                                    tooltip: 'Delete KPI',
                                    iconProps: {
                                      className: 'action-buttons delete-button',
                                    },
                                    onClick: (event, rowData) =>
                                      onStageDeleteHandler(rowData),
                                    position: 'row',
                                    hidden: isView,
                                    hidden: !permissions[
                                      PermissionProperties.deleteKpi
                                    ],
                                  },
                                  {
                                    icon: () => (
                                      <div
                                        className={
                                          'generic-button-class div-button-wrapper'
                                        }
                                        variant="contained"
                                        color="primary"
                                      >
                                        Add New KPI
                                      </div>
                                    ),
                                    tooltip: 'Add New KPI',
                                    isFreeAction: true,
                                    onClick: (event, rowData) =>
                                      onClickhandle(),
                                    hidden: isView,
                                    hidden: !permissions[
                                      PermissionProperties.addKpi
                                    ],
                                  },
                                ]}
                                options={{
                                  actionsColumnIndex: -1,
                                  filtering: true,
                                  sorting: false,
                                  search: false,
                                  pageSizeOptions: [5, 10, 20, 50],
                                  maxBodyHeight: '70vh',
                                  headerStyle: {
                                    fontSize: '0.7rem',
                                    textAlignL: 'center',
                                  },
                                  rowStyle: {
                                    fontSize: '0.6rem',
                                    fontWeight: 450,
                                    padding: '5rem',
                                  },
                                }}
                                // onRowClick={(event, rowData, toggleDetailPanel) => onStageViewHandler(rowData, true)}
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </form>
                  )}
                />
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default Kpi;
