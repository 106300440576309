import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './RepImagesStyle';
import cat1 from 'assets/img/tileCategories/cat1.JPG';
import cat2 from 'assets/img/tileCategories/cat2.JPG';
import cat3 from 'assets/img/tileCategories/cat3 new.jpg';
import cat4 from 'assets/img/tileCategories/cat4 new.jpg';
// import costPerJob from "assets/img/charts/d2.jpg";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Grid, Typography } from '@material-ui/core';
import ColorSketch from 'components/ColorSketch/ColorSketch';

const useStyles = makeStyles(styles);

export default function RepImages(props) {
  const classes = useStyles();
  const [selectedType, setSelectrType] = useState(props.selectedType);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('Category type', e.target.value);
    setSelectrType(e.target.value);
    // props.getselectedCategory = (e.target.value);
    // props.getSelectedType(e.target.value);
  };

  useEffect(() => {
    // setSelectrType(props.selectedType);
    props.getSelectedType(selectedType);
  }, [selectedType]);

  useEffect(() => {
    setSelectrType(props.selectedType);
  }, [props.catchChange]);

  return (
    <div>
      <Typography
        style={{
          marginTop: '2rem',
          marginLeft: '1rem',
          marginBottom: '0.8rem',
        }}
        className={'generic-header-text'}
      >
        {'Select Tile Category'}
      </Typography>
      <GridContainer>
        <RadioGroup
          style={{ marginLeft: '1rem' }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={props.selectedType}
          // defaultValue={selectedType}
        >
          <GridItem xs={4} sm={4} md={3}>
            <Paper
              className={`${
                selectedType === 'type1'
                  ? classes.selected
                  : classes.titleMainBox
              }`}
            >
              <label>
                <img src={cat1} className={classes.paper} />
                <FormControlLabel
                  value="type1"
                  control={<Radio />}
                  label="Type 1"
                  className={classes.radio}
                  onChange={(event) => handleChange(event)}
                />
              </label>
            </Paper>
          </GridItem>
          <GridItem xs={4} sm={4} md={3}>
            <Paper
              className={`${
                selectedType === 'type2'
                  ? classes.selected
                  : classes.titleMainBox
              }`}
            >
              <label>
                <img src={cat2} className={classes.paper} />
                <FormControlLabel
                  value="type2"
                  control={<Radio />}
                  label="Type 2"
                  className={classes.radio}
                  onChange={(event) => handleChange(event)}
                />
              </label>
            </Paper>
          </GridItem>
          <GridItem xs={4} sm={4} md={3}>
            <Paper
              className={`${
                selectedType === 'type3'
                  ? classes.selected
                  : classes.titleMainBox
              }`}
            >
              <label>
                <img src={cat3} className={classes.paper} />
                <FormControlLabel
                  value="type3"
                  control={<Radio />}
                  label="Type 3"
                  className={classes.radio}
                  onChange={(event) => handleChange(event)}
                />
              </label>
            </Paper>
          </GridItem>
          <GridItem xs={4} sm={4} md={3}>
            <Paper
              className={`${
                selectedType === 'type4'
                  ? classes.selected
                  : classes.titleMainBox
              }`}
            >
              <label>
                <img
                  src={cat4}
                  className={classes.paper}
                  style={{ height: '8.5rem' }}
                />
                <FormControlLabel
                  value="type4"
                  control={<Radio />}
                  label="Type 4"
                  className={classes.radio}
                  onChange={(event) => handleChange(event)}
                />
              </label>
            </Paper>
          </GridItem>
        </RadioGroup>
      </GridContainer>
    </div>
  );
}
