import React, { useState, useEffect, useMemo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dropzone from "react-dropzone";
import axios from "../../../axios/axios-default";
import { notyDefault, notyTypes } from "../../../components/Noty/NotyCustom";
import readXlsxFile from "read-excel-file";
import { Typography } from "@material-ui/core";
import { customerProperties } from "Util/Property/CustomerPorerties";
import * as moment from "moment";
import writeXlsxFile from "write-excel-file";
import UrlProperties from "Util/Property/UrlProperties";
import LoadingOverlay from "react-loading-overlay";
import MaterialTable from "material-table";
import Lottie from "react-lottie";
import animationData from "../../../assets/onsiteAnimation.json";
import ReportView3 from "./ReportView3";
import { title } from "assets/jss/material-dashboard-react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import reportsProperties, {
  requiredFormData,
} from "Util/Property/OpeReportProperty";
import { getCustomerForm } from "../../../Util/Property/CustomerPorerties";

import ExportIcon from "../../../assets/img/icons/export.svg";
import "./Reports.css";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function ReportView2(props) {
  const {
    open,
    fileUploadClose,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    url,
    uploadLoacation,
    acceptFileType,
    type,
    tableData,
    startDate,
    endDate,
  } = props;
  const [file, setFile] = React.useState([]);

  const [error, setError] = React.useState("");
  const [hasError, setHasError] = React.useState(false);
  const [dataArray, setDataArray] = React.useState([]);
  const [openSecondPreview, setOpenSecondPreview] = useState(false);
  const [newFile, setNewFile] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openFileUpload3, setOpenFileUpload3] = useState(false);
  const [subTableData = [], setSubTableData] = useState();
  const [tableCols = [], setTableCols] = useState([]);
  const [spreedSheetData = [], setSpreedSheetData] = useState([]);

  // const [handleClose, setHandleClose] = useState(false);

  const baseUrl = UrlProperties.baseUrl.split(":8089")[0];
  const current = new Date();
  const date = `${current.getDate()}-${current.getMonth() +
    1}-${current.getFullYear()}`;

  const handleClose = () => {
    // setFile([]);
    fileUploadClose();
  };
  const fileUploadClose2 = () => {
    setOpenFileUpload3(false);
  };

  const rowRender = (rowData, key) => {
    if (key === "age") {
      return (
        <div
          style={{
            // padding: '0.5rem',
            background:
              rowData["kpi"].value != null && rowData["age"].value != null
                ? rowData["kpi"].value > rowData["age"].value
                  ? "#92D050"
                  : rowData["kpi"].value < rowData["age"].value
                  ? "#FF3300"
                  : rowData["kpi"].value === rowData["age"].value
                  ? "#FFC000"
                  : null
                : null,
          }}
        >
          {rowData["age"].value}
        </div>
      );
    }
    return (
      <div
        style={{
          padding: "0.5rem",
        }}
      >
        {key === "province"
          ? getProvince(rowData[key].value)
          : rowData[key].value}
      </div>
    );
  };
  useEffect(() => {
    const colsArr = [];
    if (tableData.length !== 0) {
      Object.entries(tableData[0]).forEach(([key, value]) => {
        const _val = reportsProperties.reqFieldsMap[key];
        if (typeof _val !== "undefined") {
          return colsArr.push({
            id: tableData[0][key].id,
            title: reportsProperties.reqFieldsMap[key]["table"],
            field: key,
            headerStyle: {
              backgroundColor: "#e6e4df",
              // padding: '5px',
              textAlign: "center",
            },
            cellStyle: {
              textAlign: "center",
              margin: "0",
              padding: "0",
            },
            render: (rowData) => {
              return rowRender(rowData, key);
            },
          });
        }
      });
    }
    colsArr.sort(function(a, b) {
      return a.id - b.id;
    });
    const sDataArr = [];
    const sData = exportTable(tableData);
    sDataArr.push(sData);
    setSpreedSheetData(sDataArr);
    setTableCols(colsArr);
  }, []);

  const getProvince = (key) => {
    const obj = getCustomerForm().province.inputProps.options;
    const index = obj.findIndex((el) => el.value === key);
    if (obj[index] !== undefined) {
      return obj[index].displayValue;
    }
  };

  const borders = {
    top: { style: "thin" },
    bottom: { style: "thin" },
    left: { style: "thin" },
    right: { style: "thin" },
  };

  const alignment = {
    horizontal: "center",
  };

  const exportTable = (tableData) => {
    const columnsArr = [];
    const dataArr = [];
    tableData.forEach((obj, id) => {
      const arr = [];

      let sortedObj = Object.entries(obj).sort((a, b) => a[1].id - b[1].id);
      sortedObj.forEach((el) => {
        const val = reportsProperties.reqFieldsMap[el[0]];

        if (val !== undefined) {
          if (
            columnsArr.filter((__el) => __el.title === val["table"]).length ===
            0
          ) {
            columnsArr.push({
              title: val["table"],
              style: {
                alignment: { ...alignment, wrapText: true, vertical: "top" },
                border: borders,
                font: { sz: "12", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "ceedf2" } },
              },
            });
          }

          let style;
          if (el[0] === "age") {
            if (obj["kpi"].value !== null && el[1].value !== null) {
              if (obj["kpi"].value > el[1].value) {
                style = {
                  fill: { patternType: "solid", fgColor: { rgb: "92D050" } },
                };
              } else if (obj["kpi"].value < el[1].value) {
                style = {
                  fill: { patternType: "solid", fgColor: { rgb: "FF3300" } },
                };
              } else if (obj["kpi"].value === el[1].value) {
                style = {
                  fill: { patternType: "solid", fgColor: { rgb: "FFC000" } },
                };
              }
            }
          }
          arr.push({
            value: el[1].value !== null ? el[1].value : "",
            style: style
              ? { ...style, alignment: alignment, border: borders }
              : { alignment: alignment, border: borders },
          });
        }
      });

      dataArr.push(arr);
    });

    return {
      columns: columnsArr,
      data: dataArr,
    };
  };

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "140px",
    width: "400px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#B22900",
    borderStyle: "dashed",
    backgroundColor: "#ffcec7",
    color: "#f73123",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );
  const getKeyByValue = (obj, value) => {
    const key = Object.keys(obj).find((key) => obj[key].req === value);
    return key;
  };

  const assignTableData = (data) => {
    const tableArr = [];
    var num = 1;
    const fieldKeys = reportsProperties.reqFieldsMap;

    data.forEach((record) => {
      var obj = {};
      Object.assign(obj, {
        num: {
          id: "1",
          value: num,
        },
      });
      if (record["fourth_table"] !== undefined) {
        Object.assign(obj, {
          subTableData: record["fourth_table"],
        });
      }
      var keys = Object.keys(record);
      const reportName = type.value;
      const tableFields = requiredFormData.find((ob) => ob.value === reportName)
        .tableFields.table3;

      keys.forEach((field) => {
        const key = getKeyByValue(fieldKeys, field);
        if (key !== undefined) {
          const fieldObj = tableFields.find((ob) => ob.field === key);
          if (fieldObj != null) {
            obj[key] = {
              id: fieldObj.id,
              value:
                record[field] == null
                  ? "-"
                  : key === "completedPercentage" || key === "percentage"
                  ? `${record[field]}%`
                  : record[field],
            };
          }
        }
      });

      tableArr.push(obj);
      num++;
    });
    return tableArr;
  };

  return (
    <div>
      {openFileUpload3 ? (
        <ReportView3
          open={openFileUpload3}
          type={type}
          fileUploadClose={fileUploadClose2}
          tableData={subTableData}
          startDate={startDate}
          endDate={endDate}
        />
      ) : (
        <div></div>
      )}

      <Dialog
        maxWidth={
          tableCols.length <= 5 ? "sm" : tableCols.length <= 9 ? "md" : "lg"
        }
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <LoadingOverlay active={isLoading} spinner text="Loading ...">
          <DialogContent>
            <div className={"align-title"}>
              <div style={{ width: "10px", height: "10px" }}></div>
              <b>
                From: {`${startDate}     `} To: {endDate}
              </b>
              {spreedSheetData.length !== 0 ? (
                <div>
                  <ExcelFile
                    filename={`${type.displayValue}-${date}-p2`}
                    element={
                      <button className="export-button">
                        <img
                          src={ExportIcon}
                          alt="test"
                          className="export-icon"
                        />
                      </button>
                    }
                  >
                    <ExcelSheet dataSet={spreedSheetData} name="Organization" />
                  </ExcelFile>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className={"material-grid-wrapper"}>
                  <MaterialTable
                    title={
                      <div>
                        <Typography
                          variant="h6"
                          style={{ fontSize: "1rem", marginTop: "-0.7rem" }}
                        >
                          {type.displayValue}
                        </Typography>
                      </div>
                    }
                    columns={tableCols}
                    data={tableData}
                    options={{
                      actionsColumnIndex: -1,
                      // selection: true,
                      exportButton: false,
                      selectionProps: (rowData) => {},
                      pageSize: 10,
                      sorting: false,
                      search: false,
                      padding: "dense",
                      maxBodyHeight: "40vh",
                      headerStyle: {
                        fontSize: "0.7rem",
                        textAlignL: "center",
                      },
                      rowStyle: {
                        fontSize: "0.6rem",
                        fontWeight: 450,
                        lineHeight: 100,
                        // padding: '5rem',
                      },
                    }}
                    // options={{
                    //   exportButton: true
                    // }}
                    onRowClick={(event, rowData, toggleDetailPanel) => {
                      //setType1(this.type)

                      const data = assignTableData(rowData.subTableData);
                      setSubTableData(data);

                      setOpenFileUpload3(true);
                      // setOpenFileUpload2(true);
                    }}
                  />{" "}
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            {/* {uploadLoacation === "customer" ? 
                            <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
                                <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>         
                            </Button>	
                        :""}																														 */}

            <Button
              className={"generic-button-class div-button-wrapper"}
              onClick={handleClose}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>

            {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
                            Uplaod
                        </Button> */}
          </DialogActions>
        </LoadingOverlay>
      </Dialog>
    </div>
  );
  // return (
  //   <div>
  //     <ReportView3
  //       open={openFileUpload3}
  //       type={type}
  //       fileUploadClose={fileUploadClose2}
  //       tableData={subTableData}
  //     />
  //     <Dialog
  //       maxWidth="xl"
  //       fullWidth={true}
  //       open={open}
  //       onClose={handleClose}
  //       aria-labelledby="alert-dialog-title"
  //       aria-describedby="alert-dialog-description"
  //     >
  //       <LoadingOverlay active={isLoading} spinner text="Loading ...">
  //         <DialogContent>
  //           <b>
  //             <center>From:2022-06-11 To:2022-07-02</center>
  //           </b>
  //           {type == 'ON_SITE_TECHNICIANS_SUMMARY_REPORT' ? (
  //             <GridContainer>
  //               <GridItem xs={12} sm={12} md={12}>
  //                 <div className={'material-grid-wrapper'}>
  //                   <MaterialTable
  //                     title={
  //                       <div>
  //                         <Typography
  //                           variant="h6"
  //                           style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
  //                         >
  //                           On Site Field Agent Report
  //                         </Typography>
  //                       </div>
  //                     }
  //                     columns={[
  //                       {
  //                         title: '#',
  //                         field: 'num',
  //                         headerStyle: {
  //                           width: '10%',
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'SR No',
  //                         field: 'srno',
  //                         width: '5%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Priority',
  //                         field: 'priority',
  //                         width: '5%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Customer',
  //                         width: '15%',
  //                         field: 'customer',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Province',
  //                         width: '3%',
  //                         field: 'province',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Product',
  //                         width: '15%',
  //                         field: 'product',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'SA Status',
  //                         width: '14%',
  //                         field: 'sastatus',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Current Stage',
  //                         width: '12%',
  //                         field: 'currentstage',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Last Attended',
  //                         width: '5%',
  //                         field: 'lastattendedby',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Start Date',
  //                         field: 'startdate',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Last Updated',
  //                         width: '14%',
  //                         field: 'lastupdated',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Age(Days)',
  //                         field: 'age',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                         render: (rowData) => (
  //                           <div
  //                             style={{
  //                               width: '100%',
  //                               marginLeft: '20%',
  //                               background:
  //                                 rowData['age'] < rowData['kpi']
  //                                   ? '#92D050'
  //                                   : rowData['age'] > rowData['kpi']
  //                                   ? ' #FF3300'
  //                                   : '#FFC000',
  //                             }}
  //                           >
  //                             {rowData['age']}
  //                           </div>
  //                         ),
  //                       },
  //                       {
  //                         title: 'KPI',
  //                         field: 'kpi',
  //                         width: '10%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                     ]}
  //                     data={[
  //                       {
  //                         num: '1',
  //                         srno: '001',
  //                         priority: 'High',
  //                         customer: 'HNB-Dehiwala',
  //                         province: 'Western',
  //                         product: 'Finger scanner',
  //                         sastatus: 'Under SA',
  //                         currentstage: 'Complete',
  //                         lastattendedby: 'Tharaka',
  //                         startdate: '5/1/2022',
  //                         lastupdated: '5/10/2022',
  //                         age: 10,
  //                         kpi: 5,
  //                       },
  //                       {
  //                         num: '2',
  //                         srno: '002',
  //                         priority: 'Low',
  //                         customer: 'COM-Kottawa',
  //                         province: 'Western',
  //                         product: 'Note Counter',
  //                         sastatus: 'Under Warranty',
  //                         currentstage: 'Customer Visit',
  //                         lastattendedby: 'Layantha',
  //                         startdate: '5/2/2022',
  //                         lastupdated: '5/11/2022',
  //                         age: 1,
  //                         kpi: 2,
  //                       },
  //                       {
  //                         num: '3',
  //                         srno: '003',
  //                         priority: 'Mid',
  //                         customer: 'SMI-Gampaha',
  //                         province: 'Western',
  //                         product: 'Photocopier',
  //                         sastatus: 'SA Expired',
  //                         currentstage: 'Inprogress',
  //                         lastattendedby: 'Saman',
  //                         startdate: '5/3/2022',
  //                         lastupdated: '5/12/2022',
  //                         age: 6,
  //                         kpi: 1,
  //                       },
  //                     ]}
  //                     options={{
  //                       actionsColumnIndex: -1,
  //                       // selection: true,
  //                       exportButton: true,
  //                       selectionProps: (rowData) => {
  //                         // const selectable = rowData.assignedTo === null;
  //                         // //NOTE:: tableData object is not in documentation
  //                         // rowData.tableData.disabled = !selectable;
  //                         // return {
  //                         //     disabled: !selectable
  //                         // }
  //                       },
  //                       pageSize: 5,
  //                       // filtering: true,
  //                       //maxBodyHeight:"85vh",
  //                       sorting: false,
  //                       search: false,
  //                       padding: 'dense',
  //                       maxBodyHeight: '70vh',
  //                       headerStyle: {
  //                         fontSize: '0.7rem',
  //                         textAlignL: 'center',
  //                       },
  //                       rowStyle: {
  //                         fontSize: '0.6rem',
  //                         fontWeight: 450,
  //                         padding: '5rem',
  //                       },
  //                     }}
  //                     // options={{
  //                     //   exportButton: true
  //                     // }}
  //                     onRowClick={(event, rowData) => {
  //                       // Get your id from rowData and use with link.
  //                       //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
  //                       //event.stopPropagation();
  //                     }}
  //                   />{' '}
  //                 </div>
  //               </GridItem>
  //             </GridContainer>
  //           ) : type == 'Efficiency_Summary_Time' ? (
  //             <GridContainer>
  //               <GridItem xs={12} sm={12} md={12}>
  //                 <div className={'material-grid-wrapper'}>
  //                   <MaterialTable
  //                     title={
  //                       <div>
  //                         <Typography
  //                           variant="h6"
  //                           style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
  //                         >
  //                           Efficiency Summary - Time
  //                         </Typography>
  //                       </div>
  //                     }
  //                     columns={[
  //                       {
  //                         title: '#',
  //                         field: 'num',
  //                         headerStyle: {
  //                           width: '10%',
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'SR No',
  //                         field: 'srno',
  //                         width: '5%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Priority',
  //                         field: 'priority',
  //                         width: '5%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Customer',
  //                         width: '15%',
  //                         field: 'customer',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Province',
  //                         width: '3%',
  //                         field: 'province',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Product',
  //                         width: '15%',
  //                         field: 'product',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Start Date',
  //                         width: '5%',
  //                         field: 'startdate',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Completed Date',
  //                         width: '5%',
  //                         field: 'completeddate',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Current Stage',
  //                         width: '12%',
  //                         field: 'currentstage',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       //{ title: 'Start Date', field: 'startdate' , headerStyle:{backgroundColor: '#e6e4df',  padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center'}},
  //                       {
  //                         title: 'Last Updated',
  //                         width: '5%',
  //                         field: 'lastupdated',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Age (Days)',
  //                         field: 'age',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                         render: (rowData) => (
  //                           <div
  //                             style={{
  //                               width: '100%',
  //                               marginLeft: '5%',
  //                               background:
  //                                 rowData['age'] < rowData['kpi']
  //                                   ? '#92D050'
  //                                   : rowData['age'] > rowData['kpi']
  //                                   ? ' #FF3300'
  //                                   : '#FFC000',
  //                             }}
  //                           >
  //                             {rowData['age']}
  //                           </div>
  //                         ),
  //                       },
  //                       {
  //                         title: 'KPI',
  //                         field: 'kpi',
  //                         width: '10%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                     ]}
  //                     data={[
  //                       {
  //                         num: 1,
  //                         srno: '001',
  //                         priority: 'High',
  //                         customer: 'HNB-Dehiwala',
  //                         province: 'Western',
  //                         product: 'Note Counter',
  //                         startdate: '26/01/2022',
  //                         completeddate: '03/02/2022',
  //                         currentstage: 'Approval',
  //                         lastupdated: '03/02/2022',
  //                         age: 8,
  //                         kpi: 12,
  //                       },
  //                       {
  //                         num: 2,
  //                         srno: '002',
  //                         priority: 'Mid',
  //                         customer: 'COM-Kottawa',
  //                         province: 'Western',
  //                         product: 'Note Counter',
  //                         startdate: '27/01/2022',
  //                         completeddate: '',
  //                         currentstage: 'Assigned',
  //                         lastupdated: '02/02/2022',
  //                         age: 6,
  //                         kpi: 3,
  //                       },
  //                       {
  //                         num: 3,
  //                         srno: '003',
  //                         priority: 'Low',
  //                         customer: 'SMI-Gampaha',
  //                         province: 'Western',
  //                         product: 'Photocopier',
  //                         startdate: '25/01/2022',
  //                         completeddate: '',
  //                         currentstage: 'Inquiries',
  //                         lastupdated: '26/02/2022',
  //                         age: 1,
  //                         kpi: 1,
  //                       },
  //                     ]}
  //                     options={{
  //                       actionsColumnIndex: -1,
  //                       // selection: true,
  //                       exportButton: true,
  //                       selectionProps: (rowData) => {
  //                         // const selectable = rowData.assignedTo === null;
  //                         // //NOTE:: tableData object is not in documentation
  //                         // rowData.tableData.disabled = !selectable;
  //                         // return {
  //                         //     disabled: !selectable
  //                         // }
  //                       },
  //                       pageSize: 5,
  //                       // filtering: true,
  //                       //maxBodyHeight:"85vh",
  //                       sorting: false,
  //                       search: false,
  //                       padding: 'dense',
  //                       maxBodyHeight: '70vh',
  //                       headerStyle: {
  //                         fontSize: '0.7rem',
  //                         textAlignL: 'center',
  //                       },
  //                       rowStyle: {
  //                         fontSize: '0.6rem',
  //                         fontWeight: 450,
  //                         padding: '5rem',
  //                       },
  //                     }}
  //                     // options={{
  //                     //   exportButton: true
  //                     // }}
  //                     onRowClick={(event, rowData, toggleDetailPanel) => {
  //                       //setType1(this.type)
  //                       setOpenFileUpload3(true);
  //                     }}
  //                   />{' '}
  //                 </div>
  //               </GridItem>
  //             </GridContainer>
  //           ) : type == 'Efficiency_Summary_Count' ? (
  //             <GridContainer>
  //               <GridItem xs={12} sm={12} md={12}>
  //                 <div className={'material-grid-wrapper'}>
  //                   <MaterialTable
  //                     title={
  //                       <div>
  //                         <Typography
  //                           variant="h6"
  //                           style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
  //                         >
  //                           Efficiency Summary - Count
  //                         </Typography>
  //                       </div>
  //                     }
  //                     columns={[
  //                       {
  //                         title: '#',
  //                         field: 'num',
  //                         headerStyle: {
  //                           width: '3%',
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Address',
  //                         field: 'address',
  //                         width: '10%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'KPI',
  //                         field: 'kpi',
  //                         width: '3%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'SR Name',
  //                         width: '5%',
  //                         field: 'srName',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Started Date',
  //                         width: '5%',
  //                         field: 'startedDate',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Priority',
  //                         width: '5%',
  //                         field: 'priority',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Product Name',
  //                         width: '5%',
  //                         field: 'productName',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'SA Status',
  //                         width: '5%',
  //                         field: 'saStatus',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Completed Date',
  //                         width: '8%',
  //                         field: 'completedDate',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'SR Id',
  //                         field: 'srId',
  //                         width: '3%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Province',
  //                         width: '8%',
  //                         field: 'province',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Age',
  //                         width: '3%',
  //                         field: 'age',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Stage',
  //                         field: 'stage',
  //                         width: '8%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'District',
  //                         field: 'district',
  //                         width: '8%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Updated By',
  //                         field: 'updatedBy',
  //                         width: '6%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Customer Name',
  //                         field: 'customerName',
  //                         width: '8%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Updated Date',
  //                         field: 'updatedDate',
  //                         width: '8%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '0px 0px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                     ]}
  //                     data={tableData}
  //                     options={{
  //                       actionsColumnIndex: -1,
  //                       // selection: true,
  //                       exportButton: true,
  //                       selectionProps: (rowData) => {
  //                         // const selectable = rowData.assignedTo === null;
  //                         // //NOTE:: tableData object is not in documentation
  //                         // rowData.tableData.disabled = !selectable;
  //                         // return {
  //                         //     disabled: !selectable
  //                         // }
  //                       },
  //                       pageSize: 5,
  //                       // filtering: true,
  //                       //maxBodyHeight:"85vh",
  //                       sorting: false,
  //                       search: false,
  //                       padding: 'dense',
  //                       maxBodyHeight: '70vh',
  //                       headerStyle: {
  //                         fontSize: '0.7rem',
  //                         textAlignL: 'center',
  //                       },
  //                       rowStyle: {
  //                         fontSize: '0.6rem',
  //                         fontWeight: 450,
  //                         padding: '5rem',
  //                       },
  //                     }}
  //                     // options={{
  //                     //   exportButton: true
  //                     // }}
  //                     onRowClick={(event, rowData, toggleDetailPanel) => {
  //                       //setType1(this.type)

  //                       const data = assignTableData(rowData.subTableData);
  //                       setSubTableData(data);

  //                       setOpenFileUpload3(true);
  //                       // setOpenFileUpload2(true);
  //                     }}
  //                   />{' '}
  //                 </div>
  //               </GridItem>
  //             </GridContainer>
  //           ) : type == 'Average_field_Cost' ? (
  //             <GridContainer>
  //               <GridItem xs={12} sm={12} md={12}>
  //                 <div className={'material-grid-wrapper'}>
  //                   <MaterialTable
  //                     title={
  //                       <div>
  //                         <Typography
  //                           variant="h6"
  //                           style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
  //                         >
  //                           Average field Cost
  //                         </Typography>
  //                       </div>
  //                     }
  //                     columns={[
  //                       {
  //                         title: '#',
  //                         field: 'num',
  //                         width: '5%',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Employee',
  //                         field: 'employee',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Total Field Visits Count',
  //                         field: 'totalfieldvisitscount',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Total Cost Per Field Jobs',
  //                         field: 'totalcostperfieldjobs',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Total Cost Per Travelling',
  //                         field: 'totalcostpertravelling',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Total Cost Per Idling Time',
  //                         field: 'totalcostperidlingtime',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Total Time Cost',
  //                         field: 'totaltimecost',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'AVG Cost Per Field Jobs',
  //                         field: 'avgcostperfieldjobs',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'Total Distance Travelled (KM)',
  //                         field: 'totaldistancetravelled',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'AVG Cost of BA/TA',
  //                         field: 'ba',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'AVG Cost of Meal',
  //                         field: 'meal',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'AVG Cost of Allowances',
  //                         field: 'allowances',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                       {
  //                         title: 'AVG Fixed Cost',
  //                         field: 'fixedcost',
  //                         headerStyle: {
  //                           backgroundColor: '#e6e4df',
  //                           padding: '5px',
  //                           textAlign: 'center',
  //                         },
  //                         cellStyle: { textAlign: 'center' },
  //                       },
  //                     ]}
  //                     data={[
  //                       {
  //                         num: '1',
  //                         employee: 'Kapila',
  //                         totalfieldvisitscount: 20,
  //                         totalcostperfieldjobs: 2700.0,
  //                         totalcostpertravelling: 3000.0,
  //                         totalcostperidlingtime: 1350.0,
  //                         totaltimecost: 7050.0,
  //                         avgcostperfieldjobs: 352.5,
  //                         totaldistancetravelled: 250,
  //                         ba: 150.0,
  //                         meal: 700.0,
  //                         allowances: 470.0,
  //                         fixedcost: 3000.0,
  //                       },
  //                       {
  //                         num: '2',
  //                         employee: 'Saman',
  //                         totalfieldvisitscount: 35,
  //                         totalcostperfieldjobs: 2500.0,
  //                         totalcostpertravelling: 1800.0,
  //                         totalcostperidlingtime: 900.0,
  //                         totaltimecost: 2500.0,
  //                         avgcostperfieldjobs: 148.57,
  //                         totaldistancetravelled: 180,
  //                       },
  //                       {
  //                         num: '3',
  //                         employee: 'Eranga',
  //                         totalfieldvisitscount: 20,
  //                         totalcostperfieldjobs: 7300.0,
  //                         totalcostpertravelling: 5000.0,
  //                         totalcostperidlingtime: 1250.0,
  //                         totaltimecost: 1350.0,
  //                         avgcostperfieldjobs: 677.5,
  //                         totaldistancetravelled: 850,
  //                       },
  //                     ]}
  //                     options={{
  //                       actionsColumnIndex: -1,
  //                       // selection: true,
  //                       exportButton: true,
  //                       selectionProps: (rowData) => {
  //                         // const selectable = rowData.assignedTo === null;
  //                         // //NOTE:: tableData object is not in documentation
  //                         // rowData.tableData.disabled = !selectable;
  //                         // return {
  //                         //     disabled: !selectable
  //                         // }
  //                       },
  //                       pageSize: 5,
  //                       // filtering: true,
  //                       //maxBodyHeight:"85vh",
  //                       sorting: false,
  //                       search: false,
  //                       padding: 'dense',
  //                       maxBodyHeight: '70vh',
  //                       headerStyle: {
  //                         fontSize: '0.7rem',
  //                         textAlignL: 'center',
  //                       },
  //                       rowStyle: {
  //                         fontSize: '0.6rem',
  //                         fontWeight: 450,
  //                         padding: '5rem',
  //                       },
  //                     }}
  //                     // options={{
  //                     //   exportButton: true
  //                     // }}
  //                     onRowClick={(event, rowData, toggleDetailPanel) => {
  //                       //setType1(this.type)
  //                       setOpenFileUpload3(true);
  //                     }}
  //                   />
  //                 </div>
  //               </GridItem>
  //             </GridContainer>
  //           ) : null}
  //         </DialogContent>
  //         <DialogActions>
  //           {/* {uploadLoacation === "customer" ?
  //                           <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
  //                               <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>
  //                           </Button>
  //                       :""}																														 */}

  //           <Button
  //             className={'generic-button-class div-button-wrapper'}
  //             onClick={handleClose}
  //             color="primary"
  //             variant="contained"
  //           >
  //             Cancel
  //           </Button>
  //           {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
  //                           Uplaod
  //                       </Button> */}
  //         </DialogActions>
  //       </LoadingOverlay>
  //     </Dialog>
  //   </div>
  // );
}
