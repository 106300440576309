import React, { useEffect, useState ,useMemo} from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import axios from "axios";
import UrlProperties from 'Util/Property/UrlProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import {userProperties, getUserForm} from 'Util/Property/UserProperties';
import LoadingOverlay from "react-loading-overlay";
import Card from "components/Card/Card";






export default function ImagePreview (props){

    const [images, setImages] = React.useState([]);
    const [imageApi, setImageApi] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { open, fileUploadClose, getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject, url , srID} = props;

    const handleClose = () => {
        fileUploadClose();
        setImageApi(false);
    }

    


    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '400px',
        width: '650px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#B22900',
        borderStyle: 'dashed',
        backgroundColor: '#ffcec7',
        color: '#B22900',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };


    const style = useMemo(() => ({
        ...baseStyle,
    }), []);
 
    useEffect(() => {
        userData(srID);
    
      }, [open]);

      const userData = async (srID) => {
          setIsLoading(true);
        
        await axios.get(`${UrlProperties.baseUrl}getImagesForSrById/${srID}`,
        // await axios.get(`http://cbalanka.com:8089/api/v1/getImagesForSrById/${srID}`,
        {
            headers: {
              "Authorization": 'Bearer ' + localStorage.getItem("token"),
              'Content-Type': 'application/json'
        }}
        ).then(result => {
            
                  let Image = result.data;
                  let name = result.data.user_name;
                //   console.log("image====",Image);
                  if(result.data.length > 0){
                      setImages(
                          result.data.map(img =>({ 
                            original: `data:image/jpeg;base64,${img.base64Img}`,
                            thumbnail: `data:image/jpeg;base64,${img.base64Img}`,
                            originalHeight: 380,
                            originalWidth:500,
                            thumbnailHeight:50,
                            thumbnailWidth:140,
                            // originalTitle:img.title,
                            thumbnailTitle:img.title,
                            description:img.title,
                          }))
                      )
                  }
              // }
              setIsLoading(false);
              setImageApi(true);
          }).catch(error => {
                setIsLoading(false);
                setImageApi(false);
              notyDefault({ type: notyTypes.ERROR, text: userProperties.messages.error.loadData });
          })
      }
  


    return(
        <div>
            
             <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogTitle style={{ color: '#B22900', height: '20px' }} id="alert-dialog-title">{"Images"}</DialogTitle>
                <DialogContent>
                    <Card>
 
                        <LoadingOverlay
                            active={isLoading}
                            spinner
                            text='Loading ...'>
                            {images !== null && images.length > 0 ? 
                                <ImageGallery items={images} infinite={false} showPlayButton={false} slideOnThumbnailOver={true}/>
                                : imageApi=== true?'No Images Available...':'...'}
                        </LoadingOverlay>
                    </Card>
                </DialogContent>
                <DialogActions>

                    <Button className={'generic-button-class div-button-wrapper'} onClick={handleClose} color="primary" variant="contained">
                        Cancel
                    </Button>
           
                </DialogActions>
                
            </Dialog>
            
        </div>
    );
}