import Axios from 'axios';
import urlProperties from 'Util/Property/UrlProperties';
import axios from '../../axios/axios-default';

export async function getActiveBrandList(catId) {
  // alert(catId);
  try {
    // alert(catId);
    return axios.get(
      'getProductBrandsByStatus?categoryId=' + catId + '&active=true'
    );
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
  } catch (error) {
    console.log('Error Calling Brand Service');
  }
}

export async function getAllBrandList(catId) {
  // alert(catId);
  try {
    // alert(catId);
    return axios.get('getProductBrands');
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
  } catch (error) {
    console.log('Error Calling Brand Service');
  }
}
