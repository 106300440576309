import React, { useEffect, useState } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Tree from 'rc-tree';
import axios from 'axios/axios-default'; // need to add default axios
import styles from './UserMangement.module.css';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import RoleAndPermissionsProperties from 'Util/Property/RoleAndPermissionProperties';
import GeneralProperties from 'Util/Property/GeneralProperties';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { Field, Form } from 'react-final-form';
import LoadingOverlay from 'react-loading-overlay';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import Icon from '@material-ui/core/Icon';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import Spinner from 'components/Spinner/Spinner';
import { useTheme } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from 'components/TabPanels/FullWidthTabPanel';

const useStyles = makeStyles((theme) => ({
  indicator: {
    height: "0",
  },
  customTabs: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      width: "inherit",
      justifyContent: "space-around",
      minWidth: "max-content",
    },
  },
}));

const RoleAndPermissions = (props) => {
  const classes = useStyles();
  const IconForRoleTree = ({ selected }) => (
    <span className={styles.roleIconDiv}>
      {' '}
      <Icon className={styles.roleIcon}>person</Icon>{' '}
    </span>
  );

  const [roleTreeDataArray, setRoleTreeDataArray] = useState([]);
  const [actionPermissionTree, setActionPermissionTree] = useState([]);
  const [dataPermissionTree,setDataPermissionTree,] = useState([]);
  const [metaActionPermissions, setMetaActionPermissions] = useState([]);
  const [metaDataPermissions,setMetaDataPermissions,] = useState([]);
  const [selectedRoleData, setSelectedRoleData] = useState({
    selectedRole: [0],
    node: {},
  });
  const [defaultSelectedPermissions, setDefaultSelectedPermissions] = useState(
    []
  );
  const [
    defaultSelectedDataPermissions,
    setDefaultSelectedDataPermissions,
  ] = useState([]);
  const [roleDialogIsOpen, setRoleDialogIsOpen] = useState(false);
  const [roleDialogHeader, setRoleDialogHeader] = useState(
    RoleAndPermissionsProperties.addRole
  );
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialData, setInitialData] = useState({ name: '' });
  const [isDeleleteRoleDialogIsOpen, setIsDeleleteRoleDialogIsOpen] = useState(
    false
  );
  const [myRole, setMyRole] = useState(parseInt(localStorage.getItem('role'))); // this need to be set from props or dynamically
  const [permissions, setPermissions] = useState({});
  const [halfChecked, sethalfChecked] = useState([]);
  const [halfDChecked, sethalfDChecked] = useState([]);

  let userManagementTab = 'userManagementTab';

  const getInitialData = async () => {
    setIsLoading(true);
    await axios
      .get('getInitialData/' + myRole)
      .then((result) => {
        let dfPerm = [];
        let dfDPerm = [];
        if (result.data.defaultSelectedPermissions) {
          result.data.defaultSelectedPermissions.map((perm) => {
            dfPerm.push(perm.id);
          });
        }
        if (result.data.defaultSelectedDataPermissions) {
          result.data.defaultSelectedDataPermissions.map((dPerm) => {
            dfDPerm.push(dPerm.id);
          });
        }
        setDefaultSelectedPermissions(dfPerm);
        setDefaultSelectedDataPermissions(dfDPerm);
        sethalfChecked(dfPerm);
        sethalfDChecked(dfDPerm);
        setRoleTreeDataArray(result.data.roleTree);

        let modifiedPermissionTree = setTreeMetaData(result.data.permissionTree,null)
        setActionPermissionTree(modifiedPermissionTree);
        setMetaActionPermissions(convertTreeToArray(modifiedPermissionTree))

        let modifiedDataPermissionTree = setTreeMetaData(result.data.dataPermissionTree,null)
        setDataPermissionTree(modifiedDataPermissionTree);
        setMetaDataPermissions(convertTreeToArray(modifiedDataPermissionTree))

        setSelectedRoleData({
          selectedRole: [myRole],
          node: {},
        });
      })
      .catch((error) => {
        // notyDefault({type:notyTypes.ERROR, text:customerProperties.messages.error.deleteCustomer});
      });
    let permissionArray = [
      PermissionProperties.editRoleAndPermissions,
      PermissionProperties.addRoleAndPermissions,
      PermissionProperties.deleteRoleAndPermissions,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    let tab = localStorage.getItem(userManagementTab);
    if (typeof tab !== 'undefined' && tab !== null) {
      setValue(parseInt(tab));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const roleOnSelectHandler = async (selectedKeys, info, t, tt, ttt) => {
    if (selectedKeys.length === 0) {
      return;
    }
    setSelectedRoleData({
      selectedRole: selectedKeys,
      node: info.node,
    });
    setIsLoading(true);
    await axios
      .get('getLeafPermissions/' + selectedKeys[0])
      .then((result) => {
        if (result.status === 200) {
          let dfPerm = [];
          let dfDPerm = [];
          if (result.data.defaultSelectedPermissions.length > 0) {
            if (result.data.defaultSelectedPermissions) {
              result.data.defaultSelectedPermissions.map((perm) => {
                dfPerm.push(perm.id);
              });
            }
          }
          if (result.data.defaultSelectedDataPermissions.length > 0) {
            if (result.data.defaultSelectedDataPermissions) {
              result.data.defaultSelectedDataPermissions.map((dPerm) => {
                dfDPerm.push(dPerm.id);
              });
            }
          }
          setDefaultSelectedPermissions(dfPerm);
          sethalfChecked(dfPerm);
          setDefaultSelectedDataPermissions(dfDPerm);
          sethalfDChecked(dfDPerm);
          console.table({defaultSelectedPermissions : dfPerm.length , defaultSelectedDataPermissions : dfDPerm.length});
        } else {
          setIsLoading(false);
          notyDefault({ type: notyTypes.ERROR, text: result.data });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        let details = error.response.data;
        if (typeof details !== 'undefined') {
          notyDefault({
            type: notyTypes.ERROR,
            text:
              typeof details.details !== 'undefined'
                ? details.details
                : RoleAndPermissionsProperties.messages.error
                    .loadPermissionData,
          });
        }
      });

    setIsLoading(false);
  };

  const permissionOnSelectHandler = (selectedKeys, info) => {
    setDefaultSelectedPermissions([...selectedKeys, ...info.halfCheckedKeys]);
    sethalfChecked(selectedKeys);
  };

  const dataPermissionOnSelectHandler = (selectedKeys, info) => {
    setDefaultSelectedDataPermissions([...selectedKeys, ...info.halfCheckedKeys]);
    sethalfDChecked(selectedKeys);
  };

  const addRoleHandler = () => {
    setInitialData({ name: '' });
    setRoleDialogHeader(RoleAndPermissionsProperties.addRole);
    setRoleDialogIsOpen(true);
    setIsEdit(false);
  };

  const modifyRoleHandler = async () => {
    setInitialData({ name: selectedRoleData.node.title });
    setRoleDialogHeader(RoleAndPermissionsProperties.editRole);
    setRoleDialogIsOpen(true);
    setIsEdit(true);
  };

  const deleteRoleHandler = () => {
    setIsDeleleteRoleDialogIsOpen(true);
  };

  const deleteRoleConfirm = async () => {
    setIsLoading(true);
    await axios
      .delete(
        'deleteRole/' + selectedRoleData.selectedRole + '?userRoleId=' + myRole
      )
      .then((result) => {
        if (result.status === 200) {
          setSelectedRoleData({
            selectedRole: [myRole],
            node: {},
          });
          setRoleTreeDataArray(result.data);
          setIsDeleleteRoleDialogIsOpen(false);
          notyDefault({
            type: notyTypes.SUCCESS,
            text: RoleAndPermissionsProperties.messages.success.deleteRole,
          });
        } else {
          setIsDeleleteRoleDialogIsOpen(false);
          notyDefault({ type: notyTypes.ERROR, text: result.data });
        }
      })
      .catch((error) => {
        setIsDeleleteRoleDialogIsOpen(false);
        let details = error.response.data;
        if (typeof details !== 'undefined') {
          notyDefault({
            type: notyTypes.ERROR,
            text:
              typeof details.details !== 'undefined'
                ? details.details
                : RoleAndPermissionsProperties.messages.error.deleteRole,
          });
        }
      });
    setIsLoading(false);
  };

  const roleDialogCloseHangler = () => {
    setRoleDialogIsOpen(false);
  };

  const onRoleFormSubmit = async (values) => {
    let roleObj = {};
    let method = 'POST';
    let url = 'createRole?userRoleId=' + myRole;
    roleObj = {
      id: 0,
      name: values.name,
      parent_role_id: selectedRoleData.selectedRole[0],
    };
    if (isEdit) {
      url =
        'updateRole/' +
        selectedRoleData.selectedRole[0] +
        '?userRoleId=' +
        myRole;
      roleObj['id'] = selectedRoleData.selectedRole[0];
      roleObj['parent_role_id'] = selectedRoleData.node.parentRoleId;
      method = 'PATCH';
    }
    setIsLoading(true);
    await axios({
      method: method,
      url: url,
      data: roleObj,
    })
      .then((result) => {
        if (result.status === 200) {
          setRoleTreeDataArray(result.data);
          notyDefault({
            type: notyTypes.SUCCESS,
            text: RoleAndPermissionsProperties.messages.success.saveData,
          });
          setRoleDialogIsOpen(false);
          setIsLoading(false);
        } else {
          notyDefault({ type: notyTypes.ERROR, text: result.data });
        }
      })
      .catch((error) => {
        let details = error.response.data;
        if (typeof details !== 'undefined') {
          notyDefault({
            type: notyTypes.ERROR,
            text:
              typeof details.details !== 'undefined'
                ? details.details
                : RoleAndPermissionsProperties.messages.error.saveData,
          });
        }
        setIsLoading(false);
      });
  };

  const simpleMemoize = (fn) => {
    let lastArg;
    let lastResult;
    return (arg) => {
      if (arg !== lastArg) {
        lastArg = arg;
        lastResult = fn(arg);
      }
      return lastResult;
    };
  };

  const usernameAvailable = simpleMemoize(async (value) => {
    if (!value) {
      return GeneralProperties.emptyField;
    }

    let validation = '';
    await axios
      .get('isRoleExists?name=' + value)
      .then((result) => {
        if (result.data.isExist) {
          validation = RoleAndPermissionsProperties.messages.error.nameTaken;
        }
      })
      .catch((error) => {
        // notyDefault({type:notyTypes.ERROR, text:customerProperties.messages.error.deleteCustomer});
      });
    if (validation !== "") {
      return validation;
    }
  });

  const deleteRoleDialogeCloseHandler = () => {
    setIsDeleleteRoleDialogIsOpen(false);
  };

  const setTreeMetaData = (permissionTree,parent)=>{
    for (let permission of permissionTree) {
        permission.isLeaf = false;
        permission.parent = parent;
        if(permission.children.length === 0){
          permission.isLeaf = true;
        }else{
          setTreeMetaData(permission.children,permission.key)
        }
      }
      return permissionTree;
    }

  const convertTreeToArray=(data)=> {
    let result = [];
    function traverse(data) {
      for (let item of data) {
        result.push({ key: item.key, parent: item.parent });
        if (item.children && item.children.length > 0) {
          traverse(item.children);
        }
      }
    }
    traverse(data);
    return result;
  }

  const getMissingActionPermissions = () => {
    let missedActionPerms = [];
    for (let permId of defaultSelectedPermissions) {
      let permission = metaActionPermissions.find((perm) => perm.key === permId);
      while (permission && permission.parent) {
        if (!defaultSelectedPermissions.includes(permission.parent)) {
          if (!missedActionPerms.includes(permission.parent)) {
            missedActionPerms.push(permission.parent);
          }
        }
        permission = metaActionPermissions.find((perm) => perm.key === permission.parent);
      }
    }
    return missedActionPerms;
  }

  const getMissingDataPermissions = () => {
    let missedDataPerms = [];
    for (let permId of defaultSelectedDataPermissions) {
      let permission = metaDataPermissions.find((perm) => perm.key === permId);
      while (permission && permission.parent) {
        if (!defaultSelectedDataPermissions.includes(permission.parent)) {
          if (!missedDataPerms.includes(permission.parent)) {
            missedDataPerms.push(permission.parent);
          }
        }
        permission = metaActionPermissions.find((perm) => perm.key === permission.parent);
      }
    }
    return missedDataPerms;
  }
  
  const onPermissionSaveHandler = async () => {
    setIsLoading(true);
    let permisonaArrayObj = [];
    let missedActionPerms = getMissingActionPermissions();

    let dataPermisonaArrayObj = [];
    let missedDataPerms = getMissingDataPermissions();

    const combinedPermissions = [
      ...defaultSelectedPermissions,
      ...missedActionPerms,
    ];
    const combinedDataPermissions = [
      ...defaultSelectedDataPermissions,
      ...missedDataPerms,
    ];

    combinedPermissions.map((perm) => permisonaArrayObj.push({ id: perm }));
    combinedDataPermissions.map((perm) =>dataPermisonaArrayObj.push({ id: perm }));

    let roleObj = {
      id: selectedRoleData.selectedRole[0],
      name: selectedRoleData.node.title,
      parent_role_id: selectedRoleData.node.parentRoleId,
      permissions: permisonaArrayObj,
      dataPermissions: dataPermisonaArrayObj,
    };

    await axios
      .patch(
        '/updateRole/' +
          selectedRoleData.selectedRole +
          '?userRoleId=' +
          myRole,
        roleObj
      )
      .then((result) => {
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: RoleAndPermissionsProperties.messages.success.permissionSaved,
          });
        } else {
          setIsDeleleteRoleDialogIsOpen(false);
          notyDefault({ type: notyTypes.ERROR, text: result.data });
        }
      })
      .catch((error) => {
        setIsDeleleteRoleDialogIsOpen(false);
        let details = error.response.data;
        if (typeof details !== 'undefined') {
          notyDefault({
            type: notyTypes.ERROR,
            text:
              typeof details.details !== 'undefined'
                ? details.details
                : RoleAndPermissionsProperties.messages.error.permissionSaved,
          });
        }
      });
    setIsLoading(false);
  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem(userManagementTab, newValue);
  };
  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text={GeneralProperties.pleaseWait}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <div className={`material-grid-wrapper`}>
                  <GridContainer>
                    <Grid spacing={3} md={6}>
                      <span className={'grid-title-icon'}>
                        <PersonIcon />
                      </span>
                      <Typography
                        variant="h6"
                        style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                      >
                        Roles and Permissions
                      </Typography>
                    </Grid>
                  </GridContainer>
                </div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <Card>
                      <CardBody>
                        <div className={styles.treeContent}>
                          <div className={styles.headerDiv}>
                            {' '}
                            {RoleAndPermissionsProperties.roles}{' '}
                          </div>
                          {roleTreeDataArray.length > 0 ? (
                            <Tree
                              defaultExpandAll
                              treeData={roleTreeDataArray}
                              onSelect={roleOnSelectHandler}
                              selectedKeys={selectedRoleData.selectedRole}
                              icon={IconForRoleTree}
                            />
                          ) : (
                            <div> </div>
                          )}
                          <Dialog
                            open={isDeleleteRoleDialogIsOpen}
                            onClose={deleteRoleDialogeCloseHandler}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                {
                                  RoleAndPermissionsProperties.messages.normal
                                    .deleteRole
                                }
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                size="sm"
                                onClick={deleteRoleDialogeCloseHandler}
                                color="primary"
                              >
                                {GeneralProperties.cancel}
                              </Button>
                              <Button
                                size="sm"
                                onClick={deleteRoleConfirm}
                                color="primary"
                                autoFocus
                              >
                                {GeneralProperties.confirm}
                              </Button>
                            </DialogActions>
                          </Dialog>

                          <Dialog
                            fullWidth={true}
                            maxWidth="xs"
                            open={roleDialogIsOpen}
                            onClose={roleDialogCloseHangler}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle> {roleDialogHeader} </DialogTitle>
                            <Form
                              onSubmit={onRoleFormSubmit}
                              initialValues={initialData}
                              render={({
                                handleSubmit,
                                reset,
                                submitting,
                                pristine,
                                values,
                                valid,
                                validating,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  <DialogContent>
                                    <Field
                                      name="name"
                                      validate={usernameAvailable}
                                    >
                                      {({ input, meta }) => (
                                        <div style={{ position: 'relative' }}>
                                          <CustomInput
                                            labelText="Name"
                                            id="Name"
                                            inputProps={{
                                              ...input,
                                              width: '80%',
                                            }}
                                            type="input"
                                            value=""
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                          />
                                          {meta.error && meta.touched && (
                                            <span>{meta.error}</span>
                                          )}
                                          {meta.validating && <Spinner />}
                                        </div>
                                      )}
                                    </Field>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      size="sm"
                                      onClick={roleDialogCloseHangler}
                                      color="primary"
                                    >
                                      {GeneralProperties.cancel}
                                    </Button>
                                    <Button
                                      size="sm"
                                      type="submit"
                                      disabled={
                                        submitting ||
                                        validating ||
                                        !valid ||
                                        pristine
                                      }
                                      color="primary"
                                      autoFocus
                                    >
                                      {GeneralProperties.save}
                                    </Button>
                                  </DialogActions>
                                </form>
                              )}
                            />
                          </Dialog>
                        </div>
                      </CardBody>
                      <CardFooter className={styles.actionButtons}>
                        <div>
                          {permissions[
                            PermissionProperties.addRoleAndPermissions
                          ] ? (
                            <Button
                              onClick={addRoleHandler}
                              className="medium-button"
                              variant="contained"
                              color="primary"
                            >
                              {GeneralProperties.add}
                            </Button>
                          ) : (
                            ''
                          )}
                          {permissions[
                            PermissionProperties.editRoleAndPermissions
                          ] ? (
                            <Button
                              onClick={modifyRoleHandler}
                              disabled={selectedRoleData.selectedRole == myRole}
                              className="medium-button"
                              variant="contained"
                              color="primary"
                            >
                              {GeneralProperties.edit}
                            </Button>
                          ) : (
                            ''
                          )}
                          {permissions[
                            PermissionProperties.deleteRoleAndPermissions
                          ] ? (
                            <Button
                              onClick={deleteRoleHandler}
                              disabled={selectedRoleData.selectedRole == myRole}
                              className="medium-button"
                              variant="contained"
                              color="primary"
                            >
                              {GeneralProperties.delete}
                            </Button>
                          ) : (
                            ''
                          )}
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={7}>
                    <Card>
                      <AppBar
                        position="realtive"
                        color="inherit"
                        className={styles.appBarStyle}
                      >
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          classes={{
                            indicator: classes.indicator,
                          }}
                          indicatorColor="none"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="full width tabs example"
                          className={classes.customTabs}
                        >
                          <Tab
                            label={RoleAndPermissionsProperties.permissions}
                            {...a11yProps(0)}
                          />
                          <Tab
                            label={RoleAndPermissionsProperties.dataPermissions}
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </AppBar>
                      <CardBody>
                        <FullWidthTabPanel
                          value={value}
                          index={0}
                          dir={theme.direction}
                        >
                          <div className={styles.treeContent}>

                            {actionPermissionTree.length > 0 ? (
                              <Tree
                                className="myCls"
                                showLine
                                disabled={
                                  selectedRoleData.selectedRole[0] == myRole
                                }
                                checkable={true}
                                selectable={false}
                                onCheck={permissionOnSelectHandler}
                                defaultCheckedKeys={defaultSelectedPermissions}
                                checkedKeys={halfChecked}
                                treeData={actionPermissionTree}
                              />
                            ) : (
                              <div> </div>
                            )}
                          </div>
                        </FullWidthTabPanel>
                        <FullWidthTabPanel
                          value={value}
                          index={1}
                          dir={theme.direction}
                        >
                          <div className={styles.treeContent}>
                            {dataPermissionTree.length > 0 ? (
                              <Tree
                                className="myCls"
                                showLine
                                disabled={
                                  selectedRoleData.selectedRole[0] == myRole
                                }
                                checkable={true}
                                selectable={false}
                                onCheck={dataPermissionOnSelectHandler}
                                defaultCheckedKeys={
                                  defaultSelectedDataPermissions
                                }
                                checkedKeys={halfDChecked}
                                treeData={dataPermissionTree}
                              />
                            ) : (
                              <div> </div>
                            )}
                          </div>
                        </FullWidthTabPanel>
                      </CardBody>
                      <CardFooter className={styles.actionButtons}>
                        {permissions[
                          PermissionProperties.addRoleAndPermissions
                        ] ||
                        permissions[
                          PermissionProperties.editRoleAndPermissions
                        ] ? (
                          <div>
                            <Button
                              disabled={
                                selectedRoleData.selectedRole[0] == myRole
                              }
                              onClick={onPermissionSaveHandler}
                              className="medium-button"
                              size="sm"
                              variant="contained"
                              color="primary"
                            >
                              {GeneralProperties.save}
                            </Button>
                          </div>
                        ) : (
                          ''
                        )}
                      </CardFooter>
                    </Card>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default RoleAndPermissions;
