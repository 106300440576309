import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from 'components/TabPanels/FullWidthTabPanel';
import LoadingOverlay from 'react-loading-overlay';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import styles from './Product.module.css';
import Product from 'views/Products/Product/Product';
import ServiceRequests from 'views/ServiceRequests/ServiceRequests';
import Estimations from 'views/Estimations/Estimations';
import ProductServiceAgreements from 'views/Products/ServiceAgreements/ProductServiceAgreements';

const ProductDetails = (props) => {
  let productDetailsTab = 'productDetailsTab';
  const [productId, setProductId] = useState(0);
  const [permissions, setPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isStateChanged, setIsStateChanged] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    setIsLoading(true);
    let userId;
    const query = new URLSearchParams(props.location.search);
    let queryParam = {};
    for (let param of query.entries()) {
      queryParam[param[0]] = param[1];
    }
    if (typeof queryParam['id'] != 'undefined') {
      userId = queryParam['id'];
      setProductId(userId);
      if (queryParam['isEdit'] === 'true') {
        setIsEdit(true);
      }
      setCustomerId(queryParam['customerId']);
    }
    // this is for to stop Rerendering of UserComponent when viewing user
    setIsStateChanged(true);

    // if (userId > 0) {
    //     getUserData(userId);
    // }
    //TODO
    let permissionArray = [PermissionProperties.passwordChange];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    let tab = localStorage.getItem(productDetailsTab);
    if (typeof tab !== 'undefined' && tab !== null) {
      setValue(parseInt(tab));
    }
    setIsLoading(false);

    console.log('--------------------->, ', customerId);
    console.log('--------------------->, ', productId);
  }, []);

  // const getUserData = (userId) => {
  //     axios.get(UrlProperties.user.getUser + '/' + userId).then(result => {
  //         setUserName(result.data["user_name"]);
  //     }).catch(error => {
  //         notyDefault({ type: notyTypes.ERROR, text: UserProperties.messages.error.loadData });
  //     })
  // }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem(productDetailsTab, newValue);
  };

  // const deleteUserDialogCloseHandler = () => {
  //     setIsDeleteDialogOpen(false);
  // }

  return (
    <div>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <AppBar
                  position="static"
                  color="inherit"
                  className={styles.appBarStyle}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Product Details" {...a11yProps(0)} />

                    <Tab label="Service Agreements" {...a11yProps(1)} />

                    <Tab label="Service Requests" {...a11yProps(2)} />

                    <Tab label="Estimations" {...a11yProps(3)} />
                  </Tabs>
                </AppBar>
                <FullWidthTabPanel
                  value={value}
                  index={0}
                  dir={theme.direction}
                >
                  <Product
                    {...props}
                    id={productId}
                    isEdit={isEdit}
                    customerId={customerId}
                  />
                </FullWidthTabPanel>
                <FullWidthTabPanel
                  value={value}
                  index={1}
                  dir={theme.direction}
                >
                  <ProductServiceAgreements
                    {...props}
                    id={productId}
                    isEdit={isEdit}
                    customerId={customerId}
                  />
                </FullWidthTabPanel>

                <FullWidthTabPanel
                  value={value}
                  index={2}
                  dir={theme.direction}
                >
                  <ServiceRequests
                    {...props}
                    id={productId}
                    isEdit={isEdit}
                    customerId={customerId}
                  />
                </FullWidthTabPanel>

                <FullWidthTabPanel
                  value={value}
                  index={3}
                  dir={theme.direction}
                >
                  <Estimations
                    {...props}
                    id={productId}
                    isEdit={isEdit}
                    customerId={customerId}
                  />
                </FullWidthTabPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default ProductDetails;
