export const GET_ALL_USERS_START = 'GET_ALL_USERS_START';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR';

export const GET_ALL_CLAIM_REPORTS_START = 'GET_ALL_CLAIM_REPORTS_START';
export const GET_ALL_CLAIM_REPORTS_SUCCESS = 'GET_ALL_CLAIM_REPORTS_SUCCESS';
export const GET_ALL_CLAIM_REPORTS_ERROR = 'GET_ALL_CLAIM_REPORTS_ERROR';

export const CREATE_CLAIM_REPORT_START = 'CREATE_CLAIM_REPORT_START';
export const CREATE_CLAIM_REPORT_SUCCESS = 'CREATE_CLAIM_REPORT_SUCCESS';
export const CREATE_CLAIM_REPORT_ERROR = 'CREATE_CLAIM_REPORT_ERROR';

export const GET_CLAIM_REPORT_START = 'GET_CLAIM_REPORT_START';
export const GET_CLAIM_REPORT_SUCCESS = 'GET_CLAIM_REPORT_SUCCESS';
export const GET_CLAIM_REPORT_ERROR = 'GET_CLAIM_REPORT_ERROR';

export const UPDATE_CLAIM_REPORT_START = 'UPDATE_CLAIM_REPORT_START';
export const UPDATE_CLAIM_REPORT_SUCCESS = 'UPDATE_CLAIM_REPORT_SUCCESS';
export const UPDATE_CLAIM_REPORT_ERROR = 'UPDATE_CLAIM_REPORT_ERROR';

export const GET_CLAIM_ROUTE_OPTIONS_START = 'GET_CLAIM_ROUTE_OPTIONS_START';
export const GET_CLAIM_ROUTE_OPTIONS_SUCCESS =
  'GET_CLAIM_ROUTE_OPTIONS_SUCCESS';
export const GET_CLAIM_ROUTE_OPTIONS_ERROR = 'GET_CLAIM_ROUTE_OPTIONS_ERROR';

export const GENEARATE_CLAIM_REPORT_START = 'GENEARATE_CLAIM_REPORT_START';
export const GENEARATE_CLAIM_REPORT_SUCCESS = 'GENEARATE_CLAIM_REPORT_SUCCESS';
export const GENEARATE_CLAIM_REPORT_ERROR = 'GENEARATE_CLAIM_REPORT_ERROR';

export const SEND_CLAIM_REPORT_START = 'SEND_CLAIM_REPORT_START';
export const SEND_CLAIM_REPORT_SUCCESS = 'SEND_CLAIM_REPORT_SUCCESS';
export const SEND_CLAIM_REPORT_ERROR = 'SEND_CLAIM_REPORT_ERROR';
