import React, { useEffect, useState, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import urlProperties from '../../Util/Property/UrlProperties';
import { trainingProperties } from 'Util/Property/TraningProperties';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from 'components/TabPanels/FullWidthTabPanel';
import styles from './Trainings.module.css';
import { buildFilterObject } from '../../Util/Util';
import CustomInput from '../../components/CustomInput/CustomInput';
import ListAltOutlinedIcon from '@material-ui/icons/Build';
import { Typography } from '@material-ui/core';
import Spinner from 'components/Spinner/Spinner';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: '#e6e4df',
    height: '10px',
    top: '45px',
  },
  tabsWrapper: {
    background: '#e6e4df',
  },
}));
const Trainings = (props) => {
  const [permissions, setPermissions] = useState({});
  const classes = useStyles();

  let trainingTab = 'trainingTab';
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem(trainingTab, newValue);
  };

  const tableRef = useRef(null);
  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addTraining,
      PermissionProperties.viewTraining,
      PermissionProperties.editTraining,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    let tab = localStorage.getItem(trainingTab);
    if (typeof tab !== 'undefined' && tab !== null) {
      setValue(parseInt(tab));
    }
  }, []);

  const onTrainingViewHandler = (id, isEdit) => {
    props.history.push({
      pathname: props.match.url + '/training',
      search: '?id=' + id + '&isEdit=' + isEdit,
    });
  };

  const onUserCommentViewHandler = (id, isEdit) => {
    props.history.push({
      pathname: props.match.url + '/usercomment',
      search: '?id=' + id + '&isEdit=' + isEdit,
    });
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {/* {
                        permissions[PermissionProperties.addUser] ?
                            <div className={styles.AddUserDiv}>
                                <Link to={props.match.url + "/user"}>
                                    <Button className={styles.AddUserButton} variant="contained" color="primary">{userProperties.addNewUser}</Button>
                                </Link>
                            </div> : ""
                    } */}
            <CardBody>
              <AppBar
                position="static"
                color="inherit"
                className={styles.appBarStyle}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                  classes={{
                    indicator: classes.indicator,
                  }}
                  className={classes.tabsWrapper}
                >
                  <Tab label="Outbox" {...a11yProps(0)} />

                  <Tab label="Inbox" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              {/* customer table content */}
              <FullWidthTabPanel value={value} index={0} dir={theme.direction}>
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Messages
                        </Typography>
                      </div>
                    }
                    tableRef={tableRef}
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                    columns={[
                      { title: 'Date', field: 'msgCreatedDate' },
                      { title: 'Message Title', field: 'messageTitle' },
                      { title: 'Sent', field: 'sentMessageCount' },
                      { title: 'Delivered', field: 'deliveredMessageCount' },
                      { title: 'Read', field: 'seenMassageCount' },
                    ]}
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        let url =
                          urlProperties.baseUrl +
                          urlProperties.training.filterNotifications +
                          '?';
                        url += 'perPage=' + query.pageSize;
                        url += '&page=' + query.page;
                        url +=
                          '&sortColumn=' +
                          (typeof query.orderBy === 'undefined'
                            ? '-msgCreatedDate'
                            : query.orderBy.field);
                        url += '&sortOrder=' + query.orderDirection;
                        url += '&search=' + query.search;

                        fetch(url, {
                          headers: {
                            Authorization:
                              'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/json',
                          },
                          method: 'POST',
                          body: JSON.stringify(buildFilterObject(query)),
                        })
                          .then((response) => response.json())
                          .then((result) => {
                            result.data.sort(function(a, b) {
                              return (
                                new Date(b.msgCreatedDate) -
                                new Date(a.msgCreatedDate)
                              );
                            });
                            resolve({
                              data: result.data,
                              page: result.page,
                              totalCount: result.total,
                            });
                          });
                      })
                    }
                    actions={[
                      {
                        icon: 'visibility',
                        iconProps: { className: 'action-buttons view-button' },
                        tooltip: 'View More Details',
                        onClick: (event, rowData) =>
                          onTrainingViewHandler(rowData.batchId, false),
                        hidden: !permissions[PermissionProperties.viewTraining],
                      },
                      {
                        icon: () => (
                          <Link to={props.match.url + '/training'}>
                            <div
                              className={
                                'generic-button-class div-button-wrapper'
                              }
                              variant="contained"
                              color="primary"
                            >
                              {trainingProperties.composeMessage}
                            </div>
                          </Link>
                        ),
                        tooltip: 'Compose New Message',
                        isFreeAction: true,
                        hidden: !permissions[PermissionProperties.addTraining],
                      },
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: false,
                      search: false,
                      maxBodyHeight: '50vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        backgroundColor: '#e6e4df',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                    onRowClick={(event, rowData, toggleDetailPanel) =>
                      onTrainingViewHandler(rowData.batchId, false)
                    }
                  />
                </div>
              </FullWidthTabPanel>
              <FullWidthTabPanel value={value} index={1} dir={theme.direction}>
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Messages
                        </Typography>
                      </div>
                    }
                    tableRef={tableRef}
                    columns={[
                      { title: 'Id', field: 'id' },
                      { title: 'Request Type', field: 'requestTypeName' },
                      { title: 'Requested User', field: 'requestedUserName' },
                      { title: 'Title', field: 'userCommentTitle' },
                      {
                        title: 'Status',
                        field: 'requestStatus',
                        filterField: 'request_status_filter',
                      },
                    ]}
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        let url =
                          urlProperties.baseUrl +
                          urlProperties.training.filterUserRequestTrainings +
                          '?';
                        url += 'perPage=' + query.pageSize;
                        url += '&page=' + query.page;
                        url +=
                          '&sortColumn=' +
                          (typeof query.orderBy === 'undefined'
                            ? 'id'
                            : query.orderBy.field);
                        url += '&sortOrder=' + query.orderDirection;
                        url += '&search=' + query.search;
                        fetch(url, {
                          headers: {
                            Authorization:
                              'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/json',
                          },
                          method: 'POST',
                          body: JSON.stringify(buildFilterObject(query)),
                        })
                          .then((response) => response.json())
                          .then((result) => {
                            resolve({
                              data: result.data,
                              page: result.page,
                              totalCount: result.total,
                            });
                          });
                      })
                    }
                    actions={[
                      {
                        icon: 'visibility',
                        iconProps: { className: 'action-buttons view-button' },
                        tooltip: 'View More Details',
                        onClick: (event, rowData) =>
                          onUserCommentViewHandler(rowData.id, false),
                        hidden: !permissions[PermissionProperties.viewTraining],
                      },
                      // {
                      //     icon: 'edit',
                      //     tooltip: 'Edit User Trainings',
                      //     iconProps: { className: 'action-buttons edit-button' },
                      //     onClick: (event, rowData) => onUserCommentViewHandler(rowData.id, true),
                      //     hidden: !permissions[PermissionProperties.editTraining]
                      // }
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: false,
                      search: false,
                      maxBodyHeight: '50vh',
                      headerStyle: {
                        backgroundColor: '#e6e4df',
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                    onRowClick={(event, rowData, toggleDetailPanel) =>
                      onUserCommentViewHandler(rowData.id, false)
                    }
                  />
                </div>
              </FullWidthTabPanel>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Trainings;
