export const feedbackProperties = {
    feedback: "Feedback",
    feedbacks: "Feedback",
    addFeedback: "Add Feedback",
    viewFeedback: "View Feedback",
    messages: {
      error: {
        loadData: "Could not load  Feedback please try again",
        sent: "Could not sent Feedback. please try again",
        delete: "Could not delete Feedback please try again",
        loadAll: "Could not load Feedback please try again",
        loadEmails: "Coud not load user emails",
        loadUsers : "Could not load Users",
      },
      success: {
        sent: "Feedback sent successfully",
        delete: "Feedback deleted successfully"
      },
      normal: {
        deleteConfirm: "Do you want to delete this Feedback",
      }
    }
  }