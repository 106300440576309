
const checkPermissionAvailable = (permissionList)=>{
    let permissions = localStorage.getItem("permissions");
    if(!permissions){
        return false;
    }
    let allPermissionsList = permissions.split(",")
    let isAvailable = false;
    for(let perm in allPermissionsList){
        for(let permToCheck in permissionList){
            isAvailable = allPermissionsList[perm] === permissionList[permToCheck];
            if(isAvailable){
                return isAvailable;
            }
        }
       
    }
    // availablePermission = permissions.split(",").filter( perm => {
    //     let isAvalilable = false;
    //     for(let per in permissionList){
    //         isAvalilable = perm === per
    //     }
    //     return isAvalilable;
    // })
    return isAvailable;
}

const checkPermissoinListAvailable = (permissionList) => {
    let permissions = localStorage.getItem("permissions");
    if(!permissions){
        return {};
    }
    let returnObj = {};
    permissions = permissions.split(",");
    for(let val in permissionList){
       let perm = permissions.filter(perm => permissionList[val] === perm);
       if(perm.length > 0){
           returnObj[permissionList[val]] = true;
       }
    }
   return returnObj;
}

export {
    checkPermissionAvailable,
    checkPermissoinListAvailable
}