import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Styles from './Style/Style';
import moment from 'moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  ResponsiveContainer,
  Label,
} from 'recharts';
import DateRange from './Component/DateRange';
import Frequency from './Component/Frequency';
import Selection from './Component/Selection';
import { getCostLineChart } from 'services/LineChart/LineChartServices';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(Styles);
var averageColor = '#53315E';
var colorArray = [
  '#CD113B',
  '#FE430B',
  '#FFA900',
  '#9900B3',
  '#00B3E6',
  '#4D8066',
  '#3366E6',
  '#B34D4D',
  '#66991A',
  '#E6B333',
  '#999966',
  '#FFFF99',
  '#99FF99',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];

var monthList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export default function LineChartCommon(props) {
  const {
    endPoint,
    serviceRequestCategories,
    products,
    users,
    selection,
    title,
    dataSet,
    highlightsLoading,
  } = props;
  const classes = useStyles();

  const chartDataLoading = false;
  // initialStartDate.setMonth(initialStartDate.getMonth() - 1);

  const [frequency, setFrequency] = React.useState(1);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [selectionData, setSelectionData] = React.useState([]);
  const [legendData, setlegendData] = React.useState([]);
  const [legendDataSelections, setlegendDataSelections] = React.useState([]);
  const [chartStartDate, setChartStartDate] = React.useState(
    new Date(
      moment()
        .startOf('month')
        .toDate()
    )
    // new Date(
    //   moment()
    //     .subtract(1, 'months')
    //     .startOf('month')
    //     .toDate()
    // )
  );
  const [chartEndDate, setChartEndDate] = React.useState(
    // new Date(
    //   moment()
    //     .endOf('month')
    //     .toDate()
    // )
    new Date()
  );
  const [dataSeries, setDataSeries] = React.useState([]);
  const [dataSeriesBydate, setDataSeriesByDate] = React.useState([]);
  const [dataSeriesByFrequency, setDataSeriesByFrequency] = React.useState([]);
  const [lineChartData, setLineChartData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(highlightsLoading);

  const [brushDataSeries, setBrushDataSeries] = React.useState([]);

  useEffect(() => {
    filterByFrequency(frequency);
  }, [dataSeriesBydate]);

  useEffect(() => {
    filterBySelection(selectedValue);
  }, [dataSeriesByFrequency]);

  useEffect(() => {
    filterByFrequency(frequency);
  }, [frequency]);

  useEffect(() => {
    filterBySelection(selectedValue);
  }, [selectedValue.length]);

  useEffect(() => {
    setlegendDataSelections(legendData);
  }, [legendData]);

  useEffect(() => {
    const selected = selectionData.map((obj) => obj.name);
    setSelectedValue(selected);
  }, [selectionData]);

  const onChartDateChange = async (dates) => {
    const [start, end] = dates;
    setChartStartDate(start);
    setChartEndDate(end);
    if (start && end) {
      await getData(start, end);
    }
  };

  const filterBySelection = (selection) => {
    let dataSet = JSON.parse(JSON.stringify(dataSeriesByFrequency));
    if (selection.length > 0) {
      dataSet = dataSet.filter((obj) => {
        return selection.includes(obj.name);
      });
      setupData(dataSet);

      var legendArray = legendData.filter((obj) => {
        return selection.includes(obj.name);
      });

      if (legendArray.length === 0) {
        setlegendDataSelections(legendData);
      } else {
        setlegendDataSelections(legendArray);
      }
    } else {
      setupData([]);
      setlegendDataSelections([]);
    }
  };

  const getSelectionData = (data) => {
    var dataArr = data.map((obj, index) => {
      return { name: obj.name, id: index };
    });
    setSelectionData(dataArr);

    var legendArray = data.map((obj, index) => {
      return { name: obj.name, id: index };
    });
    setlegendData(legendArray);
  };

  const filterByFrequency = (frequency) => {
    switch (frequency) {
      case 1:
        filterByDaily();
        break;
      case 2:
        filterByWeekly();
        break;
      case 3:
        filterByMonthly();
        break;
      case 4:
        filterByYearly();
        break;
      default:
        filterByDaily();
        break;
    }
  };
  const filterByDaily = () => {
    const dataSet = JSON.parse(JSON.stringify(dataSeriesBydate));
    setDataSeriesByFrequency(dataSet);
  };

  const filterByWeekly = () => {
    const dataSet = JSON.parse(JSON.stringify(dataSeriesBydate));

    dataSet.forEach((dataObj) => {
      let count = 0;
      const newData = Object.values(
        dataObj.data.reduce((acc, obj) => {
          const week = obj.week_number;
          if (!acc[week]) {
            acc[week] = { value: 0, category: `Week ${count + 1}` };
            count++;
          }
          acc[week].value += obj.value;
          return acc;
        }, {})
      );
      dataObj.data = newData;
    });

    setDataSeriesByFrequency(dataSet);
  };

  // const getWeekNo = (date) => {
  //   if (date >= 1 && date <= 7) {
  //     return 0;
  //   } else if (date >= 8 && date <= 14) {
  //     return 1;
  //   } else if (date >= 15 && date <= 21) {
  //     return 2;
  //   }
  //   if (date >= 22) {
  //     return 3;
  //   }
  // };
  // const filterByWeekly = () => {
  //   const dataSet = JSON.parse(JSON.stringify(dataSeriesBydate));

  //   dataSet.forEach((dataObj) => {
  //     const newData = Object.values(
  //       dataObj.data.reduce((acc, obj) => {
  //         const date = new Date(obj.category).getDate();
  //         const week = getWeekNo(date);

  //         if (!acc[week]) acc[week] = { value: 0, category: weekList[week] };
  //         acc[week].value += obj.value;
  //         return acc;
  //       }, {})
  //     );

  //     dataObj.data = newData;
  //   });
  //   setDataSeriesByFrequency(dataSet);
  // };

  const filterByMonthly = () => {
    const dataSet = JSON.parse(JSON.stringify(dataSeriesBydate));

    dataSet.forEach((dataObj) => {
      const newData = Object.values(
        dataObj.data.reduce((acc, obj) => {
          const month = new Date(obj.category).getMonth();
          if (!acc[month])
            acc[month] = { value: 0, category: monthList[month] };
          acc[month].value += obj.value;
          return acc;
        }, {})
      );

      dataObj.data = newData;
    });
    setDataSeriesByFrequency(dataSet);
  };

  const filterByYearly = () => {
    const dataSet = JSON.parse(JSON.stringify(dataSeries));

    dataSet.forEach((dataObj) => {
      const newData = Object.values(
        dataObj.data.reduce((acc, obj) => {
          const year = new Date(obj.category).getFullYear();
          if (!acc[year]) acc[year] = { value: 0, category: year };
          acc[year].value += obj.value;
          return acc;
        }, {})
      );

      dataObj.data = newData;
    });
    setDataSeriesByFrequency(dataSet);
  };

  const getData = async (start, end) => {
    setIsLoading(true);
    const property =
      selection == 'SR Type'
        ? 'srType'
        : selection == 'Field Agent'
        ? 'user'
        : '';
    try {
      var startD = new Date(start);
      var endD = new Date(end);
      startD.setDate(startD.getDate() + 1);
      endD.setDate(endD.getDate() + 1);
      const result = await getCostLineChart(
        startD.toISOString().slice(0, 10),
        endD.toISOString().slice(0, 10),
        property
      );
      if (result.status === 200) {
        setDataSeries(result.data);
        getSelectionData(result.data);

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData(chartStartDate, chartEndDate);
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      getData(chartStartDate, chartEndDate);
      console.log('*******************');
      console.log('Updated the Chart!');
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setDataSeriesByDate(dataSeries);
    setupData(dataSeries);
  }, [dataSeries]);

  const setupData = (series) => {
    const dataSet = [];
    const brushDataSet = [];
    series.forEach((el, id) => {
      if (!dataSet[id]) {
        dataSet[id] = {
          name: el.name,
        };
      }
      el.data.forEach((__el, __id) => {
        dataSet[id][__el.category] = __el.value;

        if (!brushDataSet[__id]) {
          brushDataSet[__id] = {
            name: __el.category,
          };
        }
        brushDataSet[__id][el.name] = __el.value;
      });
    });

    setLineChartData(dataSet);
    setBrushDataSeries(brushDataSet);
  };

  const getMaxValue = () => {
    return (
      <div>
        {legendDataSelections.map((val, index) => (
          <Grid
            key={index}
            container
            style={{
              flexDirection: 'row',
              textAlign: 'left',
              marginBottom: '0.5rem',
            }}
          >
            <CheckBoxOutlineBlankIcon
              style={{
                width: '0.5rem',
                height: '0.6rem',
                marginRight: '0.5rem',
                color: getColor(val.name, index),
                backgroundColor: getColor(val.name, index),
              }}
            />
            <Typography
              style={{
                fontSize: '0.6rem',
                fontFamily: 'monospace',
                color: getColor(val.name, index),
                textAlign: 'left',
                fontWeight: 400,
              }}
            >
              {val.name}
            </Typography>
          </Grid>
        ))}
      </div>
    );
  };

  const DataFormater = (number) => {
    if (number >= 10000) {
      return number / 1000 + ' k';
    }
    return number;
  };

  const dataFormaterTooltip = (number) => {
    return 'LKR ' + number;
  };

  const getColor = (name, index) => {
    if (name == 'Average') {
      return averageColor;
    }
    return colorArray[index % colorArray.length];
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.customToolTip}>
          <p
            style={{
              fontSize: '1rem',
              fontFamily: 'monospace',
              textAlign: 'center',
            }}
          >
            {payload[0].payload.name}
          </p>

          {payload.map((option) => (
            <Grid container style={{ minWidth: '15rem' }} key={option.name}>
              <Grid item xs={5} sm={5} md={5}>
                <Typography
                  style={{
                    fontSize: '0.8rem',
                    fontFamily: 'monospace',
                    color: option.color,
                    textAlign: 'left',
                    fontWeight: 600,
                  }}
                >
                  {option.name}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Typography
                  style={{
                    fontSize: '0.8rem',
                    fontFamily: 'monospace',
                    color: option.color,
                    textAlign: 'right',
                    fontWeight: 600,
                  }}
                >
                  LKR {option.value}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Grid container className={classes.titleContiner}>
          <Grid item xs={12} sm={9} md={9}>
            <Typography className={classes.title}>
              {title.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} md={3} style={{ textAlign: 'right' }}>
            <Button size="small" className={classes.viewMoreButton}>
              View More
            </Button>
          </Grid>
        </Grid>
        {isLoading ? (
          <Skeleton
            className={classes.chartContainer}
            variant="rect"
            width={'100%'}
            height={'20rem'}
          />
        ) : (
          <Grid container>
            <Grid item xs={12} sm={9} md={9} className={classes.chartContainer}>
              <ResponsiveContainer width="100%" height="100%">
                {isLoading ? (
                  <></>
                ) : (
                  <LineChart
                    data={brushDataSeries}
                    margin={{
                      top: 5,
                      right: 10,
                      left: -5,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      // interval={0}
                      dataKey="name"
                      allowDuplicatedCategory={false}
                      tick={{ fontSize: 10, fontFamily: 'sans-serif' }}
                    />
                    <YAxis
                      domain={[0, 'dataMax + 500']}
                      tickFormatter={DataFormater}
                      tick={{ fontSize: 10, fontFamily: 'sans-serif' }}
                    />
                    <Tooltip
                      formatter={dataFormaterTooltip}
                      content={<CustomTooltip />}
                    />
                    {lineChartData.map((el, index) => {
                      return (
                        <Line
                          dataKey={el.name}
                          key={el.name}
                          type="monotone"
                          strokeWidth={2}
                          stroke={getColor(lineChartData[index]['name'], index)}
                        />
                      );
                    })}

                    <Brush
                      dataKey="name"
                      data={brushDataSeries}
                      height={30}
                      stroke="#FA3916"
                    />
                  </LineChart>
                )}
              </ResponsiveContainer>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              className={classes.LegendContainer}
            >
              {getMaxValue()}
            </Grid>
          </Grid>
        )}

        <Grid container className={classes.topContainer}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography className={classes.subTitle}>
              Select Date Range
            </Typography>
            <DateRange
              classes={classes}
              chartStartDate={chartStartDate}
              onChartDateChange={onChartDateChange}
              chartEndDate={chartEndDate}
              chartDataLoading={chartDataLoading}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Typography
              style={{ marginBottom: '0.3rem' }}
              className={classes.subTitle}
            >
              Frequency
            </Typography>
            <Frequency frequency={frequency} setFrequency={setFrequency} />
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Typography
              style={{ marginBottom: '0.3rem' }}
              className={classes.subTitle}
            >
              {selection}
            </Typography>
            <Selection
              data={selectionData}
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
