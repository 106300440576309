import * as actionTypes from '../actions/actionTypes';


let initialState = {
    customers: []
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.ADD_CUSTOMER:
            return {
                ...state,
                customers: state.customers.concat(action.customer)
            }
        case actionTypes.GET_CUSTOMERS:
            return {
                ...state,
                customers: action.customers
            }
        default:
            return state;
    }
}

export default reducer;