import * as actionTypes from './actionTypes'


export const  addCustomer = (customer) =>{
    return {
        type: actionTypes.ADD_CUSTOMER,
        customer : customer
    }
}

export const  editCustomer = (customer) =>{
    return {
        type: actionTypes.EDIT_CUSTOMER,
        customer : customer
    }
}

export const  deleteCustomer = (id) =>{
    return {
        type: actionTypes.DELETE_CUSTOMER,
        customerId : id
    }
}

export const  getCustomers = (customers) =>{
    return {
        type: actionTypes.GET_CUSTOMERS,
        customers : customers
    }
}