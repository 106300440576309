import { TrafficRounded } from '@material-ui/icons';
import { transpileModule } from 'typescript';
const emailPattern = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const status = [
  { value: 'NEW', displayValue: 'New' },
  { value: 'ESTIMATION_IN_PROGRESS', displayValue: 'Estimation In Progress' },
  { value: 'TO_BE_INVOICED', displayValue: 'To be Invoiced' },
  { value: 'DISAPPROVED', displayValue: 'Disapproved' },
  { value: 'ESTIMATION_COMPLETED', displayValue: 'Estimation Completed' },
  { value: 'SENT_TO_CUSTOMER', displayValue: 'Sent to Customer' },
  { value: 'INVOICE_ON_HOLD', displayValue: 'Invoice on hold' },
  { value: 'INVOICED', displayValue: 'Invoiced' },
  { value: 'CLOSED', displayValue: 'Closed' },
];
export const stages = [
  { value: 'NEW', displayValue: 'New' },
  { value: 'ESTIMATION_DRAFT', displayValue: 'Estimation Draft' },
  { value: 'PENDING_ERP_UPDATE', displayValue: 'Pending ERP Update' },
  { value: 'ESTIMATION_REVIEW', displayValue: 'Estimation Review' },
  { value: 'UNDER_SA_WARRANTY', displayValue: 'Under SA/ Warranty' },
  { value: 'APPROVED_FOC', displayValue: 'Approved/ FOC' },
  { value: 'PRINT', displayValue: 'Print' },
  { value: 'SENT_TO_CUSTOMER', displayValue: 'Sent to customer' },
  { value: 'CUSTOMER_REJECTED', displayValue: 'Customer Rejected' },
  { value: 'CUSTOMER_APPROVED', displayValue: 'Customer Approved' },
  { value: 'PARTS_NOT_AVAILABLE', displayValue: 'Parts not Available' },
  { value: 'INVOICED', displayValue: 'Invoiced' },
  { value: 'GOODS_ISSUED', displayValue: 'Goods issued' },
  { value: 'PAYMENT_RECEIVED', displayValue: 'Payment Received' },
  {
    value: 'GOODS_RETURNED_INVOICE_CANCELLED',
    displayValue: 'Goods Return/ Invoice Canceled',
  },
  { value: 'PRODUCT_DEACTIVATED', displayValue: 'Product De-Activated' },
];
export const estimationProperties = {
  emailPatern: emailPattern,
  addEstimation: 'Add Estimation',
  estimations: 'Estimations',
  viewEstimation: 'View Estimation',
  editEstimation: 'Edit Estimation',
  addCheckList: 'Add Checklist',
  assign: 'Assign',
  COMMENT_END: 'CBA_COM_END',
  parts: 'Parts',
  createEstimation: 'Please create your estimation',
  manageEstimationHere: 'Manage your estimations here',
  messages: {
    error: {
      loadData: 'Could not load Estimation data please try again',
      saveData: 'Could not save Estimation data please try again',
      delete: 'Could not delete Estimation please try again',
      loadAll: 'Could not load Estimations please try again',
      loadCustomers: 'Could not load Customers',
      loadServiceAgreements: 'Could not load Service Agreements',
      loadProductCategories: 'Could not load product categories',
      loadProductBrands: 'Could not load product brands',
      loadProductModels: 'Could not load product models',
      loadUsers: 'Could not load Users',
      loadEstimations: 'Could not load Estimations',
      loadCheckLists: 'Could not load Check Lists',
      loadProducts: 'Could not load Products',
      assignUser: 'Could not assign user to Estimations',
      confirmEstApproval: 'If you approve this SR will be assigned to you',
      reportGenerate: 'Could not generate Estimation Report!',
    },
    success: {
      saveData: 'Estimation details saved successfully',
      delete: 'Estimation deleted successfully',
      assignUser: 'User assigned successfully',
      removePart: 'Part removed',
    },
    normal: {
      deleteServiceRequest: 'Do you want to delete this Estimation',
      deletePartConfirm: 'Do you want to remove this part',
      deleteConfirm: 'Do you want to delete this Estimation',
    },
  },
  initialValues: {
    name: '',
    customer_id: '',
    request_type: '',
    product_category_id: '',
    product_brand_id: '',
    product_model_id: '',
    serial_number: '123',
    estimation_id: '',
    service_agreement_id: '',
    check_list_id: '',
    estimated_time_hours: 0,
    estimated_time_minutes: 0,
    assign_user_id: '',
    status: '',
    priority: 'MEDIUM',
    comments: '',
    newComment: '',
  },
};

export const getEstimationForm = () => {
  return {
    customer_id: {
      elType: 'select',
      label: 'Customer *',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        tempValue: '',
        customSearchable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
    },
    service_request_id: {
      elType: 'select',
      label: 'Service Request Id',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isFullWidth: false,
    },
    stage_name: {
      elType: 'input',
      label: 'Stage',
      inputProps: {
        type: 'text',
        placeholder: 'Stage',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isFullWidth: false,
      isHide: true,
      isDisabled: true,
      doDisable: true,
    },
    contact_number: {
      elType: 'input',
      label: 'Contact No',
      inputProps: {
        type: 'text',
        placeholder: 'Contact No',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: false,
      size: 6,
    },
    fax_number: {
      elType: 'input',
      label: 'Fax No',
      inputProps: {
        type: 'text',
        placeholder: 'Fax No',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: false,
        error: false,
      },
      isFullWidth: false,
      size: 6,
    },
    status_name: {
      elType: 'input',
      label: 'Status',
      inputProps: {
        type: 'text',
        placeholder: 'status',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isFullWidth: false,
      isHide: true,
      isDisabled: true,
      doDisable: true,
    },
    assign_user_id: {
      elType: 'select',
      label: 'Assign User',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isDisabled: true,
      doDisable: true,
    },
    contact_email: {
      elType: 'input',
      label: 'Email',
      inputProps: {
        type: 'text',
        placeholder: 'Email',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: false,
      size: 6,
    },
    requested_by: {
      elType: 'input',
      label: 'Requested By',
      inputProps: {
        type: 'text',
        placeholder: 'Requested By',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: false,
      size: 6,
    },
    productCategoryId: {
      elType: 'select',
      label: 'Product Category',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      size: 6,
      isAsyncDropDown: true,
      readOnly: true,
    },
    productBrandId: {
      elType: 'select',
      label: 'Product Brand',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      size: 6,
      isAsyncDropDown: true,
      readOnly: true,
    },
    productModelId: {
      elType: 'select',
      label: 'Product Model',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      size: 6,
      isAsyncDropDown: true,
      readOnly: true,
    },
    product_id: {
      elType: 'select',
      label: 'Product',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      size: 6,
      isAsyncDropDown: true,
      readOnly: true,
    },
    createdBy: {
      elType: 'input',
      label: 'Created By',
      inputProps: {
        type: 'text',
        placeholder: 'Created By',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: false,
        error: false,
      },
      isFullWidth: false,
      size: 6,
      isHide: false,
      readOnly: true,
    },
    createdAt: {
      elType: 'input',
      label: 'Created On',
      inputProps: {
        type: 'text',
        placeholder: 'Created On',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: false,
        error: false,
      },
      isFullWidth: false,
      size: 6,
      isHide: false,
      readOnly: true,
    },
    comment: {
      elType: 'text',
      label: 'Comments',
      inputProps: {
        type: 'text',
        placeholder: 'Comments',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: false,
    },
    newComment: {
      elType: 'input',
      label: 'Comment',
      inputProps: {
        type: 'text',
        placeholder: 'Type your comment here',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
    },
    stage_comment_List: {
      elType: 'text',
      label: 'Workflow comments',
      inputProps: {
        type: 'text',
        placeholder: 'Workflow comments',
        value: '',
        initvalue: '',
        rowsMax: 5,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: false,
    },
  };
};

export const getPartForm = () => {
  return {
    part_number: {
      elType: 'input',
      label: 'Part Number',
      inputProps: {
        type: 'text',
        placeholder: 'Part Number',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
      size: 12,
    },
    part_descrition: {
      elType: 'input',
      label: 'Part Descriptions',
      inputProps: {
        type: 'text',
        placeholder: 'Part Description',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      isFullWidth: true,
      size: 12,
    },
    quantity: {
      elType: 'input',
      label: 'Quantity',
      inputProps: {
        type: 'text',
        placeholder: 'Quantity',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
      size: 12,
    },
    taken_from_ware_house: {
      elType: 'input',
      label: 'Taken Form Warehouse',
      inputProps: {
        type: 'text',
        placeholder: 'Taken Form Warehouse',
        value: '',
        initvalue: '',
        disabled: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      isFullWidth: true,
      size: 12,
    },
    return_to_ware_house: {
      elType: 'input',
      label: 'Return to Warehouse',
      inputProps: {
        type: 'text',
        placeholder: '',
        value: '',
        initvalue: '',
        disabled: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      isFullWidth: true,
      size: 12,
    },
    unit_price: {
      elType: 'input',
      label: 'Unit Price',
      inputProps: {
        type: 'text',
        placeholder: 'Unit Price',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
      size: 6,
    },
    total_price: {
      elType: 'input',
      label: 'Total Price',
      inputProps: {
        type: 'text',
        placeholder: '',
        value: '0',
        initvalue: '0',
        readOnly: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
      size: 6,
    },
    availability: {
      elType: 'checkbox',
      label: 'Availability',
      inputProps: {
        type: 'checkbox',
        placeholder: '',
        value: false,
        initvalue: false,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
      size: 12,
    },
    partId: {
      elType: 'input',
      label: 'partId',
      inputProps: {
        type: 'text',
        placeholder: 'part id',
        value: '0',
        initvalue: '0',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
      size: 12,
      isHide: true,
    },
    id: {
      elType: 'input',
      label: 'id',
      inputProps: {
        type: 'text',
        placeholder: 'id',
        value: '0',
        initvalue: '0',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
      size: 12,
      isHide: true,
    },
  };
};
