import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb,
  idlingBlue

} from "assets/jss/material-dashboard-react.js";
import { blue, red } from "@material-ui/core/colors";

const dashboardStyle = {
  successText: {
    color: successColor[0]
  },
  successTextUp: {
    color: successColor[1]
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& a": {
      color: "#7B7B7B"
    }
  },
  cardCategory: {
    color: '#3E3E3E',
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",

  },
  cardCategoryWhite: {
    color: "#007CDA",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    color: '#4AA54E',
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleInfo: {
    color: '#FC9108',
  },
  cardTitleDanger: {
    color: '#E73D3A',
  },
  cardTitleChart: {
    color: '#3E3E3E',
  },
  cardTitleTechnicalIdlling: {
    color: "#007CDA",
    opacity: 1,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleWhite: {
    color: "#007CDA",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  pieChart: {
    '& .ct-label': {
      fill: '#fff',
      fontWeight: 400
    }
  },
  gridTitleText: {
    color: '#A843BA'
  },
  engagementScoreBox: {
    padding: '10px',
    height: "185px",
    color: "white",
    backgroundColor: "#007CDA",
    textAlign: 'center'
  },
  engagementScore: {
    padding: '10px',
    fontWeight: '100',
    marginTop: '44px',
    fontSize: '107px'
  },
  engagementScoreLabel: {
    fontWeight: '100',
    marginTop: '5px',
    fontSize: '20px'
  },
  indicator: {
    display: "flex",
    justifyContent: 'center',
    textAlign: 'center',
    position: 'absolute',
    top: '40%',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    userSelect: 'none',
    fontSize: 'x-large',
  },
  progressType: {
    textAlign: 'center',
    width: '140px',
    marginBottom: '15px',
    fontSize: 'large',
    fontWeight: '500'
  }
};

export default dashboardStyle;
