import React, { useState, useEffect, useCallback } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import {
  customerProperties,
  getCustomerForm,
} from "../../../Util/Property/CustomerPorerties";
import axios from "../../../axios/axios-default";
import LoadingOverlay from "react-loading-overlay";
import { notyDefault, notyTypes } from "../../../components/Noty/NotyCustom";
import generalProperties from "Util/Property/GeneralProperties";
import { Typography } from "@material-ui/core";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisorAccountTwoTone";
import Map from "views/Maps/Map";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import ListAltOutlinedIcon from "@material-ui/icons/Room";
import styles from "./Customer.module.css";
import { getCustomerHistory } from "services/Customer/CustomerService";
import History from "views/History/History";
import { checkPermissoinListAvailable } from "Util/Permission/CheckPermission";
import PermissionProperties from "Util/Property/PermissionProperties";
import RouterProps from "../../../Util/Property/RouterProperties";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import Spinner from "components/Spinner/Spinner";
import { set } from "dot-object";
import { buildFilterObject } from "../../../Util/Util";
import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAW4tSeL4Q-C3Tls4PmYIk14PnydnTxMb0&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `200px` }} />,
    mapElement: <div style={{ height: `650px` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};
      this.setState({
        position: null,
        lat: "",
        lng: "",
        bounds: null,
        onMarkerMounted: (ref) => {
          refs.marker = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          });
        },
        // onSearchBoxMounted: ref => {
        //   refs.searchBox = ref;
        // },
        onPositionChanged: () => {
          const position = refs.marker.getPosition();
          console.log(position.toString());
        },
        onDrag: (e) => {
          // this.setState({ lat: e.latLng.lat });
          // this.setState({ lng: e.latLng.lng });
          this.props.latitudeChange(e.latLng.lat());
          this.props.longitudeChange(e.latLng.lng());
          // console.log("latitude = ", e.latLng.lat());
          // console.log("longtitude = ", e.latLng.lng());
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={props.isEdit ? 20 : 12}
    zoom={props.zoom}
    defaultCenter={{ lat: 6.9271, lng: 79.8612 }}
    center={props.center}
  >
    <SearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
      onPlacesChanged={props.onPlacesChanged}
    >
      <input
        type="text"
        placeholder="Search places here"
        style={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `400px`,
          height: `35px`,
          padding: `5px 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
          position: "absolute",
          left: "50%",
          marginLeft: "10px",
          marginTop: "12px",
        }}
      />
    </SearchBox>
    {props.isMarkerShown && (
      <>
        <Marker
          position={
            props.latV && props.lngV
              ? { lat: props.latV, lng: props.lngV }
              : { lat: 6.9271, lng: 79.8612 }
          }
          draggable={true}
          ref={props.onMarkerMounted}
          onDrag={props.onDrag}
        />
      </>
    )}
  </GoogleMap>
));

Geocode.setApiKey("AIzaSyAW4tSeL4Q-C3Tls4PmYIk14PnydnTxMb0");
Geocode.setLanguage("en");
Geocode.setRegion("sc");

const Customer = (props) => {
  const [customerForm, setCustomerForm] = useState(getCustomerForm());
  const refs = {};
  const [id, setId] = useState(0);
  const [formIsValid, setFormIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customerAction, setCustomerAction] = useState(null);
  const [isMapDialogOpen, setIsMapDialogOpen] = useState(false);
  const [defaultLat, setDefaultLat] = useState(6.90546);
  const [defaultLng, setDefaultLng] = useState(79.85359);
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [suggesstionsCat1, setSuggestionsCat1] = useState([]);
  const [suggesstionsCat2, setSuggestionsCat2] = useState([]);
  const [cat1Val, setCat1Val] = useState(null);
  const [cat2Val, setCat2Val] = useState(null);
  const [isfirstClickCat1, setIsFirstClickCat1] = useState(true);
  const [isfirstClickCat2, setIsFirstClickCat2] = useState(true);
  const [cat1valDef, setCat1ValDef] = useState(null);
  const [cat2valDef, setCat2ValDef] = useState(null);
  const [defaultLocation, setDefaulltLoaction] = useState({
    lat: 6.90546,
    lng: 79.85359,
  });
  const [zoom, setZoom] = useState(12);

  const customerActions = {
    ADD: "ADD",
    EDIT: "EDIT",
    VIEW: "VIEW",
  };

  const [lat, setLat] = React.useState();
  // console.log("🚀 ~ Customer ~ lat:", lat);
  const [lon, setLon] = React.useState();
  // console.log("🚀 ~ Customer ~ lon:", lon);
  const latitudeChange = (lat) => {
    setLat(lat);
  };

  const longitudeChange = (lon) => {
    setLon(lon);
  };

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    let queryParam = {};
    for (let param of query.entries()) {
      queryParam[param[0]] = param[1];
    }
    if (queryParam["isEdit"]) {
      Geocode.fromAddress(customerForm.address.inputProps.value).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;

          setLat(lat);
          setLon(lng);
          setDefaulltLoaction({ lat: lat, lng: lng });
        },
        (error) => {
          console.log(error);
        }
      );
      console.log("ISDIT", true);
    }
  }, [customerForm]);

  const checkValidity = (value, rules) => {
    let returnObj = {
      isValid: true,
      errormessage: "",
    };
    if (typeof rules == "undefined") {
      return returnObj;
    }

    if (rules.required) {
      if (value.trim() === "") {
        returnObj.isValid = false;
        returnObj.errormessage = generalProperties.emptyField;
      }
    }

    if (rules.pattern) {
      if (typeof value !== "undefined" && value !== "") {
        if (!rules.pattern.test(value)) {
          returnObj.isValid = false;
          returnObj.errormessage = generalProperties.validEmail;
        }
      }
    }

    return returnObj;
  };

  const initializeLabelProps = (elementData) => {
    if (!elementData.labelProps) {
      elementData.labelProps = { error: false, errormessage: '' };
    }
  };
  
  const onChangeHandler = async (event, elId, isMap) => {
    let formData = { ...customerForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
  
    // Initialize labelProps if undefined
    initializeLabelProps(elementData);
  
    if (elementData.elType === "date") {
      inputProps.value = event.toDate();
    } else if (elementData.elType === "input" && elId === "address") {
      inputProps.value = isMap ? event : event.target.value;
    } else {
      inputProps.value = event.target.value;
    }
    if (elId === "customer_type") {
      let parentElData = formData["parent"]; // get data for form key
      let vehicleElData = formData["vehicle_user_id"]; // get data for form key
      let metadataElData = formData["metadata"]; // get data for form key
      let tidElData = formData["tid"]; // get data for form key
      let category2ElData = formData["category2"]; // get data for form key
      if (event.target.value === "PARENT") {
        parentElData.isHide = true;
        // vehicleElData.isHide = true;
        // category2ElData.isHide = true;
        // metadataElData.isHide = true;
        tidElData.label = "Distributor Id";
        parentElData.valid = true;
        parentElData.inputProps.options = [];
        vehicleElData.inputProps.options = [];
      } else {
        parentElData.isHide = false;
        // vehicleElData.isHide = false;
        // metadataElData.isHide = false; 
        // category2ElData.isHide = false;
        tidElData.label = "Outlet Id";
        let validityObj = checkValidity(
          parentElData.inputProps.value,
          parentElData.validations
        );
        parentElData.valid = validityObj.isValid;
      }
      formData["parent"] = parentElData;
    }
  
    if (elId === "parent") {
      formData = await getVehicleUsersByParentCustomer(
        formData
      );
    }
  
    if (elId === "province" && inputProps.value) {
      let districtData = formData["district"];
      let districtsInputProps = { ...districtData.inputProps };
      let districtArray = customerProperties.districts[inputProps.value];
      districtsInputProps.options = districtArray;
      districtsInputProps.value = districtArray[0].value;
      districtData.inputProps = districtsInputProps;
      formData["district"] = districtData;
    }
  
    if (elId !== "category2") {
      if (cat2Val === null || cat2Val === "") {
        setIsFirstClickCat2(true);
        setCat2Val(cat2valDef);
      }
    }
  
    console.log("🚀 ~ formData", inputProps);
    let validityObj = checkValidity(inputProps.value, elementData.validations);
    elementData.valid = validityObj.isValid;
    elementData.labelProps.error = !elementData.valid;
    elementData.labelProps.errormessage = validityObj.errormessage;
    elementData.touched = true;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    let isFormValid = true;
    for (let key in formData) {

      if(formData[key].inputProps.required && !formData[key].isHide){
        if(!formData[key].inputProps.value) {  
          isFormValid = false
        }
      }


      isFormValid = formData[key].valid && isFormValid;
    }
    setCustomerForm(formData);
    setFormIsValid(isFormValid);
  };

  const onFormSubmitHandler = (event) => {
    // setIsLoading(true);
    event.preventDefault();
    let formData = {};
    let url = "createCustomer";
    if (id > 0) {
      // eslint-disable-next-line no-self-assign
      url = "updateCustomer/" + id;
    }
    formData["id"] = id;
    for (let key in customerForm) {
      let parentValue = customerForm["customer_type"].inputProps.value;
      let inputPropValue = customerForm[key].inputProps.value;
      if (
        (key === "parent" && parentValue === "parent") ||
        inputPropValue === ""
      ) {
        continue;
      } else if (key === "parent" && parentValue === "CHILD") {
        let vals = inputPropValue.split("CBA_END");
        formData["parent_customer_id"] = parseInt(vals[0]);
        formData["parent_customer_name"] = vals[1];
      } else {
        formData[key] = inputPropValue;
      }
    }
    formData["latitude"] = lat;
    formData["longitude"] = lon;
    addCustomerCall(url, formData);
  };

  const setDefaultData = () => {
    let formData = { ...customerForm };
    let defaultData = { ...customerProperties.customerForm };
    for (let key in defaultData) {
      let elementData = formData[key];
      let inputProps = { ...elementData.inputProps };
      inputProps.value = defaultData[key].inputProps.initvalue;
      if (key === "district") {
        inputProps.options = customerProperties.districts["WESTERN"];
      }
      elementData.inputProps = inputProps;
      if (
        key !== "join_date" &&
        key !== "customer_type" &&
        key !== "parent"
      ) {
        elementData.valid = false;
        elementData.touched = false;
      }

      if (key === "parent") {
        elementData.isHide = true;
      }
      formData[key] = elementData;
    }
    setCustomerForm(formData);
    setFormIsValid(false);
    setIsLoading(false);
  };

  // const searchCategory1 = (inputVal) => {
  //   axios
  //     .get("/searchCustomersCategory1?search-text=" + inputVal)
  //     .then((result) => {
  //       setIsFirstClickCat1(false);

  //       if (cat2Val === null || cat2Val === "") {
  //         setIsFirstClickCat2(true);
  //         setCat2Val(cat2valDef);
  //       }

  //       if (result.status === 200) {
  //         let cat1Options = [];
  //         result.data.map((cat1) =>
  //           cat1Options.push({ title: cat1, displayValue: cat1 })
  //         );
  //         setSuggestionsCat1(cat1Options);
  //         setCat1Val(inputVal);
  //       } else {
  //         notyDefault({
  //           type: notyTypes.ERROR,
  //           text: customerProperties.messages.error.loadData,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       notyDefault({
  //         type: notyTypes.ERROR,
  //         text: customerProperties.messages.error.loadData,
  //       });
  //     });
  // };

  const onSearchBoxMounted = (ref) => {
    refs.searchBox = ref;
  };
  const onPlacesChanged = () => {
    const places = refs.searchBox.getPlaces();
    const location = places[0].geometry.location;
    const lat = location.lat();
    const lng = location.lng();
    console.log("Selected Location:", { lat, lng });
    setZoom(20);
    setDefaulltLoaction({ lat: lat, lng: lng });

    setLat(lat);
    setLon(lng);
  };

  // const searchCategory2 = (inputVal) => {
  //   axios
  //     .get("/searchCustomersCategory2?search-text=" + inputVal)
  //     .then((result) => {
  //       setIsFirstClickCat2(false);

  //       if (cat1Val === null || cat1Val === "") {
  //         setIsFirstClickCat1(true);
  //         setCat1Val(cat1valDef);
  //       }

  //       if (result.status === 200) {
  //         let cat2Options2 = [];
  //         result.data.map((cat2) =>
  //           cat2Options2.push({ title: cat2, displayValue: cat2 })
  //         );
  //         setSuggestionsCat2(cat2Options2);
  //         setCat2Val(inputVal);
  //       } else {
  //         notyDefault({
  //           type: notyTypes.ERROR,
  //           text: customerProperties.messages.error.loadData,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       notyDefault({
  //         type: notyTypes.ERROR,
  //         text: customerProperties.messages.error.loadData,
  //       });
  //     });
  // };

  const addCustomerCall = (url, customerData) => {
    let method = "POST";
    if (customerAction === customerActions.EDIT) {
      method = "PUT";
    }
    axios({
      method: method,
      url: url,
      data: customerData,
    })
      .then((result) => {
        if (customerAction === customerActions.EDIT) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: customerProperties.messages.success.saveData,
          });
          setIsLoading(false);
          props.history.push(RouterProps.mainDomain + "/customers");
        } else {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: customerProperties.messages.success.saveData,
          });
          setDefaultData();
          props.history.goBack();
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: customerProperties.messages.error.saveData,
        });
        setIsLoading(false);
        setCustomerForm({ ...customerProperties.customerForm });
      });
  };

  const processData = async (result, customerAction) => {
    let formData = { ...customerForm };
    for (let key in formData) {
      let elementData = formData[key];
      let inputProps = { ...elementData.inputProps };
      if (result.data[key]) {
        inputProps.value = result.data[key];
      } else {
        inputProps.value = "";
      }
      if (key === "district") {
        inputProps.options =
          customerProperties.districts[formData["province"].inputProps.value];
      }
      if (key === "parent") {
        if (formData["customer_type"].inputProps.value === "PARENT") {
          elementData.isHide = true;
          let customerTypeData = formData["customer_type"];
          let customerTypeProps = { ...customerTypeData.formControlProps };
          customerTypeProps["disabled"] = true;
          customerTypeData.formControlProps = customerTypeProps;
          formData["customer_type"] = customerTypeData;
        } else {
          elementData.isHide = false;
          await axios
            .get("/getParentsByName/" + result.data["parent_customer_name"])
            .then((result) => {
              if (result.status === 200) {
                if (result?.data?.length > 0) {
                  let customerOptions = [];
                  result.data.map((cust) =>
                    customerOptions.push({
                      value: cust.id + "CBA_END" + cust.name,
                      displayValue: cust.name,
                    })
                  );
                  if (customerOptions?.length > 0) {
                    let parent = formData["parent"];
                    let inputProps = parent.inputProps;
                    inputProps.options = customerOptions;
                    inputProps.value = customerOptions[0].value;
                    inputProps.initvalue = customerOptions[0].value;
                    parent.inputProps = inputProps;
                    formData["parent"] = parent;
                  }
                }
                setIsLoading(false);
              } else {
                setIsLoading(false);

                notyDefault({
                  type: notyTypes.ERROR,
                  text: result.data
                    ? result.data.message
                    : customerProperties.messages.error.loadData,
                });
              }
            })
            .catch((error) => {
              setIsLoading(false);

              notyDefault({
                type: notyTypes.ERROR,
                text: customerProperties.messages.error.loadData,
              });
            });
          inputProps.value =
            result.data["parent_customer_id"] +
            "CBA_END" +
            result.data["parent_customer_name"];
          inputProps.tempValue = result.data["parent_customer_name"];
        }
      }
      elementData.valid = true;
      elementData.inputProps = inputProps;

      formData[key] = elementData;
    }
    setIsLoading(true);
    if (result.data["category1"] !== null || result.data["category1"] !== " ") {
      setCat1Val(result.data["category1"]);
      setCat1ValDef(result.data["category1"]);
    }
    if (result.data["category2"] !== null || result.data["category2"] !== " ") {
      setCat2Val(result.data["category2"]);
      setCat2ValDef(result.data["category2"]);
    }
    setIsLoading(false);

    let lat = result.data["latitude"];
    let long = result.data["longitude"];
    if (typeof lat === "undefined" || lat === null || lat === 0) {
      lat = defaultLat;
      long = defaultLng;
    }
    let permissionArray = [
      PermissionProperties.viewHistory,
      PermissionProperties.addCustomer,
      PermissionProperties.editCustomer,
    ];
    let perms = checkPermissoinListAvailable(permissionArray);
    setCustomerForm(formData);
    setId(result.data.id);
    setIsLoading(false);
    setFormIsValid(true);
    setCustomerAction(customerAction);
    setDefaultLat(lat);
    setDefaultLng(long);
    setPermissions(perms);
  };

  // const getParentCustomers = async () => {
  //   await axios
  //     .get("/getAllParents")
  //     .then((result) => {
  //       if (result?.data?.length > 0) {
  //         let customerOptions = [];
  //         result.data.map((cust) =>
  //           customerOptions.push({
  //             value: cust.id + "CBA_END" + cust.name,
  //             displayValue: cust.name,
  //           })
  //         );
  //         if (customerOptions?.length > 0) {
  //           setParentDropDownValues(
  //             customerOptions,
  //             customerOptions[0].value,
  //             customerOptions[0].value
  //           );
  //         }
  //       }
  //     })
  //     .catch((error) =>
  //       notyDefault({
  //         type: notyTypes.ERROR,
  //         text: customerProperties.messages.error.loadData,
  //       })
  //     );
  // };

  const getVehicleUsersByParentCustomer = async ( data) => {
    let formdata = data;
    setIsLoading(true);
      await axios
        .get("/getVehicleUsers")
        .then((result) => {
          if (result.status === 200) {
            if (result?.data?.length > 0) {
              let userVhicleOptions = [];
              result.data.map((cust) =>
                userVhicleOptions.push({
                  value: cust.id.toString(),
                  displayValue: cust.first_name + " " + cust.last_name,
                })
              );
              if (userVhicleOptions?.length > 0) {
                let formData = { ...customerForm };
                let vehicle_user_id = { ...formData.vehicle_user_id };
                let inputProps = { ...vehicle_user_id.inputProps };
                inputProps.options = userVhicleOptions;
                inputProps.value = null;
                vehicle_user_id.inputProps = inputProps;
                formData.vehicle_user_id = vehicle_user_id;
                formdata = formData;
                setCustomerForm(formData)

              }
            }
            setIsLoading(false);
          } else {
            setIsLoading(false);
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : customerProperties.messages.error.loadData,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);

          notyDefault({
            type: notyTypes.ERROR,
            text: customerProperties.messages.error.loadData,
          });
        });
    setIsLoading(false);
    return formdata;
  };

  const searchParentCustomers = async (element) => {
    const value = element.config.inputProps.tempValue;
    if (value?.length > 0) {
      setIsLoading(true);

      await axios
        .get("/getParentsByName/" + value)
        .then((result) => {
          if (result.status === 200) {
            if (result?.data?.length > 0) {
              let customerOptions = [];
              result.data.map((cust) =>
                customerOptions.push({
                  value: cust.id + "CBA_END" + cust.name,
                  displayValue: cust.name,
                })
              );
              if (customerOptions?.length > 0) {
                setParentDropDownValues(
                  customerOptions,
                  customerOptions[0].value,
                  customerOptions[0].value
                );
              }
            }
            setIsLoading(false);
          } else {
            setIsLoading(false);

            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : customerProperties.messages.error.loadData,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);

          notyDefault({
            type: notyTypes.ERROR,
            text: customerProperties.messages.error.loadData,
          });
        });
      element.config.inputProps.tempValue = "";
    }
  };

  const setParentDropDownValues = (customerOptions, value, defaultValue) => {
    let formData = { ...customerForm };
    let parent = { ...formData.parent };
    let inputProps = { ...parent.inputProps };
    inputProps.options = customerOptions;
    inputProps.value = null;
    inputProps.initvalue = null;
    parent.inputProps = inputProps;
    formData.parent = parent;
    setCustomerForm(formData);

    // if (parent.inputProps.value != null && parent.inputProps.value != '') {
    //   getVehicleUsersByParentCustomer(inputProps.value);
    // }
  };

  const customersHandler = () => {
    let val = props.match.url;
    val = val.split("/addCustomer")[0];
    props.history.push(val);
  };

  const mapDialogCloseHandler = () => {
    setIsMapDialogOpen(false);
  };

  const mapDialogOpenHandler = (id) => {
    setIsMapDialogOpen(true);
  };

  const mapConfirmHandler = (id) => {
    // updateUserImage(saveImage);
    Geocode.fromLatLng(parseFloat(lat), parseFloat(lon)).then(
      (response) => {
        const address = response.results[0].formatted_address;
        // setCustomLat(lat);
        // setCustomLng(lng);
        onChangeHandler(String(address), "address", true);
      },
      (error) => {
        console.log(error);
      }
    );
    setIsMapDialogOpen(false);
  };

  const placeSelectHandler = (lat, lng) => {
    setDefaultLat(lat);
    setDefaultLng(lng);
  };

  const historyDialogCloseHandler = () => {
    setIsHistoryDialogOpen(false);
  };

  const historyDialogOpenHandler = () => {
    setIsHistoryDialogOpen(true);
  };

  const onSelectCat1 = (Cat1event) => {
    setCat1Val(Cat1event);
    setIsFirstClickCat1(false);

    if (cat2Val === null || cat2Val === "") {
      setIsFirstClickCat2(true);
      setCat2Val(cat2valDef);
    }
  };

  const onSelectCat2 = (Cat2event) => {
    setCat2Val(Cat2event);
    setIsFirstClickCat2(false);

    if (cat1Val === null || cat1Val === "") {
      setIsFirstClickCat1(true);
      setCat1Val(cat1valDef);
    }
  };

  const initiateData = async () => {
    const query = new URLSearchParams(props.location.search);
    let queryParam = {};
    for (let param of query.entries()) {
      queryParam[param[0]] = param[1];
    }

    getVehicleUsersByParentCustomer()

    if (typeof queryParam["id"] !== "undefined") {
      let customerAction = customerActions.VIEW;
      if (queryParam["isEdit"] === "true") {
        customerAction = customerActions.EDIT;
      }
      await axios
        .get("/getCustomer/" + queryParam["id"])
        .then(async (result) => {
          let formData = { ...customerForm };
          for (let key in formData) {
            let elementData = formData[key];
            let inputProps = { ...elementData.inputProps };
            if (result.data[key]) {
              inputProps.value = result.data[key];
            } else {
              inputProps.value = "";
            }
            if (key === "district" && formData["province"].inputProps.value) {
              inputProps.options =
                customerProperties.districts[
                  formData["province"].inputProps.value
                ];
            }
            let parentElData = formData["parent"]; // get data for form key
            let vehicleUserElement = formData["vehicle_user_id"];
            let metadataElData = formData["metadata"]; // get data for form key
            let tidElData = formData["tid"]; // get data for form key
            let category2ElData = formData["category2"]; // get data for form key
            if (key === "parent") {
              if (formData["customer_type"].inputProps.value === "PARENT") {
                elementData.isHide = true;
                vehicleUserElement.isHide = true;
                category2ElData.isHide = true;
                metadataElData.isHide = true;
                tidElData.label = "Distributor Id";
                parentElData.valid = true;
                parentElData.inputProps.options = [];
                vehicleUserElement.inputProps.options = [];
                let customerTypeData = formData["customer_type"];
                let customerTypeProps = {
                  ...customerTypeData.formControlProps,
                };
                customerTypeProps["disabled"] = true;
                customerTypeData.formControlProps = customerTypeProps;
                formData["customer_type"] = customerTypeData;
              } else {
                elementData.isHide = false;
                vehicleUserElement.isHide = false;
                parentElData.isHide = false;
                metadataElData.isHide = false;
                category2ElData.isHide = false;
                tidElData.label = "Outlet Id";
                await axios
                  .get(
                    "/getParentsByName/" + result.data["parent_customer_name"]
                  )
                  .then((result) => {
                    if (result.status === 200) {
                      if (result?.data?.length > 0) {
                        let customerOptions = [];
                        result.data.map((cust) =>
                          customerOptions.push({
                            value: cust.id + "CBA_END" + cust.name,
                            displayValue: cust.name,
                          })
                        );

                        if (customerOptions?.length > 0) {
                          inputProps.options = customerOptions;

                          inputProps.value = customerOptions[0].value;
                          inputProps.initvalue = customerOptions[0].value;
                        }
                      }
                    } else {
                      notyDefault({
                        type: notyTypes.ERROR,
                        text: result.data
                          ? result.data.message
                          : customerProperties.messages.error.loadData,
                      });
                    }
                  })
                  .catch((error) => {
                    setIsLoading(false);

                    notyDefault({
                      type: notyTypes.ERROR,
                      text: customerProperties.messages.error.loadData,
                    });
                  });
              }
            }

            if (key === "vehicle_user_id" && result.data["vehicle_user_id"]) {
              const vfData = await getVehicleUsersByParentCustomer(
                elementData
              );
              inputProps = vfData["vehicle_user_id"].inputProps;
              inputProps.value = result.data["vehicle_user_id"];
            }
            elementData.valid = true;
            elementData.inputProps = inputProps;
            formData[key] = elementData;
          }
          setIsLoading(true);
          if (
            result.data["category1"] !== null ||
            result.data["category1"] !== " "
          ) {
            setCat1Val(result.data["category1"]);
            setCat1ValDef(result.data["category1"]);
          }
          if (
            result.data["category2"] !== null ||
            result.data["category2"] !== " "
          ) {
            setCat2Val(result.data["category2"]);
            setCat2ValDef(result.data["category2"]);
          }
          setIsLoading(false);

          let lat = result.data["latitude"];
          let long = result.data["longitude"];
          setLat(lat);
          setLon(long);
          if (typeof lat === "undefined" || lat === null || lat === 0) {
            lat = defaultLat;
            long = defaultLng;
          }
          let permissionArray = [
            PermissionProperties.viewHistory,
            PermissionProperties.addCustomer,
            PermissionProperties.editCustomer,
          ];
          let perms = checkPermissoinListAvailable(permissionArray);
          setCustomerForm(formData);
          setId(result.data.id);
          setIsLoading(false);
          setFormIsValid(true);
          setCustomerAction(customerAction);
          setDefaultLat(lat);
          setDefaultLng(long);
          setPermissions(perms);
        })
        .catch((error) =>
          notyDefault({
            type: notyTypes.ERROR,
            text: customerProperties.messages.error.loadData,
          })
        );
    } else {
      if (
        window.location.pathname ===
        RouterProps.mainDomain + "/customer-dashboard"
      ) {
        const loggedInUserCustomerId = localStorage.getItem("userCustomerId");
        let customerAction = customerActions.VIEW;
        axios
          .get("/getCustomer/" + loggedInUserCustomerId)
          .then((result) => {
            processData(result, customerAction);
          })
          .catch((error) => {
            notyDefault({
              type: notyTypes.ERROR,
              text: customerProperties.messages.error.loadData,
            });
          });
      } else {
        setIsLoading(false);
        setCustomerAction(customerActions.ADD);
      }
    }
  };

  useEffect(() => {
    initiateData();
  }, []);

  let formElementArray = [];
  for (let key in customerForm) {
    formElementArray.push({
      id: key,
      config: customerForm[key],
    });
  }

  let submitButton = null;
  if (
    customerAction === customerActions.EDIT ||
    customerAction === customerActions.ADD
  ) {
    // console.log("🚀 ~ Customer ~ formIsValid:", formIsValid);
    submitButton = (
      <Button disabled={!formIsValid} type="submit" color="primary">
        Save
      </Button>
    );
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <Dialog
                maxWidth="lg"
                fullWidth={true}
                open={isMapDialogOpen}
                onClose={mapDialogCloseHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <div className={styles.map_container_height}>
                    {/* <Map
                      google={props.google}
                      center={{
                        lat: defaultLat,
                        lng: defaultLng,
                      }}
                      height="600px"
                      zoom={15}
                      placeHandler={placeSelectHandler}
                      marker={{ dragable: true }}
                      address={
                        customerForm.address.inputProps.value
                      } */}
                    <MyMapComponent
                      isMarkerShown={true}
                      latV={lat}
                      lngV={lon}
                      latitudeChange={latitudeChange}
                      longitudeChange={longitudeChange}
                      onSearchBoxMounted={onSearchBoxMounted}
                      onPlacesChanged={onPlacesChanged}
                      center={defaultLocation}
                      // zoom={isEdit ? 20 : zoom}
                      // isEdit={isEdit}
                      zoom={zoom}
                      isEdit={true}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    class="generic-button-class div-button-wrapper"
                    size="sm"
                    onClick={mapDialogCloseHandler}
                    color="primary"
                  >
                    {generalProperties.cancel}
                  </Button>
                  <Button
                    class="generic-button-class div-button-wrapper"
                    size="sm"
                    onClick={mapConfirmHandler}
                    color="primary"
                    autoFocus
                  >
                    {generalProperties.confirm}
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                maxWidth="lg"
                fullWidth={true}
                open={isHistoryDialogOpen}
                onClose={historyDialogCloseHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  {id > 0 ? (
                    <History method={getCustomerHistory} id={id} />
                  ) : (
                    ""
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    class="generic-button-class div-button-wrapper"
                    size="sm"
                    onClick={historyDialogCloseHandler}
                    color="primary"
                    autoFocus
                  >
                    {generalProperties.close}
                  </Button>
                </DialogActions>
              </Dialog>

              <div className="generic-form-container">
                <div className={"generic-form-header-wrapper"}>
                  {(customerAction === customerActions.VIEW ||
                    customerAction === customerActions.EDIT) &&
                  permissions[PermissionProperties.viewHistory] ? (
                    <div style={{ float: "right" }}>
                      <Button
                        class="generic-button-class div-button-wrapper"
                        onClick={historyDialogOpenHandler}
                        color="primary"
                      >
                        {generalProperties.history}
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <form onSubmit={onFormSubmitHandler} onKeyDown={handleKeyDown}>
                  {customerAction === customerActions.ADD ? (
                    <div className={"generic-form-header-wrapper"}>
                      <div className={"generic-page-title"}>
                        <span className={"generic-page-title-icon"}>
                          <SupervisedUserCircleIcon />
                        </span>
                        <Typography
                          className={"generic-header-text"}
                          variant="h6"
                          style={{ fontSize: "1rem", marginTop: "-0.7rem" }}
                        >
                          Add Customer
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <CardBody>
                    <GridContainer>
                      {formElementArray.map((element) => {
                        if (!element.config.isHide) {
                          let mdVal = 12;
                          if (!element.config.isFullWidth) {
                            mdVal = 6;
                          }
                          if (element.id === "customer_type") {
                            return
                          }
                          if (element.id === "address") {
                            return (
                              <GridItem
                                key={element.id}
                                xs={12}
                                sm={12}
                                md={12}
                              >
                                <GridContainer>
                                  <GridItem xs={10} sm={10} md={10}>
                                    <CustomInput
                                      labelText={element.config.label}
                                      id={element.id}
                                      formControlProps={
                                        element.config.formControlProps
                                      }
                                      inputProps={{
                                        ...element.config.inputProps,
                                        readOnly:
                                          customerAction ===
                                          customerActions.VIEW
                                            ? true
                                            : false,
                                      }}
                                      type={element.config.elType}
                                      value={element.config.value}
                                      changed={(event) =>
                                        onChangeHandler(event, element.id)
                                      }
                                      error={
                                        !element.config.valid &&
                                        element.config.touched
                                      }
                                      labelProps={element.config.labelProps}
                                    />
                                  </GridItem>
                                  <GridItem xs={2} sm={2} md={2}>
                                    <span
                                      className={styles.setting_block}
                                      title="Location on Map"
                                      onClick={mapDialogOpenHandler}
                                    >
                                      <ListAltOutlinedIcon />
                                    </span>
                                  </GridItem>
                                </GridContainer>
                              </GridItem>
                            );
                          } else {
                            return (
                              <GridItem
                                key={element.id}
                                xs={12}
                                sm={12}
                                md={mdVal}
                              >
                                <CustomInput
                                  labelText={element.config.label}
                                  id={element.id}
                                  formControlProps={
                                    element.config.formControlProps
                                  }
                                  inputProps={{
                                    ...element.config.inputProps,
                                    readOnly:
                                      customerAction === customerActions.VIEW
                                        ? true
                                        : false,
                                  }}
                                  type={element.config.elType}
                                  value={element.config.value}
                                  changed={(event) =>
                                    onChangeHandler(event, element.id)
                                  }
                                  onSelectInputChange={(event) => {
                                    element.config.inputProps.tempValue =
                                      event.target.value;
                                    if (
                                      event &&
                                      (event.key === "Enter" ||
                                        event.keyCode === 13)
                                    ) {
                                      if (element.id === "parent") {
                                        searchParentCustomers(element);
                                      }
                                    }
                                  }}
                                  onAdornmentClick={
                                    element.id === "parent"
                                      ? () => {
                                          searchParentCustomers(element);
                                        }
                                      : undefined
                                  }
                                  error={
                                    !element.config.valid &&
                                    element.config.touched
                                  }
                                  labelProps={element.config.labelProps}
                                />
                              </GridItem>
                            );
                          }
                        }
                      })}
                    </GridContainer>
                  </CardBody>
                  <CardFooter>{submitButton}</CardFooter>
                </form>
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    custsomerArray: state.customer.customers,
  };
};

const mapDispatchToProps = (dispath) => {
  return {
    addCustomerHandler: (customer) => dispath(actions.addCustomer(customer)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customer);
