import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import styles from './User.module.css';
import { userProperties, getUserForm } from 'Util/Property/UserProperties';
import GeneralProperties from 'Util/Property/GeneralProperties';
import LoadingOverlay from 'react-loading-overlay';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.js';
import { Form, Field } from 'react-final-form';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import axios from '../../../axios/axios-default';
import CustomInput from 'components/CustomInput/CustomInput.js';
import UrlProperties from 'Util/Property/UrlProperties';
import { Typography } from '@material-ui/core';
import ListAltOutlinedIcon from '@material-ui/icons/PeopleAlt';
import MapIcon from '@material-ui/icons/Room';
import Map from 'views/Maps/Map';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import generalProperties from 'Util/Property/GeneralProperties';
import Dialog from '@material-ui/core/Dialog';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';

const CustomerUser = (props) => {
  const [userForm, setUserForm] = useState(getUserForm());
  const [userHeading, setUserHeading] = useState(userProperties.addUser);
  const [userSubHeading, setUserSubHeading] = useState(
    userProperties.plsAddNewUser
  );
  const [initialData, setInitialData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem('userId'));
  const [userRole, setUserRole] = useState(localStorage.getItem('role'));
  const [isEdit, setIsEdit] = useState(false);
  const [previousUserName, setPreviousUserName] = useState('');
  const [isView, setIsView] = useState(false);
  const [isMapDialogOpen, setIsMapDialogOpen] = useState(false);
  const [defaultLocation, setDefaulltLoaction] = useState({
    defaultLat: 6.90546,
    defaultLng: 79.85359,
  });
  const [locationChanged, setLocationChanged] = useState(false);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    if (props.id && props.id > 0) {
      if (props.isEdit) {
        setIsEdit(true);
      } else {
        setIsView(true);
      }
      setUserHeading(userProperties.viewUser);
      setUserSubHeading(userProperties.userDetails);
      getUserData(props.id);
      setUserId(props.id);
    }
  }, [props.id]);

  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addUser,
      PermissionProperties.editUser,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  const getUserData = async (userId) => {
    setIsLoading(true);
    await axios
      .get(UrlProperties.user.getAllRoles)
      .then((result) => {
        if (result.status === 200) {
          let userRoleOptions = [];
          result.data.map((role) =>
            userRoleOptions.push({ value: role.id, displayValue: role.name })
          );
          let userFormData = { ...userForm };
          let roleData = userFormData['role'];
          let inputProps = { ...roleData.inputProps };
          inputProps.options = userRoleOptions;
          let defaultValue =
            userRoleOptions.length > 0 ? userRoleOptions[0].value : '';
          inputProps.value = defaultValue;
          inputProps.initvalue = defaultValue;
          roleData.inputProps = inputProps;
          userFormData['role'] = roleData;
          setUserForm(userFormData);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : userProperties.messages.error.userRoles,
          });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: userProperties.messages.error.userRoles,
        });
      });

    axios
      .get(UrlProperties.user.getUser + '/' + userId)
      .then((result) => {
        let formData = { ...userForm };
        let initData = {};

        setUserHeading(result.data.first_name + ' ' + result.data.last_name);

        for (let key in formData) {
          let elementData = formData[key]; // get data for form key
          let inputProps = { ...elementData.inputProps }; //  copy input data
          if (key === 'password') {
            elementData.isHide = true;
          }
          if (result.data[key]) {
            if (key === 'role') {
              inputProps.value = result.data[key].id; // here filter returns an array
              initData[key] = result.data[key].id;
            } else {
              initData[key] = result.data[key];
              inputProps.value = result.data[key]; // here filter returns an array
            }
          } else {
            initData[key] = '';
            inputProps.value = '';
          }
          elementData.inputProps = inputProps;
          formData[key] = elementData;
          setUserForm(formData);
        }
        if (
          typeof result.data['latitude'] !== 'undefined' &&
          result.data['latitude'] !== null &&
          result.data['latitude'] !== 0
        ) {
          let defalutLoc = {
            defaultLat: result.data['latitude'],
            defaultLng: result.data['longitude'],
          };
          setDefaulltLoaction(defalutLoc);
        }
        setInitialData(initData);

        setIsLoading(false);
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: userProperties.messages.error.loadData,
        });
        setIsLoading(false);
      });
  };

  const getRolesForUser = () => {
    setIsLoading(true);
    axios
      .get(UrlProperties.user.getAllChildRoles + '/' + userRole)
      .then((result) => {
        if (result.status === 200) {
          let userRoleOptions = [];
          result.data.map((role) =>
            userRoleOptions.push({ value: role.id, displayValue: role.name })
          );

          let userFormData = { ...userForm };
          let roleData = userFormData['role'];
          let inputProps = { ...roleData.inputProps };
          inputProps.options = userRoleOptions;
          let defaultValue =
            userRoleOptions.length > 0 ? userRoleOptions[0].value : '';
          inputProps.value = defaultValue;
          inputProps.initvalue = defaultValue;
          roleData.inputProps = inputProps;
          userFormData['role'] = roleData;
          setUserForm(userFormData);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : userProperties.messages.error.userRoles,
          });
        }
        setDefaultData();
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: userProperties.messages.error.userRoles,
        });
      });
  };

  const onChangeHandeler = (event, elId) => {
    let formData = { ...userForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = event.target.value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    if (elId === 'user_name') {
      setPreviousUserName(inputProps.value);
    }
    setUserForm(formData);
  };

  const onUserSubmit = async (values) => {
    let method = '';
    let url = '';
    let data = { ...values };
    data['latitude'] = defaultLocation.defaultLat;
    data['longitude'] = defaultLocation.defaultLng;
    if (userId > 0) {
      data['id'] = userId;
      method = 'PUT';
      url = 'updateUser/' + userId;
    } else {
      data['id'] = 0;
      method = 'POST';
      url = 'createUser';
    }

    setIsLoading(true);
    await axios({
      method: method,
      url: url,
      data: data,
    })
      .then((result) => {
        //this.props.history.goBack();
        notyDefault({
          type: notyTypes.SUCCESS,
          text: userProperties.messages.success.saveData,
        });
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: userProperties.messages.error.saveData,
        });
      });
    setIsLoading(false);
  };

  const setDefaultData = () => {
    let formData = { ...userForm };
    let defaultData = { ...userProperties.userForm };
    for (let key in defaultData) {
      let elementData = formData[key]; // get data for form key
      let inputProps = { ...elementData.inputProps }; //  copy input data
      inputProps.value = defaultData[key].inputProps.initvalue;
      elementData.inputProps = inputProps;
      if (key === 'password') {
        elementData.isHide = false;
      }
      formData[key] = elementData;
    }
    setUserForm(formData);
  };

  const validateUsername = async (value) => {
    if (
      (isEdit && initialData.user_name === value) ||
      value === previousUserName
    ) {
      return undefined;
    }

    if (!value) {
      return GeneralProperties.emptyField;
    }

    let validation = '';
    await axios
      .get('isUserExists?userName=' + value)
      .then((result) => {
        if (result.data.isExist) {
          validation = userProperties.messages.error.userNameTaken;
        }
      })
      .catch((error) => {
        // notyDefault({type:notyTypes.ERROR, text:customerProperties.messages.error.deleteCustomer});
      });
    if (validation !== '') {
      return validation;
    }
  };

  const checkForEmpltyFields = (value, values) => {
    let val = 0;
  };

  const validateEmail = (value) => {
    if (!value) {
      return GeneralProperties.emptyField;
    }
    if (!userProperties.emailPatern.test(value)) {
      return GeneralProperties.validEmail;
    }
  };

  const mockValidation = (values) => {};

  const getValidationFunction = (validations) => {
    let functionName = '';
    if (!validations) {
      return mockValidation;
    } else {
      functionName = validations[0];
    }
    if (functionName === 'checkForEmpltyFields') {
      return checkForEmpltyFields;
    } else if (functionName === 'validateUsername') {
      return validateUsername;
    } else if (functionName === 'validateEmail') {
      return validateEmail;
    } else {
      return mockValidation;
    }
  };

  const goBackHandler = () => {
    props.history.goBack();
  };

  let formElementArray = [];
  const excludeFields = ['role', 'status', 'customer_id'];
  for (let key in userForm) {
    if (!excludeFields.includes(key)) {
      formElementArray.push({
        id: key,
        config: userForm[key],
      });
    }
  }

  const mapDialogOpenHandler = (id) => {
    setIsMapDialogOpen(true);
  };

  const mapDaialogCloseHandler = () => {
    setIsMapDialogOpen(false);
  };

  const mapConfrimHandler = (id) => {
    mapDaialogCloseHandler();
  };

  const placeSelectHandler = (lat, lng) => {
    let location = { ...defaultLocation };
    location.defaultLat = lat;
    location.defaultLng = lng;
    setDefaulltLoaction(location);
    setLocationChanged(true);
  };

  return (
    <div>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <Dialog
                maxWidth="lg"
                fullWidth={true}
                open={isMapDialogOpen}
                onClose={mapDaialogCloseHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <div className={styles.map_container_height}>
                    <Map
                      google={props.google}
                      center={{
                        lat: defaultLocation.defaultLat,
                        lng: defaultLocation.defaultLng,
                      }}
                      height="600px"
                      zoom={15}
                      placeHandler={placeSelectHandler}
                      marker={{ dragable: true }}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    size="sm"
                    onClick={mapDaialogCloseHandler}
                    color="primary"
                  >
                    {generalProperties.cancel}
                  </Button>
                  <Button
                    size="sm"
                    onClick={mapConfrimHandler}
                    color="primary"
                    autoFocus
                  >
                    {generalProperties.confirm}
                  </Button>
                </DialogActions>
              </Dialog>
              <div className="generic-form-container">
                <div className={'generic-form-header-wrapper'}>
                  <div className={'generic-page-title'}>
                    <span className={'generic-page-title-icon'}>
                      <ListAltOutlinedIcon />
                    </span>
                    <Typography
                      className={'generic-header-text'}
                      variant="h6"
                      style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                    >
                      {userHeading}
                    </Typography>
                    {/* <Typography variant="body1">{userSubHeading}</Typography> */}
                  </div>
                </div>

                <Form
                  onSubmit={onUserSubmit}
                  initialValues={initialData}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    valid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <CardBody>
                        <GridContainer>
                          {formElementArray.map((element) => {
                            if (!element.config.isHide) {
                              let mdVal = 12;
                              if (!element.config.isFullWidth) {
                                mdVal = 6;
                              }

                              return (
                                <GridItem
                                  key={element.id}
                                  xs={12}
                                  sm={12}
                                  md={mdVal}
                                >
                                  <Field
                                    name={element.id}
                                    validate={getValidationFunction(
                                      element.config.validations
                                    )}
                                  >
                                    {({ input, meta, options, value }) => (
                                      <div style={{ position: 'relative' }}>
                                        {element.id === 'address' ? (
                                          <GridContainer>
                                            <GridItem xs={10} sm={10} md={10}>
                                              <CustomInput
                                                labelText={element.config.label}
                                                id={element.id}
                                                inputProps={{
                                                  ...input,
                                                  ...element.config.inputProps,
                                                  readOnly: true,
                                                }}
                                                type={element.config.elType}
                                                formControlProps={
                                                  element.config
                                                    .formControlProps
                                                }
                                                changed={(event, value) => {
                                                  input.onChange(event);
                                                  onChangeHandeler(
                                                    event,
                                                    element.id
                                                  );
                                                }}
                                                labelProps={{
                                                  ...element.config.labelProps,
                                                  error:
                                                    meta.error && meta.touched,
                                                }}
                                              />
                                            </GridItem>
                                            <GridItem xs={2} sm={2} md={2}>
                                              <span
                                                className={styles.setting_block}
                                                title="Loacation on Map"
                                                onClick={mapDialogOpenHandler}
                                              >
                                                <MapIcon />
                                              </span>
                                            </GridItem>
                                          </GridContainer>
                                        ) : (
                                          <CustomInput
                                            labelText={element.config.label}
                                            id={element.id}
                                            inputProps={{
                                              ...input,
                                              ...element.config.inputProps,
                                              readOnly: true,
                                            }}
                                            type={element.config.elType}
                                            formControlProps={
                                              element.config.formControlProps
                                            }
                                            changed={(event, value) => {
                                              input.onChange(event);
                                              onChangeHandeler(
                                                event,
                                                element.id
                                              );
                                            }}
                                            labelProps={{
                                              ...element.config.labelProps,
                                              error: meta.error && meta.touched,
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </GridItem>
                              );
                            }
                          })}
                        </GridContainer>
                      </CardBody>
                      <CardFooter>
                        {!isView &&
                        (permissions[PermissionProperties.addUser] ||
                          permissions[PermissionProperties.editUser]) ? (
                          <Button
                            type="submit"
                            disabled={
                              (submitting || !valid || pristine) &&
                              !locationChanged
                            }
                            color="primary"
                            autoFocus
                          >
                            {GeneralProperties.save}
                          </Button>
                        ) : (
                          ''
                        )}
                      </CardFooter>
                    </form>
                  )}
                />
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default CustomerUser;
